import React, { useEffect, useState } from "react";
import { Box, Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import useGetAllAddress from "../../../buying/hooks/addAddress/useGetAllAddress";
import AllAddresses from "./AllAddresses/AllAddresses";
import AddAddressModal from "../../../buying/components/addAddress/modals/AddAddressModal";
import { cartPageBackdrop } from "../../../../store/atoms/addToCart/addToCart";
import { useSetRecoilState } from "recoil";
import MuiBackdrop from "../../../../muicomponents/shared/MuiBackdrop";

const Address = () => {
  const theme = useTheme();
  const userId = localStorage.getItem("userId");
  const [modalOpen, setModalOpen] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const { allAddress, loading, error, refetch } = useGetAllAddress(userId);
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setBackdropOpen(loading);
  }, [loading]);

  if (error) {
    return <Box>Error: {error.message}</Box>;
  }

  return (
    <>
      <MuiBackdrop />
      {!loading && (
        <>
          {" "}
          <Box
            sx={{
              minHeight: "calc(100vh - 115px)",
              width: "100%",
              backgroundColor: isSmallScreen ? "white" : "#D1E5E7",
              borderRadius: "1rem",
            }}
          >
            <Box
              sx={{
                padding: isSmallScreen ? "0.75rem" : "1.5rem",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                  <CustomTypography
                    variant={"subtitle1"}
                    sx={{ fontWeight: "600" }}
                  >
                    Saved Addresses
                  </CustomTypography>
                </Box>
                <Box>
                  <Button
                    onClick={() => setModalOpen(true)}
                    variant="outlined"
                    sx={{
                      border: "1px solid black",
                      color: "black",
                      "&:hover": {
                        border: "1px solid black",
                      },
                    }}
                  >
                    + Add new Address
                  </Button>
                </Box>
              </Box>
              <Box>
                <Box>
                  {allAddress && allAddress.length > 0 ? (
                    <AllAddresses addresses={allAddress} />
                  ) : (
                    <>No Address Found</>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <AddAddressModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </>
      )}
    </>
  );
};

export default Address;
