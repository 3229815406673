import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ResponsiveContainer from "../../../../muicomponents/shared/ResponsiveConatiner";
import Banner from "./Banner/Banner";
import Services from "./services/Services";
import SellingAccessories from "./sellingAccessories/SellingAccessories";
import PrelovedGadgets from "./prelovedGadgets/PrelovedGadgets";
import CorporateVendorOptions from "./corporateVendor/CorporateVendorOptions";
import SellingGuide from "./sellingGuide/SellingGuide";
import HowWeWork from "./howwework/HowWeWork";
import WhyPeopleTrustUs from "./whyTrustUsSection/WhyPeopleTrustUs";
import FeaturePointsHome from "./featurePoints/FeaturePointsHome";
import PrelovedElectronicGadgets from "./prelovedElectronicGadgets/PrelovedElectronicGadgets";
import TrendingGadgets from "./trendingGadgets/TrendingGadgets";
import Testimonials from "./testimonials/Testimonials";
import ValuableImpressions from "./valuableImpressions/ValuableImpressions";

const Home = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <ResponsiveContainer
      sx={{
        minHeight: "calc(100vh - 86px)",
        padding: "1rem 0",
        minWidth: "300px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: {
            mobile: "1.25rem",
            tablet: "1.25rem",
            laptop: "1.25rem",
            desktop: "1.25rem",
          },
        }}
      >
        <Banner />
        <Services />
        <SellingAccessories />
        <PrelovedGadgets />
        <CorporateVendorOptions />
        <SellingGuide />
        <HowWeWork />
        <WhyPeopleTrustUs />
        <FeaturePointsHome />
        <PrelovedElectronicGadgets />
        <TrendingGadgets />
        <Testimonials />
        <ValuableImpressions />
      </Box>
    </ResponsiveContainer>
  );
};

export default Home;
