import React from "react";
import { Box } from "@mui/material";
import AllTrendingGadgets from "./AllTrendingGadgets";
import HomePageContainer from "../shared/HomePageContainer";
import Heading from "../shared/Heading";

const TrendingGadgets = () => {
  return (
    <HomePageContainer sx={{ margin: { xs: "0 0.5rem", laptop: 0 } }}>
      <Heading text={"Trending Gadgets"} />
      <AllTrendingGadgets />
    </HomePageContainer>
  );
};

export default TrendingGadgets;
