import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import Mobiles from "../../../../../Images/slider/mobiles.JPEG";
import AppleLaptops from "../../../../../Images/slider/AppleLaptops.JPEG";
import Tablets from "../../../../../Images/slider/Tablets.JPEG";
import Laptop from "../../../../../Images/slider/Laptop.JPEG";
import Watches from "../../../../../Images/slider/Watches.JPEG";
import Others from "../../../../../Images/slider/Others.JPEG";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import PrelovedGadgetCard from "./PrelovedGadgetCard";

const PrelovedGadgets = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const swiperRef = useRef(null);

  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const prelovedGadgets = [
    {
      img: Mobiles,
      title: "Apple iPhones and Other Brands",
      description1: `UPTO 50% OFF`,
      description2: "Easy EMI options available.",
      description3: "With Added Accessories.",
      link: "/buying-products/Phones",
    },
    {
      img: AppleLaptops,
      title: "Apple MacBook",
      description1: `UPTO 50% OFF`,
      description2: "Easy EMI options available.",
      description3: "With Added Accessories.",
      link: "/buying-product-filter/2/Laptops/APPLE",
    },
    {
      img: Tablets,
      title: "Apple iPads and Other Tablets",
      description1: `UPTO 50% OFF`,
      description2: "Easy EMI options available.",
      description3: "With Added Accessories.",
      link: "/buying-products/Tablets",
    },
    {
      img: Laptop,
      title: "Gaming Laptops and Others",
      description1: `UPTO 50% OFF`,
      description2: "Easy EMI options available.",
      description3: "With Added Accessories.",
      link: "/buying-products/Laptops",
    },
    {
      img: Watches,
      title: "Best Smartwatches",
      description1: `UPTO 50% OFF`,
      description2: "Easy EMI options available.",
      description3: "With Added Accessories.",
      link: "/buying-products/Smart Watches",
    },
    {
      img: Others,
      title: "Other Gadgets you may love",
      description1: `Special Discount Offer.`,
      description2: "Easy EMI options available.",
      description3: "",
      link: "/buying-products/Other Accessories/",
    },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        borderRadius: isMobileScreen ? "0" : "1rem",
        minHeight: "375px",
        width: "100%",
        gap: "0.5rem",
        background:
          "radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0.8), rgba(117, 188, 196, 0.7), rgba(221, 226, 235, 0.6))",
        position: "relative",
      }}
    >
      <Box sx={{ padding: "1rem 2rem" }}>
        <CustomTypography
          variant="subtitle1"
          sx={{
            color: "#214C7B",
            fontWeight: "600",
            fontSize: {
              mobile: "1.125rem",
              tablet: "1.25rem",
              laptop: "1.5rem",
              desktop: "1.5rem",
            },
          }}
        >
          Preloved Electronic Gadgets <br /> TO BUY
        </CustomTypography>
      </Box>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          padding: {
            xs: "0 0 1rem 0",
            desktop: "0 2rem 1.5rem 2rem",
          },
        }}
      >
        <Swiper
          ref={swiperRef}
          modules={[Navigation, Pagination]}
          pagination={{
            clickable: true,
            el: ".peg-swiper-pagination-custom",
          }}
          navigation={{
            nextEl: ".peg-swiper-button-next",
            prevEl: ".peg-swiper-button-prev",
          }}
          loop={true}
          centeredSlides={true}
          slidesPerView={1.2}
          breakpoints={{
            0: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
            600: {
              slidesPerView: 2.5,
              spaceBetween: 20,
            },

            1025: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          className="preloved-electronic-gadgets"
          style={{
            width: "100%",
            "--swiper-navigation-color": "#000",
            "--swiper-pagination-color": "#214C7B",
          }}
        >
          {prelovedGadgets.map((gadget, index) => (
            <SwiperSlide key={index}>
              <PrelovedGadgetCard {...gadget} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="peg-swiper-pagination-custom" />
        <Box
          className="peg-swiper-controls"
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            zIndex: 10,
            top: "50%",
            transform: "translateY(-50%)",
            left: 0,
            right: 0,
            padding: "0 2rem",
          }}
        >
          <button
            className="peg-swiper-button-prev"
            aria-label="Previous"
            style={{ border: "2px solid grey" }}
            onClick={handlePrevClick}
          >
            <ArrowBackIosNew fontSize="medium" sx={{ color: "grey" }} />
          </button>
          <button
            className="peg-swiper-button-next"
            aria-label="Next"
            style={{ border: "2px solid grey" }}
            onClick={handleNextClick}
          >
            <ArrowForwardIos fontSize="medium" sx={{ color: "grey" }} />
          </button>
        </Box>
      </Box>
      <style jsx>{`
        .preloved-electronic-gadgets .swiper-slide .preloved-gadget-card {
          transform: scale3d(0.95, 0.95, 1);
          transition: all 0.3s ease;
        }
        @media (min-width: 600px) and (max-width: 821px) {
          .preloved-electronic-gadgets .swiper-slide .preloved-gadget-card {
            transform: scale3d(0.9, 0.9, 1);
          }
        }
        @media (min-width: 821px) {
          .preloved-electronic-gadgets .swiper-slide .preloved-gadget-card {
            transform: scale3d(0.85, 0.85, 1);
          }
        }

        .preloved-electronic-gadgets
          .swiper-slide-active
          .preloved-gadget-card {
          transform: scale3d(1, 1, 1);
          z-index: 1;
        }
        .preloved-electronic-gadgets {
          width: 100% !important;
        }

        .preloved-electronic-gadgets .swiper-slide {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .preloved-electronic-gadgets .swiper-slide .preloved-gadget-card {
          transition: all 0.3s ease;
          width: 100%;
        }

        .preloved-electronic-gadgets
          .swiper-slide-active
          .preloved-gadget-card {
          z-index: 1;
        }

        .peg-swiper-pagination-custom {
          margin-top: 1rem;
          display: flex;
          justify-content: center;
        }

        .peg-swiper-pagination-custom .swiper-pagination-bullet {
          background-color: #214c7b;
          opacity: 0.6;
          width: 8px;
          height: 8px;
          margin: 0 5px;
          transition: opacity 0.3s;
        }

        .peg-swiper-pagination-custom .swiper-pagination-bullet-active {
          opacity: 1;
        }

        .peg-swiper-button-prev,
        .peg-swiper-button-next {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: rgba(255, 255, 255, 0.8);
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid grey;
          transition: opacity 0.3s;
          z-index: 10;
          cursor: pointer;
        }

        .peg-swiper-button-prev {
          left: -25px;
        }

        .peg-swiper-button-next {
          right: -25px;
        }

        @media (max-width: 1025px) {
          .peg-swiper-button-prev,
          .peg-swiper-button-next {
            display: none;
          }
        }

        @media (max-width: 600px) {
          .preloved-electronic-gadgets {
            padding: 10px !important;
          }
        }
      `}</style>
    </Box>
  );
};

export default PrelovedGadgets;
