import {
  Box,
  Button,
  IconButton,
  Modal,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Close } from "@mui/icons-material";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import useDeleteAddress from "../../../../buying/hooks/addAddress/useDeleteAddress";

const AddressCloseModal = ({
  address,
  modalOpen,
  setModalOpen,
  modalTitle,
  modalDescription,
}) => {
  const { deleteAddressById } = useDeleteAddress();
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleDeleteAddress = async () => {
    handleModalClose();
    await deleteAddressById(address.id, address.user);
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const style = {
    position: "fixed",
    top: isSmallScreen ? "" : "50%",
    left: isSmallScreen ? "0" : "50%",
    bottom: isSmallScreen ? "0" : "",
    minWidth: isSmallScreen ? "100%" : "400px",
    transform: isSmallScreen ? "" : "translate(-50%, -50%)",
    bgcolor: "white",
    border: "none",
    borderRadius: "0.2rem",
    boxShadow: 24,
    p: isSmallScreen ? 2 : 4,
    gap: "1rem",
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ position: "relative" }}>
          <IconButton
            sx={{
              position: "absolute",
              right: isSmallScreen ? "0px" : "-80px",
              top: isSmallScreen ? "-10px" : "-50px",
            }}
            onClick={handleModalClose}
          >
            <Close
              sx={{
                color: isSmallScreen ? "black" : "white",
                width: "30px",
                height: "30px",
              }}
            />
          </IconButton>
          <Box>
            <CustomTypography
              variant={"h6"}
              sx={{ fontWeight: "600", padding: "0 1rem 1.5rem 0" }}
            >
              {modalTitle}
            </CustomTypography>
          </Box>
          <Box>
            <CustomTypography
              variant={"subtitle1"}
              sx={{ padding: "0 1rem 2rem 0", color: "#878787" }}
            >
              {modalDescription}
            </CustomTypography>
          </Box>
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Button
              variant="contained"
              onClick={handleDeleteAddress}
              sx={{
                width: "100%",
                borderRadius: "0.2rem",
                padding: "0.5rem",
                backgroundColor: "rgb(6, 155, 170)",
                boxShadow: "none",
                "&:hover": {
                  boxShadow: "none",
                  backgroundColor: "rgb(6, 155, 170)",
                },
              }}
            >
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontWeight: "600" }}
              >
                DELETE
              </CustomTypography>
            </Button>
            <Button
              variant="contained"
              sx={{
                width: "100%",
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
                border: "1px solid #C0C0C0",
                "&:hover": {
                  color: "rgb(6, 155, 170)",
                  backgroundColor: "white",
                  boxShadow: "none",
                },
              }}
              onClick={handleModalClose}
            >
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontWeight: "600" }}
              >
                CANCEL
              </CustomTypography>
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddressCloseModal;
