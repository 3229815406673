import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const TitleSubtitle = ({ title, subtitle }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box
      sx={{
        borderLeft: "5px solid #214C7B",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CustomTypography
        sx={{
          color: "#214C7B",
          fontWeight: "600",
          paddingLeft: "0.5rem",
          lineHeight: "1.25",
          fontSize: {
            mobile: "1.125rem",
            tablet: "1.25rem",
            laptop: "1.5rem",
            desktop: "1.5rem",
          },
        }}
        variant={"subtitle1"}
      >
        {title}
      </CustomTypography>
      <CustomTypography
        sx={{
          color: "#214C7B",
          fontWeight: "600",
          paddingLeft: "0.5rem",
          fontSize: {
            mobile: "0.75rem",
            tablet: "0.85rem",
            laptop: "0.95rem",
            desktop: "1rem",
          },
          color: "#54B0BA",
        }}
        variant={"subtitle2"}
      >
        {subtitle}
      </CustomTypography>
    </Box>
  );
};

export default TitleSubtitle;
