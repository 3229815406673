import Footer from "../Footer"
import Navigation from "../Navigation"


const ReturnReq = () => {

    return (
        <>
            <Navigation />
            ReturnReq
            <Footer />
        </>
    )
}
export default ReturnReq