import { Box } from "@mui/material";
import React, { useRef } from "react";
import { QUERY_GET_TRENDING_PRODUCTS } from "../../../../../graphql/Query";
import { useQuery } from "@apollo/client";
import TrendingGadgetCard from "./TrendingGadgetCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";

const AllTrendingGadgets = () => {
  const { data, loading, error } = useQuery(QUERY_GET_TRENDING_PRODUCTS);
  const swiperRef = useRef(null);
  console.log(data);

  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  if (loading) {
    return <>Loading....</>;
  }

  return (
    <Box sx={{ flexGrow: 1, width: "100%" }}>
      <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
        <Swiper
          ref={swiperRef}
          modules={[Navigation, Pagination]}
          pagination={{
            clickable: true,
            el: ".tg-swiper-pagination-custom",
          }}
          navigation={{
            nextEl: ".tg-swiper-button-next",
            prevEl: ".tg-swiper-button-prev",
          }}
          spaceBetween={20}
          slidesPerView={2}
          slidesPerGroup={1}
          loop={false}
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            600: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            821: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1025: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
          }}
          className="trending-gadgets"
          style={{
            "--swiper-navigation-color": "#000",
            "--swiper-pagination-color": "#000",
          }}
        >
          <div className="swiper-wrapper">
            {data.getTrendingBuyProduct.map((product) => (
              <SwiperSlide key={product.id}>
                <TrendingGadgetCard
                  img={product.images[0]}
                  title={product.productName}
                  price={product.productPrice}
                  mrp={product.mrp}
                />
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
        <div className="tg-swiper-pagination-custom" />
        <Box
          className="tg-swiper-controls"
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            zIndex: 10,
            top: "50%",
            transform: "translateY(-50%)",
            left: 0,
            right: 0,
            padding: "0 2rem",
          }}
        >
          <button
            className="tg-swiper-button-prev"
            aria-label="Previous"
            style={{ border: "2px solid grey", cursor: "pointer" }}
            onClick={handlePrevClick}
          >
            <ArrowBackIosNew fontSize="medium" sx={{ color: "grey" }} />
          </button>
          <button
            className="tg-swiper-button-next"
            aria-label="Next"
            style={{ border: "2px solid grey", cursor: "pointer" }}
            onClick={handleNextClick}
          >
            <ArrowForwardIos fontSize="medium" sx={{ color: "grey" }} />
          </button>
        </Box>
      </Box>
      <style jsx>{`
        .trending-gadgets {
          width: 100% !important;
        }

        .trending-gadgets .swiper-wrapper {
          display: flex;
          width: 100%;
        }

        .trending-gadgets .swiper-slide {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-shrink: 0;
        }

        .trending-gadgets .swiper-slide .trending-gadget-card {
          transition: all 0.3s ease;
          width: 100%;
        }

        .tg-swiper-pagination-custom {
          margin-top: 1rem;
          display: flex;
          justify-content: center;
        }

        .tg-swiper-pagination-custom .swiper-pagination-bullet {
          background-color: #214c7b;
          opacity: 0.6;
          width: 8px;
          height: 8px;
          margin: 0 5px;
          transition: opacity 0.3s;
        }

        .tg-swiper-pagination-custom .swiper-pagination-bullet-active {
          opacity: 1;
        }

        .tg-swiper-button-prev,
        .tg-swiper-button-next {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: rgba(255, 255, 255, 0.8);
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid grey;
          transition: opacity 0.3s;
          z-index: 10;
          cursor: pointer;
        }

        .tg-swiper-button-prev {
          left: -25px;
        }

        .tg-swiper-button-next {
          right: -25px;
        }

        @media (max-width: 1025px) {
          .tg-swiper-button-prev,
          .tg-swiper-button-next {
            display: none;
          }
        }

        @media (max-width: 600px) {
          .trending-gadgets {
            padding: 5px !important;
          }
        }
      `}</style>
    </Box>
  );
};

export default AllTrendingGadgets;
