import { Box } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import DefaultAddress from "./DefaultAddress";
import OtherAddresses from "./OtherAddresses";

const AllAddresses = ({ addresses }) => {
  const defaultAddress = addresses.find((address) => address.isDefault);
  const nonDefaultAddress = addresses.filter((address) => !address.isDefault);
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
      <Box display={"flex"} flexDirection={"column"} gap={"0.75rem"}>
        <Box>
          <CustomTypography
            variant={"subtitle2"}
            fontWeight={"600"}
            sx={{ textTransform: "uppercase" }}
          >
            Default Address
          </CustomTypography>
        </Box>
        <Box>
          <DefaultAddress address={defaultAddress} />
        </Box>
        {nonDefaultAddress.length > 0 && (
          <>
            <Box>
              <CustomTypography
                variant={"subtitle2"}
                fontWeight={"600"}
                sx={{ textTransform: "uppercase" }}
              >
                Other Addresses
              </CustomTypography>
            </Box>

            <Box >
              <OtherAddresses addresses={nonDefaultAddress} />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AllAddresses;
