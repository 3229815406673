import { useState, useEffect } from "react";
import { Row, Col, Container, Button, Image, Card } from 'react-bootstrap'
import Navigation from '../../Navigation'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import '../../../ComponentsCss/QuestionnariesCss/step2.css'
import backCameraIcon from '../../../Images/QuestionIcons/back_camera_issue.png'
import speakerIcon from '../../../Images/desktopicon/speaker.png'
import webcamIcon from '../../../Images/desktopicon/webcam.png'
import micIcon from '../../../Images/QuestionIcons/mic_issue.png'
import charginPortIcon from '../../../Images/desktopicon/socket.png'
import sensorIcon from '../../../Images/QuestionIcons/sensor_issue.png'
import batterIcon from '../../../Images/QuestionIcons/battery_issue.png'
import buttonIcon from '../../../Images/desktopicon/stand.png'
import wiffiicon from '../../../Images/desktopicon/wifi.png'
import bluetoothIcon from '../../../Images/desktopicon/bluetooth.png'
import flashLighIcon from '../../../Images/QuestionIcons/flash_light_issue.png'
import gpsIcon from '../../../Images/QuestionIcons/gps_issue.png'

import keyboardIcon from '../../../Images/desktopicon/keyboard.png'
import usbIcon from '../../../Images/desktopicon/usb.png'
import hardisk from '../../../Images/desktopicon/hardisk.png'
import hang from '../../../Images/desktopicon/hang.png'
import earphone from '../../../Images/desktopicon/earphoneport.png'
import ram from '../../../Images/desktopicon/ram.png'
import graphics from '../../../Images/desktopicon/graphics.png'
import fingerIssue from '../../../Images/desktopicon/fingerissue.png'
import faceIssue from '../../../Images/desktopicon/facesissue.png'

import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Footer from '../../Footer'

const DesktopStep2 = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate();
    const [keyBoardState, setKeyBoardState] = useState(false);
    const [trackpadState, setTrackpadState] = useState(false);
    const [speaker, setSpeaker] = useState(false)
    const [webCamState, SetWebCamState] = useState(false)
    const [micState, setMicState] = useState(false)
    const [usbPortState, SetUsbPortState] = useState(false)
    const [chargingPortState, setChargingPort] = useState(false)
    const [batterState, setBatterState] = useState(false)
    const [hingeLooseState, setHingeLooseState] = useState(false)
    const [wiffiState, setWiffiState] = useState(false)
    const [bluetooth, setBluetoohState] = useState(false)
    const [chargerFaultyState, setChargerFaultyState] = useState(false)
    const [hardDriveState, setHardDriveState] = useState(false)
    const [autoRestartState, setAutoRestartState] = useState(false)
    const [earphoneState, setearphoneState] = useState(false)
    const [ramIssueState, setRamIssueState] = useState(false)
    const [graphicsCardState, setGraphicsCardState] = useState(false)
    const [fingerPrintIssueState, setFingerPrintIssue] = useState(false)
    const [faceSensorIssueState, setFaceSensorIssue] = useState(false)
    // getting previous selection 
    const location = useLocation()

    const handleContinue = () => {

        navigate("/desktop-products-step3", {
            state: {
                id: location.state.id, isDeviceSwitch: location.state.isDeviceSwitch, issueWithDeviceDisplay: location.state.issueWithDeviceDisplay, deviceScreen: location.state.deviceScreen, deviceEverRepair: location.state.deviceEverRepair,
                keyBoardState: keyBoardState, trackpadState: trackpadState, SpeakerIssue: speaker, MicIssue: micState, usbPortState: usbPortState, CharginPortIssue: chargingPortState, webCamState: webCamState, BatteryIssue: batterState, hingeLooseState: hingeLooseState, WifiIssue: wiffiState, BluetoothIssue: bluetooth,
                chargerFaultyState: chargerFaultyState, AutoRestartIssue: autoRestartState, EarphonePortIssue: earphoneState, graphicsCardState: graphicsCardState, hardDriveState: hardDriveState, ramIssueState: ramIssueState, productCategory: location.state.productCategory,
                productBrand: location.state.productBrand,
                productName: location.state.productName, imgurl: location.state.imgurl ,fingerPrintIssueState: fingerPrintIssueState, faceSensorIssueState: faceSensorIssueState
            }
        })

    }
    return (
        <>
            <Navigation />
            <div className="banner">
                <h3  >
                    <span onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                        <FaArrowLeft />
                    </span>
                    <span style={{ fontWeight: "bold", textAlign: "center", marginLeft: "5%" }}>
                        Steps - 2/5
                    </span>
                </h3>            </div>
            <Container fluid>
                <h3 style={{ textAlign: "center", marginTop: "1rem", fontSize: "25px", fontWeight: "bold" }} > Want to know the exact value of your device? </h3>
                <div style={{ textAlign: "center", fontWeight: "600", margin: "1rem" }} > Select the appropriate defects of your device </div>
                <Row>
                    <Col md={2} xs={6}>
                        <Card className={keyBoardState ? "step2-container-checked" : 'step2-container'} onClick={() => keyBoardState ? setKeyBoardState(false) : setKeyBoardState(true)}>
                            <Card.Body>
                                <Image src={keyboardIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Keyboard Faulty
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* <Col md={2} xs={6}>

                        <Card className={trackpadState  ? "step2-container-checked" : 'step2-container'} onClick={() => trackpadState  ? setTrackpadState (false) : setTrackpadState (true)}>
                            <Card.Body>
                                <Image src={backCameraIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Trackpad Faulty
                                </p>
                            </Card.Body>
                        </Card>
                    </Col> */}

                    <Col md={2} xs={6}>
                        <Card className={speaker ? "step2-container-checked" : 'step2-container'} onClick={() => speaker ? setSpeaker(false) : setSpeaker(true)} >
                            <Card.Body>
                                <Image src={speakerIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Speaker
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>
                        <Card className={webCamState ? "step2-container-checked" : 'step2-container'} onClick={() => webCamState ? SetWebCamState(false) : SetWebCamState(true)} >
                            <Card.Body>
                                <Image src={webcamIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Webcam Faulty
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>

                        <Card className={micState ? "step2-container-checked" : 'step2-container'} onClick={() => micState ? setMicState(false) : setMicState(true)}>
                            <Card.Body>
                                <Image src={webcamIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Mic
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>

                        <Card className={usbPortState ? "step2-container-checked" : 'step2-container'} onClick={() => usbPortState ? SetUsbPortState(false) : SetUsbPortState(true)}>
                            <Card.Body>
                                <Image src={usbIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Usb Port
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={2} xs={6}>

                        <Card className={chargingPortState ? "step2-container-checked" : 'step2-container'} onClick={() => chargingPortState ? setChargingPort(false) : setChargingPort(true)}>
                            <Card.Body>
                                <Image src={charginPortIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    AC Socket (Loose/Not Working)
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>

                    {/* <Col md={2} xs={6}>
                        <Card className={batterState ? "step2-container-checked" : 'step2-container'} onClick={() => batterState ? setBatterState(false) : setBatterState(true)}>
                            <Card.Body>
                                <Image src={batterIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Battery (Power/Low Backup/Bulge)
                                </p>
                            </Card.Body>
                        </Card>
                    </Col> */}

                    <Col md={2} xs={6}>

                        <Card className={hingeLooseState ? "step2-container-checked" : 'step2-container'} onClick={() => hingeLooseState ? setHingeLooseState(false) : setHingeLooseState(true)} >
                            <Card.Body>
                                <Image src={buttonIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Hinge loose
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>

                        <Card className={wiffiState ? "step2-container-checked" : 'step2-container'} onClick={() => wiffiState ? setWiffiState(false) : setWiffiState(true)}>
                            <Card.Body>
                                <Image src={wiffiicon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Wifi
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>

                        <Card className={bluetooth ? "step2-container-checked" : 'step2-container'} onClick={() => bluetooth ? setBluetoohState(false) : setBluetoohState(true)}>
                            <Card.Body>
                                <Image src={bluetoothIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Bluetooth
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* <Col md={2} xs={6}>

                        <Card className={chargerFaultyState ? "step2-container-checked" : 'step2-container'} onClick={() => chargerFaultyState ? setChargerFaultyState(false) : setChargerFaultyState(true)}>
                            <Card.Body>
                                <Image src={flashLighIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Charger Faulty
                                </p>
                            </Card.Body>
                        </Card>
                    </Col> */}

                    <Col md={2} xs={6}>
                        <Card className={hardDriveState ? "step2-container-checked" : 'step2-container'} onClick={() => hardDriveState ? setHardDriveState(false) : setHardDriveState(true)}>
                            <Card.Body>
                                <Image src={hardisk} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Hard drive/SSD
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>
                        <Card className={autoRestartState ? "step2-container-checked" : 'step2-container'} onClick={() => autoRestartState ? setAutoRestartState(false) : setAutoRestartState(true)}>
                            <Card.Body>
                                <Image src={hang} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Auto Restart/Rooted/Hanging/Heating
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={2} xs={6}>
                        <Card className={earphoneState ? "step2-container-checked" : 'step2-container'} onClick={() => earphoneState ? setearphoneState(false) : setearphoneState(true)}>
                            <Card.Body>
                                <Image src={earphone} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Earphone Port
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>






                    <Col md={2} xs={6}>

                        <Card className={ramIssueState ? "step2-container-checked" : 'step2-container'} onClick={() => ramIssueState ? setRamIssueState(false) : setRamIssueState(true)}>
                            <Card.Body>
                                <Image src={ram} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Ram 
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>

                        <Card className={graphicsCardState ? "step2-container-checked" : 'step2-container'} onClick={() => graphicsCardState ? setGraphicsCardState(false) : setGraphicsCardState(true)}>
                            <Card.Body>
                                <Image src={graphics} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Graphics Card 
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={2} xs={6}>
                        <Card className={fingerPrintIssueState ? "step2-container-checked" : 'step2-container'} onClick={() => fingerPrintIssueState ? setFingerPrintIssue(false) : setFingerPrintIssue(true)}>
                            <Card.Body>
                                <Image src={fingerIssue} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Finger Print 
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={2} xs={6}>
                        <Card className={faceSensorIssueState ? "step2-container-checked" : 'step2-container'} onClick={() => faceSensorIssueState ? setFaceSensorIssue(false) : setFaceSensorIssue(true)}>
                            <Card.Body>
                                <Image src={faceIssue} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Face Sensor 
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                

                </Row>
                <div style={{ display: "flex", justifyContent: "center", margin: "1rem", marginTop: "3rem" }}>
                    <Button variant="warning" onClick={() => handleContinue()} style={{ borderRadius: '0px', width: 150, color: '#fff', fontWeight: 'bold', fontSize: '20px', backgroundColor: "#40a737", border: "0px", borderRadius: '10px' }}>Next <FaArrowRight /></Button>

                    {/* <Button variant="info " style={style={{ borderRadius: '0px', width: 150, color: '#fff', fontWeight: 'bold', fontSize: '20px' }} >Next <FaArrowRight } onClick={() => handleContinue()}>Next...</Button> */}
                </div>
            </Container>
        </>
    )
}
export default DesktopStep2