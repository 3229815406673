import * as yup from "yup";

export const userNameSchema = yup.object().shape({
  fName: yup.string().required("required"),
  lName: yup.string().required("required"),
  originalfName: yup.string().optional(),
  originallName: yup.string().optional(),
});

export const userEmailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("required"),
  originalEmail: yup.string().optional().nullable(),
});

export const userMobileSchema = yup.object().shape({
  contact: yup
    .string()
    .length(10, "Phone number must be exactly 10 digits")
    .required("Please enter your phone number"),
  originalContact: yup.string().optional(),
});
