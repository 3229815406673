import React from "react";
import { Box } from "@mui/material";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";

const ImageZoomSmall = ({
  imageUrl,
  zoomImageUrl,
  isSmallScreen,
  handlers,
}) => {
  return (
    <Box
      {...(isSmallScreen ? handlers : {})}
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: isSmallScreen ? "100%" : "65%",
        height: isSmallScreen ? "75%" : "100%",
        overflow: "hidden",
        padding: isSmallScreen ? "0.5rem" : "0.5rem 2rem 0.5rem 0.5rem",
        borderRight: isSmallScreen ? "none" : "1px solid black",
      }}
    >
      <InnerImageZoom
        src={imageUrl}
        zoomSrc={zoomImageUrl}
        moveType="pan"
        zoomType="click"
        zoomScale={1.2}
        fadeDuration={150}
        zoomPreload={true}
        fullscreenOnMobile={true}
        mobileBreakpoint={640}
        hideHint={true}
        className="imagezoom"
      />
    </Box>
  );
};

export default ImageZoomSmall;
