import React, { useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import MuiBackdrop from "../../../../muicomponents/shared/MuiBackdrop";
import PriceDetails from "../addAddress/priceDetails/PriceDetails";
import useGetCartItems from "../../hooks/addToCart/useGetCartItems";
import useGetAddressById from "../../hooks/payment/useGetAddressById";
import PaymentComponent from "./paymentComponents/PaymentComponent";
import { useNavigate } from "react-router-dom";
import useCombineCartSelectedAddress from "../../hooks/payment/useCombineCartSelectedAddress";
import { useSetRecoilState } from "recoil";
import { cartPageBackdrop } from "../../../../store/atoms/addToCart/addToCart";

const Payment = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);

  const {
    cartData,
    selectedAddressState,
    loading,
    error,
    refetchCartItems,
    refetchAddress,
  } = useCombineCartSelectedAddress();                                    

  useEffect(() => {
    setBackdropOpen(loading);
  }, [loading]);

  useEffect(() => {
    if (cartData && cartData.items.length === 0) {
      navigate("/checkout/cart");
    } else if (
      cartData &&
      cartData.items
        .filter((item) => item.isCheckedForCheckout === true)
        .some((item) => item.product.quantity === 0)
    ) {
      navigate("/checkout/cart");
    }
  }, [cartData]);

  if (error) {
    return <>Error...</>;
  }

  return (
    <>
      <MuiBackdrop />
      {!loading && (
        <Box>
          <Box
            display={"flex"}
            flexDirection={isSmallScreen ? "column" : "row"}
          >
            <Box
              flexBasis={isSmallScreen ? "100%" : "65%"}
              sx={{ width: "100%" }}
            >
              {cartData && cartData.totalAmount && <PaymentComponent />}
            </Box>
            {!isSmallScreen && (
              <Box flexBasis={"35%"} sx={{ width: "100%" }}>
                <PriceDetails isPaymentPage={true} />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Payment;
