import { Box } from "@mui/material";
import React from "react";
import { ArrowBack } from "@mui/icons-material";
import CustomTypography from "../muicomponents/shared/CustomTypography";
import { useNavigate } from "react-router-dom";

const NavbarCartMobile = ({
  title,
  step,
  isAddressMain = true,
  isAddAddressPage = false,
  navigateReturn,
  isBackPage = false,
}) => {
  const navigate = useNavigate();
  const previousPage = localStorage.getItem("step1prev");
  const handleBackClick = () => {
    if (step === "1" && previousPage) {
      // console.log("In Step 1, navigating to:", previousPage);
      navigate(previousPage);
      return;
    }

    if (isBackPage) {
      navigate(-1);
      return;
    }

    if (navigateReturn) {
      navigate(navigateReturn);
    }
  };
  return (
    <Box
      sx={{
        overflow: "hidden",
        position: "sticky",
        width: "100%",
        top: "0",
        left: "0",
        backgroundColor: "white",
        marginBottom: isAddAddressPage ? "0" : "0.5rem",
        padding: "1rem",
        zIndex: 1100,
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
      }}
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box display={"flex"} flexDirection={"row"} gap={"1rem"}>
          <Box>
            <ArrowBack onClick={handleBackClick} />
          </Box>
          {title && (
            <Box>
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontWeight: "600", textTransform: "uppercase" }}
              >
                {title}
              </CustomTypography>
            </Box>
          )}
        </Box>
        {isAddressMain && step && (
          <Box>
            <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "500" }}>
              STEP {step}/3
            </CustomTypography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default NavbarCartMobile;
