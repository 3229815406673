import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";

const ResponsiveContainer = ({ children, sx }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery("(min-width:768px) and (max-width:899px)");
  const isSmall = useMediaQuery("(min-width:900px) and (max-width:1280px)");
  // const isSmallLaptop = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  // const isDesktop = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  // const isExtraLarge = useMediaQuery(theme.breakpoints.up('xl'));

  const maxWidth = isMobile || isTablet ? "100%" : "1280px";
  const margin = isSmall
    ? "0 2rem"
    : isTablet
    ? "0 0.75rem"
    : isMobile
    ? "0"
    : "auto";

  return (
    <Box
      sx={{
        maxWidth,
        margin,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default ResponsiveContainer;
