import { useParams } from "react-router-dom"
import BuyMainPage from "./BuyMainPage";
import BuyLaptopMain from "./BuyLaptopMainPage";
import BuyTabletMain from "./TabletMainPage";


const MainPages = () => {
    const {category} = useParams()
    return (
        <>
            {
                category === "Phones" ? <BuyMainPage/> : null
            }
            {
                category === "Laptops" ? <BuyLaptopMain/> : null
            }
            {
                category === "Tablets" ? <BuyTabletMain/> : null
            }
          
        </>
    )
}
export default MainPages