import React from "react";
import useGetCartItems from "../addToCart/useGetCartItems";
import useGetAllAddress from "./useGetAllAddress";

const useCobmbineAdrsCart = () => {
  const userId = localStorage.getItem("userId");
  const {
    cartData,
    loading: cartDataLoading,
    error: cartDataError,
    refetch: cartDataRefetch,
  } = useGetCartItems(userId);
  const {
    allAddress,
    loading: addressLoading,
    error: addressError,
    refetch: addressRefetch,
    addressCount,
  } = useGetAllAddress(userId);

  const loading = cartDataLoading || addressLoading;
  const error = cartDataError || addressError;

  return {
    cartData,
    allAddress,
    addressCount,
    loading,
    error,
    cartDataRefetch,
    addressRefetch,
  };
};

export default useCobmbineAdrsCart;
