import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Image, Nav, Row, Tab } from 'react-bootstrap'
import Navigation from '../Navigation'
import Footer from '../Footer'
import '../../ComponentsCss/userCss/UserDashboard.css'
import { Link, Navigate, Outlet, useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { MUTATION_GET_USER } from '../../graphql/Mutations'
import BlockedUser from './BlockedUser'
import DefaultProfile from '../../Images/Downloads/user.png'
export default function UserDashboard() {
    const userToken = localStorage.getItem('userToken')
    const navigate = useNavigate()
    const userId = localStorage.getItem('userId')
    const [logedUserdata, setLogeduserdata] = useState(null)
    const [getUser] = useMutation(MUTATION_GET_USER, {
    })
    useEffect(() => {

        getUser({

            variables: {
                "userId": `${userId}`
            }

        }).then((data) => {
            setLogeduserdata(data.data.getUser);
        })

    }, [])


    function userlogoutHandle() {
        localStorage.removeItem('userToken')
        localStorage.removeItem('userId')
        window.location.reload()
    }
    if (!userToken) {
        return <Navigate to="/userloginpage" />
    }
    if (logedUserdata?.status === "blocked") {
        return <BlockedUser />

    }
    const handleProfilBtn=()=>{
       navigate("/userDashboard/userprofile" , { state :{  userdata :logedUserdata }})
    }

    return (
        <>
            <Navigation />
            <Container className='dashmain' fluid>
                <div className='bgmaindash'></div>
                <div className='userdash mx-auto d-block' style={{marginTop:'-150px'}}>
                    <Row>
                        <Col md={3}>
                            <div className='linksdash'>
                                <div className='imgdash'>
                                <Image src={DefaultProfile} rounded style={{ height: '100%', width: '100%' }}></Image>
                                </div>
                                <h5 style={{ marginTop: 10, fontWeight: 500 }}>{logedUserdata?.contact}</h5>
                                <Link to='/userDashboard/yourorder'><Button className='btnlnk'>Orders</Button></Link>
                                <Link><Button className='btnlnk'>Saved Payments </Button></Link>
                                <Link to ='/userDashboard/useraddress'><Button className='btnlnk'>Saved Address </Button></Link>
                                <Button onClick={()=> handleProfilBtn()} className='btnlnk'>Profile </Button>
                                <Button className='btnlnk' onClick={() => userlogoutHandle()}>Logout </Button>
                            </div>
                        </Col>
                        <Col md={9}>
                            <Outlet />
                        </Col>
                    </Row>
                </div>
            </Container>
            <Footer />
        </>
    )
}