import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import UPI from "../../../../../Images/payment/UPI 1.png";
import CHECK from "../../../../../Images/payment/Check.png";
import COD from "../../../../../Images/payment/Cod.png";
import CREDITEMI from "../../../../../Images/payment/Credit card.png";
import CREDITDEBIT from "../../../../../Images/payment/Credit.png";
import ONLINE from "../../../../../Images/payment/Online banking.png";
import WORK from "../../../../../Images/payment/Work.png";
import PaymentOptionCard from "./PaymentOptionCard";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { useRecoilState, useRecoilValue } from "recoil";
import { cartTotals } from "../../../../../store/selectors/addToCart/addToCart";
import useRazorpay from "../../../hooks/payment/useRazorpay";
import { cartItems } from "../../../../../store/atoms/addToCart/addToCart";
import PaymentSuccessComponent from "../modals/PaymentSuccessComponent";
import {
  paymentInfoModalOpen,
  paymentSuccessAtom,
} from "../../../../../store/atoms/payment/paymentAtom";

const PaymentComponent = () => {
  const [isSelected, setIsSelected] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const cartData = useRecoilValue(cartItems);
  const [paymentModalOpen, setPaymentModalOpen] =
    useRecoilState(paymentInfoModalOpen);
  const [openModal, setOpenModal] = useState(false);
  const paymentSuccess = useRecoilValue(paymentSuccessAtom);

  useEffect(() => {
    if (paymentModalOpen) {
      setOpenModal(true);
    }
  }, [paymentModalOpen]);

  const isCOD = cartData.totalAmount < 20000;
  const { isRazorpayLoaded, handlePayment } = useRazorpay(
    cartData.totalAmount,
    paymentType,
    isSelected === "doorstep"
      ? 300
      : isSelected === "booking"
      ? 500
      : cartData.totalAmount
  );
  const theme = useTheme();
  const isTabletScreen = useMediaQuery(
    theme.breakpoints.between("laptop", "desktop")
  );
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));

  const payment = [
    {
      id: 1,
      title: "UPI",
      name: "upi",
      paymentType: "full",
      subTitle: "Instant Discount Available",
      img: UPI,
      dropdown: false,
    },
    {
      id: 2,
      title: "Debit Card/Credit Card",
      name: "debit/credit",
      paymentType: "full",
      subTitle: "Instant Discount Available",
      img: CREDITDEBIT,
      dropdown: false,
    },
    {
      id: 3,
      name: "nocostemi",
      title: "No Cost EMI (Debit/Credit) Card",
      subTitle: "Instant Discount Available",
      paymentType: "full",
      img: CREDITEMI,
      dropdown: false,
    },
    {
      id: 4,
      title: "Net Banking",
      name: "netbanking",
      paymentType: "full",
      subTitle: "Instant Discount Available",
      img: ONLINE,
      dropdown: false,
    },
    isCOD && {
      id: 5,
      name: "cod",
      paymentType: "cod",
      title: "Cash On Delivery",
      subTitle: "Pay at your doorstep",
      img: COD,
      dropdown: false,
    },
    {
      id: 6,
      name: "doorstep",
      title: "Check and Pay At Your Doorstep",
      subTitle:
        "Service Available within 10km from Koramangala, Bengaluru only.",
      img: CHECK,
      paymentType: "doorstep",
      dropdown: true,
      additionalText:
        "You can check the device before purchasing it or exchange your old device with us at the comfort of your doorstep. A Rs 300 convenience fee will be charged on this order, which is non-refundable.",
    },
    {
      id: 7,
      title: "Collect Directly From Our Store",
      subTitle: "Book and Collect at your convenience",
      name: "booking",
      paymentType: "booking",
      img: WORK,
      dropdown: true,
      additionalText:
        "A Rs 500 of non-refundable booking fee will be charged on this order, which will be adjusted against the main order at the time of purchase. We will hold the product for a maximum time period of 48 hours.",
    },
  ].filter(Boolean);

  return (
    <>
      {cartData && cartData.totalAmount && (
        <Box
          sx={{
            padding: isSmallScreen ? "0" : "1rem 1rem 0 0",
            marginBottom: "1rem",
            borderRight: isSmallScreen ? "none" : "1px solid #eaeaec",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Box width={isSmallScreen ? "100%" : isTabletScreen ? "80%" : "60%"}>
            <Box>
              <CustomTypography
                varinat={"subtitle2"}
                sx={{
                  fontWeight: "600",
                  margin: isSmallScreen ? "1rem 1rem 0 1.2rem" : "0 0 0 0.5rem",
                  textTransform: "uppercase",
                }}
              >
                Payment options{" "}
              </CustomTypography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",

                flexDirection: "column",
                borderRadius: "1rem",
                gap: "1rem",
                padding: "1rem",
                marginTop: isSmallScreen ? "0" : "1rem",
                boxShadow: isSmallScreen
                  ? ""
                  : "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              }}
            >
              {payment.map((pay) => (
                <PaymentOptionCard
                  key={pay.id}
                  title={pay.title}
                  subTitle={pay.subTitle}
                  paymentType={pay.paymentType}
                  img={pay.img}
                  dropdown={pay.dropdown}
                  additionalText={pay.additionalText}
                  setPaymentType={setPaymentType}
                  isSelected={isSelected}
                  setIsSelected={setIsSelected}
                  name={pay.name}
                />
              ))}
            </Box>
            <Box
              sx={
                isSmallScreen
                  ? {
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "1rem",
                      padding: "1rem",
                      position: "fixed",
                      bottom: "0",
                      left: "0",
                      right: "0",
                    }
                  : {
                      marginTop: isSmallScreen ? "0" : "1rem",
                    }
              }
            >
              {isSmallScreen && (
                <Box>
                  <Box>
                    <CustomTypography variant="h6" sx={{ fontWeight: "700" }}>
                      ₹{cartData.totalAmount}
                    </CustomTypography>
                  </Box>
                  <Box>
                    <CustomTypography
                      variant="h6"
                      sx={{
                        fontWeight: "600",
                        fontSize: "1rem",
                        textDecoration: "line-through",
                      }}
                    >
                      ₹{cartData.totalMRP}
                    </CustomTypography>
                  </Box>
                </Box>
              )}
              <Button
                disabled={isSelected.length === 0 || !isRazorpayLoaded}
                variant="contained"
                onClick={handlePayment}
                sx={{
                  color: "white",
                  width: isSmallScreen ? "none" : "100%",
                  borderRadius: "0.2rem",
                  padding: isSmallScreen ? "0.5rem 2rem" : "0.5rem",
                  backgroundColor: "rgb(6, 155, 170)",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "rgb(6, 155, 170)",
                  },
                }}
              >
                <CustomTypography
                  variant="subtitle2"
                  sx={{ fontWeight: "700" }}
                >
                  Order Now
                </CustomTypography>
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      <PaymentSuccessComponent
        success={paymentSuccess}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default PaymentComponent;
