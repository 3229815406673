import { Box } from "@mui/material";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { ArrowForward } from "@mui/icons-material";

const PrelovedGadgetCard = ({
  img,
  title,
  description1,
  description2,
  description3,
  link,
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: {
          xs: "385px",
          tabletMedium: "380px",
          laptop: "375px",
          desktop: "400px",
        },
      }}
      className={"preloved-gadget-card"}
    >
      <img
        src={img}
        style={{ width: "100%", height: "100%", borderRadius: "1rem" }}
      />
      <Box
        display={"flex"}
        flexDirection={"row"}
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          p: 2,
          bgcolor: "rgba(217, 217, 217, 0.75)",
          borderRadius: "0 0 1rem 1rem",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          flexBasis={"90%"}
        >
          <CustomTypography
            variant="subtitle1"
            sx={{
              color: "#214C7B",
              fontWeight: 600,
              fontSize: {
                xs: "0.95rem",
                desktop: "1rem",
              },
            }}
          >
            {title}
          </CustomTypography>
          <CustomTypography
            variant="body2"
            sx={{
              fontSize: {
                xs: "0.875rem",
                desktop: "0.90rem",
              },
            }}
          >
            {description1}
          </CustomTypography>
          <CustomTypography
            sx={{
              fontSize: {
                xs: "0.875rem",
                desktop: "0.90rem",
              },
            }}
            variant="body2"
          >
            {description2}
          </CustomTypography>
          <CustomTypography
            sx={{
              fontSize: {
                xs: "0.875rem",
                desktop: "0.90rem",
              },
            }}
            variant="body2"
          >
            {description3}
          </CustomTypography>
        </Box>
        <Box flexBasis={"10%"} sx={{ display: "flex", alignItems: "center" }}>
          <ArrowForward
            sx={{ fontSize: "2rem", color: "#214C7B", fontWeight: "600" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PrelovedGadgetCard;
