import { S3Client } from "@aws-sdk/client-s3";

const s3Client = new S3Client({
  region: "ap-south-1",
  credentials: {
    accessKeyId: "AKIAQ3EGQHQFAW22MOEA",
    secretAccessKey: "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",
  },
});

export default s3Client;
