import { Box, useMediaQuery, useTheme, Button } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import s3Client from "../../../../config/s3Config";
import { useSetRecoilState } from "recoil";
import { cartPageBackdrop } from "../../../../store/atoms/addToCart/addToCart";

const ShippingAddressComponent = ({
  orderType,
  address,
  currentItem,
  order,
}) => {
  const currentStatus = currentItem?.unit?.status?.at(-1);
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));

  const handleDownload = async () => {
    try {
      setBackdropOpen(true);
      if (!order.invoice) {
        console.error("Invoice path is empty or invalid.");
        return;
      }
      const params = {
        Bucket: "hellofi",
        Key: order.invoice,
      };
      const command = new GetObjectCommand(params);
      const data = await s3Client.send(command);

      const blob = await new Response(data.Body).blob();

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = order.invoice.split("/").pop() || "invoice.pdf";

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error in download process:", error);
      alert("Failed to download invoice");
    } finally {
      setBackdropOpen(false);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: isSmallScreen ? "0" : "0.5rem",
        minHeight: "60px",
        display: "flex",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        background: "linear-gradient(120deg,#F6EDED 8%,#DFE8EA 76%)",
      }}
    >
      <Box
        sx={{
          flexBasis: isSmallScreen ? "100%" : "50%",
          padding: isSmallScreen ? "0.75rem" : "1rem",
        }}
      >
        <Box>
          <CustomTypography
            variant={"h6"}
            sx={{ fontSize: "1rem", fontWeight: "600" }}
          >
            Delivery Address
          </CustomTypography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box>
            <CustomTypography
              variant={"subtitle1"}
              sx={{ fontWeight: "600", fontSize: "0.85rem" }}
            >
              {address.name}
            </CustomTypography>
          </Box>
          <Box
            sx={{
              backgroundColor: "#f4f4f5",
              borderRadius: "1rem",
              padding: "0.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "1.5rem",
            }}
          >
            <CustomTypography
              variant={"subtitle2"}
              sx={{
                textTransform: "uppercase",
                fontSize: "0.65rem",
                fontWeight: 500,
              }}
            >
              {address.addressType}
            </CustomTypography>
          </Box>
        </Box>

        <CustomTypography
          sx={{ fontSize: "0.80rem", color: "#756E6E", fontWeight: "400" }}
        >
          {address.address}
        </CustomTypography>
        <CustomTypography
          sx={{ fontSize: "0.80rem", color: "#756E6E", fontWeight: "400" }}
        >
          {address.cityDistrict} - <span>{address.pinCode}</span>
        </CustomTypography>
        <CustomTypography
          sx={{ fontSize: "0.80rem", color: "#756E6E", fontWeight: "400" }}
        >
          {address.state}
        </CustomTypography>
        <CustomTypography
          sx={{ fontSize: "0.80rem", color: "#756E6E", fontWeight: "400" }}
        >
          Email: {address.email}
        </CustomTypography>
        <CustomTypography
          sx={{ fontSize: "0.80rem", color: "#756E6E", fontWeight: "400" }}
        >
          Contact: {address.contact}
          {address.alternateContactNo && ` ,${address.alternateContactNo}`}
        </CustomTypography>
        {address.gst && (
          <CustomTypography
            sx={{ fontSize: "0.80rem", color: "#756E6E", fontWeight: "400" }}
          >
            GST: {address.gst}
          </CustomTypography>
        )}
        {address.landMark && (
          <CustomTypography
            sx={{ fontSize: "0.80rem", color: "#756E6E", fontWeight: "400" }}
          >
            Landmark: {address.landMark}
          </CustomTypography>
        )}
      </Box>

      <Box></Box>
      {!isSmallScreen &&
        currentStatus?.status !== "failed" &&
        currentStatus?.status !== undefined &&
        currentStatus?.status !== "cancelled" && (
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"0.5rem"}
            sx={{
              flexBasis: "50%",
              padding: "1rem",
            }}
          >
            <Box>
              <CustomTypography
                variant={"h6"}
                sx={{ fontSize: "1rem", fontWeight: "600" }}
              >
                More Actions
              </CustomTypography>
            </Box>

            {currentStatus?.status === "delivered" &&
              order.invoiceAvailable && (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <CustomTypography variant={"subtitle2"}>
                    Dowload Invoice
                  </CustomTypography>
                  <Button
                    variant="contained"
                    onClick={handleDownload}
                    sx={{
                      width: "auto",
                      color: "black",
                      textTransform: "capitalize",
                      border: "1px black solid",
                      padding: "0.2rem 3rem",
                      backgroundColor: "white",
                      boxShadow: "none",
                      "&:hover": {
                        boxShadow: "none",
                        backgroundColor: "white",
                      },
                    }}
                  >
                    Download
                  </Button>
                </Box>
              )}
            {!order.invoiceAvailable && (
              <Box>
                <CustomTypography variant={"subtitle2"}>
                  Invoice can be donwloaded after 24 hours of delivery.
                </CustomTypography>
              </Box>
            )}
          </Box>
        )}
    </Box>
  );
};

export default ShippingAddressComponent;
