import React from "react";
import HomePageContainer from "../shared/HomePageContainer";
import AllImpressions from "./AllImpressions";

const ValuableImpressions = () => {
  return (
    <HomePageContainer>
      <AllImpressions />
    </HomePageContainer>
  );
};

export default ValuableImpressions;
