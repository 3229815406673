import Navigation from "../../Navigation"
import { GET_SPECIFIC_PRODUCT } from '../../../graphql/Query'
import { QUERY_GET_SELL_MODAL_IMAGE_BY_BRANDNAME } from '../../../graphql/Query'
import { Col, Container, Button, Image, Row, Spinner, Card, Breadcrumb, Dropdown } from 'react-bootstrap'
import { useQuery } from '@apollo/client';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Footer from "../../Footer";
import '../../../ComponentsCss/Brandpage/specificProduct.css'
import { FaArrowRight } from 'react-icons/fa'
import GadgetSalePreparation from "../../instruction";

const SpecificProduct = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const location = useLocation()
    const { product } = useParams()
    const navigate = useNavigate()
    const [mobileStorage, setMobileStorage] = useState("STORAGE")
    const [mobileRam, setMobileRam] = useState("RAM")
    const handleStorage = (storage) => {
        setMobileStorage(storage)
    }
    useEffect(() => {
        if (location.state === null) {
            navigate("/");
        }
    }, []);
    const handleRamSelection = (ram) => {
        setMobileRam(ram)
    }
    const { data: getSpecificProduct, loading: productLoading } = useQuery(GET_SPECIFIC_PRODUCT,
        {
            variables:
            {
                "productName": location.state?.productName

            }
        });
    const { data: getSellImageModalByBrandName, loading: ImageLoading } = useQuery(QUERY_GET_SELL_MODAL_IMAGE_BY_BRANDNAME,
        {
            variables:
            {
                "brandName": location.state?.productBrand

            }
        });
    const handleContinue = (variant) => {

        if (location && location.state.productCategory === "Tablets") {

            navigate(`/product-max-price/${getSpecificProduct?.getSpecificProduct[0].id}`, {

                state: {
                    id: `${getSpecificProduct.getSpecificProduct[0].id}`, ram: `${mobileRam}`, storage: `${mobileStorage}`
                    , productCategory: location.state.productCategory,
                    productBrand: location.state?.productBrand,
                    productName: location.state?.productName, imgurl: getSellImageModalByBrandName?.getSellImageModalByBrandName[0]?.image,
                    productPrice: getSpecificProduct.getSpecificProduct[0]?.productPrice

                }
            })
        } else {


            navigate(`/product-max-price/${getSpecificProduct?.getSpecificProduct[0].id}`, {

                state: {
                    id: `${getSpecificProduct?.getSpecificProduct[0].id}`, ram: `${mobileRam}`, storage: `${mobileStorage}`
                    , productCategory: location.state.productCategory,
                    productBrand: location.state?.productBrand,
                    productName: location.state?.productName, imgurl: getSellImageModalByBrandName?.getSellImageModalByBrandName[0]?.image,
                    productPrice: getSpecificProduct.getSpecificProduct[0]?.productPrice
                }
            })
        }
    }
    const handleSelectBrand = () => {
      let  cat = location.state?.productCategory
        if (cat === "Desktop/Imac") {
            cat = "Desktop-Imac"
        }
    navigate(`/selling/${cat}`, )
    }
    const handleSelectProduct = () => {
        navigate(`/allbrands/${location.state?.productBrand}`, {
            state: {
                productCategory: location.state?.productCategory, productBrand: location.state?.productBrand,
            }
        })
    }
    return (
        <>
            <Navigation />
            <div className="banner">
                <h2 style={{ color: '#000' }}>
                    Sell your Old {location.state && location.state.productCategory}
                </h2>
                {/* <div className="search-bar">
                    <input type="text" placeholder="Search..." className="search-input" />
                </div> */}
                <Breadcrumb>
                    <Breadcrumb.Item ><Link to="/" style={{ fontWeight: 'bold', color: '#2ecc71' }}>Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item> <a onClick={handleSelectBrand} style={{ fontWeight: 'bold', color: '#2ecc71' }}>Select Brand</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item> <a onClick={handleSelectProduct} style={{ fontWeight: 'bold', color: '#2ecc71' }}>Select Product</a>
                    </Breadcrumb.Item>
                    {/* <Breadcrumb.Item active style={{ fontWeight: 'bold', color: '#7f8c8d' }}>Choose variant</Breadcrumb.Item> */}
                </Breadcrumb>
            </div>

            {
                productLoading && ImageLoading ? <Spinner animation="border" variant="success" className='mx-auto d-block' /> :
                    <Container style={{ height: "100%" }} >
                        <h3 style={{ textAlign: "center", margin: "1rem", fontWeight: "700", color: '#000' }}>Sell Old {location.state?.productName}</h3>
                        <Card className="specific-product-card" style={{ padding: 10, borderRadius: "25px", width: "100%" }}  >
                            <Row>
                                <Col md={12} >
                                    <Image style={{ height: "20rem" }} src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${getSellImageModalByBrandName?.getSellImageModalByBrandName[0]?.image}`} className="brandImgShadow mx-auto d-block" fluid />
                                </Col>
                            </Row>
                            <Row >
                                <Col >
                                    <h1 className="productNameCss" style={{ textAlign: 'center', fontSize: "20px" }}>{getSpecificProduct?.getSpecificProduct[0]?.productName}</h1>
                                    <h1 className="productVariantCss" style={{ textAlign: 'center' }}>Choose a variant <br /> (from the drop down)</h1>
                                    <div style={{ width: 200, height: 200, margin: 'auto' }}>
                                        <Dropdown drop="down">
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="storageBtn">{mobileStorage}</Dropdown.Toggle>
                                            <Dropdown.Menu popperConfig={{
                                                modifiers: [
                                                    {
                                                        name: 'flip',
                                                        enabled: false // Disable flipping to prevent opening in top direction
                                                    },
                                                    {
                                                        name: 'offset',
                                                        options: {
                                                            offset: [0, 10] // Adjust the offset as needed
                                                        }
                                                    }
                                                
                                                ]
                                            }} style={{ fontSize: "12px", fontWeight: "bold" }} >
                                                <Dropdown.Item onClick={() => handleStorage("16 GB")} >16 GB</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleStorage("32 GB")} >32 GB</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleStorage("64 GB")} >64 GB </Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleStorage("128 GB")} >128 GB</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleStorage("256 GB")} >256 GB</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleStorage("512 GB")} >512 GB</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleStorage("1 TB")} >1 TB</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleStorage("2 TB")} >2 TB</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                    <div style={{ width: 200, margin: 'auto', marginTop: '-150px' }}>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="ramBtn">
                                                {mobileRam}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu popperConfig={{
                                                modifiers: [
                                                    {
                                                        name: 'flip',
                                                        enabled: false // Disable flipping to prevent opening in top direction
                                                    },
                                                    {
                                                        name: 'offset',
                                                        options: {
                                                            offset: [0, 10] // Adjust the offset as needed
                                                        }
                                                    }
                                                ]
                                            }}  style={{ fontSize: "12px", fontWeight: "bold" }}>
                                                <Dropdown.Item onClick={() => handleRamSelection("2 GB")} >2 GB</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleRamSelection("3 GB")} >3 GB</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleRamSelection("4 GB")} >4 GB</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleRamSelection("6 GB")}>6 GB</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleRamSelection("8 GB")}>8 GB</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleRamSelection("12 GB")}>12 GB</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleRamSelection("16 GB")}>16 GB</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleRamSelection("18 GB")}>18 GB</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleRamSelection("24 GB")}>24 GB</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleRamSelection("32 GB")}>32 GB</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                    {
                                        mobileStorage !== "Storage" && mobileRam !== "RAM" ?
                                            <Button variant="warning" className="nextBtn mx-auto d-block" onClick={() => handleContinue()} >Next <FaArrowRight style={{ marginTop: '-4px' }} /></Button>
                                            : ""
                                    }
                                    <div className="text-danger fw-bold text-center warningDiv" >Please select the Appropriate Storage and Ram as mentioned in the Title</div>
                                </Col>
                            </Row>

                        </Card>

                    </Container >
            }

        </>
    )
}

export default SpecificProduct 