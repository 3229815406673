import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React, { useMemo, useState } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { useRecoilValue } from "recoil";
import { useNavigate } from "react-router-dom";
import { cartItems } from "../../../../../store/atoms/addToCart/addToCart";
import { Info } from "@mui/icons-material";
import AppliedCouponModal from "../modals/AppliedCouponModal";
import UnselectSelectedItems from "../modals/UnselectSelectedItems";

const PriceDetails = () => {
  const theme = useTheme();
  const [unselectModalOPen, setUnselectModalOpen] = useState(false);
  const smallScreenButtonStyles = {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "white",
    padding: "1rem",
    zIndex: 1000,
  };

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const cartData = useRecoilValue(cartItems);

  const allItemsInStock = useMemo(() => {
    const checkedItems = cartData?.items?.filter(
      (item) => item.isCheckedForCheckout === true
    );
    return checkedItems?.every((item) => parseInt(item.product.quantity) > 0);
  }, [cartData]);

  const [modalOpen, setModalOpen] = useState(false);

  const handleOrder = () => {
    if (allItemsInStock) {
      navigate("/checkout/address");
    } else {
      setUnselectModalOpen(true);
    }
  };

  const navigate = useNavigate();

  return (
    <>
      {cartData != null && (
        <>
          <Box display={"flex"} flexDirection={"column"} gap={"0.6rem"}>
            <Box margin={"1rem 0 0.4rem"}>
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontWeight: "600", color: "#535766" }}
              >
                PRICE DETAILS {`(${cartData.count}) Items`}
              </CustomTypography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontSize: "0.85rem", fontWeight: "400" }}
              >
                Total MRP
              </CustomTypography>
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontWeight: "500" }}
              >
                ₹{cartData.totalMRP}
              </CustomTypography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontSize: "0.86rem", fontWeight: "400" }}
              >
                Discount on MRP
              </CustomTypography>
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontWeight: "500", color: "#03a685" }}
              >
                -₹{cartData.discountOnMRP}
              </CustomTypography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontSize: "0.86rem", fontWeight: "400" }}
              >
                Coupon Discount{" "}
                {cartData.coupon !== null && (
                  <Info
                    onClick={() => setModalOpen(true)}
                    sx={{
                      color: "black",
                      backgroundColor: "white",
                      fontSize: "1rem",
                      "&:hover": {
                        cursor: "pointer",
                      },
                    }}
                  />
                )}
              </CustomTypography>
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontWeight: "500" }}
              >
                ₹{cartData.couponDiscount === 0 ? "0" : cartData.couponDiscount}{" "}
              </CustomTypography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontSize: "0.86rem", fontWeight: "400" }}
              >
                Platform fee
              </CustomTypography>
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontWeight: "500", color: "#03a685" }}
              >
                {cartData.platformFee === 0 ? "Free" : cartData.platformFee}
              </CustomTypography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontSize: "0.86rem", fontWeight: "400" }}
              >
                Delivery Charges
              </CustomTypography>
              <CustomTypography
                variant={"subtitle2"}
                sx={{ fontWeight: "500", color: "#03a685" }}
              >
                {cartData.deliveryCharges === 0
                  ? "Free"
                  : cartData.deliveryCharges}
              </CustomTypography>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              sx={{ borderTop: "1px solid #eaeaec", paddingTop: "1rem" }}
            >
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontWeight: "600" }}
              >
                Total Amount
              </CustomTypography>
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontWeight: "600" }}
              >
                ₹{cartData.totalAmount}
              </CustomTypography>
            </Box>
            <Box sx={isSmallScreen ? smallScreenButtonStyles : {}}>
              {isSmallScreen && (
                <Box display={"flex"} justifyContent={"center"}>
                  <CustomTypography
                    variant={"subtitle1"}
                    sx={{ fontWeight: "600" }}
                  >
                    {cartData.count} Items selected for order
                  </CustomTypography>
                </Box>
              )}
              <Button
                variant="contained"
                onClick={handleOrder}
                sx={{
                  width: "100%",
                  borderRadius: "0.2rem",
                  padding: "0.5rem",
                  backgroundColor: "rgb(6, 155, 170)",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "rgb(6, 155, 170)",
                  },
                }}
              >
                PLACE ORDER
              </Button>
            </Box>
          </Box>
          {cartData.coupon !== null && (
            <AppliedCouponModal
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
            />
          )}
          <UnselectSelectedItems
            modalOpen={unselectModalOPen}
            setModalOpen={setUnselectModalOpen}
          />
        </>
      )}
    </>
  );
};

export default PriceDetails;
