import { Box, Grid, Table, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import PrelovedGadgetCard from "./PrelovedGadgetCard";
import Android_Phones from "../../../../../Images/home/prelovedGadgets/Android_Phones.png";
import Iphones from "../../../../../Images/home/prelovedGadgets/Iphones.png";
import Laptops from "../../../../../Images/home/prelovedGadgets/Laptops.png";
import Macbooks from "../../../../../Images/home/prelovedGadgets/Macbooks.png";
import Tablets from "../../../../../Images/home/prelovedGadgets/Tablets.png";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import PreownedBox from "./PreownedBox";

const AllPrelovedGadgets = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box sx={{ flexGrow: "1", paddingLeft: "1rem" }}>
      <Grid
        container
        rowSpacing={isMobileScreen ? 0.5 : 2}
        columnSpacing={isMobileScreen ? 4 : 5}
        sx={{ justifyContent: "center" }}
      >
        <Grid item xs={4} tabletMedium={3} desktop={2.4}>
          <PrelovedGadgetCard img={Android_Phones} />
          <PreownedBox title={"Android Phones"} />
        </Grid>
        <Grid item xs={4} tabletMedium={3} desktop={2.4}>
          <PrelovedGadgetCard img={Iphones} />
          <PreownedBox title={"Apple Phones"} />
        </Grid>
        <Grid item xs={4} tabletMedium={3} desktop={2.4}>
          <PrelovedGadgetCard img={Laptops} />
          <PreownedBox title={"Laptops"} />
        </Grid>
        <Grid item xs={4} tabletMedium={3} desktop={2.4}>
          <PrelovedGadgetCard img={Macbooks} />
          <PreownedBox title={"Apple Macbooks"} />
        </Grid>
        <Grid item xs={4} tabletMedium={3} desktop={2.4}>
          <PrelovedGadgetCard img={Tablets} />
          <PreownedBox title={"Tablets"} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AllPrelovedGadgets;
