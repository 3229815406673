import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import SmallCard from "../shared/SmallCard";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const PrelovedGadgetCard = ({ img }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <SmallCard
      display={"flex"}
      flexDirection={"column"}
      sx={{
        position: "relative",
        justifyContent: "space-between",
        minHeight: isMobileScreen ? "85px" : "130px",
        height: isMobileScreen ? "85px" : "150px",
        padding: isMobileScreen ? "0.5rem" : "1rem",
        borderRadius: "0.5rem",
      }}
    >
      <img
        src={img}
        style={{
          width: isMobileScreen ? "100%" : "85%",
          height: isMobileScreen ? "85px" : "125px",
          position: "absolute",
          bottom: "0",
          left: "-20px",
        }}
      />
    </SmallCard>
  );
};

export default PrelovedGadgetCard;
