import React, { useEffect } from "react";
import Footer from "../Footer";
import Navigation from "../Navigation";

const Careers = () => {
    useEffect(() => { 
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navigation />
            <div className="term-container">
                <div className="careers-content px-3" style={{ textAlign: "justify" }}>
                    <h2>Join Our Team</h2>
                    <p>Are you an environment enthusiast and want to make a difference?</p>

                    <p>We at HelloFi are looking for motivated individuals like you. Write to us at <a href="mailto:hr@hellofi.in">hr@hellofi.in</a> and tell us how you can contribute towards our goal.</p>

                    <h3>Open Positions</h3>
                    <p>We are actively recruiting individuals with good technical knowledge and passion for tech for the role of <strong>Field Quality Check Associate</strong>. We are hiring for different locations.</p>
                    <p>To know if you are eligible, mail us at <a href="mailto:hr@hellofi.in">hr@hellofi.in</a> along with your technical expertise and CV, or call us at <a href="tel:8150835583">8150835583</a> for further details.</p>

                    <p>We await environmental enthusiasts like you to join our journey!</p>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Careers;
