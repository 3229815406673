import { Helmet } from 'react-helmet';

const HelmetSection = ({title,des}) => {
   const description= "Discover the power of emotion in buying, selling, and exchanging used mobiles, laptops, and other gadgets with HelloFi. Best prices and offers are guaranteed."
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
        </Helmet>
    );
}

export default HelmetSection;