import { Button, Card, Col, Container, Form, Row, Table, Spinner, Image, Dropdown, Modal, Toast, ListGroup, Accordion } from 'react-bootstrap'
import { FaTag, FaShoppingCart, FaShippingFast } from "react-icons/fa";
import { CartContext } from "../../Contex/ProductDetailContex";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const ProductList = ({ products }) => {
    const navigate = useNavigate()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const handleProductClick = (product) => {
        navigate(`/buying-product-details/${product.id}`, {
            state: {
                product: product
            }
        })
    }
    return (
        <>
            {
                products?.length == 0 ? <div className='text-center d-flex justify-content-center align-items-center w-100' style={{ flexDirection: "column" }}>
                    <Image src={'https://img.freepik.com/free-vector/empty-concept-illustration_114360-1188.jpg?w=740&t=st=1695129002~exp=1695129602~hmac=92471af375cbc1dc7a13542cdb5c957d521eef09715afd1b4bb4bc536834e939'} fluid width={300} className='d-block'></Image>

                    <h1>No Product Found</h1></div> :

                    products &&
                    products && products?.map((product, index) => (
                        <Card
                        style={{height:"38rem"}}
                            key={index}
                            className="m-2 shadow-sm bg-body-tertiary rounded buy-card card-hover"
                            // Adjust max width as needed
                            onClick={() => handleProductClick(product)}
                        >
                            <div className="product-buy-show">
                                <div className="product-buy-show-body text-center">
                                    <Card.Img
                                        variant="top"
                                        src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${product?.image[0]}`}
                                        className="buyProImg"
                                        style={{ width: '200px', height: '200px', margin: '0 auto' }}
                                        alt={product?.productName}
                                    />
                                </div>
                                <div className="product-buy-show-body ">
                                    <Card.Body>
                                        <Card.Text>
                                            <h4 className="fs-4">{product?.productName}</h4>
                                            <p className="fs-5">
                                                <span className="fw-bold">₹{product?.productPrice}</span> <span className="sellprice tags">₹ {product?.mrp}</span>
                                            </p>
                                            <p className="d-flex tags">
                                                <FaShippingFast className="d-block m-1" style={{ fontSize: "14px" }} /> <span className="d-block">Free Delivery
                                                    {

                                                        parseInt(product?.productPrice) <= 20000 ?
                                                            " + COD Available" : " "


                                                    }

                                                    </span>
                                            </p>

                                            <div className="d-flex justify-content-between w-100 tags">
                                                <p className="d-flex ">
                                                    <FaTag className="d-block m-1" style={{ fontSize: "12px" }} /> <span className="d-block">Easy EMI</span>
                                                </p>
                                                <p className="d-flex ms-1">
                                                    <FaTag className="d-block m-1 " style={{ fontSize: "12px" }} /> <span className="d-block ">Bank offer Available</span>
                                                </p>

                                            </div>

                                            <Button className="btn btn-hover w-100 " variant="outline-success">View Details</Button>
                                        </Card.Text>
                                    </Card.Body>
                                </div>
                            </div>
                        </Card>
                    ))
            }

        </>
    )
}

export default ProductList