import React from "react";
import ChooseUsBox from "../muicomponents/home/ChooseUsBox";
import FlexCenter from "../muicomponents/shared/FlexCenter";
import OwlCarousel from "react-owl-carousel";
import { Box, Typography, useTheme } from "@mui/material";
import { Container } from "react-bootstrap";

const FeaturePoints = () => {
  const theme = useTheme();
  const options = {
    loop: true,
    center: false,
    items: 1,
    autoplay: true,
    autoplayTimeout: 4000,
    smartSpeed: 450,
    nav: false,
    dots: false,
  };
  const data = [
    {
      text: "Easy EMI Options",
    },
    {
      text: "Fast and Free Delivery across all the Pincodes.",
    },
    {
      text: "Brand Warranty/Seller Service Warranty",
    },
    {
      text: "COD available for selected products",
    },
    {
      text: "Delivery time of 2 days in Metro and Major Cities.",
    },
    {
      text: "Real time video experience pre purchase.",
    },
  ];
  return (
    <>
      <OwlCarousel
        id="emi-slider"
        className="owl-carousel owl-theme"
        {...options}
      >
        {data.map((textdata, index) => {
          return (
            <Container key={index}>
              <ChooseUsBox
                sx={{
                  padding: {
                    mobile: "2rem 0.5rem",
                    tablet: "2rem 1rem",
                    laptop: "2rem 1.5rem",
                    desktop: "2rem",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      mobile: "0.8rem",
                      tablet: "1.3rem",
                      laptop: "1.5rem",
                      desktop: "1.8rem",
                    },
                    fontFamily: '"Open Sans", sans-serif',
                    fontWeight: "600",
                  }}
                >
                  {textdata.text}
                </Typography>
              </ChooseUsBox>
            </Container>
          );
        })}
      </OwlCarousel>
    </>
  );
};

export default FeaturePoints;
