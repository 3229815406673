import React, { useEffect } from "react";
import Footer from "../Footer";
import Navigation from "../Navigation";
// import "../../ComponentsCss/PrivacyPolicy.css"; // Ensure this CSS file exists for styling

const ShippingDeliveryPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navigation />
            <div className="term-container">
                <div className="privacy-content px-3" style={{ textAlign: "justify" }}>
                    <h2 className="mb-5">Shipping and Delivery Policy</h2>

                    <h3>1.	Method of delivery: </h3>
                    <p>Customers may be provided a choice for the method of delivery; fast/regular or other . The availability of options depends upon the delivery location.</p>

                    <h3>2.	Order processing:</h3>
                    <p>Orders are processed immediately or within 2 business days after payment confirmation. All updated regarding your order will be shared with you via the preferred medium of communication mentioned by you. </p>

                    <h3>3.	Charges:</h3>
                    <p>It's mostly Free of Cost. In some case shipping charges are levied based upon the delivery location, selected method and product(s) and will be showed to you before checkout.</p>

                    <h3>4.	Time:</h3>
                    <p>The delivery time is calculated with respect to the choice of delivery method and delivery location. The typical delivery time is within 7 working days. </p>


                </div>
            </div>
            <Footer />
        </>
    );
};

export default ShippingDeliveryPolicy;
