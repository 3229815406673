import { Box } from "@mui/material";
import React from "react";
import HomePageContainer from "../shared/HomePageContainer";
import TitleSubtitle from "../shared/TitleSubtitle";
import CorporateVendorCards from "./CorporateVendorCards";

const CorporateVendorOptions = () => {
  return (
    <HomePageContainer sx={{ margin: { xs: "0 0.5rem", laptop: 0 } }}>
      <TitleSubtitle
        title={"Are you a Corporate / Vendor ?"}
        subtitle={"Want to sell/buy in Quantity ?"}
      />
      <CorporateVendorCards />
    </HomePageContainer>
  );
};

export default CorporateVendorOptions;
