import React, { useEffect } from "react";
import {
  loadingStateAtom,
  productAtom,
  productDetailsAtom,
  productIdAtom,
} from "../../../../store/atoms/productDetails/productAtom";
import { Box, useMediaQuery } from "@mui/material";
import ProductImages from "./ProductImages";
import ProductDescription from "./ProductDescription";
import useGetProductById from "../../hooks/useGetProductById";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import ProductOverview from "./ProductOverview";
import HelloFiAssured from "./HelloFiAssured";
import RelatedProduct from "./RelatedProduct";
import RecentlyAdded from "./RecentlyAdded";
import ProductImageSlider from "./ProductImageSlider";
import { useQuery } from "@apollo/client";
import { GET_BUY_PRODUCT_BY_ID } from "../../../../graphql/Query";

const ProductDetails = ({ productId }) => {
  const isSmallScreen = useMediaQuery("(max-width:767px)");
  const isExtraSmallScreen = useMediaQuery("(max-width:300px)");

  const { product, loading, error } = useGetProductById(productId);

  const setProductDetails = useSetRecoilState(productDetailsAtom);
  const setProductId = useSetRecoilState(productIdAtom);
  const setLoadingState = useSetRecoilState(loadingStateAtom);

  useEffect(() => {
    setProductId(productId);
    if (loading) {
      setLoadingState(true);
    } else if (product) {
      setProductDetails(product);
      setLoadingState(false);
    } else if (error) {
      setLoadingState(false);
    }
    return () => {
      setProductDetails(null);
    };
  }, [product, loading, error]);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"1rem"}
        sx={{ minWidth: "300px" }}
      >
        <Box
          display={"flex"}
          gap={isSmallScreen ? "0" : "0.5rem"}
          flexDirection={
            isExtraSmallScreen ? "column" : isSmallScreen ? "column" : "row"
          }
          sx={{ width: "100%" }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={isSmallScreen ? "0" : "1rem"}
            flexBasis={
              isExtraSmallScreen ? "100%" : isSmallScreen ? "100%" : "50%"
            }
          >
            {isSmallScreen ? <ProductImageSlider /> : <ProductImages />}
            {isExtraSmallScreen || isSmallScreen ? (
              <ProductDescription productId={productId} />
            ) : (
              <>
                <ProductOverview />
              </>
            )}
          </Box>
          <Box
            marginTop={isExtraSmallScreen || isSmallScreen ? "1rem" : ""}
            flexBasis={
              isExtraSmallScreen ? "100%" : isSmallScreen ? "100%" : "50%"
            }
          >
            {isExtraSmallScreen || isSmallScreen ? (
              <>
                <ProductOverview />
              </>
            ) : (
              <ProductDescription productId={productId} />
            )}
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"1rem"}
          margin={isExtraSmallScreen || isSmallScreen ? "0 0.8rem" : ""}
        >
          <HelloFiAssured />
          {product && !loading && !error && (
            <>
              <RelatedProduct product={product} />
              <RecentlyAdded product={product} />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ProductDetails;
