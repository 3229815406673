import { Box, CircularProgress, useTheme } from "@mui/material";
import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  useRecoilValue,
  useRecoilValueLoadable,
  useSetRecoilState,
} from "recoil";
import { useParams } from "react-router-dom";
import Product from "./Product";
import "../../../../css/style.css";
import {
  categoryState,
  selectedFilterState,
  sortState,
} from "../../../../../../store/atoms/productListing/productListing";
import { productsSelector } from "../../../../../../store/selectors/productListing/productListing";
import CustomTypography from "../../../../../../muicomponents/shared/CustomTypography";
import { cartPageBackdrop } from "../../../../../../store/atoms/addToCart/addToCart";
import { useQuery } from "@apollo/client";
import { GET_PRODUCTS_BY_CATEGORY } from "../../../../../../graphql/Query";

const ProductCards = () => {
  let { category } = useParams();
  const theme = useTheme();
  const filters = useRecoilValue(selectedFilterState);
  const [allProducts, setAllProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);
  const [firstItemsLoading, setFirstItemsLoading] = useState(false);
  const sortCriteria = useRecoilValue(sortState);
  const categoryAtom = useRecoilValue(categoryState);
  if (category === "Desktop-Imac") {
    category = "Desktop/Imac";
  }

  const {
    data,
    refetch,
    loading: dataLoading,
    error,
  } = useQuery(GET_PRODUCTS_BY_CATEGORY, {
    variables: { category, filters, page, limit, sortCriteria },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const observer = useRef();
  const lastProductElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    setPage(1);
    setHasMore(true);
  }, [filters, category]);

  useEffect(() => {
    if (data?.getProductsByCat) {
      const newProducts = data.getProductsByCat;
      if (page == 1) {
        setAllProducts([...newProducts]);
      } else {
        setAllProducts((prev) => [...prev, ...newProducts]);
      }

      setLoading(false);
      if (newProducts.length < limit) {
        setHasMore(false);
      }
    }
  }, [data, limit]);
  useEffect(() => {
    if (dataLoading && page === 1) {
      setBackdropOpen(true);
      setFirstItemsLoading(true);
    } else {
      setBackdropOpen(false);
      setFirstItemsLoading(false);
    }
  }, [dataLoading]);

  return (
    <>
      {!firstItemsLoading && (
        <Box>
          <Box
            display={"flex"}
            flexDirection={"row"}
            flexWrap={"wrap"}
            sx={{
              alignItems: "flex-start",
              overflow: "hidden",
              gap: "1rem",
              padding: "0.5rem 0.5rem",
            }}
          >
            {allProducts.map((product, index) => (
              <Product
                ref={
                  allProducts.length === index + 1
                    ? lastProductElementRef
                    : null
                }
                key={`${product.id}-${index}`}
                product={product}
              />
            ))}
          </Box>
          {!loading && allProducts.length == 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              padding="1rem"
            >
              <CustomTypography variant={"h6"}>
                No Products Found
              </CustomTypography>
            </Box>
          )}
          {/* {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          padding="1rem"
        >
          <CircularProgress />
        </Box>
      )} */}
          {!hasMore && allProducts.length > 0 && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              padding="1rem"
            >
              <span>No more products</span>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default ProductCards;
