import { Box, Grid } from "@mui/material";
import React from "react";
import Bulb from "../../../../../Images/home/whytrustus/Bulb.png";
import HandGesture from "../../../../../Images/home/whytrustus/HandGesture.png";
import HeadPhones from "../../../../../Images/home/whytrustus/Headphones.png";
import PriceTag from "../../../../../Images/home/whytrustus/PriceTag.png";
import Shield from "../../../../../Images/home/whytrustus/Shield.png";
import TrustBenefitCard from "./TrustBenefitCard";

const TrustBenefitsCards = () => {
  const whyTrustUs = [
    {
      title: "One Step Solution",
      body: "Buy, Sell & Exchange Your Gadgets.",
      img: Bulb,
    },
    {
      title: "Best Price Guarantee",
      body: "We offer you the best price when you deal.",
      img: PriceTag,
    },
    {
      title: "Safe And Secure",
      body: "No more privacy concerns for your data.",
      img: Shield,
    },
    {
      title: "24×7 Customer Support",
      body: "Our support is here to help you.",
      img: HeadPhones,
    },
    {
      title: "Quick And Hasslefree",
      body: "Send the deal in just 3 steps.",
      img: HandGesture,
    },
  ];
  return (
    <Box
      sx={{
        background:
          "linear-gradient(45deg, rgba(255, 255, 255, 0.3) 0%, rgba(117, 216, 216, 0.3) 58%)",
        minHeight: "250px",
        width: "100%",
        padding: "1rem",
        borderRadius: "1rem",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 1, tablet: 2 }}
          sx={{ justifyContent: "center" }}
        >
          {whyTrustUs.map((obj, index) => (
            <Grid key={index} item xs={12} laptop={2.4}>
              <TrustBenefitCard
                title={obj.title}
                img={obj.img}
                body={obj.body}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default TrustBenefitsCards;
