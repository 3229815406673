import { Box } from "@mui/material";
import React from "react";
import Navigation from "../../../Components/Navigation";
import ResponsiveContainer from "../../../muicomponents/shared/ResponsiveConatiner";
import ProfileOrderDetails from "../components/profileOrderDetails/ProfileOrderDetails";
import Footer from "../../../Components/Footer";

const OrderDetailsPage = () => {
  return (
    <Box>
      <Navigation />
      <ResponsiveContainer sx={{ minWidth: "300px", minHeight: "100vh" }}>
        <ProfileOrderDetails />
      </ResponsiveContainer>
      <Footer />
    </Box>
  );
};

export default OrderDetailsPage;
