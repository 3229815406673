import { useQuery } from "@apollo/client";
import { GET_BUY_PRODUCT_BY_ID } from "../../../graphql/Query";
import {
  loadingStateAtom,
  productDetailsAtom,
} from "../../../store/atoms/productDetails/productAtom";
import { useSetRecoilState } from "recoil";
import { useEffect } from "react";

const useGetProductById = (productId) => {
  const { data, loading, error } = useQuery(GET_BUY_PRODUCT_BY_ID, {
    variables: { productId },
    fetchPolicy: "no-cache",
  });

  const setProductDetails = useSetRecoilState(productDetailsAtom);
  const setLoadingState = useSetRecoilState(loadingStateAtom);

  useEffect(() => {
    setLoadingState(loading);
    if (data) {
      setProductDetails(data.product);
    }
    if (error) {
    }
  }, [data, loading, error, setProductDetails, setLoadingState]);

  return {
    product: data ? data.getBuyProductById : null,
    loading,
    error,
  };
};

export default useGetProductById;
