import { QUERY_GET_DESKTOP_QUESTIONS } from '../../graphql/Query'
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Table, Spinner, Modal, Button, Form } from 'react-bootstrap'
import { FaPen } from 'react-icons/fa'
import { MUTATION_UPDATE_DESKTOP_QUESTION } from '../../graphql/Mutations'

const AddDesktopQuestionariesPer = () => {

    const processorNames = [
        // Intel Core Processors
        { name: "Intel Core i3 13th Gen", field: "intelCoreI313Gen" },
        { name: "Intel Core i5 13th Gen", field: "intelCoreI513Gen" },
        { name: "Intel Core i7 13th Gen", field: "intelCoreI713Gen" },
        { name: "Intel Core i9 13th Gen", field: "intelCoreI913Gen" },
        { name: "Intel Core i7 12th Gen", field: "intelCoreI712Gen" },
        { name: "Intel Core i9 12th Gen", field: "intelCoreI912Gen" },

        // AMD Ryzen Processors
        { name: "AMD Ryzen 3 2nd Gen", field: "amdRyzen32Gen" },
        { name: "AMD Ryzen 3 3rd Gen", field: "amdRyzen33Gen" },
        { name: "AMD Ryzen 3 4th Gen", field: "amdRyzen34Gen" },
        { name: "AMD Ryzen 3 5th Gen", field: "amdRyzen35Gen" },
        { name: "AMD Ryzen 3 6th Gen", field: "amdRyzen36Gen" },
        { name: "AMD Ryzen 3 7th Gen", field: "amdRyzen37Gen" },
        { name: "AMD Ryzen 5 2nd Gen", field: "amdRyzen52Gen" },
        { name: "AMD Ryzen 5 3rd Gen", field: "amdRyzen53Gen" },
        { name: "AMD Ryzen 5 4th Gen", field: "amdRyzen54Gen" },
        { name: "AMD Ryzen 5 5th Gen", field: "amdRyzen55Gen" },
        { name: "AMD Ryzen 5 6th Gen", field: "amdRyzen56Gen" },
        { name: "AMD Ryzen 5 7th Gen", field: "amdRyzen57Gen" },
        { name: "AMD Ryzen 7 2nd Gen", field: "amdRyzen72Gen" },
        { name: "AMD Ryzen 7 3rd Gen", field: "amdRyzen73Gen" },
        { name: "AMD Ryzen 7 4th Gen", field: "amdRyzen74Gen" },
        { name: "AMD Ryzen 7 5th Gen", field: "amdRyzen75Gen" },
        { name: "AMD Ryzen 7 6th Gen", field: "amdRyzen76Gen" },
        { name: "AMD Ryzen 7 7th Gen", field: "amdRyzen77Gen" },
        { name: "AMD Ryzen 9 2nd Gen", field: "amdRyzen92Gen" },
        { name: "AMD Ryzen 9 3rd Gen", field: "amdRyzen93Gen" },
        { name: "AMD Ryzen 9 4th Gen", field: "amdRyzen94Gen" },
        { name: "AMD Ryzen 9 5th Gen", field: "amdRyzen95Gen" },
        { name: "AMD Ryzen 9 6th Gen", field: "amdRyzen96Gen" },
        { name: "AMD Ryzen 9 7th Gen", field: "amdRyzen97Gen" },

        // AMD Ryzen Pro Processors
        { name: "AMD Ryzen 3 Pro", field: "amdRyzen3Pro" },
        { name: "AMD Ryzen 3 Pro 2nd Gen", field: "amdRyzen3Pro2Gen" },
        { name: "AMD Ryzen 3 Pro 3rd Gen", field: "amdRyzen3Pro3Gen" },
        { name: "AMD Ryzen 3 Pro 4th Gen", field: "amdRyzen3Pro4Gen" },
        { name: "AMD Ryzen 3 Pro 5th Gen", field: "amdRyzen3Pro5Gen" },
        { name: "AMD Ryzen 3 Pro 6th Gen", field: "amdRyzen3Pro6Gen" },
        { name: "AMD Ryzen 3 Pro 7th Gen", field: "amdRyzen3Pro7Gen" },
        { name: "AMD Ryzen 5 Pro 2nd Gen", field: "amdRyzen5Pro2Gen" },
        { name: "AMD Ryzen 5 Pro 3rd Gen", field: "amdRyzen5Pro3Gen" },
        { name: "AMD Ryzen 5 Pro 4th Gen", field: "amdRyzen5Pro4Gen" },
        { name: "AMD Ryzen 5 Pro 5th Gen", field: "amdRyzen5Pro5Gen" },
        { name: "AMD Ryzen 5 Pro 6th Gen", field: "amdRyzen5Pro6Gen" },
        { name: "AMD Ryzen 5 Pro 7th Gen", field: "amdRyzen5Pro7Gen" },
        { name: "AMD Ryzen 7 Pro 2nd Gen", field: "amdRyzen7Pro2Gen" },
        { name: "AMD Ryzen 7 Pro 3rd Gen", field: "amdRyzen7Pro3Gen" },
        { name: "AMD Ryzen 7 Pro 4th Gen", field: "amdRyzen7Pro4Gen" },
        { name: "AMD Ryzen 7 Pro 5th Gen", field: "amdRyzen7Pro5Gen" },
        { name: "AMD Ryzen 7 Pro 6th Gen", field: "amdRyzen7Pro6Gen" },
        { name: "AMD Ryzen 7 Pro 7th Gen", field: "amdRyzen7Pro7Gen" },
        
        // Apple M-series Processors
        
        { name: "Apple M3 Chip", field: "appleM3Chip" },
        { name: "Apple M3 Pro Chip", field: "appleM3ProChip" },
        { name: "Apple M3 Max Chip", field: "appleM3MaxChip" },
        { name: "Apple M1 Ultra Chip", field: "appleM1UltraChip" },
        { name: "Apple M2 Ultra Chip", field: "appleM2UltraChip" }
    ];
    const [updateDesktopQuestions] = useMutation(MUTATION_UPDATE_DESKTOP_QUESTION, {
        refetchQueries: [
            QUERY_GET_DESKTOP_QUESTIONS]
    })
    

    const { data: getDesktopQuestion, loading: questionLoading } = useQuery(QUERY_GET_DESKTOP_QUESTIONS);
    // const [editQuestion, setEditQuestion] = useState("");
    const [editPercentage, setEditPercentage] = useState("");
    const [questionNameState, setQuestionNameState] = useState("");


    const [showEditModal, setEditModal] = useState(false)

    const handleClickEdit = (que) => {
        setEditModal(true)
    }
    const handleEdit1 = () => {

        if (questionNameState === "isDeviceSwitchPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
       
        else if (questionNameState === "issueWithDeviceDisplayPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceEverRepairPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "KeyboardFaultyPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "TrackpadFaultyPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "SpeakerIssuePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "MicIssuePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "UsbPortIssuePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "CharginPortIssuePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ChargerFaultyPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "BatteryIssuePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HardDriveFaultyPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "WifiIssuePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "BluetoothIssuePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "RamIssuePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "GraphicsCardIssuePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "AutoRestartIssuePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "EarphonePortIssuePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "WebcamFaulty") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HingeLoosePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ConditionDeviceScreenPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ConditionDeviceBodyPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveBoxWithIMEIPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HavePowerCablePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveAdapterPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveFunctMousePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }

        else if (questionNameState === "HaveFunctKeyBoardPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
          else if (questionNameState === "HaveBillPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "AdditionalInsurancePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "PurchasedPlacePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "WarrantyPeriodPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveBoxWithIMEIPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
       
        else if (questionNameState === "ram2gb") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram3gb") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram4gb") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram6gb") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram8gb") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram12gb") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram16gb") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram32gb") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenOriginPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenOriginalChangedPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenOriginPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceRepairAuthCenterPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceRepairUnAuthCenterPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceRepairNoPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceConditionMinorPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceConditionMajorPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceConditionNoScratchPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceBodyConditionExcellentPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceBodyConditionGoodPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceBodyConditionAveragePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceBodyConditionPoorPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "addInsAppleCarePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "addInsSamsungCarePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "addInsThirdPartyPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "addInsNotApplicablePer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "purchasePlaceIndiaPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "purchasePlaceAbroadPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billBelowOneMonthPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billOneToThreeMonthPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billThreeToSixMonthPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billSixToNineMonthPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billNineToTwelveMonthPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billAboveOneYear") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenChangesPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "GraphicsCardYesPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "GraphicsCardNoPer") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ScreenSize10To11Per") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ScreenSize12To13Per") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ScreenSize13To14Per") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ScreenSize14To15Per") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ScreenSize15To20Per") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ScreenSize20To30Per") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ScreenSizeAbove30Per") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage250GbHDD") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage320GbHDD") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage500GbHDD") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage640GbHDD") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage750GbHDD") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage1TbHDD") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage1P5TbHDD") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage128SDD") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage256SDD") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage2TbHDD") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage500Plus128SDD") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage1TbPlus128SDD") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage512SSD") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage512SSD") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage1TbHDDPlus256Ssd") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage1TbHDDPlus512Ssd") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage1Tbssd") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage2Tbssd") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage3Tbssd") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage4Tbssd") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI33Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI34Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI35Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI36Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI37Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI38Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI39Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI53Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI54Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI55Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI56Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI57Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI58Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI59Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI73Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI74Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI75Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI76Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI77Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI78Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI79Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI98Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI99Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI310Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI311Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI312Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI510Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI511Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI512Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI710Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI711Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI910Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreI911Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "appleM1Chip") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "appleM1MaxChip") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "appleM1PlusChip") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "appleM1ProChip") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "appleM2Chip") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "appleM2MaxChip") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "appleM2PlusChip") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "appleM2ProChip") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdA4") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdA6") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdA8") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdA9") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdA10") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdBrazosDualCoreC70") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdDualCoreA6") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdDualCoreA97gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdDualCoreE1") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdDualCoreE2") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdE1") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdQuadCore") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdQuadCoreA4") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdQuadCoreA6") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdQuadCoreA8") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdQuadCoreA10") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdQuadCoreA15") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdQuadCoreE26thGen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdQuadCoreE27thGen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdRyzen3") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdRyzen5") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdRyzen5Pro") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdRyzen7") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdRyzen7Pro") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdRyzen9") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdRyzenThreadDripper") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "amdSeries") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCeleronDualCore3gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCeleronDualCore4gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCeleronDualCore5gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCeleronQuadCore4gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreM36gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreM37gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreM38gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreM55gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreM56gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelCoreM76gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelPentiumDualCore3Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelPentiumDualCore4Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelPentiumOctaCore") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelPentiumQuadCore") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelPentiumQuadCore3rdGen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelPentiumQuadCore4Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelPentiumQuadCore6Gen") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "intelXeon") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "microSoftSq1") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "microSoftSq2") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "quadCore") {
            updateDesktopQuestions({
                variables: {
                    id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        } else if (questionNameState === "intelCoreI313Gen") {
        handleUpdate(questionNameState)
        }
        else if (questionNameState === "intelCoreI513Gen") {
          handleUpdate(questionNameState)
        }
        else if (questionNameState === "intelCoreI713Gen") {
            handleUpdate(questionNameState)
         
        }else if (questionNameState === "intelCoreI913Gen") {
            handleUpdate(questionNameState)
        }else if (questionNameState === "intelCoreI712Gen") {
            handleUpdate(questionNameState)
        }else if (questionNameState === "intelCoreI912Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen32Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen33Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen34Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen35Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen36Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen37Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen52Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen53Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen54Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen55Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen56Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen57Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen72Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen73Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen74Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen75Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen76Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen77Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen92Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen93Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen94Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen95Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen96Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen97Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen3Pro") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen3Pro2Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen3Pro3Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen3Pro4Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen3Pro5Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen3Pro6Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen3Pro7Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen5Pro2Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen5Pro3Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen5Pro4Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen5Pro5Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen5Pro6Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen5Pro7Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen7Pro2Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen7Pro3Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen7Pro4Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen7Pro5Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen7Pro6Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "amdRyzen7Pro7Gen") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "appleM3Chip") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "appleM3ProChip") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "appleM3MaxChip") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "appleM1UltraChip") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "appleM2UltraChip") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "storage2tb") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "storage1tbHDDPlus128GBSSD") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "storage2tbHDDPlus128GBSSD") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "storage2tbHDDPlus256GBSSD") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "storage2tbHDDPlus512GBSSD") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "ram18gb") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "ram24gb") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "ram64gb") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "ram128gb") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "FaceSensorIssuePer") {
            handleUpdate(questionNameState)
        }
        else if (questionNameState === "FingerPrintIssuePer") {
            handleUpdate(questionNameState)
        }


    }

    function setQuestionName(qName) {
        setQuestionNameState(qName)
        handleClickEdit()
    }
    const handleUpdate = (questionNameState) => {
        updateDesktopQuestions({
            variables: {
                id: `${getDesktopQuestion?.getDesktopQuestion[0].id}`,
                question: `${questionNameState}`,
                questionPer: `${editPercentage}`
            }
        });
        setQuestionName("");
        setEditPercentage("");
        setEditModal(false);
    };

    return (
        <>
            {
                questionLoading ?
                    <Spinner animation="border" variant="success" className='mx-auto d-block' />
                    :

                    <>
                        <Table responsive striped bordered hover>
                            <thead className='table-head table-head-question' >
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>Tell us about the Device Condition</th>
                                </tr>
                                <tr>
                                    <th>Questions</th>
                                    <th>Percentage deduction</th>
                                    <th>Edit Percentage</th>
                                </tr>
                                <tr>
                                    <td>Device Swithing</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.isDeviceSwitchPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("isDeviceSwitchPer")}><FaPen /></i></td>
                                </tr>
                               
                                <tr>
                                    <td>Device Display Issue </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.issueWithDeviceDisplayPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("issueWithDeviceDisplayPer")}><FaPen /></i> </td>
                                </tr>
                                <tr>
                                    <td>Device Screen Isuue (original) </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.deviceScreenOriginPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceScreenOriginPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Screen Changed (Compatible) </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.deviceScreenChangesPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceScreenChangesPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Screen Changed with Original Bill </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.deviceScreenOriginalChangedPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceScreenOriginalChangedPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Repaired in Authorised Service Center</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.deviceRepairAuthCenterPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceRepairAuthCenterPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Repaired in UnAuthorised Service Center</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.deviceRepairUnAuthCenterPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceRepairUnAuthCenterPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> No Device Repaired </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.deviceRepairNoPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceRepairNoPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>Want to know the exact value of your device?</th>
                                </tr>

                                <tr>
                                    <td>Key Board Faulty</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.KeyboardFaultyPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("KeyboardFaultyPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Trackpad Faulty</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.TrackpadFaultyPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("TrackpadFaultyPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Speaker Issue</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.SpeakerIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("SpeakerIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Mic Issue</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.MicIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("MicIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Usb Port Issue </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.UsbPortIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("UsbPortIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Charging Port Issue</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.CharginPortIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("CharginPortIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Charger Faulty </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ChargerFaultyPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ChargerFaultyPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Battery Issue</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.BatteryIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("BatteryIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Hard drive/ SSD issue</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.HardDriveFaultyPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HardDriveFaultyPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Wiffi Issue</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.WifiIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("WifiIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Bluetooth Isuue</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.BluetoothIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("BluetoothIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Ram issue</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.RamIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("RamIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Graphics card issue </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.GraphicsCardIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("GraphicsCardIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Auto Restart Issue</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.AutoRestartIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("AutoRestartIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Ear Phone Port Issue</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.EarphonePortIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("EarphonePortIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Web cam Faulty</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.WebcamFaulty}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("WebcamFaulty")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Hinge loose </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.HingeLoosePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HingeLoosePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Finger Print Issue</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.FingerPrintIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("FingerPrintIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Face Sensor Issue</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.FaceSensorIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("FaceSensorIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>Tell us about the cosmetic/physical condition of your device</th>
                                </tr>
                                <tr>
                                    <td>Condition of Device Screen (Minor)</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.deviceConditionMinorPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceConditionMinorPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Screen (Major)</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.deviceConditionMajorPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceConditionMajorPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Screen (No Scratches)</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.deviceConditionNoScratchPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceConditionNoScratchPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Body (Excellent)</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.deviceBodyConditionExcellentPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceBodyConditionExcellentPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Body (Good)</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.deviceBodyConditionGoodPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceBodyConditionGoodPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Body (Average)</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.deviceBodyConditionAveragePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceBodyConditionAveragePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Body (Poor)</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.deviceBodyConditionPoorPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceBodyConditionPoorPer")}><FaPen /></i></td>
                                </tr>

                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>  Select the Original Accessories available with your Device.</th>
                                </tr>
                                <tr>
                                    <td>   Box (with same IMEI/Serial number)</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.HaveBoxWithIMEIPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveBoxWithIMEIPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Power cable</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.HavePowerCablePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HavePowerCablePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Adapter</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.HaveAdapterPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveAdapterPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Functional Keyboard</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.HaveFunctKeyBoardPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveFunctKeyBoardPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Functional Mouse</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.HaveFunctMousePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveFunctMousePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Have Bill </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.HaveBillPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveBillPer")}><FaPen /></i></td>
                                </tr>

                                <tr>
                                    <td> Insurance AppleCare </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.addInsAppleCarePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("addInsAppleCarePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Insurance SamsungCare </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.addInsSamsungCarePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("addInsSamsungCarePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Insurance 3rd Prty </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.addInsThirdPartyPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("addInsThirdPartyPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Insurance Not Applicable </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.addInsNotApplicablePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("addInsNotApplicablePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Purchased Place (India) </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.purchasePlaceIndiaPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("purchasePlaceIndiaPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Purchased Place (Abroad) </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.purchasePlaceAbroadPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("purchasePlaceAbroadPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (below 1 month) </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.billBelowOneMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billBelowOneMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (1 to 3 month) </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.billOneToThreeMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billOneToThreeMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (3 to 6 month)</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.billThreeToSixMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billThreeToSixMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (6 to 9 month)</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.billSixToNineMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billSixToNineMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (9to 12 month) </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.billNineToTwelveMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billNineToTwelveMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period ( Above 1 year) </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.billAboveOneYear}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billAboveOneYear")}><FaPen /></i></td>
                                </tr>

                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>  Storage Price </th>
                                </tr>
                                <tr>
                                    <td> 250 GB HDD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage250GbHDD}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage250GbHDD")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 320 GB HDD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage320GbHDD}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage320GbHDD")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 500 GB HDD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage500GbHDD}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage500GbHDD")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 640 GB HDD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage640GbHDD}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage640GbHDD")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 750 GB HDD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage750GbHDD}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage750GbHDD")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 1 TB HDD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage1TbHDD}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage1TbHDD")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 1.5 TB HDD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage1P5TbHDD}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage1P5TbHDD")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 128 GB SSD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage128SDD}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage128SDD")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 256 GB SSD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage256SDD}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage256SDD")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 2 TB HDD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage2TbHDD}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage2TbHDD")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 500 GB HDD + 128 GB SSD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage500Plus128SDD}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage500Plus128SDD")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 1 TB HDD + 128 GB SSD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage1TbPlus128SDD}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage1TbPlus128SDD")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 512 GB  SSD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage512SSD}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage512SSD")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 1 TB HDD + 256 GB SSD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage1TbHDDPlus256Ssd}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage1TbHDDPlus256Ssd")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 1 TB HDD + 512 GB SSD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage1TbHDDPlus512Ssd}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage1TbHDDPlus512Ssd")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 1 TB SSD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage1Tbssd}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage1Tbssd")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 2 TB SSD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage2Tbssd}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage2Tbssd")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 3 TB SSD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage3Tbssd}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage3Tbssd")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 4 TB SSD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage4Tbssd}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage4Tbssd")}><FaPen /></i></td>
                                </tr>

                                <tr>
                                    <td> 2 TB</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage2tb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage2tb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 1TB HDD + 128GB SSD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage1tbHDDPlus128GBSSD}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage1tbHDDPlus128GBSSD")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 2TB HDD + 128GB SSD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage2tbHDDPlus128GBSSD}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage2tbHDDPlus128GBSSD")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 2TB HDD + 256GB SSD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage2tbHDDPlus256GBSSD}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage2tbHDDPlus256GBSSD")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 2TB HDD + 512GB SSD</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.storage2tbHDDPlus512GBSSD}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage2tbHDDPlus512GBSSD")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>  Ram Price</th>
                                </tr>
                                <tr>
                                    <td> 2 GB</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ram2gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram2gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 3 GB</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ram3gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram3gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 4 GB</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ram4gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram4gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 6 GB</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ram6gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram6gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 8 GB</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ram8gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram8gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 12 GB</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ram12gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram12gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 16 GB</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ram16gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram16gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 32 GB</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ram32gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram32gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 18 GB</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ram18gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram18gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 24 GB</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ram24gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram24gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 64 GB</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ram64gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram64gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 128 GB</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ram128gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram128gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>  Graphics Card Price </th>
                                </tr>
                                <tr>
                                    <td> Yes</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.GraphicsCardYesPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("GraphicsCardYesPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> No</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.GraphicsCardNoPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("GraphicsCardNoPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}> Screen Size Percentage </th>
                                </tr>
                                <tr>
                                    <td> 10-11 Inch</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ScreenSize10To11Per}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ScreenSize10To11Per")}><FaPen /></i></td>
                                </tr>
                             
                                <tr>
                                    <td> 12-13 Inch</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ScreenSize12To13Per}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ScreenSize12To13Per")}><FaPen /></i></td>
                                </tr>
                             
                                <tr>
                                    <td> 13-14 Inch</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ScreenSize13To14Per}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ScreenSize13To14Per")}><FaPen /></i></td>
                                </tr>
                             
                                <tr>
                                    <td> 14-15 Inch</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ScreenSize14To15Per}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ScreenSize14To15Per")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 15-20 Inch</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ScreenSize15To20Per}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ScreenSize15To20Per")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 20-30 Inch</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ScreenSize20To30Per}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ScreenSize20To30Per")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Above 30 Inches</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.ScreenSizeAbove30Per}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ScreenSizeAbove30Per")}><FaPen /></i></td>
                                </tr>
                             
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}> Processors Price</th>
                                </tr>
                                <tr>
                                    <td> Intel Core i3 3rd Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI33Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI33Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i3 4th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI34Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI34Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i3 5th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI35Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI35Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i3 6th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI36Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI36Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i3 7th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI37Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI37Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i3 8th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI38Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI38Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i3 9th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI39Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI39Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i3 10th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI310Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI310Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i3 11th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI311Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI311Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i3 12th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI312Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI312Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i5 3rd Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI53Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI53Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i5 4th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI54Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI54Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i5 5th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI55Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI55Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i5 6th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI56Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI56Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i5 7th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI57Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI57Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i5 8th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI58Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI58Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i5 9th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI59Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI59Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i5 10th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI510Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI510Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i5 11th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI511Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI511Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i5 12th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI512Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI512Gen")}><FaPen /></i></td>
                                </tr>
                             
                                <tr>
                                    <td> Intel Core i7 3rd Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI73Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI73Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i7 4th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI74Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI74Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i7 5th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI75Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI75Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i7 6th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI76Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI76Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i7 7th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI77Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI77Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i7 8th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI78Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI78Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i7 9th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI79Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI79Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i7 10th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI710Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI710Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i7 11th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI711Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI711Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i9 8th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI98Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI98Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i9 9th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI99Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI99Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i9 10th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI910Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI910Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core i9 11th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreI911Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreI911Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Apple M1 Chip</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.appleM1Chip}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("appleM1Chip")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Apple M1 Max Chip</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.appleM1MaxChip}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("appleM1MaxChip")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Apple M1 Pro Chip</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.appleM1ProChip}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("appleM1ProChip")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Apple M1 Plus Chip</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.appleM1PlusChip}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("appleM1PlusChip")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Apple M2 Chip</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.appleM2Chip}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("appleM2Chip")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Apple M2 Max Chip</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.appleM2MaxChip}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("appleM2MaxChip")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Apple M2 Pro Chip</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.appleM2ProChip}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("appleM2ProChip")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Apple M2 Plus Chip</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.appleM2PlusChip}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("appleM2PlusChip")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> AMD A9</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdA9}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdA9")}><FaPen /></i></td>
                                </tr>
                            
                                <tr>
                                    <td> AMD A10</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdA10}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdA10")}><FaPen /></i></td>
                                </tr>
                            
                                <tr>
                                    <td> AMD A4</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdA4}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdA4")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> AMD A6</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdA6}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdA6")}><FaPen /></i></td>
                                </tr>
                            
                                <tr>
                                    <td> AMD A8</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdA8}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdA8")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> AMD Brazos Dual Core C-70</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdBrazosDualCoreC70}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdBrazosDualCoreC70")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> AMD Dual Core A6</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdDualCoreA6}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdDualCoreA6")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> AMD Dual Core A9 7th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdDualCoreA97gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdDualCoreA97gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> AMD Dual Core E1</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdDualCoreE1}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdDualCoreE1")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> AMD Dual Core E2</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdDualCoreE2}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdDualCoreE2")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> AMD E Series</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdSeries}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdSeries")}><FaPen /></i></td>
                                </tr>
                            
                                <tr>
                                    <td> AMD Dual E 1</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdE1}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdE1")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> AMD Quad Core</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdQuadCore}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdQuadCore")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> AMD Quad Core A10</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdQuadCoreA10}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdQuadCoreA10")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> AMD Quad Core A15</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdQuadCoreA15}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdQuadCoreA15")}><FaPen /></i></td>
                                </tr>
                            
                                <tr>
                                    <td> AMD Quad Core A4</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdQuadCoreA4}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdQuadCoreA4")}><FaPen /></i></td>
                                </tr>
                            
                                <tr>
                                    <td> AMD Quad Core A6</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdQuadCoreA6}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdQuadCoreA6")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> AMD Quad Core A8</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdQuadCoreA8}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdQuadCoreA8")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> AMD Quad Core E2 7th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdQuadCoreE27thGen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdQuadCoreE27thGen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> AMD Quad Core E2 6th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdQuadCoreE26thGen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdQuadCoreE26thGen")}><FaPen /></i></td>
                                </tr>
                            
                                <tr>
                                    <td> AMD Ryzen 3</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdRyzen3}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdRyzen3")}><FaPen /></i></td>
                                </tr>
                            
                            
                                <tr>
                                    <td> AMD Ryzen 5</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdRyzen5}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdRyzen5")}><FaPen /></i></td>
                                </tr>
                            
                            
                                <tr>
                                    <td> AMD Ryzen 5 Pro</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdRyzen5Pro}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdRyzen5Pro")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> AMD Ryzen 7</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdRyzen7}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdRyzen7")}><FaPen /></i></td>
                                </tr>
                            
                                <tr>
                                    <td> AMD Ryzen 7 Pro</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdRyzen7Pro}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdRyzen7Pro")}><FaPen /></i></td>
                                </tr>
                            
                                <tr>
                                    <td> AMD Ryzen 9</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdRyzen9}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdRyzen9")}><FaPen /></i></td>
                                </tr>
                            
                                <tr>
                                    <td> AMD Ryzen Threadripper</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.amdRyzenThreadDripper}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("amdRyzenThreadDripper")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Celeron Dual Core 3rd Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCeleronDualCore3gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCeleronDualCore3gen")}><FaPen /></i></td>
                                </tr>
                            
                                <tr>
                                    <td> Intel Celeron Dual Core 4th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCeleronDualCore4gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCeleronDualCore4gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Celeron Dual Core 5th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCeleronDualCore5gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCeleronDualCore5gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Celeron Quad Core 4th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCeleronQuadCore4gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCeleronQuadCore4gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core M3 6th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreM36gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreM36gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core M3 7th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreM37gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreM37gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core M3 8th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreM38gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreM38gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core M5 5th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreM55gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreM55gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core M5 6th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreM56gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreM56gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Core M7 6th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelCoreM76gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelCoreM76gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Pentium Dual Core 3rd Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelPentiumDualCore3Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelPentiumDualCore3Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Pentium Dual Core 4th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelPentiumDualCore4Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelPentiumDualCore4Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Pentium Octa Core</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelPentiumOctaCore}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelPentiumOctaCore")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Pentium Quad Core</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelPentiumQuadCore}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelPentiumQuadCore")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Pentium Quad Core 3rd Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelPentiumQuadCore3rdGen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelPentiumQuadCore3rdGen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Pentium Quad Core 4th Gen</td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelPentiumQuadCore4Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelPentiumQuadCore4Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Pentium Octa Core </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelPentiumOctaCore}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelPentiumOctaCore")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Pentium Quad Core </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelPentiumQuadCore}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelPentiumQuadCore")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Pentium Quad Core 3rd Gen </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelPentiumQuadCore3rdGen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelPentiumQuadCore3rdGen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Pentium Quad Core 4th Gen </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelPentiumQuadCore4Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelPentiumQuadCore4Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Pentium Quad Core 6th Gen </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelPentiumQuadCore6Gen}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelPentiumQuadCore6Gen")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Intel Xeon </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.intelXeon}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("intelXeon")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Microsoft SQ1 </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.microSoftSq1}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("microSoftSq1")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Microsoft SQ2 </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.microSoftSq2}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("microSoftSq2")}><FaPen /></i></td>
                                </tr>
                            
                                <tr>
                                    <td> Quad Core </td>
                                    <td>{getDesktopQuestion?.getDesktopQuestion[0]?.quadCore}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("quadCore")}><FaPen /></i></td>
                                </tr>
                         
                                {
                                    processorNames.map((item, index) => {

                                        return (
                                            <tr key={index}>
                                                <td>{item.name}</td>
                                                <td>{getDesktopQuestion?.getDesktopQuestion[0]?.[item.field]}</td>
                                                <td><i className='bx bx-book-alt' onClick={() => setQuestionName(item.field)}><FaPen /></i></td>
                                            </tr>
                                        )
                                    })


                                }
                            
                            </thead>
                        </Table>
                    </>
            }
            {

            }
            <Modal show={showEditModal} onHide={() => setEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Percentage</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="number" placeholder="Enter value" onChange={(e) => setEditPercentage(e.target.value)} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => { handleEdit1(); }}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default AddDesktopQuestionariesPer