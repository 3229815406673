import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import useRemainingAmtPay from "../../hooks/useRemainingAmtPay";
import {
  paymentInfoModalOpen,
  paymentSuccessAtom,
} from "../../../../store/atoms/payment/paymentAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import PaymentSuccessComponent from "../../../buying/components/payment/modals/PaymentSuccessComponent";

const PaymentDetails = ({ orderDetails, currentItem, orderType }) => {
  const getActiveItemsCount = (items) =>
    items.filter(
      (item) =>
        item?.unit?.status[item?.unit?.status?.length - 1]?.status !==
        "cancelled"
    ).length;

  const itemsCount = getActiveItemsCount(orderDetails.items);

  const lastStatus = currentItem.unit.status.length
    ? currentItem.unit.status.slice(-1)[0]
    : null;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const { isRazorpayLoaded, handlePayment } = useRemainingAmtPay();
  const paymentSuccess = useRecoilValue(paymentSuccessAtom);
  const [paymentModalOpen, setPaymentModalOpen] =
    useRecoilState(paymentInfoModalOpen);
  const [openModal, setOpenModal] = useState(false);
  const addressDetails = {
    name: orderDetails.address.name,
    email: orderDetails.address.email,
    contact: orderDetails.address.contact,
  };

  useEffect(() => {
    if (paymentModalOpen) {
      setOpenModal(true);
    }
  }, [paymentModalOpen]);
  const handlePay = () => {
    handlePayment(
      "Razorpay",
      "remaining",
      orderDetails.remainingAmount,
      orderDetails.id,
      addressDetails
    );
  };
  const couponDiscount =
    orderDetails.couponDiscount / orderDetails.items.length;
  let totalAmount = Number(currentItem.product.productPrice) - couponDiscount;

  if (
    orderDetails.orderType === "advance" &&
    lastStatus.status !== "cancelled"
  ) {
    if (orderDetails.advancePaid) {
      totalAmount -= orderDetails.advanceFee / itemsCount;
    }
  }
  const discountOnMRP =
    currentItem.product.mrp - currentItem.product.productPrice;

  return (
    <>
      <Box
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          background: "linear-gradient(120deg,#F6EDED 8%,#DFE8EA 76%)",
          width: "100%",
          borderRadius: isSmallScreen ? "0" : "0.5rem",
          minHeight: "60px",
          display: "flex",
          padding: isSmallScreen ? "1rem" : "2rem",
        }}
      >
        <Box display={"flex"} flexDirection={"column"} flexGrow={1}>
          <CustomTypography variant={"subtitle1"} sx={{ fontWeight: "600" }}>
            Order Payment Details
          </CustomTypography>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ padding: "0.5rem 0", borderBottom: "1px solid #C4C2BD" }}
          >
            <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "500" }}>
              MRP
            </CustomTypography>
            <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "600" }}>
              ₹{currentItem.product.mrp}
            </CustomTypography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ padding: "0.5rem 0", borderBottom: "1px solid #C4C2BD" }}
          >
            <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "500" }}>
              Discount on MRP
            </CustomTypography>
            <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "600" }}>
              ₹{discountOnMRP}
            </CustomTypography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ padding: "0.5rem 0", borderBottom: "1px solid #C4C2BD" }}
          >
            <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "500" }}>
              Coupon Discount
            </CustomTypography>
            <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "600" }}>
              ₹{couponDiscount}
            </CustomTypography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ padding: "0.5rem 0", borderBottom: "1px solid #C4C2BD" }}
          >
            <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "500" }}>
              Platform Fee
            </CustomTypography>
            <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "600" }}>
              {orderDetails.platformFee > 0
                ? `₹${orderDetails.platformFee}`
                : "free"}
            </CustomTypography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ padding: "0.5rem 0", borderBottom: "1px solid #C4C2BD" }}
          >
            <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "500" }}>
              Deliver Charges
            </CustomTypography>
            <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "600" }}>
              {orderDetails.deliveryCharges > 0
                ? `₹${orderDetails.deliveryCharges}`
                : "free"}
            </CustomTypography>
          </Box>

          <Box
            display={"flex"}
            justifyContent={"space-between"}
            sx={{ padding: "0.5rem 0", borderBottom: "1px solid #C4C2BD" }}
          >
            <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "700" }}>
              Total Amount
            </CustomTypography>
            <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "700" }}>
              ₹{totalAmount}
            </CustomTypography>
          </Box>
          {orderDetails?.remainingAmount > 0 &&
            orderDetails?.orderType === "cod" &&
            orderDetails?.paymentStatus === "cod_pending" && (
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"center"}
              >
                <CustomTypography
                  variant="subtitle2"
                  sx={{ fontWeight: "500" }}
                >
                  Amount to be collected at the time delivery.
                </CustomTypography>
              </Box>
            )}
          {orderDetails?.remainingAmountPaymentLink &&
            lastStatus?.status !== "cancelled" && (
              <>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  sx={{
                    padding: "0.5rem 0",
                    borderBottom: "1px solid #C4C2BD",
                  }}
                >
                  <CustomTypography
                    variant={"subtitle2"}
                    sx={{ fontWeight: "700" }}
                  >
                    Remaining Amount (to be paid) <br />
                    Click here to{" "}
                    <span
                      onClick={handlePay}
                      style={{
                        color: "#0077c0",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      pay
                    </span>
                  </CustomTypography>
                  <CustomTypography
                    variant={"subtitle2"}
                    sx={{ fontWeight: "700" }}
                  >
                    ₹{orderDetails.remainingAmount}
                  </CustomTypography>
                </Box>
                {orderDetails.items.length > 1 &&
                  orderDetails.itemsToBeDelivered > 1 && (
                    <Box display={"flex"} justifyContent={"center"}>
                      <CustomTypography
                        variant={"subtitle2"}
                        sx={{ fontWeight: "600" }}
                      >
                        Note :
                        <span style={{ fontWeight: "300" }}>
                          This is inclusive of all the items booked together
                        </span>
                      </CustomTypography>
                    </Box>
                  )}
              </>
            )}
        </Box>
      </Box>
      <PaymentSuccessComponent
        success={paymentSuccess}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default PaymentDetails;
