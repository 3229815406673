import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import '../ComponentsCss/DealerCard.css'
import { HiShieldCheck } from "react-icons/hi";
import { FaMapMarkedAlt } from "react-icons/fa";
import step1 from "../Images/homepage/workflow/step1.png"
import step2 from "../Images/homepage/workflow/step2.png"
import step3 from "../Images/homepage/workflow/step3.png"
import step4 from "../Images/homepage/workflow/step4.png"
import step5 from "../Images/homepage/workflow/step5.png"
import business from "../Images/homepage/b2.png"


export default function WorkFlow() {

  return (
    <>
      <Container className='cardbgservice2' style={{ marginTop: 5 }} fluid id='workflow'>
        <Container>
          <Row>
            <Col md={5}>
              <div style={{ display: "flex", marginTop: 10 }}>
                <div style={{ marginTop: '-25px' }}>
                  <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 1</h1>
                  <div className='SellBox'>
                    <Image src={step1} style={{ width: 50, height: 50 }} />
                  </div>
                  <div className='lineArrow1'></div>
                  <div id="dialog2" class="triangle_down1"></div>
                </div>
                <div style={{ marginLeft: 20 }}>
                  <h5 style={{ marginTop: 10, fontSize: 18, fontWeight: 600 }}>Pack your Products</h5>
                  <p style={{ fontSize: 13, fontWeight: 500 }}>Pack your products safely to avoid damage in transit. Use Bubblewraps, thermocols. </p>
                </div>
              </div>

              <div style={{ display: "flex", marginTop: 10 }}>
                <div style={{ marginTop: '-25px' }}>
                  <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 2</h1>
                  <div className='SellBox'>
                    <Image src={step2} style={{ width: 50, height: 50 }} />
                  </div>
                  <div className='lineArrow2'></div>
                  <div id="dialog2" class="triangle_down2"></div>
                </div>
                <div style={{ marginLeft: 20 }}>
                  <h5 style={{ marginTop: 10, fontSize: 18, fontWeight: 600 }}>Handover the Package</h5>
                  <p style={{ fontSize: 13, fontWeight: 500 }}>Handover the package to the renowned logistics partner assigned for the job. You can track your device with the tracking number provided to you. </p>
                </div>
              </div>


              <div style={{ display: "flex", marginTop: 10 }}>
                <div style={{ marginTop: '-25px' }}>
                  <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 3</h1>
                  <div className='SellBox'>
                    <Image src={step3} style={{ width: 50, height: 50 }} />
                  </div>
                  <div className='lineArrow3'></div>
                  <div id="dialog2" class="triangle_down3"></div>
                </div>
                <div style={{ marginLeft: 20 }}>
                  <h5 style={{ marginTop: 10, fontSize: 18, fontWeight: 600 }}>Product Reached Our Headoffice</h5>
                  <p style={{ fontSize: 13, fontWeight: 500 }}> Once Product reaches our Office you will be notified through Email/SMS. </p>
                </div>
              </div>
              <div style={{ display: "flex", marginTop: 10 }}>
                <div style={{ marginTop: '-25px' }}>
                  <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 4</h1>
                  <div className='SellBox'>
                    <Image src={step4} style={{ width: 50, height: 50 }} />
                  </div>
                  <div className='lineArrow4'></div>
                  <div id="dialog2" class="triangle_down4"></div>
                </div>
                <div style={{ marginLeft: 20 }}>
                  <h5 style={{ marginTop: 10, fontSize: 18, fontWeight: 600 }}>Inspection of your device</h5>
                  <p style={{ fontSize: 13, fontWeight: 500 }}>We will do the  Inspection of your device and the result will be notified through Email. Usually it will be completed on the same business day. </p>
                </div>
              </div>

              <div style={{ display: "flex", marginTop: 10 }}>
                <div style={{ marginTop: '-25px' }}>
                  <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 5</h1>
                  <div className='SellBox'>
                    <Image src={step5} style={{ width: 50, height: 50 }} />
                  </div>

                </div>
                <div style={{ marginLeft: 20 }}>
                  <h5 style={{ marginTop: 10, fontSize: 18, fontWeight: 600 }}>Payment Completion</h5>
                  <p style={{ fontSize: 13, fontWeight: 500 }}>Once Inspection is completed Payment will be done on the same day based on Inspection results. </p>
                </div>
              </div>
            </Col>
            <Col md={7}>

              {/* <Lottie options={defaultOptions} style={{ width: "80%", height: "80%", marginTop: 20 }} className="mx-auto d-block" /> */}
              <Image src={business} fluid />

            </Col>
          </Row>
          <Row>
            <Col md={6} xs={6}>
              <div className='whycardNew' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='imgwhyNew'>
                  <HiShieldCheck color="#fff" className='imgchooseNew' />
                </div>
                <div style={{ textAling: 'left' }}>
                  <h4 className='txtchooseNew'>100% Safe and Secure</h4>
                </div>
              </div>
            </Col>
            <Col md={6} xs={6}>
              <div className='whycardNew' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className='imgwhyNew'>
                  <FaMapMarkedAlt color="#fff" className='imgchooseNew' />
                </div>
                <div style={{ textAling: 'left' }}>
                  <h4 className='txtchooseNew'>Real Time Tracking</h4>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  )
}

//d-none d-lg-block