import { Container, Form, Button, Card, Row, Col, Breadcrumb, Image, Dropdown, InputGroup } from "react-bootstrap"
import Footer from "../../Footer"
import Navigation from "../../Navigation"
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
// import { FaSearch, FaArrowCircleRight } from 'react-icons/fa'
import { FaArrowRight } from "react-icons/fa";
import { useEffect, useState } from "react";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";


const PickSlot = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const [checkBocClick, setCheckboxClick] = useState(false);
    const [pickupDateState, setPickUpDateState] = useState(false);
    const [pickupTimeStateAM, setPickUpTimeStateAM] = useState();
    const [pickupTimeStatePM, setPickUpTimeStatePM] = useState();
    const [pickupTimeState, setPickUpTimeState] = useState();
    const [isTimeShow, setIsTimeShow] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleProceed = () => {
        navigate("/checkout/payment", {
            state: {
                id: location.state.id, basePrice: location.state.basePrice, imgurl: location.state.imgurl, flateState: location.state.flateState, nameState: location.state.nameState, emailState: location.state.emailState, contactState: location.state.contactState, stateState: location.state.stateState
                , cityState: location.state.cityState, landmarkState: location.state.landmarkState, pincodeState: location.state.pincodeState, alternativeMobState: location.state.alternativeMobState, saveAsState: location.state.saveAsState, wantToPickupYourself: checkBocClick,
                pickupDateState: pickupDateState, pickupTimeStateAM: pickupTimeStateAM, pickupTimeStatePM: pickupTimeStatePM, pickupTimeState: pickupTimeState, ram: location.state.ram, storage: location.state.storage, isDeviceSwitch: location.state.isDeviceSwitch, ableToMakeReceiveCall: location.state.ableToMakeReceiveCall, issueWithDeviceDisplay: location.state.issueWithDeviceDisplay, deviceScreen: location.state.deviceScreen, deviceEverRepair: location.state.deviceEverRepair,
                FrontCameraIssue: location.state.FrontCameraIssue, BackCameraIssue: location.state.BackCameraIssue, SpeakerIssue: location.state.SpeakerIssue, MicIssue: location.state.MicIssue, RingerIssue: location.state.RingerIssue, CharginPortIssue: location.state.CharginPortIssue, SensorIssue: location.state.SensorIssue, BatteryIssue: location.state.BatteryIssue,
                ButtonIssue: location.state.ButtonIssue, WifiIssue: location.state.WifiIssue, BluetoothIssue: location.state.BluetoothIssue, FlashLightIssue: location.state.FlashLightIssue, AutoRestartIssue: location.state.AutoRestartIssue, EarphonePortIssue: location.state.EarphonePortIssue, GpsIssue: location.state.GpsIssue, VibratorIssue: location.state.VibratorIssue,
                SimCardIssue: location.state.SimCardIssue, ConditionDeviceScreen: location.state.ConditionDeviceScreen, ConditionDeviceBody: location.state.ConditionDeviceBody, HaveBoxWithIMEI: location.state.HaveBoxWithIMEI, HaveDataCable: location.state.HaveDataCable, HaveAdapter: location.state.HaveAdapter, HaveEarphones: location.state.HaveEarphones, PurchasedPlace: location.state.PurchasedPlace, AdditionalInsurance: location.state.AdditionalInsurance, HaveBill: location.state.HaveBill
                , WarrantyPeriod: location.state.WarrantyPeriod, productCategory: location.state.productCategory,
                productBrand: location.state.productBrand,
                productName: location.state.productName, defects: location.state.defects, HaveApplePencil: location.state.HaveApplePencil,
                id: location.state.id, isDeviceSwitch: location.state.isDeviceSwitch, issueWithDeviceDisplay: location.state.issueWithDeviceDisplay, deviceScreen: location.state.deviceScreen, deviceEverRepair: location.state.deviceEverRepair,
                keyBoardState: location.state.keyBoardState, trackpadState: location.state.trackpadState, SpeakerIssue: location.state.SpeakerIssue, MicIssue: location.state.MicIssue, usbPortState: location.state.usbPortState, CharginPortIssue: location.state.CharginPortIssue, webCamState: location.state.webCamState, BatteryIssue: location.state.BatteryIssue, hingeLooseState: location.state.hingeLooseState, WifiIssue: location.state.WifiIssue, BluetoothIssue: location.state.BluetoothIssue, chargerFaultyState: location.state.chargerFaultyState, AutoRestartIssue: location.state.AutoRestartIssue, EarphonePortIssue: location.state.EarphonePortIssue, graphicsCardState: location.state.graphicsCardState, hardDriveState: location.state.hardDriveState, ramIssueState: location.state.ramIssueState, productCategory: location.state.productCategory, productBrand: location.state.productBrand,
                productName: location.state.productName, imgurl: location.state.imgurl, processorState: location.state.processorState, laptopStorage: location.state.laptopStorage, laptopRam: location.state.laptopRam, graphicsState: location.state.graphicsState, screenSizeState: location.state.screenSizeState, ConditionDeviceScreen: location.state.ConditionDeviceScreen, ConditionDeviceBody: location.state.ConditionDeviceBody, boxClick: location.state.boxClick, powerCableClick: location.state.powerCableClick, adapterClick: location.state.adapterClick, purchasedPlaceClick: location.state.purchasedPlaceClick, additionalClick: location.state.additionalClick, warrantyPeriod: location.state.warrantyPeriod, defects: location.state.defects, funcKeyboardClick: location.state.funcKeyboardClick, funcMouseClick: location.state.funcMouseClick
                , strapClick: location.state.strapClick, financeClick: location.state.financeClick, basePrice: location.state.basePrice
                , addressId: location.state.addressId
            }
        })
    }
    // const today = new Date()
    const todayDate = new Date().toISOString().split('T')[0];
    const handleTimeChange = (selectedDates) => {
        // if (selectedDates.length > 0) {
        setPickUpTimeState(selectedDates);
        // }
    };
    // Helper function to check if a time is within a specified range
    const isTimeWithinRange = (time, minTime, maxTime) => {
        return time >= minTime && time <= maxTime;
    };
    const handleTimeInput = (newValue) => {
        // Check if the new time is within the valid range (10 AM to 8 PM)
        const isValidTime = isTimeWithinRange(newValue, "10:00", "20:00");

        if (isValidTime) {
            handleTimeChange(newValue)
        } else {
            // You can handle an invalid time selection here (e.g., show an error message).
            handleTimeChange("")
        }
    };
    const handleTimeSlot = (newValue) => {
        setPickUpTimeState(newValue)
        setIsTimeShow(true)
    }
    const handleSelectBrand = () => {
        let cat = location.state?.productCategory
        if (cat === "Desktop/Imac") {
            cat = "Desktop-Imac"
        }
        navigate(`/selling/${cat}`,)
    }
    const handleSelectProduct = () => {
        navigate(`/allbrands/${location.state?.productBrand}`, {
            state: {
                productCategory: location.state?.productCategory, productBrand: location.state?.productBrand,
            }
        })
    }
    return (
        <>
            <Navigation />
            <div className="banner">
                <h2>
                    Please Select Your Pick Up Slot
                </h2>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="search-input"

                    />
                    {/* <div className="search-icon">
                        <FaSearch />
                    </div> */}
                </div>
                <Breadcrumb>
                    <Breadcrumb.Item ><Link to="/">Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a onClick={handleSelectBrand}>  Select Brand</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={handleSelectProduct}>Sell Product</Breadcrumb.Item>
                </Breadcrumb>

            </div>

            <Container>
                <Row>
                    <Col md={6}>
                        <Card>
                            <Card.Title style={{ fontWeight: "bold", margin: "1rem" }}>Pick Up Slot</Card.Title>

                            <Card.Text style={{ fontWeight: "bold", marginTop: "1rem" }} >
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        required
                                        label="Please check if you want to drop your item in our Office ."
                                        feedbackType="invalid"
                                        onClick={() => checkBocClick ? setCheckboxClick(false) : setCheckboxClick(true)}
                                    />
                                </Form.Group>
                            </Card.Text>
                        </Card>
                        <Card style={{ marginTop: "1rem" }}>
                            {!checkBocClick ?
                                <>

                                    <Card.Body>
                                        <>
                                            <Card.Text style={{ fontWeight: "bold" }}>Please Select your preferable pickup date</Card.Text>

                                            <Row>
                                                <Form.Group as={Col} md="4" controlId="validationCustom01" style={{ width: "100%", marginTop: ".8rem", fontWeight: "bold" }}>
                                                    <Form.Label>Select Date</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="date"
                                                        onChange={(e) => setPickUpDateState(e.target.value)}
                                                        value={pickupDateState}
                                                        // max={todayDate}
                                                        min={todayDate} // Set the min attribute to the current date

                                                    />
                                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                </Form.Group>


                                                <Card.Text style={{ marginTop: "2rem", fontWeight: "bold" }}>Please Select your preferable time</Card.Text>
                                                <Card style={{ width: "12rem", margin: "1rem" }} className={pickupTimeState == "10:AM-12:PM" ? "greenColorBorder" : "hover-effect"} onClick={() => handleTimeSlot("10:AM-12:PM")} >
                                                    <Card.Body>

                                                        <Card.Title style={{ fontSize: "12px", fontWeight: "bold" }} >
                                                            10:00 AM - 12:00 PM
                                                        </Card.Title>

                                                    </Card.Body>
                                                </Card>
                                                <Card style={{ width: "12rem", margin: "1rem" }} className={pickupTimeState == "12:PM-2:PM" ? "greenColorBorder" : "hover-effect"} onClick={() => handleTimeSlot("12:PM-2:PM")}>
                                                    <Card.Body >
                                                        <Card.Title style={{ fontSize: "12px", fontWeight: "bold" }}>
                                                            12:00 PM - 2:00 PM
                                                        </Card.Title>
                                                    </Card.Body>
                                                </Card>
                                                <Card style={{ width: "12rem", margin: "1rem" }} className={pickupTimeState == "2:PM-4:PM" ? "greenColorBorder" : "hover-effect"} onClick={() => handleTimeSlot("2:PM-4:PM")}>
                                                    <Card.Body >
                                                        <Card.Title style={{ fontSize: "12px", fontWeight: "bold" }}>
                                                            2:00 PM - 4:00 PM
                                                        </Card.Title>
                                                    </Card.Body>
                                                </Card>
                                                <Card style={{ width: "12rem", margin: "1rem" }} className={pickupTimeState == "4:PM-6:PM" ? "greenColorBorder" : "hover-effect"} onClick={() => handleTimeSlot("4:PM-6:PM")}>
                                                    <Card.Body >
                                                        <Card.Title style={{ fontSize: "12px", fontWeight: "bold" }}>
                                                            4:00 PM - 6:00 PM
                                                        </Card.Title>
                                                    </Card.Body>
                                                </Card>
                                                <Card style={{ width: "12rem", margin: "1rem" }} className={pickupTimeState == "6:PM-8:PM" ? "greenColorBorder" : "hover-effect"} onClick={() => handleTimeSlot("6:PM-8:PM")}>
                                                    <Card.Body >
                                                        <Card.Title style={{ fontSize: "12px", fontWeight: "bold" }}>
                                                            6:00 PM - 8:00 PM
                                                        </Card.Title>
                                                    </Card.Body>
                                                </Card>
                                                <Card.Text style={{ fontWeight: "bold" }}> Select Time Manually</Card.Text>
                                                {isTimeShow ? "" :
                                                    <div style={{ width: "15rem", margin: "1rem", textAlign: "center" }} >


                                                        <TimePicker
                                                            onChange={handleTimeInput}
                                                            value={pickupTimeState}
                                                            minTime="10:00"
                                                            maxTime="20:00"
                                                        />


                                                    </div>
                                                }
                                            </Row>
                                        </>
                                    </Card.Body>
                                </> : ""}
                            <Card.Footer >


                                <Button variant="warning" disabled={checkBocClick || pickupDateState && pickupTimeState || pickupTimeStateAM && pickupTimeStatePM ? false : true} onClick={() => handleProceed()} style={{ borderRadius: '0px', width: 150, color: '#fff', fontWeight: 'bold', fontSize: '20px', backgroundColor: "#40a737", border: "0px", borderRadius: '10px' }}>Next <FaArrowRight /></Button>

                            </Card.Footer>
                        </Card>
                    </Col>

                    <Col md={6}>
                        <Card className="d-none d-sm-block">
                            <Card.Body>

                                <Card.Title style={{ fontWeight: "bold" }}>Price Summary</Card.Title>
                                <Image fluid style={{ width: "8rem", margin: "1rem" }} src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${location.state.imgurl}`} className='mx-auto d-block' />
                                <Card.Text style={{ marginTop: "1rem", fontWeight: "bold" }} className="sell-price"> Final Selling Price<span style={{ color: "rgb(64, 167, 55)" }} className="price-val" > Rs.{location.state.basePrice} </span></Card.Text>

                                <Card.Title style={{ fontWeight: "bold", marginTop: "1rem" }}> Address : </Card.Title>
                                <Card.Text  > <span style={{ fontWeight: "bold" }}>Name: </span> {location.state.nameState}</Card.Text>
                                <div  > <span style={{ fontWeight: "bold", marginLeft: "12px" }}>Email:  </span> {location.state.emailState}  </div>
                                <Card.Text  > <span style={{ fontWeight: "bold" }}>Flat no / Office:  </span> {location.state.flateState}  </Card.Text>
                                <Card.Text  > <span style={{ fontWeight: "bold" }}>Land Mark: </span> {location.state.landmarkState}  </Card.Text>
                                <Card.Text  > <span style={{ fontWeight: "bold" }}> Pin code: </span>  {location.state.pincodeState}  </Card.Text>
                                <Card.Text  ><span style={{ fontWeight: "bold" }}>City: </span> {location.state.cityState}  </Card.Text>
                                <Card.Text  ><span style={{ fontWeight: "bold" }}>State: </span>  {location.state.stateState}  </Card.Text>
                                <Card.Text  > <span style={{ fontWeight: "bold" }}>Contact Number: </span>   {location.state.contactState} ,{location.state?.alternativeMobState} </Card.Text>
                                <Card.Text  ><span style={{ fontWeight: "bold" }}>Address Type: </span> {location.state.saveAsState}  </Card.Text>
                                <Card.Text  >   </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    )
}
export default PickSlot 