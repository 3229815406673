import React from "react";
import { Box } from "@mui/material";
import Navigation from "../../../Components/Navigation";
import { Outlet } from "react-router-dom";
import Footer from "../../../Components/Footer";
import Home from "../components/home/Home";

const HomePage = () => {
  return (
    <Box sx={{ minHeight: "100vh" }}>
      <Navigation />
      <Home />
      <Footer />
    </Box>
  );
};

export default HomePage;
