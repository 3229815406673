import { Button, Card, Col, Container, Image, Row, Spinner } from 'react-bootstrap';
import { FaTag, FaShippingFast } from "react-icons/fa";
import { CartContext } from "../../Contex/ProductDetailContex";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Navigation from '../../Navigation';
import { GET_BUY_PRODUCT_BY_PRICERANGE } from '../../../graphql/Query'
import { useLazyQuery } from '@apollo/client';
import { QUERY_GET_FILTERED_BUY_PRODUCT } from '../../../graphql/Query'
import Footer from '../../Footer';

const NavigatePageBySections = () => {
    const { section,category,filter} = useParams();
    const [products, setProducts] = useState(null); // or useState([]);
    const [loading, setLoading] = useState(true); const navigate = useNavigate();

    const [GetProductByPrice, { loading: buyProLoadingPrice, error: productE, data: getProductByPrice }] = useLazyQuery(GET_BUY_PRODUCT_BY_PRICERANGE);
    let [getFilterProduct, { buyProLoading, productError, data: getFilterBuyProduct }] = useLazyQuery(QUERY_GET_FILTERED_BUY_PRODUCT);

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            setLoading(true);

            if (section == 1) {
                try {
                    await GetProductByPrice({
                        variables: {
                            productRange:filter,
                            category: category,
                        },
                    }).then((data) => {
                        setProducts(data.data.getProductByPrice);
                        setLoading(false)
                    });
                } catch (error) {
                    console.error("Error fetching data:", error);
                    // Handle error as needed (e.g., show a toast, log, etc.)
                }
            }
            if (section == 2) {
                try {
                    await getFilterProduct({
                        variables: {
                            "filterFileld": "productCategory",
                            "filterVal": category,
                            "filterFileld2": "productBrand",
                            "filterVal2": filter

                        }
                    }).then((data) => {
                        setProducts(data.data.getFilterBuyProduct);
                        setLoading(false)
                    });

                } catch (error) {
                    console.error("Error fetching data:", error);
                    // Handle error as needed (e.g., show a toast, log, etc.)
                }
            }
            if (section == 3) {
                try {
                    await getFilterProduct({
                        variables: {
                            "filterFileld": "productCategory",
                            "filterVal": category,
                            "filterFileld2": "ram",
                            "filterVal2": filter

                        }
                    }).then((data) => {

                        setProducts(data.data.getFilterBuyProduct);
                        setLoading(false)

                    });
                } catch (error) {
                    console.error("Error fetching data:", error);
                    // Handle error as needed (e.g., show a toast, log, etc.)
                }
            }
        };

        fetchData();
    }, [section,filter, category]);

    const handleProductClick = (product) => {
        navigate(`/buying-product-details/${product.id}`, {
            state: {
                product: product
            }
        });
    };

    return (
        <>
            <Navigation />
            {loading && <div className='d-flex justify-content-center align-items-center w-100' style={{ height: "100vh" }}> <Spinner animation="border" variant="primary" className='d-block' /> </div>}
            <div className="d-flex flex-wrap justify-content-start ms-lg-5 ms-md-0">
                {products?.length === 0 && !loading && (
                    <div className="text-center d-flex justify-content-center align-items-center w-100" style={{ flexDirection: 'column' }}>
                        <Image
                            src={'https://img.freepik.com/free-vector/empty-concept-illustration_114360-1188.jpg?w=740&t=st=1695129002~exp=1695129602~hmac=92471af375cbc1dc7a13542cdb5c957d521eef09715afd1b4bb4bc536834e939'}
                            fluid
                            width={300}
                            className="d-block"
                        ></Image>
                        <h1>No Product Found</h1>
                    </div>
                )}
                {products?.length > 0 && (
                    products.map((product, index) => (
                        <Card
                            key={index}
                            className="m-2 shadow-sm bg-body-tertiary rounded buy-card card-hover"
                            onClick={() => handleProductClick(product)}
                        >
                            <div className="product-buy-show">
                                <div className="product-buy-show-body text-center">
                                    <Card.Img
                                        variant="top"
                                        src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${product?.image[0]}`}
                                        className="buyProImg"
                                        style={{ width: '200px', height: '200px', margin: '0 auto' }}
                                        alt={`Product: ${product?.productName}`}
                                    />
                                </div>
                                <div className="product-buy-show-body">
                                    <Card.Body>
                                        <Card.Text>
                                            <h4 className="fs-4">{product?.productName}</h4>
                                            <p className="fs-5">
                                                <span className="fw-bold">₹{product?.productPrice}</span>{' '}
                                                <span className="sellprice tags">₹ {product.mrp}</span>
                                            </p>
                                            <p className="d-flex tags">
                                                <FaShippingFast className="d-block m-1" style={{ fontSize: '14px' }} />{' '}
                                                <span className="d-block">Free Delivery

                                                    {

                                                        parseInt(product?.productPrice) <= 20000 ?
                                                            " + COD Available" : " "


                                                    }
                                                </span>
                                            </p>

                                            <div className="d-flex justify-content-between w-100 tags">
                                                <p className="d-flex">
                                                    <FaTag className="d-block m-1" style={{ fontSize: '12px' }} />{' '}
                                                    <span className="d-block">Easy EMI</span>
                                                </p>
                                                <p className="d-flex ms-1">
                                                    <FaTag className="d-block m-1 " style={{ fontSize: '12px' }} />{' '}
                                                    <span className="d-block ">Bank offer Available</span>
                                                </p>
                                            </div>

                                            <Button className="btn btn-hover w-100 " variant="outline-success">
                                                View Details
                                            </Button>
                                        </Card.Text>
                                    </Card.Body>
                                </div>
                            </div>
                        </Card>
                    ))
                )}
            </div>
            <Footer/>
        </>
    );
};

export default NavigatePageBySections;