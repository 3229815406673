import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { QUERY_GET_GOOGLE_REVIEWS } from "../../../../../graphql/Query";
import { useQuery } from "@apollo/client";
import TestimonialCard from "./TestimonialCard";

const AllTestimonials = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const swiperRef = useRef(null);
  const { data, loading, error } = useQuery(QUERY_GET_GOOGLE_REVIEWS);

  console.log(data);

  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  if (loading) {
    return <>Loading.....</>;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        borderRadius: isMobileScreen ? "0" : "1rem",
        minHeight: "375px",
        width: "100%",
        gap: "0.5rem",
        background:
          "radial-gradient(circle at 30% 30%, rgba(255, 255, 255, 0.8), rgba(117, 188, 196, 0.7), rgba(221, 226, 235, 0.6))",
        position: "relative",
      }}
    >
      <Box
        sx={{
          padding: { xs: "1rem", laptop: "1rem 2rem" },
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CustomTypography
          variant="subtitle1"
          sx={{
            color: "#214C7B",
            fontWeight: "600",
            fontSize: {
              mobile: "1.125rem",
              tablet: "1.25rem",
              laptop: "1.5rem",
              desktop: "1.5rem",
            },
          }}
        >
          - What Our Customers Say -
        </CustomTypography>
      </Box>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          padding: {
            xs: "0 0 1rem 0",
            desktop: "0 2rem 1.5rem 2rem",
          },
        }}
      >
        <Swiper
          ref={swiperRef}
          modules={[Navigation, Pagination]}
          pagination={{
            clickable: true,
            el: ".testimonials-swiper-pagination-custom",
          }}
          navigation={{
            nextEl: ".testimonials-swiper-button-next",
            prevEl: ".testimonials-swiper-button-prev",
          }}
          loop={true}
          centeredSlides={true}
          slidesPerView={1.2}
          breakpoints={{
            0: {
              slidesPerView: 1.2,
              spaceBetween: 10,
            },
            600: {
              slidesPerView: 2.1,
              spaceBetween: 20,
            },

            1025: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          className="customer-testimonials"
          style={{
            width: "100%",
            "--swiper-navigation-color": "#000",
            "--swiper-pagination-color": "#214C7B",
            padding: isMobileScreen ? "3rem 0 1rem 0" : "3rem 0 1rem 0",
          }}
        >
          {data.getGoogleReviews.map((review, index) => (
            <SwiperSlide key={index}>
              <TestimonialCard review={review} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="testimonials-swiper-pagination-custom" />
        <Box
          className="peg-swiper-controls"
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            zIndex: 10,
            top: "50%",
            transform: "translateY(-50%)",
            left: 0,
            right: 0,
            padding: "0 2rem",
          }}
        >
          <button
            className="testimonials-swiper-button-prev"
            aria-label="Previous"
            style={{ border: "2px solid grey" }}
            onClick={handlePrevClick}
          >
            <ArrowBackIosNew fontSize="medium" sx={{ color: "grey" }} />
          </button>
          <button
            className="testimonials-swiper-button-next"
            aria-label="Next"
            style={{ border: "2px solid grey" }}
            onClick={handleNextClick}
          >
            <ArrowForwardIos fontSize="medium" sx={{ color: "grey" }} />
          </button>
        </Box>
      </Box>
      <style jsx>{`
        .customer-testimonials .swiper-slide .customer-testimonials-card {
          transition: all 0.3s ease;
          transform: scale3d(0.95, 0.95, 1);
        }
        @media (min-width: 600px) and (max-width: 821px) {
          .customer-testimonials .swiper-slide .customer-testimonials-card {
            transform: scale3d(0.85, 0.85, 1);
          }
        }
        @media (min-width: 821px) {
          .customer-testimonials .swiper-slide .customer-testimonials-card {
            transform: scale3d(0.8, 0.8, 1);
          }
        }

        .customer-testimonials
          .swiper-slide-active
          .customer-testimonials-card {
          transform: scale3d(1, 1, 1);
          z-index: 1;
        }
        .customer-testimonials {
          width: 100% !important;
        }

        .customer-testimonials .swiper-slide {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .customer-testimonials .swiper-slide .preloved-gadget-card {
          transition: all 0.3s ease;
          width: 100%;
        }

        .customer-testimonials .swiper-slide-active .preloved-gadget-card {
          z-index: 1;
        }

        .testimonials-swiper-pagination-custom {
          margin-top: 1rem;
          display: flex;
          justify-content: center;
        }

        .testimonials-swiper-pagination-custom .swiper-pagination-bullet {
          background-color: #214c7b;
          opacity: 0.6;
          width: 8px;
          height: 8px;
          margin: 0 5px;
          transition: opacity 0.3s;
        }

        .testimonials-swiper-pagination-custom
          .swiper-pagination-bullet-active {
          opacity: 1;
        }

        .testimonials-swiper-button-prev,
        .testimonials-swiper-button-next {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: rgba(255, 255, 255, 0.8);
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid grey;
          transition: opacity 0.3s;
          z-index: 10;
          cursor: pointer;
        }

        .testimonials-swiper-button-prev {
          left: -25px;
        }

        .testimonials-swiper-button-next {
          right: -25px;
        }

        @media (max-width: 1025px) {
          .testimonials-swiper-button-prev,
          .testimonials-swiper-button-next {
            display: none;
          }
        }
      `}</style>
    </Box>
  );
};

export default AllTestimonials;
