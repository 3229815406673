
import { QUERY_GET_ALL_QUOTE_REQ } from '../../graphql/Query'
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Button, Col, Container, Form, Image, Modal, Row, Spinner, Table } from 'react-bootstrap'
import { FaEye } from 'react-icons/fa'


const OtherProductRequest = () => {
    const { data: getAllReq, loading: reqLoading } = useQuery(QUERY_GET_ALL_QUOTE_REQ);
    let count = 1
    const [show, setShow] = useState(false);
    const [selectedReq, setSelectedReq] = useState("")
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleReqDetails = (req) => {
        setSelectedReq(req)
        handleShow()
    }

    return (
        <>
            <Table responsive striped bordered hover>
                <thead className='table-head'>
                    <tr>
                        <th>s.no.</th>
                        <th>Request Id</th>
                        <th>Product Type</th>
                        <th>Product Brand</th>
                        <th>View Details</th>

                    </tr>
                </thead>
                <tbody>
                    {
                        reqLoading ?
                            <Spinner animation="border" variant="success" className='mx-auto d-block' />

                            :
                            getAllReq && getAllReq?.getQuoteRequest?.map((data) => {


                                return (

                                    <tr className='table-data'>
                                        <td>{count++}</td>
                                        <td>{data?.reqNum}</td>
                                        <td>{data?.productType}</td>
                                        <td>{data?.productBrand}</td>
                                        <td className='mx-auto d-block' style={{ cursor: "pointer" }} onClick={() => handleReqDetails(data)} >  <FaEye /> </td>

                                    </tr>

                                )
                            }
                            )

                    }
                </tbody>
            </Table>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Request Details</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{fontWeight:"bold"}}>
                     <div style={{margin:"1rem"}}>
                         Name of Customer : {selectedReq?.name}
                     </div>
                     <div style={{margin:"1rem"}}>
                         Email of Customer : {selectedReq?.email}
                     </div>
                     <div style={{margin:"1rem"}}>
                         Phone Number of Customer : {selectedReq?.phone}
                     </div>
                     <div style={{margin:"1rem"}}>
                         Age Of Product : {selectedReq?.ageOfProduct}
                     </div>
                     <div style={{margin:"1rem"}}>
                         Physical Condition : {selectedReq?.physicalCondition}
                     </div>
                     <div style={{margin:"1rem"}}>
                         Accessories Included : {selectedReq?.accessoriesIncluded}
                     </div>
                     <div style={{margin:"1rem"}}>
                         Original Invoice : {selectedReq?.originalInvoice}
                     </div>
                     <div style={{margin:"1rem"}}>
                         Product Defects : {selectedReq?.deviceDefect}
                     </div>
                     <div style={{ margin: "1rem" ,overflow:"hidden" }} >
                        Product Model :{" "}
                        <a href={selectedReq?.productModel} target="_blank" rel="noopener noreferrer" >
                            {selectedReq?.productModel}
                        </a>
                    </div>
                     <div style={{margin:"1rem"}}>
                         Product Storage : {selectedReq?.productStorage}
                     </div>
                     <div style={{margin:"1rem"}}>
                         Product Ram : {selectedReq?.productRam}
                     </div>
                     <div style={{margin:"1rem"}}>
                         Product Processor : {selectedReq?.productProcessor}
                     </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                  
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default OtherProductRequest