import { gql } from "@apollo/client";

export const MUTATION_ADMIN_LOGIN = gql`
  mutation CreateAdmin($username: String, $password: String) {
    adminLogin(username: $username, password: $password) {
      adminToken
      adminTokenExpire
      adminId
    }
  }
`;

export const CREATE_REMAINING_AMOUNT_ORDER = gql`
  mutation CreateRemainingAmountOrder(
    $paymentMethod: String
    $paymentType: String
    $amount: Int
    $orderId: ID
  ) {
    createRemainingAmountOrder(
      paymentMethod: $paymentMethod
      paymentType: $paymentType
      amount: $amount
      orderId: $orderId
    ) {
      amount
      currency
      orderId
      order_Id
      paymentId
      paymentType
    }
  }
`;

export const VERIFY_RAZORPAY_PAYMENT = gql`
  mutation VerifyRazorPay_Payment(
    $id: ID
    $razorpay: razorpay_details
    $paymentId: ID
    $isFull: Boolean
  ) {
    verifyRazorPay_Payment(
      id: $id
      razorpay: $razorpay
      paymentId: $paymentId
      isFull: $isFull
    ) {
      orderId
      paymentSuccess
    }
  }
`;

export const ADD_ADDRESSID = gql`
  mutation AddAddressId($addressId: ID!, $userId: ID!) {
    addAddressId(addressId: $addressId, userId: $userId) {
      addressId
      error
      message
      success
    }
  }
`;
export const EMPTY_CART = gql`
  mutation ClearCart($userId: ID!) {
    clearCart(userId: $userId) {
      deletedCount
      message
    }
  }
`;

export const UPDATE_RESERVE_STOCK = gql`
  mutation UpdateReserveStock($orderId: ID!, $isFull: Boolean) {
    updateReserveStock(orderId: $orderId, isFull: $isFull) {
      message
      success
    }
  }
`;

export const UPDATE_CANCELLATION_REQUEST = gql`
  mutation UpdateCancellationStatus($itemId: ID, $status: String) {
    updateCancellationStatus(itemId: $itemId, status: $status) {
      cancellationStatus
      message
    }
  }
`;

export const DELETE_SELLING_PRODUCT_IMAGE = gql`
mutation DeleteSellingImage($sellingProductId: ID!) {
  deleteSellingImage(sellingProductId: $sellingProductId) {
    message
    success
  }
}
`

export const UPDATE_SELLING_PRODUCT_IMAGE = gql`
  mutation UploadModelImage($sellingProductId: ID!, $file: Upload!) {
    uploadModelImage(sellingProductId: $sellingProductId, file: $file) {
      invoiceUrl
      message
      success
    }
  }
`;

export const UPDATE_INVOICE = gql`
  mutation UploadOrderInvoice($orderId: ID!, $file: Upload!) {
    uploadOrderInvoice(orderId: $orderId, file: $file) {
      invoiceUrl
      message
      success
    }
  }
`;

export const DELETE_INVOICE = gql`
  mutation DeleteInvoice($orderId: ID!) {
    deleteInvoice(orderId: $orderId) {
      message
      success
    }
  }
`;

export const ORDER_SUMMARY = gql`
  query GetOrderSummary($orderId: ID!) {
    getOrderSummary(orderId: $orderId) {
      address {
        address
        addressType
        alternateContactNo
        cityDistrict
        contact
        email
        gst
        id
        isDefault
        landMark
        name
        pinCode
        state
        user
      }
      advanceFee
      convenienceFee
      orderType
      coupon
      couponDiscount
      deliveryCharges
      discountOnMRP
      paymentStatus
      advancePaid
      convenienceFeePaid
      items {
        id
        itemId
        product {
          id
          image
          images {
            _2xl
            lg
            md
            sm
            xl
            xs
          }
          mrp
          productName
          productDescription
          productPrice
          condition
          warranty
        }
        quantity
        unit {
          courierTrackingInfo
          id
          item
          unitId
          status {
            status
            unitId
          }
        }
      }
      orderNo
      payments {
        amount
        id
        isRetry
        order
        paymentChannel
        paymentMethod
        paymentStatus
        paymentType
      }
      platformFee
      remainingAmount
      totalAmount
      totalMRP
      user
    }
  }
`;

export const UPDATE_PAYMENT_STATUS = gql`
  mutation UpdatePaymentStatus($id: ID!, $status: String!, $paymentId: ID!) {
    updatePaymentStatus(id: $id, status: $status, paymentId: $paymentId) {
      message
      orderId
      paymentStatus
      success
    }
  }
`;
export const CREATE_ORDER = gql`
  mutation CreateOrder(
    $id: ID
    $amount: Int
    $selectedCartItems: [CheckoutCartItem]
    $addressId: ID!
    $userId: ID
    $paymentType: String
    $totalProductPrice: Int
    $paymentMethod: String
  ) {
    createOrder(
      id: $id
      amount: $amount
      selectedCartItems: $selectedCartItems
      addressId: $addressId
      userId: $userId
      paymentType: $paymentType
      totalProductPrice: $totalProductPrice
      paymentMethod: $paymentMethod
    ) {
      orderId
      order_Id
      currency
      amount
      paymentId
      paymentType
    }
  }
`;
export const MUTATION_SEND_USEROTP = gql`
  mutation Mutation($phoneNumber: String) {
    sendOtp(phoneNumber: $phoneNumber) {
      orderId
      errorMessage
      statusCode
    }
  }
`;
export const MUTATION_USER_OTP_LOGIN = gql`
  mutation Mutation($contact: String, $otp: String, $name: String) {
    userOtpLogin(contact: $contact, otp: $otp, name: $name) {
      userId
      userToken
      userTokenExpiration
    }
  }
`;

export const MUTATION_VERIFY_OTP = gql`
  mutation Mutation($orderId: String!, $otp: String!, $phoneNumber: String!) {
    verifyOtp(orderId: $orderId, otp: $otp, phoneNumber: $phoneNumber) {
      isOTPVerified
      reason
    }
  }
`;

export const MUTATION_RESEND_OTP = gql`
  mutation Mutation($orderId: String!) {
    resendOtp(orderId: $orderId) {
      orderId
      message
    }
  }
`;
export const MUTATION_SELL_PRODUCT = gql`
  mutation Mutation($sellProductInput: sellProductInput) {
    createSellProduct(SellProductInput: $sellProductInput) {
      id
      productName
      productPrice
      productDescription
      variant
      series
      productType
      category
      productBrand
      image
      createdDateTime
      status
    }
  }
`;

export const MUTATION_UPDATE_YOUTUBE_URL = gql`
  mutation UpdateYoutubeURL($youtubeId: ID, $url: String) {
    updateYoutubeURL(youtubeId: $youtubeId, url: $url) {
      url
      title
      thumbnail
      id
      youtube_ID
    }
  }
`;

export const MUTATION_UPDATE_USERNAME = gql`
  mutation Mutation($userName: userNameInput, $id: ID) {
    updateUserName(userName: $userName, id: $id) {
      message
      user {
        contact
        createDateTime
        email
        fName
        id
        lName
        status
      }
    }
  }
`;

export const MUTATION_UPDATE_EMAIL = gql`
  mutation UpdateUserEmail($id: ID, $userEmail: String) {
    updateUserEmail(id: $id, userEmail: $userEmail) {
      message
      user {
        contact
        createDateTime
        email
        fName
        id
        lName
        status
      }
    }
  }
`;

export const MUTATION_UPDATE_CONTACT = gql`
  mutation UpdateUserContact($id: ID, $userContact: String) {
    updateUserContact(id: $id, userContact: $userContact) {
      message
      user {
        contact
        createDateTime
        email
        fName
        id
        lName
        status
      }
    }
  }
`;

export const MUTATION_UPDATE_PRODUCT = gql`
  mutation Mutation($sellProductInput: sellProductInput) {
    updateSellProduct(SellProductInput: $sellProductInput) {
      id
      productName
      productDescription
      productBrand
      productPrice
      variant
      series
      productType
      category
      image
      productPrice
      createdDateTime
      status
    }
  }
`;

export const UPDATE_PROFILE_INFROMATION = gql`
  mutation UpdateProfile($updateProfileId: ID, $profileInput: ProfileInput) {
    updateProfile(id: $updateProfileId, profileInput: $profileInput) {
      message
      user {
        contact
        createDateTime
        email
        fName
        id
        lName
        status
      }
    }
  }
`;

export const MUTATION_UPDATE_PRO_IMAGE = gql`
  mutation Mutation($sellProductInput: sellProductInput) {
    updateProductImage(SellProductInput: $sellProductInput) {
      id
      productName
      productDescription
      variant
      series
      productType
      category
      image
      productPrice
      createdDateTime
      status
    }
  }
`;
export const MUTATION_DELETE_SELL_PRODUCT = gql`
  mutation Mutation($sellProductId: ID) {
    deleteSellProductByid(sellProductId: $sellProductId) {
      id
      productName
      productDescription
      variant
      series
      productType
      category
      image
      productPrice
      createdDateTime
      status
    }
  }
`;
export const MUTATION_GET_USER = gql`
  mutation Mutation($userId: ID) {
    getUser(userId: $userId) {
      id
      fName
      lName
      contact
      email
      createDateTime
      status
    }
  }
`;
export const UPDATE_USER = gql`
  mutation Mutation($userInput: userInput) {
    updateUser(UserInput: $userInput) {
      id
    }
  }
`;
export const MUTATION_CREATE_BRAND = gql`
  mutation CreateMobileBrand($mobileBrandInput: mobileBrandInput) {
    createMobileBrand(mobileBrandInput: $mobileBrandInput) {
      id
      brandName
      brandImage
      category
      createdDateTime
      status
    }
  }
`;

export const MUTATION_CREATE_BUY_BRAND = gql`
  mutation Mutation($buyBrandInput: buyBrandInput) {
    createBuyBrand(BuyBrandInput: $buyBrandInput) {
      id
      brandName
      brandImage
      category
      createdDateTime
      status
    }
  }
`;
export const MUTATION_ADD_QUESTION = gql`
  mutation Mutation($questionInput: questionInput) {
    addQuestion(QuestionInput: $questionInput) {
      isDeviceSwitch
      isDeviceSwitchPer
      ableToMakeReceiveCall
      ableToMakeReceiveCallPer
      issueWithDeviceDisplay
      issueWithDeviceDisplayPer
      deviceScreen
      deviceScreenPer
      deviceEverRepair
      deviceEverRepairPer
      FrontCameraIssue
      FrontCameraIssuePer
      BackCameraIssue
      BackCameraIssuePer
      SpeakerIssue
      SpeakerIssuePer
      MicIssue
      MicIssuePer
      RingerIssue
      RingerIssuePer
      CharginPortIssue
      CharginPortIssuePer
      SensorIssue
      SensorIssuePer
      BatteryIssue
      BatteryIssuePer
      ButtonIssue
      ButtonIssuePer
      WifiIssue
      WifiIssuePer
      BluetoothIssue
      BluetoothIssuePer
      FlashLightIssue
      FlashLightIssuePer
      VibratorIssue
      VibratorIssuePer
      AutoRestartIssue
      AutoRestartIssuePer
      EarphonePortIssue
      EarphonePortIssuePer
      SimCardIssue
      SimCardIssuePer
      GpsIssue
      GpsIssuePer
      ConditionDeviceScreen
      ConditionDeviceScreenPer
      ConditionDeviceBody
      ConditionDeviceBodyPer
      createdDateTime
      status
    }
  }
`;
export const MUTATION_UPDATE_isDeviceSwitchPer = gql`
  mutation UpdateisDeviceSwitchPer($id: String, $questionPer: String) {
    updateisDeviceSwitchPer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_ableToMakeReceiveCallPer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateableToMakeReceiveCallPer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_issueWithDeviceDisplayPer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateissueWithDeviceDisplayPer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_deviceScreenPer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updatedeviceScreenPer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_deviceEverRepairPer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updatedeviceEverRepairPer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_FrontCameraIssuePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateFrontCameraIssuePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_SpeakerIssuePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateSpeakerIssuePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_BackCameraIssuePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateBackCameraIssuePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_MicIssuePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateMicIssuePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_RingerIssuePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateRingerIssuePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_CharginPortIssuePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateCharginPortIssuePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_SensorIssuePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateSensorIssuePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;

export const MUTATION_UPDATE_BatteryIssuePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateBatteryIssuePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_ButtonIssuePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateButtonIssuePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_WifiIssuePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateWifiIssuePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_BluetoothIssuePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateBluetoothIssuePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_FlashLightIssuePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateFlashLightIssuePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_VibratorIssuePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateVibratorIssuePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_AutoRestartIssuePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateAutoRestartIssuePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_EarphonePortIssuePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateEarphonePortIssuePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_SimCardIssuePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateSimCardIssuePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_GpsIssuePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateGpsIssuePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_ConditionDeviceScreenPer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateConditionDeviceScreenPer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_ConditionDeviceBodyPer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateConditionDeviceBodyPer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_HaveBoxWithIMEIPer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateHaveBoxWithIMEIPer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_HaveDataCablePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateHaveDataCablePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_HaveAdapterPer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateHaveAdapterPer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_HaveEarphonesPer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateHaveEarphonesPer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_AdditionalInsurancePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateAdditionalInsurancePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_PurchasedPlacePer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updatePurchasedPlacePer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_UPDATE_WarrantyPeriodPer = gql`
  mutation Mutation($id: String, $questionPer: String) {
    updateWarrantyPeriodPer(ID: $id, questionPer: $questionPer) {
      id
    }
  }
`;
export const MUTATION_ADD_ADDRESS = gql`
  mutation CreateSaveAddress($saveAddressInput: saveAddressInput) {
    createSaveAddress(SaveAddressInput: $saveAddressInput) {
      id
      userId
      address
      sellername
      landmark
      city
      pincode
      state
      contactNo
      alternativeContactNo
      email
      addressType
      createDateTime
      status
    }
  }
`;
export const MUTATION_UPDATE_QUESTION = gql`
  mutation Mutation($id: String, $question: String, $questionPer: String) {
    updateQuestions(ID: $id, question: $question, questionPer: $questionPer) {
      status
    }
  }
`;
export const MUTATION_DELETE_BRAND = gql`
  mutation Mutation($brandId: ID) {
    deleteBrandById(brandId: $brandId) {
      brandName
      status
    }
  }
`;
export const MUTATION_DELETE_BUY_BRAND = gql`
  mutation DeleteBuyBrandById($brandId: ID) {
    deleteBuyBrandById(brandId: $brandId) {
      id
    }
  }
`;
export const MUTATION_UPDATE_BRAND = gql`
  mutation Mutation($brandId: ID, $brandName: String, $brandCategory: String) {
    updateBrandById(
      brandId: $brandId
      brandName: $brandName
      brandCategory: $brandCategory
    ) {
      id
      brandName
    }
  }
`;
export const MUTATION_UPDATE_BRAND_IMAGE = gql`
  mutation UpdateBrandImage($brandId: ID, $brandImage: String) {
    updateBrandImage(brandId: $brandId, brandImage: $brandImage) {
      brandImage
      id
    }
  }
`;
export const MUTATION_UPDATE_SELLORDER_STATUS = gql`
  mutation Mutation($orderId: String, $status: String) {
    updateSellOrderStatusById(orderId: $orderId, status: $status) {
      status
    }
  }
`;
export const MUTATION_CREATE_SELL_ORDER = gql`
  mutation Mutation($sellOrderInput: sellOrderInput) {
    createSellOrder(SellOrderInput: $sellOrderInput) {
      userId
      imageKey
      productCategory
      productBrand
      productName
      storage
      ram
      addressName
      addressEmail
      addressContact
      addressFlat
      addressState
      addressCity
      addressLandmark
      addressPincode
      addressAlternatContact
      addressType
      wantToPickupYourself
      pickupDate
      pickupTime
      paymentMethod
      bankName
      bankAccountHolderName
      bankIfsc
      bankAccountNo
      upiId
      sellingAmount
      createdDateTime
      status
      details {
        que
        ans
      }
    }
  }
`;

export const CREATE_BUY_PRODUCT = gql`
  mutation CreateProduct($productInput: productInput) {
    createProduct(ProductInput: $productInput) {
      productName
      productSubTitle
      warrantyDescription
      AdditionalFeatures
      dimensionsDescrption
      isTrending
      screenSize
      type
      networkType
      liveLink
      quantity
      mrp
      ram
      productPrice
      productDescription
      image
      images {
        _2xl
        lg
        md
        sm
        xl
        xs
      }
      condition
      storage
      productCategory
      productBrand
    }
  }
`;
export const MUTATION_DELETE_BUY_ORDER = gql`
  mutation Mutation($productId: String) {
    deleteBuyOrderByID(productId: $productId) {
      id
      status
    }
  }
`;
export const MUTATION_UPDATE_BUY_ORDER = gql`
  mutation Mutation($productInput: productInput) {
    updateBuyProduct(ProductInput: $productInput) {
      id
      productName
      productPrice
      productSubTitle
      productDescription
      warrantyDescription
      warranty
      AdditionalFeatures
      dimensionsDescrption
      isTrending
      screenSize
      type
      networkType
      liveLink
      quantity
      mrp
      ram
      image
      condition
      storage
      productCategory
      productBrand
    }
  }
`;
export const MUTATION_DELETE_ADDRESS_BY_ID = gql`
  mutation Mutation($addressId: String) {
    deleteAddressByID(AddressId: $addressId) {
      id

      status
    }
  }
`;
export const MUTATION_UPDATE_ADD_ADDRESS = gql`
  mutation Mutation($saveAddressInput: saveAddressInput) {
    editAddressById(SaveAddressInput: $saveAddressInput) {
      id
      userId
      address
      sellername
      landmark
      city
      pincode
      state
      contactNo
      alternativeContactNo
      email
      addressType
      createDateTime
      status
    }
  }
`;
export const MUTATION_SELL_MODAL_IMAGE = gql`
  mutation Mutation($sellProductImageModalInput: sellProductImageModalInput) {
    createSellImageModal(
      SellProductImageModalInput: $sellProductImageModalInput
    ) {
      brandName
      createdDateTime
      id
      image
      status
    }
  }
`;
export const MUTATION_DEL_SELL_MODAL_IMAGE = gql`
  mutation Mutation($imageId: ID) {
    deleteSellImageModal(imageId: $imageId) {
      id
    }
  }
`;
export const MUTATION_UPDATE_SELL_MODAL = gql`
  mutation Mutation($modalId: ID, $brandName: String) {
    updateSellImageModal(modalId: $modalId, brandName: $brandName) {
      id
    }
  }
`;
export const MUTATION_CREATE_QUOTE_REQ = gql`
  mutation Mutation($quoteRequestInput: quoteRequestInput) {
    createQuoteReq(QuoteRequestInput: $quoteRequestInput) {
      id
      userId
      reqNum
      productType
      productBrand
      ageOfProduct
      physicalCondition
      accessoriesIncluded
      originalInvoice
      deviceDefect
      createdDateTime
      status
    }
  }
`;
export const MUTATION_UPDATE_LAPTOP_QUESTION = gql`
  mutation UpdateLaptopQuestions(
    $id: String
    $question: String
    $questionPer: String
  ) {
    updateLaptopQuestions(
      ID: $id
      question: $question
      questionPer: $questionPer
    ) {
      id
    }
  }
`;
export const MUTATION_UPDATE_SMARTWATCH_QUESTION = gql`
  mutation Mutation($id: String, $question: String, $questionPer: String) {
    updateWatchQuestions(
      ID: $id
      question: $question
      questionPer: $questionPer
    ) {
      id
      createdDateTime
      status
    }
  }
`;
export const MUTATION_UPDATE_TABLET_QUESTION = gql`
  mutation UpdateTabletQuestions(
    $id: String
    $question: String
    $questionPer: String
  ) {
    updateTabletQuestions(
      ID: $id
      question: $question
      questionPer: $questionPer
    ) {
      id
      createdDateTime
      status
    }
  }
`;
export const MUTATION_UPDATE_DESKTOP_QUESTION = gql`
  mutation Mutation($questionPer: String, $question: String, $id: String) {
    updateDesktopQuestions(
      questionPer: $questionPer
      question: $question
      ID: $id
    ) {
      id

      createdDateTime
      status
    }
  }
`;
export const MUTATION_CREATE_SERIES = gql`
  mutation CreateSeries($seriesInput: seriesInput) {
    createSeries(SeriesInput: $seriesInput) {
      id
      brandName
      seriesName
      createDateTime
      status
    }
  }
`;

export const MUTATION_DELETE_SERIES = gql`
  mutation DeleteSeries($seriesId: String) {
    deleteSeries(seriesId: $seriesId) {
      id
      brandName
      seriesName
      createDateTime
      status
    }
  }
`;
export const MUTATION_UPDATE_SERIES = gql`
  mutation UpdateSeries($seriesInput: seriesInput) {
    updateSeries(SeriesInput: $seriesInput) {
      id
      brandName
      seriesName
      createDateTime
      status
    }
  }
`;

export const UPDATE_CART_ITEM_CHECKOUT = gql`
  mutation UpdateCartItemCheckout($id: ID!, $userId: ID) {
    updateCartItemCheckout(id: $id, userId: $userId) {
      count
      discountOnMRP
      message
      totalAmount
      totalMRP
      updatedCartItem {
        id
        isCheckedForCheckout
        quantity
        product {
          productName
          productPrice
          mrp
          image
          warranty
          condition
        }
      }
    }
  }
`;

export const CREATE_COD_ORDER = gql`
  mutation CreateCodOrder(
    $addressId: ID!
    $amount: Int
    $createCodOrderId: ID
    $selectedCartItems: [CheckoutCartItem]
    $userId: ID
    $paymentType: String
    $totalProductPrice: Int
    $paymentMethod: String
  ) {
    createCodOrder(
      addressId: $addressId
      amount: $amount
      id: $createCodOrderId
      selectedCartItems: $selectedCartItems
      userId: $userId
      paymentType: $paymentType
      totalProductPrice: $totalProductPrice
      paymentMethod: $paymentMethod
    ) {
      message
      orderId
      success
    }
  }
`;
export const MUTATION_CREATE_CART_ITEM = gql`
  mutation CreateCartItem($cartItemInput: cartItemInput) {
    createCartItem(CartItemInput: $cartItemInput) {
      message
    }
  }
`;
export const MUTATION_DELETE_CART_ITEM = gql`
  mutation DeleteCartItem($itemId: String, $userId: ID) {
    deleteCartItem(itemId: $itemId, userId: $userId) {
      count
      discountOnMRP
      message
      totalAmount
      totalMRP
      deletedCartItem {
        id
        isCheckedForCheckout
        product {
          productName
          productPrice
          mrp
          image
          warranty
          condition
        }
        quantity
      }
    }
  }
`;
export const MUTATION_UPDATE_CART_ITEM = gql`
  mutation Mutation($cartItemInput: cartItemInput) {
    updateCartItem(CartItemInput: $cartItemInput) {
      id
      userId
      quantity
    }
  }
`;
export const MUTATION_CREATE_BUY_ORDER = gql`
  mutation Mutation($buyOrderInput: buyOrderInput) {
    createBuyOrder(BuyOrderInput: $buyOrderInput) {
      id
      orderNo
      userId
      productDetails {
        productId
        productName
        productPrice
        totalPrice
        productImage
        quantity
        category
        storage
        color
        condition
      }
      paymentId
      totalprice
      paymentMethod
      fName
      lName
      contact
      alternateContactNo
      email
      address
      landmark
      city
      pincode
      state
      createdDateTime
      status
    }
  }
`;
export const MUTATION_UPDATE_BUY_ORDERS_BY_ID = gql`
  mutation UpdateBuyOrderStatusById(
    $currentStatus: String
    $timeline: String
    $status: String
    $orderId: String
    $unitId: String
    $orderType: String
    $paymentStatus: String
    $itemId: ID
  ) {
    updateBuyOrderStatusById(
      currentStatus: $currentStatus
      timeline: $timeline
      status: $status
      orderId: $orderId
      unitId: $unitId
      orderType: $orderType
      paymentStatus: $paymentStatus
      itemId: $itemId
    ) {
      message
    }
  }
`;

export const MUTATION_UNSELECT_OUT_OF_STOCK_ITEMS = gql`
  mutation UnselectCartItems($userId: ID!) {
    unselectCartItems(userId: $userId) {
      count
      coupon
      couponDiscount
      discountOnMRP
      message
      totalAmount
      totalMRP
      updatedOutOfStockItems {
        id
        isCheckedForCheckout
        quantity
      }
    }
  }
`;
export const MUTATION_CREATE_FORM_REQUEST = gql`
  mutation Mutation($formRequestInput: formRequestInput) {
    createRequest(FormRequestInput: $formRequestInput) {
      id
      userId
      reqNum
      title
      phone
      email
      address
      reqDetails

      createdDateTime
      status
    }
  }
`;

export const MUTATION_TRENDING = gql`
  mutation UpdateTrendingProduct($proId: String, $isTrending: Boolean) {
    updateTrendingProduct(proId: $proId, IsTrending: $isTrending) {
      id
      isTrending
    }
  }
`;

export const MUTATION_CREATE_OFFER = gql`
  mutation CreateOffer($offerInput: offerInput) {
    createOffer(OfferInput: $offerInput) {
      id
      # category
      offerDesc
      createdDateTime
      status
    }
  }
`;

export const MUTATION_DELETE_ADDRESS = gql`
  mutation DeleteAddress($id: ID!, $userId: ID!) {
    deleteAddress(id: $id, userId: $userId) {
      message
      success
      newDefaultAddressId
    }
  }
`;

export const MUTATION_UPDATE_ADDRESS = gql`
  mutation UpdateAddress($input: AddressInput!) {
    updateAddress(input: $input) {
      address {
        address
        addressType
        alternateContactNo
        cityDistrict
        contact
        email
        gst
        id
        isDefault
        landMark
        name
        pinCode
        state
        user
      }
      newDefaultAddressId
      message
      success
    }
  }
`;

export const MUTATION_PURCHASE_ADD_ADDRESS = gql`
  mutation AddAddress($input: AddressInput!) {
    addAddress(input: $input) {
      id
      user
      name
      contact
      email
      address
      pinCode
      landMark
      cityDistrict
      state
      addressType
      isDefault
    }
  }
`;
export const MUTATION_DELETE_OFFER = gql`
  mutation DeleteOfferById($offerId: ID) {
    deleteOfferById(offerId: $offerId) {
      id
      # category
      offerDesc
      createdDateTime
      status
    }
  }
`;

export const MUTATION_APPLY_COUPON = gql`
  mutation ApplyCoupon($userId: ID!, $couponCode: String!) {
    applyCoupon(userId: $userId, couponCode: $couponCode) {
      coupon {
        code
        createdAt
        description
        discountType
        discountValue
        endingDate
        id
        maxDiscount
        minOrder
        startingDate
        status
        updatedAt
      }
      couponDiscount
      message
      totalAmount
    }
  }
`;
export const MUTATION_CREATE_COUPON = gql`
  mutation CreateCoupon($couponInput: couponInput) {
    createCoupon(CouponInput: $couponInput) {
      coupon {
        maxDiscount
        code
        createdAt
        description
        discountType
        discountValue
        minOrder
        endingDate
        id
        startingDate
        status
        updatedAt
      }
      message
    }
  }
`;
export const MUTATION_DELETE_COUPON = gql`
  mutation DeleteCoupon($couponId: ID) {
    deleteCoupon(couponId: $couponId) {
      id
    }
  }
`;
export const MUTATION_CREATE_PINCODE = gql`
  mutation Mutation($cityPincodeInput: cityPincodeInput) {
    createPincode(CityPincodeInput: $cityPincodeInput) {
      id
      pincode
      city
      status
      createdDateTime
    }
  }
`;
export const MUTATION_DELETE_PINCODE = gql`
  mutation DeletePincode($dataId: ID) {
    deletePincode(dataId: $dataId) {
      id
      pincode
      city
      createdDateTime
      status
    }
  }
`;
export const MUTATION_CREATE_TESTIMONIAL = gql`
  mutation CreateTestimonial($testimonialInput: testimonialInput) {
    createTestimonial(TestimonialInput: $testimonialInput) {
      name
      position
      description
      id
    }
  }
`;
export const MUTATION_DELETE_TESTIMONIAL = gql`
  mutation Mutation($testimonialId: ID) {
    deleteTestimonial(testimonialId: $testimonialId) {
      id
      name
      position
      description
    }
  }
`;
export const MUTATION_CREATE_BLOG = gql`
  mutation Mutation($blogInput: blogInput) {
    createBlog(BlogInput: $blogInput) {
      id
      title
      description
      imageKey
      createDateTime
      status
    }
  }
`;
export const MUTATION_DELETE_BLOG = gql`
  mutation DeleteBlog($blogId: ID) {
    deleteBlog(blogId: $blogId) {
      id
      title
      description
      imageKey
      createDateTime
      status
    }
  }
`;

export const MUTATION_CREATE_NEWS = gql`
  mutation Mutation($newsInput: newsInput) {
    createNews(NewsInput: $newsInput) {
      id
      title
      description
      imageKey
      newsLink
      createDateTime
      status
    }
  }
`;

export const MUTATION_DELETE_NEWS = gql`
  mutation Mutation($newsId: ID) {
    deleteNews(newsId: $newsId) {
      id
      title
      description
      imageKey
      createDateTime
      status
    }
  }
`;

export const MUTATION_PAYMENT = gql`
  mutation InitiatePhonePePayment(
    $amount: Int
    $transactionId: String
    $contact: String
  ) {
    initiatePhonePePayment(
      amount: $amount
      transactionId: $transactionId
      contact: $contact
    )
  }
`;

export const MUTATION_CHECK_PAYMENT = gql`
  mutation CheckPhonePePayment($transactionId: String) {
    checkPhonePePayment(transactionId: $transactionId)
  }
`;

export const MUTATION_ORDER_PAYMENT_UPDATE = gql`
  mutation BuyOrderUpdate($buyOrderUpdateInput: buyOrderUpdateInput) {
    buyOrderUpdate(BuyOrderUpdateInput: $buyOrderUpdateInput) {
      id
    }
  }
`;
