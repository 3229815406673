import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const TrustBenefitCard = ({ title, img, body }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box
      display={"flex"}
      flexDirection={isMobileScreen ? "row" : "column"}
      justifyContent={isMobileScreen ? "flex-start" : "center"}
      gap={"1rem"}
      height={isMobileScreen ? "75px" : "100%"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"1rem"}
        alignItems={"center"}
        justifyContent={isMobileScreen ? "center" : "flex-start"}
      >
        <Box
          sx={{
            display: "flex",
            width: { xs: "70px", laptop: "80px" },
            height: { xs: "70px", laptop: "80px" },
            backgroundColor: "#54B0BA",
            borderRadius: "50%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={img}
            style={{
              height: isMobileScreen ? "40px" : "50px",
              width: isMobileScreen ? "40px" : "50px",
            }}
          />
        </Box>
      </Box>

      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={isMobileScreen ? "0" : "1rem"}
        justifyContent={isMobileScreen ? "center" : "flex-start"}
        height={"100%"}
      >
        <Box
          display={"flex"}
          justifyContent={isMobileScreen ? "flex-start" : "center"}
        >
          <CustomTypography
            variant={"subtitle1"}
            sx={{
              fontWeight: "600",
              fontSize: {
                xs: "0.95rem",
                desktop: "1rem",
              },
            }}
          >
            {title}
          </CustomTypography>
        </Box>
        <Box display={"flex"}>
          <CustomTypography
            variant={"subtitle2"}
            sx={{
              textAlign: "justify",
              fontSize: {
                xs: "0.875rem",
                desktop: "0.90rem",
              },
            }}
          >
            {body}
          </CustomTypography>
        </Box>
      </Box>
    </Box>
  );
};

export default TrustBenefitCard;
