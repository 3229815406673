import { createTheme } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      mobile: 321,
      tablet: 481,
      tabletMedium: 600,
      laptop: 769,
      tabletLarge: 821,
      desktop: 1025,
      extraLarge: 1201,
    },
  },
});

export default theme;
