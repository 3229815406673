import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { cartTotals } from "../../../../store/selectors/addToCart/addToCart";
import useGetCartItems from "../../hooks/addToCart/useGetCartItems";
import PriceDetails from "./priceDetails/PriceDetails";
import AddressWrapper from "./AddressWrapper";
import MuiBackdrop from "../../../../muicomponents/shared/MuiBackdrop";
import { useNavigate } from "react-router-dom";
import useCobmbineAdrsCart from "../../hooks/addAddress/useCobmbineAdrsCart";
import { cartPageBackdrop } from "../../../../store/atoms/addToCart/addToCart";

const AddAddress = () => {
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);
  const {
    cartData,
    allAddress,
    loading,
    error,
    cartDataRefetch,
    addressRefetch,
  } = useCobmbineAdrsCart();
  const navigate = useNavigate();

  useEffect(() => {
    cartDataRefetch();
    addressRefetch();
  }, []);

  useEffect(() => {
    if (cartData && cartData.items.length === 0) {
      navigate("/checkout/cart");
    } else if (
      cartData &&
      cartData.items
        .filter((item) => item.isCheckedForCheckout === true)
        .some((item) => item.product.quantity === 0)
    ) {
      navigate("/checkout/cart");
    }
  }, [cartData]);

  useEffect(() => {
    setBackdropOpen(loading);
  }, [loading]);

  if (error) {
    return <>Error..</>;
  }
  return (
    <>
      <MuiBackdrop />
      {!loading && (
        <Box>
          <Box display={"flex"} flexDirection={"row"}>
            <Box flexBasis={"65%"} sx={{ width: "100%" }}>
              <AddressWrapper allAddress={allAddress} />
            </Box>
            <Box flexBasis={"35%"} sx={{ width: "100%" }}>
              <PriceDetails />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AddAddress;
