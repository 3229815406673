import { Box } from "@mui/material";
import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import FeatureCard from "./FeatureCard";

const FeatureCards = () => {
  const data = [
    {
      text: "Easy EMI Options",
    },
    {
      text: "Fast and Free Delivery across all the Pincodes.",
    },
    {
      text: "Brand Warranty/Seller Service Warranty",
    },
    {
      text: "COD available for selected products",
    },
    {
      text: "Delivery time of 2 days in Metro and Major Cities.",
    },
    {
      text: "Real time video experience pre purchase.",
    },
  ];
  return (
    <Box>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={10}
        slidesPerView={1}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        style={{
          backgroundColor: "white",
          boxShadow: "none",
          "--swiper-navigation-size": "16px",
        }}
      >
        {data.map((feature, index) => (
          <SwiperSlide
            key={index}
            style={{
              width: "100%",
            }}
          >
            <FeatureCard text={feature.text} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default FeatureCards;
