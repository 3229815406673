import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const Heading = ({ text }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box sx={{ borderLeft: "5px solid #214C7B" }}>
      <CustomTypography
        sx={{
          color: "#214C7B",
          fontWeight: "600",
          paddingLeft: "0.5rem",
          fontSize: {
            mobile: "1.125rem",
            tablet: "1.25rem",
            laptop: "1.5rem",
            desktop: "1.5rem",
          },
        }}
        variant={"subtitle1"}
      >
        {text}
      </CustomTypography>
    </Box>
  );
};

export default Heading;
