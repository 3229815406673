import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Container, Col, Row, Breadcrumb, Spinner } from "react-bootstrap";
import Navigation from "../Navigation";
import Footer from "../Footer";
import Banner from "../../Images/sellingBanner/Selling.jpg";
import PhoneQuote from "../PhonesQuoteForm/PhonesQuote";
import {
  QUERY_GET_ALL_BRANDS,
  QUERY_GET_ALL_BRANDS_BY_CAT,
} from "../../graphql/Query";
import { GET_SEARCH_SELL_PRODUCTS } from "../../graphql/Query";
import "../../ComponentsCss/Brandpage/mainPage.css";
import ReactGA from "react-ga";

import { Helmet } from "react-helmet";
import { useMediaQuery, useTheme } from "@mui/material";
const BrandMainPage = () => {
  let { category } = useParams();
  const theme = useTheme();
  const isVerySmallScreen = useMediaQuery(theme.breakpoints.down(481));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(992));
  const isIpadMin = useMediaQuery(theme.breakpoints.between(768, 822));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down(768));
  if (category === "Desktop-Imac") {
    category = "Desktop/Imac";
  }

  const calculateTimeRemaining = (targetDate) => {
    const now = new Date();
    const difference = targetDate - now;

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const seconds = Math.floor((difference / 1000) % 60);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const days = Math.floor(difference / (1000 * 60 * 60 * 24));

    return { days, hours, minutes, seconds };
  };
  const targetDate = new Date("2024-11-15T00:00:00");

  const [timeRemaining, setTimeRemaining] = useState(
    calculateTimeRemaining(targetDate)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining(targetDate));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  const location = useLocation();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [filteredBrands, setFilteredBrands] = useState([]);
  const [showProducts, setShowProducts] = useState(true);
  const [searchSellProduct, { data: searchData, loading: productLoading }] =
    useLazyQuery(GET_SEARCH_SELL_PRODUCTS);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: getAllBrandByCategory, loading: brandLoading } = useQuery(
    QUERY_GET_ALL_BRANDS_BY_CAT,
    {
      variables: {
        category: category,
      },
    }
  );

  const getProducts = (brand) => {
    ReactGA.event({
      category: "Button",
      action: "Click",
      label: "Brand Click",
    });

    navigate("/allbrands/" + brand, {
      state: {
        productCategory: category,
        productBrand: brand,
      },
    });
  };

  const handleSearch = (val) => {
    setSearchInput(val);
    if (searchInput) {
      searchSellProduct({
        variables: {
          name: `${val}`,
          category: category,
        },
      });
    }
  };
  const handleSelect = (product, id, brand) => {
    if (category === "Laptops") {
      navigate("/alllaptopproduct/" + id, {
        state: {
          productCategory: category,
          productBrand: brand,
          productName: product,
        },
      });
    } else if (category === "Phones") {
      navigate("/allproduct/" + id, {
        state: {
          productCategory: category,
          productBrand: brand,
          productName: product,
        },
      });
    } else if (category === "Smart Watches") {
      navigate("/smartwatch-price/" + id, {
        state: {
          productCategory: category,
          productBrand: brand,
          productName: product,
        },
      });
    } else if (category === "Tablets") {
      navigate("/allproduct/" + id, {
        state: {
          productCategory: category,
          productBrand: brand,
          productName: product,
        },
      });
    } else if (category === "Desktop/Imac") {
      navigate("/desktop-show-price/" + id, {
        state: {
          productCategory: category,
          productBrand: brand,
          productName: product,
        },
      });
    }
  };
  return (
    <>
      <Helmet>
        <title>{`${category} - Our Products`}</title>
        <meta
          name="description"
          content=" Discover the power of emotion in buying, selling, and exchanging used mobiles, laptops, and other gadgets with HelloFi. Best prices and offers are guaranteed."
        />
      </Helmet>
      <Navigation />
      <div
        style={{
          width: "100%",
          height: isSmallScreen ? "calc(100vh - 45px)" : "calc(100vh - 81px)",
          position: "relative",
        }}
      >
        <img
          src={Banner}
          alt="Banner"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: 0,
          }}
        />
        <div
          style={{
            position: "absolute",
            width: "100%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
            textAlign: "center",
            zIndex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              fontSize: isMobileScreen ? "1.5rem" : "3rem",
              fontWeight: 600,
            }}
          >
            We are Coming soon.
          </h1>
          <p
            style={{
              fontSize: isMobileScreen ? "1rem" : "1.5rem",
              width: isMobileScreen ? "90%" : "80%",
              fontWeight: "400",
            }}
          >
            We're working on a fresh new look to enhance your experience. Stay
            tuned for our upcoming redesign!
          </p>
          <div
            style={{
              fontSize: isMobileScreen ? "1.7rem" : "4rem",
              fontWeight: "500",
            }}
          >
            <span>
              {String(timeRemaining.days).padStart(2, "0")}{" "}
              <span style={{ fontSize: "2rem" }}>Days</span>{" "}
            </span>
            <span>
              {String(timeRemaining.hours).padStart(2, "0")}{" "}
              <span style={{ fontSize: "2rem" }}>Hours</span>{" "}
            </span>
            {isVerySmallScreen && <br />}
            <span>
              {String(timeRemaining.minutes).padStart(2, "0")}{" "}
              <span style={{ fontSize: "2rem" }}>Minutes</span>{" "}
            </span>
            {isIpadMin || (isVerySmallScreen && <br />)}
            <span>
              {String(timeRemaining.seconds).padStart(2, "0")}{" "}
              <span style={{ fontSize: "2rem" }}>Seconds</span>{" "}
            </span>
          </div>
          <p
            style={{
              width: isMobileScreen ? "90%" : "80%",
              fontSize: isMobileScreen ? "1rem" : "1.3rem",
            }}
          >
            Don't Worry! You can still sell your device with us.{" "}
          </p>
          <p
            style={{
              width: isMobileScreen ? "90%" : "80%",
              fontSize: isMobileScreen ? "1rem" : "1.3rem",
            }}
          >
            Call/WhatsApp us on 8150835583/8618394673 to know the price of your
            device instantly or fill the form below.
          </p>
        </div>
      </div>
      {/*  <div className="banner">
                <h2 style={{ color: '#000' }}>
                    Sell Old {category} Online.
                </h2>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search model to sell"
                        className="search-input"
                        value={searchInput}
                        onChange={(e) => handleSearch(e.target.value)}
                    />
                    <div
                        className="search-icon"
                        style={{ marginLeft: "-45px", cursor: "pointer" }}
                        onClick={() => handleSearch(searchInput)}
                    >
                        <FaSearch />
                    </div>

                </div>
                {searchInput && searchData ? (
                    <div className="sellsearch d-flex overflow-scroll position-absolute" style={{ height: "308px", width: "297px" }}>
                        {searchData?.searchSellProduct && searchData.searchSellProduct.length > 0 ? (
                            <div className="">
                                {searchData.searchSellProduct.map((product) => (
                                    <div
                                        key={product.productId}
                                        onClick={() => handleSelect(product.productName, product.id, product?.productBrand)}
                                        className="sellsearchitem"
                                    >
                                        {product.productName}
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div>No products found</div>
                        )}
                    </div>
                ) : null}
                <Breadcrumb className="breadcrumb">
                    <Breadcrumb.Item><Link to="/" style={{ fontWeight: 'bold', color: '#2ecc71' }}>Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item active style={{ fontWeight: 'bold', color: '#7f8c8d' }}>Select Brand</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h3 style={{ margin: "1rem", fontWeight: "600", fontSize: "20px" }}> Start Selling {category} By Selecting Brand.</h3>
            <Container className="brand-container" style={{ margin: "0px" }} fluid>
                <Row>
                    {brandLoading ? (
                        <Spinner animation="border" variant="success" className='mx-auto d-block' />
                    ) : getAllBrandByCategory.getAllBrandByCategory ? (
                        getAllBrandByCategory.getAllBrandByCategory.map((data) => (
                            <Col md={2} xs={4} style={{ marginTop: ".5rem", marginBottom: ".5rem" }} key={data.brandName}>
                                <div style={{ marginTop: ".1rem", padding: "3vw 1vw" }} onClick={() => getProducts(data.brandName)} className="card-1">
                                    <img variant="top" src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${data.brandImage}`} className="img-icon" />
                                </div>
                            </Col>
                        ))
                    ) : (
                        <p className="ms-2">No brands available.</p>
                    )}
                </Row> */}
      <PhoneQuote category={category} />
      {/* </Container> */}
      <Footer />
    </>
  );
};

export default BrandMainPage;
