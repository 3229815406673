import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Spinner,
  Image,
  Dropdown,
  Modal,
} from "react-bootstrap";
import React, { useState } from "react";
import { CREATE_BUY_PRODUCT } from "../../graphql/Mutations";
import { QUERY_All_BUY_ORDERS } from "../../graphql/Query";
import { QUERY_GET_EXPLORE_BRAND } from "../../graphql/Query";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import swal from "sweetalert";
import AWS from "aws-sdk";
import uniqid from "uniqid";

import {
  BtnBold,
  BtnItalic,
  Editor,
  EditorProvider,
  Toolbar,
  createButton,
} from "react-simple-wysiwyg";
import MuiBackdrop from "../../muicomponents/shared/MuiBackdrop";
import { useSetRecoilState } from "recoil";
import { cartPageBackdrop } from "../../store/atoms/addToCart/addToCart";

const ID = "AKIA6GB4RFKTJXDPLPOH";
const SECRET = "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";
const BUCKET_NAME = "sanjaytestbucket";

const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});
let imageKey = [];

const AddBuyOrders = () => {
  const [validated, setValidated] = useState(false);
  const [productNameState, setProductNameState] = useState("");
  const [productSubtitleState, setProductSubtitleState] = useState("");
  const [productPriceState, setProductPriceState] = useState("");
  const [productStorageState, setProductStorageState] = useState("");
  const [category, setCategory] = useState("");
  const [warranty, setWarranty] = useState("");
  const [productCondition, setProCondition] = useState("");
  const [images, setImages] = useState([]);
  const [productBrand, setProductBrand] = useState("");
  const [productRAM, setProductRAM] = useState("");
  const [productMRP, setProductMRP] = useState("");
  const [productLink, setProductLink] = useState("");
  const [networkType, setNetworkType] = useState("");
  const [screenSize, setScreenSize] = useState("");
  const [productType, setProductType] = useState("");
  const [producytQuantity, setProductQuantity] = useState(0);
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);

  const [imageBox, setImageBox] = useState([]);
  const [createProduct] = useMutation(CREATE_BUY_PRODUCT, {
    refetchQueries: [QUERY_All_BUY_ORDERS],
  });
  const [
    getExploreByBrands,
    { data: getExploreByBrandsdata, loading: ExploreLoading },
  ] = useLazyQuery(QUERY_GET_EXPLORE_BRAND);
  // images selecting
  const handleIamges = (e) => {
    setImages([...e.target.files]);
  };

  // product desc
  const BtnAlignCenter = createButton("Align center", "≡", "justifyCenter");

  const [value, setValue] = useState();

  function onChange(e) {
    setValue(e.target.value);
  }

  // Warranty Desc
  const [value2, setValue2] = useState();

  function onChange2(e) {
    setValue2(e.target.value);
  }
  const [value3, setValue3] = useState();

  function onChange3(e) {
    setValue3(e.target.value);
  }
  const [value4, setValue4] = useState();

  function onChange4(e) {
    setValue4(e.target.value);
  }

  // data send
  const handleSave = (event) => {
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //     event.preventDefault();
    //     event.stopPropagation();
    // }

    // setValidated(true);
    setBackdropOpen(true);
    createProduct({
      variables: {
        productInput: {
          productName: `${productNameState}`,
          productSubTitle: `${productSubtitleState}`,
          productPrice: `${productPriceState}`,
          productDescription: `${value}`,
          warranty: `${warranty}`,
          warrantyDescription: `${value2}`,
          dimensionsDescrption: `${value3}`,
          AdditionalFeatures: `${value4}`,
          image: imageKey,
          storage: `${productStorageState}`,
          condition: `${productCondition}`,
          productCategory: `${category}`,
          productBrand: `${productBrand}`,
          liveLink: `${productLink}`,
          quantity: `${producytQuantity}`,
          mrp: `${productMRP}`,
          ram: `${productRAM}`,
          networkType: `${networkType}`,
          type: `${productType}`,
          screenSize: `${screenSize}`,
          files: images,
        },
      },
    }).then((data) => {
      setBackdropOpen(false);
      swal({
        title: "Success",
        text: "Product Added Successfully",
        icon: "success",
      });
    });
    setWarranty("");
    setProductNameState("");
    setProductSubtitleState("");
    setProductPriceState("");
    setProductBrand("");
    setCategory("");
    setProCondition("");
    setProductStorageState("");
    setValue("");
    setProductQuantity(0);
    setValue2("");
    setImages([]);
    setProductLink("");
    setProductMRP("");
    setProductRAM("");
    setScreenSize("");
    setProductType("");
    setImages([]);
    setValue3("");
    setValue4("");

    imageKey = [];
  };
  //images Uploading
  const handleImagesUpload = () => {
    try {
      images.map(async (fileData) => {
        const uniqueId = uniqid();
        var regex = new RegExp("[^.]+$");
        var extension = fileData.name.match(regex);
        var uniqueFileName =
          "hellofi" + "-" + uniqueId + "-" + uniqueId + "." + extension[0];
        imageKey.push(uniqueFileName);
        const params = {
          Bucket: BUCKET_NAME,
          Key: uniqueFileName,
          Body: fileData,
        };
        s3.upload(params, function (err, data) {
          if (err) {
            throw err;
          } else {
            setImageBox(uniqueFileName);
            swal({
              title: "Successfull!!!",
              text: " Uploaded Successfully",
              icon: "success",
            });
            // setMultipleImgKey([])
            setImages("");
            // setUploadLoading(false)
            // setUploadDone(true)
          }
        });
      });
    } catch (err) {}
  };

  const handleCategoryChange = (selectedCategory) => {
    setCategory(selectedCategory);
    getExploreByBrands({
      variables: {
        category: selectedCategory,
      },
    });
  };
  return (
    <>
      <MuiBackdrop />
      <Container>
        <Card style={{ width: "55rem" }} className="mx-auto d-block">
          <Card.Body>
            <Card.Title style={{ textAlign: "center" }}>
              Add Buy Product Here
            </Card.Title>
            <Form noValidate validated={validated}>
              <Form.Group as={Col} md="12" controlId="validationCustom02">
                <Dropdown style={{ marginTop: "1rem" }}>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    style={{ width: "100%" }}
                  >
                    {" "}
                    Category
                  </Dropdown.Toggle>
                  <span>{category}</span>
                  <Dropdown.Menu
                    popperConfig={{
                      modifiers: [
                        {
                          name: "flip",
                          enabled: false, // Disable flipping to prevent opening in top direction
                        },
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10], // Adjust the offset as needed
                          },
                        },
                      ],
                    }}
                    style={{ width: "100%" }}
                  >
                    <div style={{ overflowY: "scroll", height: "200px" }}>
                      <Dropdown.Item
                        onClick={() => handleCategoryChange("Phones")}
                      >
                        Phones
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleCategoryChange("Laptops")}
                      >
                        Laptops
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleCategoryChange("Tablets")}
                      >
                        Tablets
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleCategoryChange("Smart Watches")}
                      >
                        Smart Watches
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleCategoryChange("Desktop/Imac")}
                      >
                        Desktop/Imac
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() =>
                          handleCategoryChange("Other Accessories")
                        }
                      >
                        Other Accessories
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom02">
                <Dropdown style={{ marginTop: "1rem" }}>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    style={{ width: "100%" }}
                  >
                    Warranty
                  </Dropdown.Toggle>
                  <span>{warranty}</span>
                  <Dropdown.Menu
                    popperConfig={{
                      modifiers: [
                        {
                          name: "flip",
                          enabled: false,
                        },
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10],
                          },
                        },
                      ],
                    }}
                    style={{ width: "100%" }}
                  >
                    <div style={{ overflowY: "scroll", height: "auto" }}>
                      <Dropdown.Item
                        onClick={() => setWarranty("HelloFi Warranty")}
                      >
                        HelloFi Warranty
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setWarranty("Brand Warranty")}
                      >
                        Brand Warranty
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setWarranty("No Warranty")}>
                        No Warranty
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="validationCustom02">
                <Dropdown style={{ marginTop: "1rem" }}>
                  <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    style={{ width: "100%" }}
                  >
                    {" "}
                    Product Condition{" "}
                  </Dropdown.Toggle>
                  <span>{productCondition}</span>
                  <Dropdown.Menu
                    popperConfig={{
                      modifiers: [
                        {
                          name: "flip",
                          enabled: false, // Disable flipping to prevent opening in top direction
                        },
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10], // Adjust the offset as needed
                          },
                        },
                      ],
                    }}
                    style={{ width: "100%" }}
                  >
                    <div style={{ overflowY: "scroll", height: "120px" }}>
                      <Dropdown.Item
                        onClick={(e) => setProCondition("Unboxed")}
                      >
                        Unboxed
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(e) => setProCondition("Superb")}>
                        Superb
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(e) => setProCondition("Good")}>
                        Good
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(e) => setProCondition("Fair")}>
                        Fair
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => setProCondition("Partially-Fair")}
                      >
                        Partially-Fair
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              {category ? (
                <Form.Group as={Col} md="12" controlId="validationCustom02">
                  <Dropdown style={{ marginTop: "1rem" }}>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{ width: "100%" }}
                    >
                      {" "}
                      Choose Product Brand{" "}
                    </Dropdown.Toggle>
                    <span>{productBrand}</span>
                    <Dropdown.Menu
                      popperConfig={{
                        modifiers: [
                          {
                            name: "flip",
                            enabled: false, // Disable flipping to prevent opening in top direction
                          },
                          {
                            name: "offset",
                            options: {
                              offset: [0, 10], // Adjust the offset as needed
                            },
                          },
                        ],
                      }}
                      style={{ width: "100%" }}
                    >
                      <div style={{ overflowY: "scroll", height: "120px" }}>
                        {getExploreByBrandsdata &&
                          getExploreByBrandsdata?.getExploreByBrands.map(
                            (data) => {
                              return (
                                <Dropdown.Item
                                  onClick={() =>
                                    setProductBrand(data.brandName)
                                  }
                                >
                                  {data.brandName}
                                </Dropdown.Item>
                              );
                            }
                          )}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group>
              ) : (
                ""
              )}

              <Form.Group controlId="validationCustom01">
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Product Name"
                  value={productNameState}
                  onChange={(e) => setProductNameState(e.target.value)}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Product Quantity</Form.Label>
                <Form.Control
                  required
                  type="Number"
                  placeholder="Product quantity"
                  value={producytQuantity}
                  onChange={(e) => {
                    const value = e.target.value;
                    setProductQuantity(value === "" ? "" : parseInt(value));
                  }}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="validationCustom01">
                <Form.Label>Product Subtitle</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Product Subtitle"
                  value={productSubtitleState}
                  onChange={(e) => setProductSubtitleState(e.target.value)}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Product MRP</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="MRP"
                  value={productMRP}
                  onChange={(e) => {
                    const value = e.target.value;
                    setProductMRP(value === "" ? "" : parseInt(value));
                  }}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Product Price</Form.Label>
                <Form.Control
                  required
                  type="number"
                  placeholder="Product Price (Rs)"
                  value={productPriceState}
                  onChange={(e) => {
                    const value = e.target.value;
                    setProductPriceState(value === "" ? "" : parseInt(value));
                  }}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Realtime Product Video</Form.Label>
                <Form.Control
                  required
                  type="url"
                  placeholder="Valid youtube link "
                  value={productLink}
                  onChange={(e) => setProductLink(e.target.value)}
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              {(category && category == "Phones") ||
              category == "Laptops" ||
              category == "Tablets" ||
              category == "Desktop/Imac" ? (
                <>
                  <Dropdown style={{ marginTop: "1rem" }}>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      style={{ width: "100%" }}
                    >
                      {" "}
                      Product Storage{" "}
                    </Dropdown.Toggle>
                    <span>{productStorageState}</span>
                    <Dropdown.Menu
                      popperConfig={{
                        modifiers: [
                          {
                            name: "flip",
                            enabled: false, // Disable flipping to prevent opening in top direction
                          },
                          {
                            name: "offset",
                            options: {
                              offset: [0, 10], // Adjust the offset as needed
                            },
                          },
                        ],
                      }}
                      style={{ width: "100%" }}
                    >
                      <div style={{ overflowY: "scroll", height: "120px" }}>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("16GB")}
                        >
                          16GB
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("32GB")}
                        >
                          32GB
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("64GB")}
                        >
                          64GB
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("128GB")}
                        >
                          128GB
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("256GB")}
                        >
                          256GB
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("512GB")}
                        >
                          512GB
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("1TB")}
                        >
                          1TB
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("2TB")}
                        >
                          2TB
                        </Dropdown.Item>

                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("Above512")}
                        >
                          Above512
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={(e) => setProductStorageState("Below512")}
                        >
                          Below512
                        </Dropdown.Item>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* <Form.Group controlId="validationCustom01">
                                    <Form.Label>Product storage</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Storage Eg 64GB"
                                        value={productStorageState}
                                        onChange={(e) => setProductStorageState(e.target.value)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group> */}
                  <Form.Group controlId="validationCustom01">
                    <Dropdown style={{ marginTop: "1rem" }}>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        style={{ width: "100%" }}
                      >
                        {" "}
                        Product RAM{" "}
                      </Dropdown.Toggle>
                      <span>{productRAM}</span>
                      <Dropdown.Menu
                        popperConfig={{
                          modifiers: [
                            {
                              name: "flip",
                              enabled: false, // Disable flipping to prevent opening in top direction
                            },
                            {
                              name: "offset",
                              options: {
                                offset: [0, 10], // Adjust the offset as needed
                              },
                            },
                          ],
                        }}
                        style={{ width: "100%" }}
                      >
                        <div>
                          <Dropdown.Item onClick={(e) => setProductRAM("2GB")}>
                            2GB
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setProductRAM("3GB")}>
                            3GB
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setProductRAM("4GB")}>
                            4GB
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setProductRAM("6GB")}>
                            6GB
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setProductRAM("8GB")}>
                            8GB
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setProductRAM("12GB")}>
                            12GB
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setProductRAM("16GB")}>
                            16GB
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setProductRAM("32GB")}>
                            32GB
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setProductRAM("64GB")}>
                            64GB
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                  <Form.Group md="12" controlId="validationCustom02">
                    <Dropdown style={{ marginTop: "1rem" }}>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        style={{ width: "100%" }}
                      >
                        {" "}
                        Network Type{" "}
                      </Dropdown.Toggle>
                      <span>{networkType}</span>
                      <Dropdown.Menu
                        popperConfig={{
                          modifiers: [
                            {
                              name: "flip",
                              enabled: false, // Disable flipping to prevent opening in top direction
                            },
                            {
                              name: "offset",
                              options: {
                                offset: [0, 10], // Adjust the offset as needed
                              },
                            },
                          ],
                        }}
                        style={{ width: "100%" }}
                      >
                        <div>
                          <Dropdown.Item onClick={(e) => setNetworkType("4G")}>
                            4G
                          </Dropdown.Item>
                          <Dropdown.Item onClick={(e) => setNetworkType("5G")}>
                            5G
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setNetworkType("Only WiFi")}
                          >
                            Only WiFi
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setNetworkType("Cellular + WiFi")}
                          >
                            Cellular + WiFi
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </>
              ) : (
                ""
              )}
              {(category && category == "Laptops") ||
              category == "Desktop/Imac" ? (
                <>
                  <Form.Group md="12" controlId="validationCustom02">
                    <Dropdown style={{ marginTop: "1rem" }}>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        style={{ width: "100%" }}
                      >
                        {" "}
                        Screen Size{" "}
                      </Dropdown.Toggle>
                      <span>{screenSize}</span>
                      <Dropdown.Menu
                        popperConfig={{
                          modifiers: [
                            {
                              name: "flip",
                              enabled: false, // Disable flipping to prevent opening in top direction
                            },
                            {
                              name: "offset",
                              options: {
                                offset: [0, 10], // Adjust the offset as needed
                              },
                            },
                          ],
                        }}
                        style={{ width: "100%" }}
                      >
                        <div>
                          <Dropdown.Item
                            onClick={(e) => setScreenSize("Below 13 inches")}
                          >
                            Below 13 inche
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setScreenSize("13-14 inches")}
                          >
                            13-14 inches
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setScreenSize("14-15 inches")}
                          >
                            14-15 inches
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setScreenSize("Above 15 inches")}
                          >
                            Above 15 inches
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setScreenSize("Below 30 inches")}
                          >
                            Below 30 inches
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setScreenSize("Above 30 inches")}
                          >
                            Above 30 inches
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </>
              ) : (
                ""
              )}
              {category && category == "Other Accessories" ? (
                <>
                  <Form.Group md="12" controlId="validationCustom02">
                    <Dropdown style={{ marginTop: "1rem" }}>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        style={{ width: "100%" }}
                      >
                        {" "}
                        Product Type{" "}
                      </Dropdown.Toggle>
                      <span>{productType}</span>
                      <Dropdown.Menu
                        popperConfig={{
                          modifiers: [
                            {
                              name: "flip",
                              enabled: false, // Disable flipping to prevent opening in top direction
                            },
                            {
                              name: "offset",
                              options: {
                                offset: [0, 10], // Adjust the offset as needed
                              },
                            },
                          ],
                        }}
                        style={{ width: "100%" }}
                      >
                        <div>
                          <Dropdown.Item
                            onClick={(e) => setProductType("Camera")}
                          >
                            Camera
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setProductType("Airpod")}
                          >
                            Airpod
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setProductType("Earphone")}
                          >
                            Earphone
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setProductType("Drone")}
                          >
                            Drone
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setProductType("Printer")}
                          >
                            Printer
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </>
              ) : (
                ""
              )}
              <Form.Group controlId="validationCustom01">
                <Form.Label>Product Description</Form.Label>
                <EditorProvider>
                  <Editor
                    value={value}
                    onChange={onChange}
                    style={{ height: "25rem" }}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                      <BtnAlignCenter />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Warranty Description</Form.Label>
                <EditorProvider>
                  <Editor
                    value={value2}
                    onChange={onChange2}
                    style={{ height: "25rem" }}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                      <BtnAlignCenter />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Return and Replacement Policy</Form.Label>
                <EditorProvider>
                  <Editor
                    value={value3}
                    onChange={onChange3}
                    style={{ height: "25rem" }}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                      <BtnAlignCenter />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Packaging Information</Form.Label>
                <EditorProvider>
                  <Editor
                    value={value4}
                    onChange={onChange4}
                    style={{ height: "25rem" }}
                  >
                    <Toolbar>
                      <BtnBold />
                      <BtnItalic />
                      <BtnAlignCenter />
                    </Toolbar>
                  </Editor>
                </EditorProvider>
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="validationCustom01">
                <Form.Label>Product Images</Form.Label>
                <Form.Control
                  required
                  type="file"
                  onChange={handleIamges}
                  multiple
                  accept="image/*"
                />
                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              </Form.Group>
              {images
                ? images.map((image) => {
                    return (
                      <span style={{ marginLeft: "1rem" }}> {image.name}</span>
                    );
                  })
                : ""}
              {/* <div>
                <Button
                  style={{ fontSize: "11px" }}
                  onClick={() => handleImagesUpload()}
                  disabled={images ? false : true}
                >
                  {" "}
                  Upload{" "}
                </Button>
              </div> */}
            </Form>
            <Button
              onClick={() => handleSave()}
              disabled={
                productNameState &&
                producytQuantity &&
                productPriceState &&
                productSubtitleState &&
                value &&
                value2 &&
                value3 &&
                value4 &&
                imageKey &&
                productLink &&
                productMRP
                  ? false
                  : true
              }
              className="mx-auto d-block mt-2"
            >
              {" "}
              Save{" "}
            </Button>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};
export default AddBuyOrders;
