import { Box } from "@mui/material";
import React from "react";
import HomePageContainer from "../shared/HomePageContainer";
import Heading from "../shared/Heading";
import AllPrelovedGadgets from "./AllPrelovedGadgets";

const PrelovedGadgets = () => {
  return (
    <HomePageContainer sx={{ margin: { xs: "0 0.5rem", laptop: 0 } }}>
      <Heading text={"Buy Preloved Gadgets today"} />
      <AllPrelovedGadgets />
    </HomePageContainer>
  );
};

export default PrelovedGadgets;
