import { atom } from "recoil";

export const profileAtom = atom({
  key: "profileAtom",
  default: null,
});

export const userNameAtom = atom({
  key: "userNameAtom",
  default: { fName: "", lName: "", originalfName: "", originallName: "" },
});

export const userEmailAtom = atom({
  key: "userEmailAtom",
  default: { email: "", originalEmail: "" },
});

export const userContactAtom = atom({
  key: "userContactAtom",
  default: { contact: "", originalContact: "" },
});
