import React from "react";
import HomePageContainer from "../shared/HomePageContainer";
import AllTestimonials from "./AllTestimonials";

const Testimonials = () => {
  return (
    <HomePageContainer>
      <AllTestimonials />
    </HomePageContainer>
  );
};

export default Testimonials;
