import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import { format } from "date-fns";

const OrderNumber = ({ orderType, orderNo, createdAt }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const timestamp = new Date(Number(createdAt));
  const formattedDate = format(timestamp, "EEE, dd MMM");
  return (
    <Box
      sx={{
        margin: isSmallScreen ? "1rem 0.75rem 0 0.75rem" : "1rem 0 0 0",
      }}
    >
      <CustomTypography
        variant={"subtitle1"}
        sx={{
          fontWeight: "600",
        }}
      >
        Order Id : <span>{orderNo}</span>
      </CustomTypography>
      <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "500" }}>
        {formattedDate}
      </CustomTypography>
    </Box>
  );
};

export default OrderNumber;
