import React, { useState } from "react";
import {
  Box,
  Modal,
  Typography,
  IconButton,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import ImagesShowModal from "./ImagesShowModal";
import { useRecoilValue } from "recoil";
import {
  loadingStateAtom,
  productDetailsAtom,
} from "../../../../store/atoms/productDetails/productAtom";

const ProductMainImage = ({ images, imageUrl, activeImageIndex }) => {
  const isSmallScreen = useMediaQuery("(max-width:767px)");
  const isTabletScreen = useMediaQuery(
    "(min-width:768px) and (max-width:1023px)"
  );
  const isLargeScreen = useMediaQuery("(max-width:1024px)");
  const [openModal, setOpenModal] = useState(false);
  const handleImageClick = () => {
    setOpenModal(true);
  };

  const product = useRecoilValue(productDetailsAtom);
  const loading = useRecoilValue(loadingStateAtom);

  if (loading || !product) {
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        width={"100%"}
        height={isTabletScreen ? "375px" : "500px"}
        style={{ borderRadius: "0.5rem" }}
      />
    );
  }

  return (
    <>
      <>
        <Box
          onClick={handleImageClick}
          display={"flex"}
          justifyContent={"center"}
          style={{
            cursor: "pointer",
            padding: "1rem",
            borderRadius: "0.5rem",
            border: "0.5px solid #D3D3D3",
            width: "100%",
            height: isTabletScreen ? "375px" : "500px",
            maxHeight: isTabletScreen ? "375px" : "500px",
            overflow: "hidden",
            boxSizing: "border-box",
          }}
        >
          <img
            src={`https://hellofi.s3.ap-south-1.amazonaws.com/${product.images[activeImageIndex].lg}`}
            alt={product.image[activeImageIndex]}
            loading="lazy"
            style={{
              width: isLargeScreen ? "100%" : "70%",
              height: "100%",
              borderRadius: "0.5rem",
              objectFit: "cover",
              boxSizing: "border-box",
            }}
          />
        </Box>
      </>

      <ImagesShowModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        images={product.images}
      />
    </>
  );
};

export default ProductMainImage;
