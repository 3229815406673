import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { ArrowForward } from "@mui/icons-material";
import BrowGradient from "../shared/BrowGradient";

const ServiceCard = ({
  img,
  title,
  subtitleBefore,
  bold,
  subtitleAfter,
  buttonName,
  buttonLink,
}) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("desktop"));
  return (
    <BrowGradient sx={{ maxHeight: "250px", height: "175px" }}>
      <Box
        display="flex"
        sx={{ position: "relative", zIndex: 2, height: "100%" }}
      >
        <Box
          flexBasis="60%"
          flexDirection={"column"}
          justifyContent={"space-between"}
          display={"flex"}
          sx={{
            paddingRight: "0.5rem",
          }}
        >
          <Box>
            <CustomTypography
              variant="subtitle2"
              sx={{
                fontWeight: "600",
                color: "#285B84",
                fontSize: {
                  xs: "0.875rem",
                  tablet: "0.95rem",
                  laptop: "1rem",
                  desktop: "1rem",
                },
              }}
            >
              {title}
            </CustomTypography>
            <CustomTypography
              variant="subtitle2"
              sx={{
                fontSize: {
                  xs: "0.875rem",
                  tablet: "0.95rem",
                  laptop: "1rem",
                  desktop: "1rem",
                },
              }}
            >
              {subtitleBefore}{" "}
              <span style={{ fontWeight: "600", color: "#285B84" }}>
                {bold}
              </span>
            </CustomTypography>
            <CustomTypography
              variant="subtitle2"
              sx={{
                fontSize: {
                  xs: "0.875rem",
                  tablet: "0.95rem",
                  laptop: "1rem",
                  desktop: "1rem",
                },
                whiteSpace: "nowrap",
              }}
            >
              {subtitleAfter}
            </CustomTypography>
          </Box>
          <Box sx={{ marginTop: "auto" }}>
            <Button
              variant="contained"
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#F1E8E8",
                textTransform: "none",
                color: "black",
                marginTop: "2rem",
                padding: "0.2rem 0.75rem",
                borderRadius: "0.5rem",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "#F1E8E8",
                },
              }}
            >
              <CustomTypography
                variant={"subtitle2"}
                sx={{
                  fontWeight: "600",
                  fontSize: { xs: "0.80rem", tablet: "0.875rem" },
                  color: "#285B84",
                }}
              >
                {buttonName}{" "}
                <ArrowForward
                  sx={{ fontSize: "1.25rem", marginLeft: "1rem" }}
                />
              </CustomTypography>
            </Button>
          </Box>
        </Box>
        <Box
          flexBasis="40%"
          sx={{
            overflow: "hidden",
            display: "flex",
            height: "100%",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={img}
            alt={title}
            style={{
              width: isMobileScreen ? "90%" : "100%",
              height: isMobileScreen ? "90%" : "100%",
              objectFit: "contain",
              animation: "sway 6s ease-in-out infinite",
              maxHeight: "150px",
              maxWidth: "180px",
            }}
          />
        </Box>
      </Box>
      <style>
        {`
          @keyframes sway {
            0% {
              transform: translateY(0);
            }
            25% {
              transform: translateY(-5px);
            }
            50% {
              transform: translateY(5px);
            }
            75% {
              transform: translateY(-5px);
            }
            100% {
              transform: translateY(0);
            }
          }
        `}
      </style>
    </BrowGradient>
  );
};

export default ServiceCard;
