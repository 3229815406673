import Navigation from "../Navigation"
import { GET_SPECIFIC_PRODUCT } from '../../graphql/Query'
import { QUERY_GET_SELL_MODAL_IMAGE_BY_BRANDNAME } from '../../graphql/Query'
import { Col, Container, Button, Image, Row, Spinner, Card, Breadcrumb, Dropdown } from 'react-bootstrap'
import { useQuery } from '@apollo/client';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Footer from "../Footer";
import { FaArrowRight } from 'react-icons/fa'

const ShowLaptopMaxPrice = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const location = useLocation()
    const { product } = useParams()
    const navigate = useNavigate()

    const { data: getSpecificProduct, loading: productLoading } = useQuery(GET_SPECIFIC_PRODUCT,
        {
            variables:
            {
                "productName": location.state.productName

            }
        });
    const { data: getSellImageModalByBrandName, loading: ImageLoading } = useQuery(QUERY_GET_SELL_MODAL_IMAGE_BY_BRANDNAME,
        {
            variables:
            {
                "brandName": location.state.productBrand

            }
        });
    let prevlink = `/allbrands/${getSpecificProduct?.getSpecificProduct[0]?.productBrand}`
    const handleContinue = () => {


        navigate(`/product/step1`, {
            state: {
                id: `${getSpecificProduct.getSpecificProduct[0].id}`,
                productCategory: location.state.productCategory,
                productBrand: location.state.productBrand,
                productName: location.state.productName, imgurl: getSellImageModalByBrandName?.getSellImageModalByBrandName[0]?.image
            }
        })
    }
    const handleSelectBrand = () => {
    navigate(`/selling/${location.state?.productCategory}`, )
    }
    const handleSelectProduct = () => {
        navigate(`/allbrands/${location.state?.productBrand}`, {
            state: {
                productCategory: location.state?.productCategory, productBrand: location.state?.productBrand,
            }
        })
    }

    return (
        <>
            <Navigation />
            <div className="banner">
                <h2 style={{ color: '#000' }}>
                    Sell your Old Laptops
                </h2>
                {/* <div className="search-bar">
                    <input type="text" placeholder="Search..." className="search-input" />
                </div> */}
                <Breadcrumb>
                    <Breadcrumb.Item ><Link to="/" style={{ fontWeight: 'bold', color: '#2ecc71' }}>Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item> <a onClick={handleSelectBrand} style={{ fontWeight: 'bold', color: '#2ecc71' }}>Select Brand</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item> <a onClick={handleSelectProduct} style={{ fontWeight: 'bold', color: '#2ecc71' }}>Select Product</a>
                    </Breadcrumb.Item>
                    {/* <Breadcrumb.Item active style={{ fontWeight: 'bold', color: '#7f8c8d' }}>Choose variant</Breadcrumb.Item> */}
                </Breadcrumb>
            </div>

            {
                productLoading && ImageLoading ? <Spinner animation="border" variant="success" className='mx-auto d-block' /> :
                    <Container>
                        <h3 style={{ textAlign: "center", margin: "1rem", fontWeight: "700", color: '#000' }}>Sell Old {getSpecificProduct?.getSpecificProduct[0]?.productName}</h3>
                        <Card className="specific-product-card" style={{ padding: 10, borderRadius: "25px", width: "100%" }}  >
                            <Row>
                                <Col >
                                    <Image src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${getSellImageModalByBrandName?.getSellImageModalByBrandName[0]?.image}`} className="brandImgShadow mx-auto d-block" fluid style={{ width: "20rem" }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col >
                                    <h1 className="productNameCss" style={{ textAlign: 'center', fontSize: "25px" }}>{getSpecificProduct?.getSpecificProduct[0]?.productName}</h1>
                                    <div style={{ fontWeight: "500", color: "grey", textAlign: "center" }}  > Get Upto</div>
                                    <div style={{ color: "red", fontWeight: "bold", fontSize: "42px ", textAlign: "center" }} > Rs. {getSpecificProduct?.getSpecificProduct[0]?.productPrice} </div>
                                    <Button variant="warning" className="nextBtn mx-auto d-block " onClick={() => handleContinue()} >Get Exact Value <FaArrowRight style={{ marginTop: '-4px' }} /></Button>
                                    <p className="text-danger fw-bold text-center mt-3 " >The above price is purely based on your inputs. Please provide the correct information and continue until the final page for more accurate pricing </p>

                                </Col>
                            </Row>

                        </Card>
                    </Container >
            }

        </>
    )
}

export default ShowLaptopMaxPrice 