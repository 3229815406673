import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const SellingGuideCard = ({ img, title, subtitle, body }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("tabletLarge"));
  return (
    <Box
      display={"flex"}
      flexDirection={isMobileScreen ? "row" : "column"}
      gap={isMobileScreen ? "1rem" : "0.5rem"}
      sx={{ height: isMobileScreen ? "125px" : "100%" }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"0.5rem"}
        alignItems={"center"}
      >
        <CustomTypography
          variant={"subtitle1"}
          sx={{
            fontSize: {
              xs: "0.95rem",
              tablet: "1rem",
            },
            fontWeight: "600",
          }}
        >
          {title}
        </CustomTypography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: isMobileScreen ? "70px" : "80px",
            height: isMobileScreen ? "70px" : "80px",
            backgroundColor: "#54B0BA",
            borderRadius: "50%",
          }}
        >
          <img
            src={img}
            style={{
              width: isMobileScreen ? "45px" : "50px",
              height: isMobileScreen ? "45px" : "50px",
            }}
          />
        </Box>
      </Box>
      <Box
        display={"flex"}
        flexGrow={1}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={isMobileScreen ? "center" : "flex-start"}
        gap={"0.5rem"}
      >
        <Box>
          <CustomTypography
            variant={"subtitle2"}
            sx={{
              color: "#214C7B",
              fontWeight: "600",
              textAlign: "center",
              fontSize: {
                xs: "0.875rem",
                tablet: "0.95rem",
              },
            }}
          >
            {subtitle}
          </CustomTypography>
        </Box>
        <Box>
          <CustomTypography
            variant={"subtitle2"}
            sx={{
              fontWeight: "400",
              textAlign: "justify",
              margin: "0 auto",
              width: "100%",
              fontSize: {
                xs: "0.875rem",
                tablet: "0.90rem",
              },
            }}
          >
            {body}
          </CustomTypography>
        </Box>
      </Box>
    </Box>
  );
};

export default SellingGuideCard;
