import { Container } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import GReviewCard from "./GReviewCard";
import "../ComponentsCss/greview.css";
import { AccountCircle } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import FlexCenter from "../muicomponents/shared/FlexCenter";
import { Typography, useMediaQuery } from "@mui/material";
import { useQuery } from "@apollo/client";
import { QUERY_GET_GOOGLE_REVIEWS } from "../graphql/Query";
import { useEffect, useState } from "react";

export default function Googlereviews() {
  const { data: getGoogleReviews, loading: Loading } = useQuery(
    QUERY_GET_GOOGLE_REVIEWS
  );
  const isSmallScreen = useMediaQuery("(max-width:576px)");

  if (Loading) return <p>Loading...</p>;
  const options = {
    loop: true,
    center: true,
    items: 1,
    autoplay: true,
    autoplayTimeout: 4000,
    smartSpeed: 450,
    responsive: {
      0: {
        items: 1,
        nav: true,
        dots: true,
      },
      707: {
        items: 2,
        nav: true,
        dots: true,
      },
      1000: {
        items: 3,
        nav: true,
        dots: true,
      },
    },
    nav: true,
    dots: true,
  };

  const user_reviews = [
    {
      rating: 5,
      review_content:
        "Very professional..It just took 1 day to sell my old iPad Air.",
      user_Icon: AccountCircle,
      user_name: "Rohan",
    },
  ];

  return (
    <Container className="trndmain">
      <div className="google-reviews">
        <FlexCenter>
          <Box>
            <Typography
              variant="h4"
              sx={{
                fontSize: {
                  mobile: "1.1rem",
                  tablet: "1.75rem",
                },
                fontFamily: "Poppins",
                fontWeight: 500,
                lineHeight: "1.5",
              }}
            >
              - What Our Customers Say -
            </Typography>
          </Box>
        </FlexCenter>

        <OwlCarousel
          id="greviews-slider"
          className="owl-carousel owl-theme"
          {...options}
        >
          {!Loading &&
            getGoogleReviews.getGoogleReviews &&
            getGoogleReviews.getGoogleReviews.map((review) => {
              return (
                <div className="item" key={review.id}>
                  <div className="comma">
                    <Box
                      sx={{
                        display: "flex",
                        alignSelf: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                        width: isSmallScreen ? "80px" : "100px",
                        height: isSmallScreen ? "80px" : "100px",
                        borderRadius: "50%",
                        bottom: "-30px",
                        backgroundColor: "white",
                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faQuoteLeft}
                        style={{ fontSize: isSmallScreen ? "1.5rem" : "2rem" }}
                      />
                    </Box>
                  </div>

                  <div>
                    <GReviewCard review={review} />
                  </div>
                </div>
              );
            })}
        </OwlCarousel>
      </div>
    </Container>
  );
}
