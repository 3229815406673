import { useState, useEffect } from "react";
import Navigation from "../../Navigation"
import { Form, Button, Image, Card, Row, Col, Container } from 'react-bootstrap'
import '../../../ComponentsCss/QuestionnariesCss/step3.css'
import displayIcon from '../../../Images/desktopicon/screencondition.png'
import bodyConditionIcon from '../../../Images/desktopicon/devicecondition.png'
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import screenNo from '../../../Images/QuestionIcons/no.png'
import { useLocation, useNavigate, useParams } from "react-router-dom"

const DesktopStep4 = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()
    const [question1, setQuestion1] = useState(null);
    const [question2, setQuestion2] = useState(null);
    const location = useLocation();
    const handleContinue = () => {
        navigate("/desktop-products-step5", {
            state: {
                id: location.state.id, isDeviceSwitch: location.state.isDeviceSwitch, issueWithDeviceDisplay: location.state.issueWithDeviceDisplay, deviceScreen: location.state.deviceScreen, deviceEverRepair: location.state.deviceEverRepair,
                keyBoardState: location.state.keyBoardState, trackpadState: location.state.trackpadState, SpeakerIssue: location.state.SpeakerIssue, MicIssue: location.state.MicIssue, usbPortState: location.state.usbPortState, CharginPortIssue: location.state.CharginPortIssue, webCamState: location.state.webCamState, BatteryIssue: location.state.BatteryIssue, hingeLooseState: location.state.hingeLooseState, WifiIssue: location.state.WifiIssue, BluetoothIssue: location.state.BluetoothIssue, chargerFaultyState: location.state.chargerFaultyState, AutoRestartIssue: location.state.AutoRestartIssue, EarphonePortIssue: location.state.EarphonePortIssue, graphicsCardState: location.state.graphicsCardState, hardDriveState: location.state.hardDriveState, ramIssueState: location.state.ramIssueState, productCategory: location.state.productCategory, productBrand: location.state.productBrand,
                productName: location.state.productName, imgurl: location.state.imgurl, processorState: location.state.processorState, laptopStorage: location.state.laptopStorage, laptopRam: location.state.laptopRam, graphicsState: location.state.graphicsState, screenSizeState: location.state.screenSizeState, ConditionDeviceScreen: question1, ConditionDeviceBody: question2,
                fingerPrintIssueState : location.state.fingerPrintIssueState, faceSensorIssueState : location.state.faceSensorIssueState
            }
        })
    }


    return (
        <>
            <Navigation />
            <div className="banner">
                <h3  >
                    <span onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                        <FaArrowLeft />
                    </span>
                    <span style={{ fontWeight: "bold", textAlign: "center", marginLeft: "5%" }}>
                        Steps - 4/5
                    </span>
                </h3>
            </div>

            <Container className="containerStyle">
                <h3 style={{ textAlign: "center", margin: "1rem", fontWeight: "bold", fontSize: "21px" }} > Tell us about the cosmetic/physical condition of your device </h3>
                <Row >
                    <Col xs={12} md={6}   >

                        <Card style={{ width: '90%', height: "30rem", fontSize: "12px", padding: "1rem", margin: "1rem" }} className="step3-card">
                            <div>
                                <Image variant="top" src={displayIcon} className="question-icon-step3 mx-auto d-block" />
                            </div>
                            {/* <Card.Body > */}
                            <Card.Title style={{ fontSize: "12px", textAlign: "center", fontWeight: "bold" }}> Select condition of your device screen</Card.Title>
                            <Card.Text style={{ fontSize: "12px" }} >
                                {
                                    question1 === "minor" ?
                                        <div >
                                            <Form.Check
                                                type="radio"
                                                name="1"
                                                checked
                                                label={`Minor/least visible scratches (Less than 3)`}
                                            />
                                        </div> :
                                        <div onClick={() => setQuestion1("minor")}>
                                            <Form.Check
                                                type="radio"
                                                name="1"
                                                label={`Minor/least visible scratches (Less than 3)`}
                                            />
                                        </div>
                                }

                                <Form.Check
                                    type="radio"
                                    name="1"

                                    label={`Major/more visible scratches (More than 3)`}
                                    onChange={() => setQuestion1("major")}

                                />
                                <Form.Check
                                    type="radio"
                                    name="1"

                                    label={`No Scratches`}
                                    onChange={() => setQuestion1("no")}

                                />
                            </Card.Text>
                            {/* </Card.Body> */}
                        </Card>
                    </Col>
                    <Col xs={12} md={6}>
                        <Card style={{ width: '90%', height: "30rem", fontSize: "12px", padding: "1rem", margin: "1rem" }} className="step3-card">
                            <div>
                                <Image variant="top" src={bodyConditionIcon} className="question-icon-step3 mx-auto d-block" />
                            </div>
                            <Card.Title style={{ fontSize: "12px", textAlign: "center", fontWeight: "bold" }}> Select condition of your device body</Card.Title>
                            {/* <Card.Body></Card.Body> */}
                            <Card.Text style={{ fontSize: "12px" }} >
                                <Form.Check
                                    type="radio"
                                    name="2"
                                    label={`Excellent Condition (No visible sign of usage)`}
                                    onChange={() => setQuestion2("excellent")}
                                />
                                <Form.Check
                                    type="radio"
                                    name="2"
                                    onChange={() => setQuestion2("good")}

                                    label={`Good Condition (Minor visible scratches, Minor discoloration`}
                                />
                                <Form.Check
                                    type="radio"
                                    name="2"
                                    onChange={() => setQuestion2("average")}

                                    label={`Average Condition (Major visible scratches, Minor dent, Major discoloration)`}
                                />
                                <Form.Check
                                    type="radio"
                                    name="2"
                                    onChange={() => setQuestion2("poor")}

                                    label={`Poor Condition (Major visible scratches, Major discoloration, Cracks, Bend, Gap between display and body, Camera glass broken)`}
                                />
                            </Card.Text>

                        </Card>
                    </Col>
                </Row>
                <div style={{ display: "flex", justifyContent: "center", margin: "1rem", marginTop: "3rem" }}>

                    <Button disabled={question1 && question2 ? false : true} variant="warning" onClick={() => handleContinue()} style={{ borderRadius: '0px', width: 150, color: '#fff', fontWeight: 'bold', fontSize: '20px', backgroundColor: "#40a737", border: "0px", borderRadius: '10px' }}>Next <FaArrowRight /></Button>
                </div>


            </Container>
        </>
    )
}
export default DesktopStep4
