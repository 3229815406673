import { useQuery } from "@apollo/client";
import { QUERY_GET_FILTERED_BUY_PRODUCT } from "../../../../graphql/Query";
import useGetAllOffers from "./useGetAllOffers";
import useGetCartItems from "./useGetCartItems";
import useGetAllCoupons from "./useGetAllCoupons";

const useCombineOffersRelated = () => {
  const userId = localStorage.getItem("userId");
  const {
    cartData,
    loading: cartDataLoading,
    error: cartDataError,
    refetch: cartDataRefetch,
  } = useGetCartItems(userId);

  const {
    data: couponsData,
    loading: couponsLoading,
    error: couponsError,
    refetch: couponsRefetch,
  } = useGetAllCoupons();

  const {
    offers,
    loading: offersLoading,
    error: offersError,
    refetch: refetchOffers,
  } = useGetAllOffers();

  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
    refetch: productsRefetch,
  } = useQuery(QUERY_GET_FILTERED_BUY_PRODUCT, {
    variables: {
      filterFileld: "productCategory",
      filterVal: "Laptops",
    },
  });

  const loading =
    offersLoading || productsLoading || cartDataLoading || couponsLoading;
  const error = offersError || productsError || cartDataError || couponsError;

  return {
    offers,
    products: productsData?.getFilterBuyProduct,
    cartData,
    couponsData,
    loading,
    error,
    refetchOffers,
    productsRefetch,
    cartDataRefetch,
    couponsRefetch,
  };
};

export default useCombineOffersRelated;
