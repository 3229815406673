import { useState, useEffect } from "react";
import Navigation from "../../Navigation"
import { Form, ButtonGroup, ToggleButton, Button, Image, Row, Col, Toast, Container, Card } from 'react-bootstrap';
import '../../../ComponentsCss/QuestionnariesCss/step.css'
import { useNavigate, useParams, useLocation,Link } from "react-router-dom";
import switchOnIcon from '../../../Images/tableticon/switchon.png'
import displayIcon from '../../../Images/tableticon/displayissue.png'
import changedDisplay from '../../../Images/tableticon/displaychanged.png'
import deviceRepaired from '../../../Images/tableticon/displayrepair.png'
import Footer from "../../Footer";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";


const TabletStep1 = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { productdetail } = useParams()
    const location = useLocation()
    const navigate = useNavigate();
    const [answer1, setAnswer1] = useState(null);
    const [answer2, setAnswer2] = useState(null);
    const [answer3, setAnswer3] = useState(null);
    const [answer4, setAnswer4] = useState(null);
    const [showA, setShowA] = useState(true);
    const [showB, setShowB] = useState(true);

    const toggleShowA = () => setShowA(!showA);
    const toggleShowB = () => setShowB(!showB);


    const handleContinue = () => {

        navigate(`/tablet-products-step2`, {
            state: {
                id: location.state.id, ram: location.state.ram, storage: location.state.storage, isDeviceSwitch: answer1, issueWithDeviceDisplay: answer2, deviceScreen: answer3, deviceEverRepair: answer4, productCategory: location.state.productCategory,
                productBrand: location.state.productBrand,
                productName: location.state.productName, imgurl: location.state.imgurl
            }
        })
    }
    return (
        <>
            <Navigation />
            <div className="banner">
                <h3  >
                    <span onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                        <FaArrowLeft />
                    </span>
                    <span style={{ fontWeight: "bold", textAlign: "center", marginLeft: "5%" }}>
                        Steps - 1/5
                    </span>
                </h3>
            </div>
            <Container style={{ marginTop: 50 }}>
                <Row>
                    <Col md={6}>
                        <Row>
                            <Col md={3} xs={4} >
                                <div className="changeAnimation">
                                    <Image variant="top" src={switchOnIcon} fluid className="imgShadow1" />
                                </div>
                            </Col>
                            <Col className="imgCol" md={9} xs={8} >
                                <Form.Label className="labelClass"> 1. Is your device switching on? </Form.Label>
                                {
                                    answer1 === "yes" ? <div className="answers" style={{ width: 250 }} onClick={() => setAnswer1("yes")} >
                                        <Form.Check type="radio" checked name="1" label={"Yes (Sell Now)"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                    </div> :
                                        <div className="answers" style={{ width: 250 }} onClick={() => setAnswer1("yes")} >
                                            <Form.Check type="radio" name="1" label={"Yes (Sell Now)"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                        </div>
                                }
                                {
                                    answer1 === "no" ?
                                        <div className="answers" style={{ width: 250 }} onClick={() => setAnswer1("no")}>
                                            <Form.Check type="radio" checked name="1" label={"No (Recycle)"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                        </div> :
                                        <div className="answers" style={{ width: 250 }} onClick={() => setAnswer1("no")} >
                                            <Form.Check type="radio" name="1" label={"No (Recycle)"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                        </div>
                                }
                            </Col>
                        </Row>
                    </Col>
                    {
                        answer1 === "no" ?
                            <>
                                <h1 style={{ textAlign: 'center', fontSize: 15, color: '#e74c3c', marginTop: 20 }}>If your device is not switching ON we won't be able to use it again.</h1>
                                <h1 style={{ textAlign: 'center', fontSize: 15, color: 'green', marginTop: 20, fontWeight: "bold" }}>But Still if you want to SELL it you can contact us on 8150835583</h1>
                                <Link to="tel:8878719466">
                                    <Button variant="success" className="w-25 mt-3 mx-auto d-block  " >
                                        <span style={{ fontWeight: "bold" }}>
                                            Call Us
                                        </span>
                                    </Button>
                                </Link>
                            </>
                            :
                            <>

                                <Row>
                                    <Col md={6}>
                                        <Row>
                                            <Col md={3} xs={4}>
                                                <div className="changeAnimation">
                                                    <Image variant="top" src={displayIcon} fluid className="imgShadow3" />
                                                </div>
                                            </Col>
                                            <Col className="imgCol" md={9} xs={8}>
                                                <Form.Label className="labelClass"> 2. Any issue with your device display ?
                                                <span className="fw-normal">
                                                    (While, Black, Yellow dot/spot/patch/shadow, Line, Flickering, Broken, Touch not responding)
                                                </span>
                                                 </Form.Label>
                                                {
                                                    answer2 === "yes" ? <div className="answers" style={{ width: 250 }} >
                                                        <Form.Check type="radio" checked aria-label="radio 1" name="3" label={"Yes"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                                    </div> : <div className="answers" style={{ width: 250 }} onClick={() => setAnswer2("yes")}>
                                                        <Form.Check type="radio" aria-label="radio 1" name="3" label={"Yes"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                                    </div>
                                                }
                                                {
                                                    answer2 === "no" ? <div className="answers" style={{ width: 250 }} >
                                                        <Form.Check type="radio" checked aria-label="radio 1" name="3" label={"No"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                                    </div> :
                                                        <div className="answers" style={{ width: 250 }} onClick={() => setAnswer2("no")}>
                                                            <Form.Check type="radio" aria-label="radio 1" name="3" label={"No"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                                        </div>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={6}>
                                        <Row>
                                            <Col md={3} xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                                                <Image variant="top" src={changedDisplay} fluid className="imgShadow4" />
                                            </Col>
                                            <Col className="imgCol" md={9} xs={8}>
                                                <Form.Label className="labelClass"> 3. Tell us about your gadget’s screen? </Form.Label>
                                                {
                                                    answer3 === "original" ?
                                                        <div className="answers" style={{ width: 250 }} >
                                                            <Form.Check type="radio" checked aria-label="radio 1" name="4" label={"Original"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                                        </div> :
                                                        <div className="answers" style={{ width: 250 }} onClick={() => setAnswer3("original")} >
                                                            <Form.Check type="radio" aria-label="radio 1" name="4" label={"Original"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                                        </div>
                                                }
                                                {
                                                    answer3 === "change1" ?

                                                        <div className="answers" style={{ width: 250 }}>
                                                            <Form.Check type="radio" checked aria-label="radio 1" name="4" label={"Changed (Compatible)"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                                        </div> :
                                                        <div className="answers" style={{ width: 250 }} onClick={() => setAnswer3("change1")}>
                                                            <Form.Check type="radio" aria-label="radio 1" name="4" label={"Changed (Compatible)"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                                        </div>

                                                }
                                                {
                                                    answer3 === "change2" ?
                                                        <div className="answers" style={{ width: 250 }}>
                                                            <Form.Check type="radio" checked aria-label="radio 1" name="4" label={"Changed but Original (Replacement Bill Required)"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                                        </div> :
                                                        <div className="answers" style={{ width: 250 }} onClick={() => setAnswer3("change2")}>
                                                            <Form.Check type="radio" aria-label="radio 1" name="4" label={"Changed but Original (Replacement Bill Required)"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                                        </div>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <Row>
                                            <Col md={3} xs={4} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <Image variant="top" src={deviceRepaired} fluid className="imgShadow5" />
                                            </Col>
                                            <Col className="imgCol" md={9} xs={8}>
                                                <Form.Label className="labelClass"> 4. Is your device ever repaired? </Form.Label>
                                                {
                                                    answer4 === "yes1" ?
                                                        <div className="answers" style={{ width: 250 }}>
                                                            <Form.Check type="radio" checked aria-label="radio 1" name="5" label={"Yes (Repaired in Authorised Service Centre - Job Sheet Required)"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                                        </div> :
                                                        <div className="answers" style={{ width: 250 }} onClick={() => setAnswer4("yes1")}>
                                                            <Form.Check type="radio" aria-label="radio 1" name="5" label={"Yes (Repaired in Authorised Service Centre - Job Sheet Required)"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                                        </div>
                                                }
                                                {answer4 === "yes2" ?
                                                    <div className="answers" style={{ width: 250 }}>
                                                        <Form.Check type="radio" checked aria-label="radio 1" name="5" label={"Yes (Repaired in Unauthorised Centre)"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                                    </div> :
                                                    <div className="answers" style={{ width: 250 }} onClick={() => setAnswer4("yes2")}>
                                                        <Form.Check type="radio" aria-label="radio 1" name="5" label={"Yes (Repaired in Unauthorised Centre)"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                                    </div>
                                                }
                                                {
                                                    answer4 === "no" ?
                                                        <div className="answers" style={{ width: 250 }}>
                                                            <Form.Check type="radio" checked aria-label="radio 1" name="5" label={"No"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                                        </div> :
                                                        <div className="answers" style={{ width: 250 }} onClick={() => setAnswer4("no")}>
                                                            <Form.Check type="radio" aria-label="radio 1" name="5" label={"No"} style={{ fontSize: 12, marginTop: 5 }} className="answer-option" />
                                                        </div>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </>
                    }

                </Row>


                {
                    answer1 === "no" ?
                        <div style={{ display: "flex", justifyContent: "center", margin: "1rem", marginTop: "3rem" }}>
                        </div>
                        :
                        <div style={{ display: "flex", justifyContent: "center", margin: "1rem", marginTop: "3rem" }}>
                            <Button disabled={answer1 && answer2 && answer3 && answer4 ? false : true} variant="warning" onClick={() => handleContinue()} style={{ borderRadius: '10px', width: 150, color: '#fff', fontWeight: 'bold', fontSize: '20px', backgroundColor: "#40a737", border: "0px" }}>Next<FaArrowRight /></Button>
                        </div>
                }
            </Container>
        </>
    )
}

export default TabletStep1