import { Box, Button } from "@mui/material";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import React from "react";
import { useNavigate } from "react-router-dom";
import useDeleteAddress from "../../../hooks/addAddress/useDeleteAddress";

const AddressMobile = ({ selectedAddress, isList = false }) => {
  const { deleteAddressById } = useDeleteAddress();
  const handleDeleteAddress = () => {
    deleteAddressById(selectedAddress.id, selectedAddress.user);
  };
  const navigate = useNavigate();
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"0.5rem"}
      sx={{
        backgroundColor: "white",
        padding: isList ? "1rem 1rem 1rem 0" : "1rem",
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
      >
        <Box>
          <CustomTypography
            variant={"subtitle2"}
            sx={{ fontWeight: "600", textTransform: "uppercase" }}
          >
            {selectedAddress.name}{" "}
            {!isList && selectedAddress.isDefault && (
              <span style={{ color: "grey" }}>{`(default)`}</span>
            )}
          </CustomTypography>
        </Box>
        <Box>
          <CustomTypography
            sx={{
              fontSize: "0.75rem",
              textTransform: "uppercase",
              border: "1px solid #03a685",
              color: "#03a685",
              padding: "0.1rem 0.3rem",
              borderRadius: "3rem",
            }}
            variant={"subtitle2"}
            fontWeight={"600"}
          >
            {selectedAddress.addressType}
          </CustomTypography>
        </Box>
      </Box>

      <Box>
        <CustomTypography
          variant={"subtitle2"}
          sx={{ fontWeight: "500", textTransform: "uppercase" }}
        >
          {selectedAddress.address}
        </CustomTypography>
        <CustomTypography
          variant={"subtitle2"}
          sx={{ fontWeight: "500", textTransform: "uppercase" }}
        >
          {`${selectedAddress.state}, ${selectedAddress.cityDistrict} - ${selectedAddress.pinCode}`}
        </CustomTypography>
        <CustomTypography
          variant={"subtitle2"}
          sx={{ fontWeight: "500", textTransform: "uppercase" }}
        >
          Mobile:{selectedAddress.contact}
          {selectedAddress.alternateContactNo &&
            ` ,${selectedAddress.alternateContactNo}`}
        </CustomTypography>
        <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "500" }}>
          <span style={{ textTransform: "uppercase" }}>Email: </span>
          {selectedAddress.email}
        </CustomTypography>
        {selectedAddress.gst && (
          <CustomTypography variant={"subtitle2"}>
            GST: {selectedAddress.gst}
          </CustomTypography>
        )}
        {selectedAddress.landMark && (
          <CustomTypography variant={"subtitle2"}>
            LANDMARK: {selectedAddress.landMark}
          </CustomTypography>
        )}
      </Box>

      {!isList ? (
        <Box>
          <Button
            onClick={() => navigate("/checkout/address/list")}
            variant={"contained"}
            sx={{
              width: "100%",
              color: "black",
              borderRadius: "0.2rem",
              backgroundColor: "white",
              border: "1px solid black",
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
                backgroundColor: "white",
              },
            }}
          >
            <CustomTypography
              variant={"subtitle1"}
              fontWeight="600"
              sx={{ fontSize: "0.9rem" }}
            >
              Change or add address
            </CustomTypography>
          </Button>
        </Box>
      ) : (
        <Box display={"flex"} gap="1rem">
          <Button
            onClick={handleDeleteAddress}
            variant={"contained"}
            sx={{
              width: "100%",
              color: "black",
              borderRadius: "0.2rem",
              backgroundColor: "white",
              border: "1px solid black",
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
                backgroundColor: "white",
              },
            }}
          >
            <CustomTypography
              variant={"subtitle1"}
              fontWeight="600"
              sx={{ fontSize: "0.9rem" }}
            >
              Remove
            </CustomTypography>
          </Button>
          <Button
            onClick={() =>
              navigate(`/checkout/address/edit`, {
                state: { address: selectedAddress },
              })
            }
            variant={"contained"}
            sx={{
              width: "100%",
              color: "black",
              borderRadius: "0.2rem",
              backgroundColor: "white",
              border: "1px solid black",
              boxShadow: "none",
              "&:hover": {
                boxShadow: "none",
                backgroundColor: "white",
              },
            }}
          >
            <CustomTypography
              variant={"subtitle1"}
              fontWeight="600"
              sx={{ fontSize: "0.9rem" }}
            >
              Edit
            </CustomTypography>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default AddressMobile;
