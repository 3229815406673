import { Button, Card, Col, Container, Form, Row, Table, Spinner, Image, Dropdown, Modal } from 'react-bootstrap'
import React, { useState } from 'react'

import { MUTATION_SELL_MODAL_IMAGE } from '../../graphql/Mutations'
import { MUTATION_DEL_SELL_MODAL_IMAGE } from '../../graphql/Mutations'
import { MUTATION_UPDATE_SELL_MODAL } from '../../graphql/Mutations'
import { QUERY_GET_ALL_BRANDS } from '../../graphql/Query'

import { QUERY_GET_SELL_MODAL_IMAGE } from '../../graphql/Query'
import AWS from "aws-sdk";
import uniqid from "uniqid";
import swal from 'sweetalert';
import { useMutation, useQuery } from '@apollo/client'

const ID = "AKIA6GB4RFKTJXDPLPOH";
const SECRET = "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";
const BUCKET_NAME = "sanjaytestbucket";

const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET,
});

const AddSellModalImage = () => {
    const [brandName, setBrandName] = useState("")
    const [image, setImage] = useState("");
    const [brandNameEdit, setBrandNameEdit] = useState("")
    const [selectedBrand, setSelectedBrand] = useState("")
    const [validated, setValidated] = useState(false);
    const [createSellImageModal, { loading: Loading }] = useMutation(MUTATION_SELL_MODAL_IMAGE, {
        refetchQueries: [
            QUERY_GET_SELL_MODAL_IMAGE
        ]
    })
    const [deleteSellImageModal, { loading: DelLoading }] = useMutation(MUTATION_DEL_SELL_MODAL_IMAGE, {
        refetchQueries: [
            QUERY_GET_SELL_MODAL_IMAGE
        ]
    })
    const [updateSellImageModal, { loading: UpdateLoading }] = useMutation(MUTATION_UPDATE_SELL_MODAL, {
        refetchQueries: [
            QUERY_GET_SELL_MODAL_IMAGE
        ]
    })
    const { data: getSellImageModal, loading: allModalLoading } = useQuery(QUERY_GET_SELL_MODAL_IMAGE);
    const { data: getAllBrands, loading: allBrandLoading } = useQuery(QUERY_GET_ALL_BRANDS);


    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        } else {
            setValidated(false);
            event.preventDefault();
            try {
                const uniqueId = uniqid();
                var regex = new RegExp('[^.]+$');
                var extension = image.name.match(regex);
                var uniqueFileName = 'hellofi-img' + uniqueId + '.' + extension[0];
                const params = {
                    Bucket: BUCKET_NAME,
                    Key: uniqueFileName,
                    Body: image,
                };
                s3.upload(params, function (err, data) {
                    if (err) {
                        throw err;
                    } else {
                        createSellImageModal({
                            variables: {
                                "sellProductImageModalInput": {
                                    "brandName": `${brandName}`,
                                    "image": `${uniqueFileName}`
                                }
                            }
                        })
                        swal({
                            title: "Success",
                            text: "Image Add successfully",
                            icon: "success",
                        })

                        setImage('')
                        setBrandName('')
                    }

                });
            } catch (err) {
                console.log(err);
            }
        }
    }
    let count = 1;
    const showDelModal = (id) => {

        deleteSellImageModal({
            variables: {
                "imageId": `${id}`
            }
        })
        swal({
            title: "Deleted",
            text: "Deleted successfully",
            icon: "success",
        })
    }
    const showEditModal = (brand) => {
        handleShow()
        setBrandNameEdit(brand.brandName)
        setSelectedBrand(brand)
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleChanges = () => {
        updateSellImageModal({
            variables: {
                "modalId": `${selectedBrand.id}`,
                "brandName": `${brandNameEdit}`
            }
        }).then((data) => {
            handleClose()
        }).catch((e) => console.log(e))

    }
    return (
        <>
            <Container style={{ padding: 20 }} fluid>
                <Row>
                    <Col md={4}>
                        <div style={{ padding: "1rem" }} className='box-shadow'>
                            <h3 style={{ textAlign: 'center' }}>Add Modal Image</h3>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Dropdown drop='down'>

                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: "100%" }} >
                                            Select Brand
                                        </Dropdown.Toggle>
                                        <span>
                                            {brandName}
                                        </span>
                                        <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}style={{ width: "100%" }} >
                                            <div style={{ overflowY: 'scroll', height: '300px' }}>

                                                {
                                                    getAllBrands && getAllBrands?.getAllBrands.map((brand) => {
                                                        return (
                                                            <Dropdown.Item onClick={() => setBrandName(brand.brandName)}  >{brand.brandName}</Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </div>

                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Form.Control
                                            required
                                            type="file"
                                            className='txtinpt'
                                            style={{ marginTop: 20 }}
                                            placeholder="Image"
                                            onChange={(e) => setImage(e.target.files[0])}
                                        />
                                    </Form.Group>

                                </Row>
                                <Button type="submit" className='mx-auto d-block'>Add Image</Button>
                            </Form>
                        </div>
                    </Col>
                    <Col>
                        <Table responsive striped bordered hover>
                            <thead className='table-head'>
                                <tr>
                                    <th>s.no.</th>
                                    <th>Brand Name</th>
                                    <th>Brand Image</th>
                                    <th>Remove Image</th>
                                    <th>Edit Image</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allModalLoading ?
                                        <Spinner animation="border" variant="success" className='mx-auto d-block' />

                                        :
                                        getSellImageModal && getSellImageModal.getSellImageModal.slice(0).reverse().map(data =>
                                            <tr className='table-data'>
                                                <td>
                                                    {count++}
                                                </td>
                                                <td>
                                                    {data.brandName}
                                                </td>

                                                <td>
                                                    <Image fluid src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${data.image}`} style={{ height: "80%", width: 80 }}></Image>

                                                </td>
                                                <td><Button variant='danger' className='mx-auto d-block' onClick={() => showDelModal(data.id)}>-</Button></td>
                                                <td><Button variant='success' className='mx-auto d-block' onClick={() => showEditModal(data)}>Edit</Button></td>
                                            </tr>

                                        )

                                }
                            </tbody>

                        </Table>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Brand </Modal.Title>
                </Modal.Header>
                <Modal.Body> <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Control
                        required
                        type="text"
                        value={brandNameEdit}
                        className='txtinpt'
                        onChange={(e) => setBrandNameEdit(e.target.value)}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                </Form.Group></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleChanges}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default AddSellModalImage