import React, { useEffect } from "react";
import NavbarCartMobile from "../../../../../layout/NavbarCartMobile";
import AddressList from "./AddressList";
import useGetAllAddress from "../../../hooks/addAddress/useGetAllAddress";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useGetCartItems from "../../../hooks/addToCart/useGetCartItems";
import useCobmbineAdrsCart from "../../../hooks/addAddress/useCobmbineAdrsCart";
import { cartPageBackdrop } from "../../../../../store/atoms/addToCart/addToCart";
import { useSetRecoilState } from "recoil";
import MuiBackdrop from "../../../../../muicomponents/shared/MuiBackdrop";

const AddressListPageMobile = () => {
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);
  const {
    cartData,
    allAddress,
    addressCount,
    loading,
    error,
    cartDataRefetch,
    addressRefetch,
  } = useCobmbineAdrsCart();

  const navigate = useNavigate();

  useEffect(() => {
    setBackdropOpen(loading);
  }, [loading]);

  useEffect(() => {
    cartDataRefetch();
    addressRefetch();
  }, []);

  useEffect(() => {
    if (
      cartData &&
      cartData.items
        .filter((item) => item.isCheckedForCheckout === true)
        .some((item) => item.product.quantity === 0)
    ) {
      navigate("/checkout/cart");
    }
  }, []);

  if (error) {
    return <>Error:{error.message}</>;
  }
  return (
    <>
      <MuiBackdrop />
      <Box>
        <NavbarCartMobile
          title={"Select Address"}
          isAddressMain={false}
          isAddAddressPage={true}
          navigateReturn={"/checkout/address"}
        />

        <Box sx={{ minHeight: "100vh" }}>
          {!loading && (
            <Box>
              {allAddress && allAddress.length > 0 ? (
                <AddressList addresses={allAddress} />
              ) : (
                <>No Address Found</>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default AddressListPageMobile;
