import { useLocation } from 'react-router-dom'
import { useState } from "react";
import { useQuery, useMutation } from '@apollo/client';
import swal from 'sweetalert';

import { Col, Container, Button, Image, Row, Spinner, Card, Breadcrumb, Modal, Form } from 'react-bootstrap'
import '../../ComponentsCss/userCss/UserProfile.css'
import { QUERY_GET_USER_DETAIL_BY_ID } from '../../graphql/Query'
import { QUERY_GET_ALL_USER } from '../../graphql/Query'

import {UPDATE_USER} from  '../../graphql/Mutations'
// import { FaPencilAlt } from 'react-icons/fa'
const UserProfile = () => {
    const userId = localStorage.getItem('userId');

    const location = useLocation()
    const { data: getUserDetailById, loading: userLoading } = useQuery(QUERY_GET_USER_DETAIL_BY_ID,
        {
            variables:
            {
                "userId": userId

            }
        });
        const [updateUser] = useMutation(UPDATE_USER, {
            refetchQueries: [
                QUERY_GET_USER_DETAIL_BY_ID
            ]
        })
    const [nameState, setNameState] = useState()
    const [emailState, setEmailState] = useState()
    const [lastNameState, setLastNameState] = useState()
    const [contactState, setContactState] = useState()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleEditClick = () => {
        handleShow();
        setNameState(getUserDetailById.getUserDetailById.fName)
        setLastNameState(getUserDetailById.getUserDetailById.lName)
        setEmailState(getUserDetailById.getUserDetailById.email)
        setContactState(getUserDetailById.getUserDetailById.contact)
    }
    const saveProfile=()=>{

        updateUser({
            variables: {
                "userInput": {
                    "id" : userId ,
                    "fName" : `${nameState}`,
                    "lName" : `${lastNameState}`,
                    "email" : `${emailState}`
                }
            }
        }).then((data) => {
            swal({
                title: 'Successfull!!!',
                text: ' Profile Edited Successfully',
                icon: 'success',
            });
            handleClose()
        }).catch((e) => console.log(e))
    }
    return (
        <>
            {
                userLoading ? <Spinner animation="border" variant="success" className='mx-auto d-block' />
                    :
                    <Card>
                        <Card.Body>

                            <Card.Text style={{ margin: "2rem", display: "flex" }} className='profile-box' >

                                <div style={{ fontWeight: "bold" }} className="profile-content-box" >First Name : </div>
                                <div className='shadow-sm   bg-white rounded profile-content-box'  > {getUserDetailById?.getUserDetailById?.fName}
                                </div>

                            </Card.Text>
                            <Card.Text style={{ margin: "2rem", display: "flex" }} className='profile-box'>
                                <div style={{ fontWeight: "bold" }} className="profile-content-box">Last Name : </div>
                                <div className='shadow-sm    bg-white rounded profile-content-box'> {getUserDetailById?.getUserDetailById?.lName} </div>
                            </Card.Text>
                            <Card.Text style={{ margin: "2rem", display: "flex" }} className='profile-box'>
                                <div style={{ fontWeight: "bold" }} className="profile-content-box" > Email : </div>
                                <div className='shadow-sm   bg-white rounded profile-content-box'> {getUserDetailById?.getUserDetailById?.email} </div>
                            </Card.Text>
                            <Card.Text style={{ margin: "2rem", display: "flex" }} className='profile-box'>
                                <div style={{ fontWeight: "bold" }} className="profile-content-box"> Contact : </div>
                                <div className='shadow-sm   bg-white rounded profile-content-box'> {getUserDetailById?.getUserDetailById?.contact} </div>
                            </Card.Text>
                            <Button className='mx-auto d-block' variant='success' onClick={() => handleEditClick()} >Edit Profile</Button>
                        </Card.Body>
                    </Card>
            }
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3"  >
                        <Form.Label style={{ fontWeight: "500", fontSize: "12px", color: "grey" }}> First Name : </Form.Label>
                        <Form.Control type="text" value={nameState} style={{ fontWeight: "bold" }} onChange={(e) => setNameState(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3"  >
                        <Form.Label style={{ fontWeight: "500", fontSize: "12px", color: "grey" }}> Last Name : </Form.Label>
                        <Form.Control type="text" value={lastNameState} style={{ fontWeight: "bold" }} onChange={(e) => setLastNameState(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ fontWeight: "500", fontSize: "12px", color: "grey" }}> Email: </Form.Label>
                        <Form.Control type="email" value={emailState} style={{ fontWeight: "bold" }} onChange={(e) => setEmailState(e.target.value)} />
                    </Form.Group>
                    {/* <Form.Group className="mb-3" >
                        <Form.Label style={{ fontWeight: "500", fontSize: "12px", color: "grey" }}> Contact : </Form.Label>

                        <Form.Control type="number" value={contactState} style={{ fontWeight: "bold" }} onChange={(e) => setContactState(e.target.value)} />
                    </Form.Group> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary"onClick={()=>saveProfile()} >Save Changes</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default UserProfile