import { Box } from "@mui/material";
import React from "react";
import AddressWrapper from "./AddressWrapper";

const OtherAddresses = ({ addresses }) => {
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
      {addresses.map((address) => (
        <AddressWrapper key={address.id} address={address} />
      ))}
    </Box>
  );
};

export default OtherAddresses;
