import React, { useState } from "react";
import { Box, Skeleton, useMediaQuery } from "@mui/material";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import { LocalShippingOutlined } from "@mui/icons-material";
import swal from "sweetalert";
import { useRecoilValue } from "recoil";
import { loadingStateAtom } from "../../../../store/atoms/productDetails/productAtom";
import CustomPinCodeInput from "./CustomPinCodeInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShippingFast } from "@fortawesome/free-solid-svg-icons";

const PinCodeCheck = () => {
  const [isValidPincode, setIsValidPincode] = useState(false);
  const [deliveryPincode, setDeliveryPincode] = useState("");
  const isMobileScreen = useMediaQuery("(max-width:640px)");
  const loading = useRecoilValue(loadingStateAtom);

  const handleValidPincode = (pincode) => {
    setIsValidPincode(true);
    setDeliveryPincode(pincode);

    const currentDate = new Date();
    const currentDate2 = new Date();
    currentDate.setDate(currentDate.getDate() + 7);
    currentDate2.setDate(currentDate2.getDate() + 5);
    const options = { month: "short", day: "2-digit" };
    const deliveredDate = currentDate.toLocaleDateString(undefined, options);
    const deliveredDate2 = currentDate2.toLocaleDateString(undefined, options);
  };

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={isValidPincode ? "0" : "0.5rem"}
      sx={{ width: "100%" }}
    >
      <Box>
        {loading ? (
          <Skeleton
            variant="subtitle1"
            width={100}
            height={30}
            sx={{ borderRadius: "0.5rem" }}
          />
        ) : (
          <CustomTypography variant={"h6"} sx={{ fontWeight: "500" }}>
            Delivery
          </CustomTypography>
        )}
      </Box>
      <Box>
        {loading ? (
          <Skeleton
            variant="rectangular"
            width={isMobileScreen ? "100%" : 300}
            height={40}
            sx={{ borderRadius: "0.5rem" }}
          />
        ) : (
          <Box>
            {!isValidPincode ? (
              <CustomPinCodeInput onValidPincode={handleValidPincode} />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  gap: "0.5rem",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <Box>
                  <FontAwesomeIcon
                    icon={faShippingFast}
                    style={{
                      fontSize: isMobileScreen ? "1.5rem" : "2rem",
                      color: "rgb(6, 155, 170)",
                    }}
                  />
                </Box>
                <Box>
                  <CustomTypography
                    variant={"subtitle1"}
                    sx={{
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    {`Delivery to ${deliveryPincode} by ${new Date(
                      new Date().setDate(new Date().getDate() + 5)
                    ).toLocaleDateString(undefined, {
                      month: "short",
                      day: "2-digit",
                    })} - ${new Date(
                      new Date().setDate(new Date().getDate() + 7)
                    ).toLocaleDateString(undefined, {
                      month: "short",
                      day: "2-digit",
                    })}`}
                  </CustomTypography>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PinCodeCheck;
