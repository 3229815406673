
import Alert from 'react-bootstrap/Alert';
import Navigation from '../Navigation'
import Footer from '../Footer'
import image from '../../Images/Downloads/blocked.jpg'
const BlockedUser = () => {

    return (
        <>
            <Navigation />

            <div style={{ display: 'flex', justifyContent: "center" }}>
                <img src={image} alt='blocked' height={400} />
            </div>
            <Alert variant="danger" style={{ display: 'flex', justifyContent: "center" }} >

                <Alert.Heading>Oh snap! You are Blocked Please Contact to Administration</Alert.Heading>

            </Alert>
            {/* <Footer /> */}

        </>
    )
}

export default BlockedUser