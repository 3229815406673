import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { PersonOutlineOutlined } from "@mui/icons-material";
import CustomTypography from "../../muicomponents/shared/CustomTypography";
import LogoutButton from "./LogoutButton";
import ProfileCustomButton from "./ProfileCustomButton";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_DETAIL_BY_ID } from "../../graphql/Query";
import { useRecoilState, useSetRecoilState } from "recoil";
import { profileAtom } from "../../store/atoms/profile/profileAtoms";
import MuiBackdrop from "../../muicomponents/shared/MuiBackdrop";
import { cartPageBackdrop } from "../../store/atoms/addToCart/addToCart";

const ProfileSideBar = () => {
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);
  const userId = localStorage.getItem("userId");
  const [profile, setProfile] = useRecoilState(profileAtom);
  const { data, loading, error, refetch } = useQuery(
    QUERY_GET_USER_DETAIL_BY_ID,
    {
      variables: { userId },
    }
  );
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const location = useLocation();
  const path = location.pathname.split("/").pop();

  useEffect(() => {
    refetch();
  }, []);
  useEffect(() => {
    if (data && data.getUserDetailById) {
      setProfile(data.getUserDetailById);
    }
  }, [data]);

  useEffect(() => {
    setBackdropOpen(loading);
  }, [loading]);

  if (error) {
    return <>Error....</>;
  }
  return (
    <>
      <MuiBackdrop />
      {!loading && (
        <Box
          sx={{
            background: `linear-gradient(
            to right,
            rgba(6, 155, 170, 0.3) 0%,
            rgba(242, 212, 212, 0.2) 66%
          )`,
            flexBasis: "30%",
            borderRadius: "1rem",
            minHeight: "calc(100vh - 115px)",
            maxHeight: "calc(100vh - 115px)",
            padding: "1.5rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            position: "relative",
          }}
        >
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Box flexGrow={1} display={"flex"}>
              <Box sx={{ padding: "0 0.5rem " }}>
                <CustomTypography
                  variant={"subtitle1"}
                  sx={{ fontWeight: "600" }}
                >
                  Hi {data?.getUserDetailById?.fName}{" "}
                  {data?.getUserDetailById?.lName}
                </CustomTypography>
                <CustomTypography
                  variant={"subtitle1"}
                  sx={{ fontWeight: "500" }}
                >
                  {data?.getUserDetailById?.contact}{" "}
                  {data.getUserDetailById.email &&
                    `${data?.getUserDetailById?.email}`}
                </CustomTypography>
              </Box>
            </Box>
          </Box>

          <ProfileCustomButton
            text={"Profile"}
            link={"/user/dashboard/profile"}
            buttonSx={{
              border:
                path === "profile" ? "2px solid rgb(6, 155, 170)" : "none",
            }}
          />

          <ProfileCustomButton
            text={"Orders"}
            link={"/user/dashboard/orders"}
            buttonSx={{
              border: path === "orders" ? "2px solid rgb(6, 155, 170)" : "none",
            }}
          />
          <ProfileCustomButton
            text={"Saved Address"}
            link={"/user/dashboard/address"}
            buttonSx={{
              border:
                path === "address" ? "2px solid rgb(6, 155, 170)" : "none",
            }}
          />
          <LogoutButton />
        </Box>
      )}
    </>
  );
};

export default ProfileSideBar;
