import { Box } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import { useMutation } from "@apollo/client";
import { UPDATE_CANCELLATION_REQUEST } from "../../../../graphql/Mutations";
import { QUERY_GET_ORDER_INFO_BY_ORDERID } from "../../../../graphql/Query";

const ShowCancellationRequest = ({ status, itemId, currentItem }) => {
  const [updateCancellationStatus, { loading, error }] = useMutation(
    UPDATE_CANCELLATION_REQUEST,
    {
      refetchQueries: [QUERY_GET_ORDER_INFO_BY_ORDERID],
    }
  );
  const updateCancellationRequest = async () => {
    try {
      const res = await updateCancellationStatus({
        variables: {
          itemId,
          status: "requested",
        },
      });
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      {status === "none" &&
        currentItem?.unit?.status?.length !== 0 &&
        currentItem?.unit?.status[0].status !== "failed" && (
          <CustomTypography
            variant={"subtitle2"}
            sx={{ fontWeight: "600", textAlign: "center" }}
          >
            Click Here to request for{" "}
            <span
              style={{
                textDecoration: "underline",
                color: "#f16565",
                cursor: "pointer",
              }}
              onClick={updateCancellationRequest}
            >
              cancellation
            </span>
          </CustomTypography>
        )}

      {status === "requested" && (
        <CustomTypography
          variant="subtitle2"
          sx={{ fontWeight: "600", textAlign: "center" }}
        >
          Cancellation Request Initiated. Please wait for 6-7 hours for
          confirmation.
        </CustomTypography>
      )}

      {status === "completed" && (
        <CustomTypography
          variant="subtitle2"
          sx={{ fontWeight: "600", textAlign: "center", color: "green" }}
        >
          Cancellation Request Completed.
        </CustomTypography>
      )}

      {status === "rejected" && (
        <CustomTypography
          variant="subtitle2"
          sx={{ fontWeight: "600", textAlign: "center", color: "red" }}
        >
          Cancellation Request Rejected. Please contact support for further
          assistance.
        </CustomTypography>
      )}
    </Box>
  );
};

export default ShowCancellationRequest;
