import { Modal, Card, Button, Col, Row, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ConditionImg from '../../Images/Downloads/phone-fair.png'
import '../../ComponentsCss/SellModal.css'
import { useState } from 'react';

const ConditionModal = ({ showCondition, setShowCondition, productCategory, image1, image2, image3, image4, image5 }) => {

    const navigate = useNavigate();
    const [ConditionState, setConditionState] = useState("")
    const [showTermAndCondition, setShowTermAndCondition] = useState()

    const [smShow, setSmShow] = useState(false);
    const [selectedDesc, setSelectedDesc] = useState(false);
    const [selectedCondition, setSelectedCondition] = useState("");
    const [selectedAcce, setSelectedAcc] = useState("");
    const [selectedWarnty, setSelectedWarnty] = useState(""); 

    const handleSelectCondition = (condition) => {
        setSelectedCondition(condition)
        setSmShow(true)
        if (condition === "Unboxed") {
            setSelectedDesc("No Visible Scratches or marks. <br> Next to Brand New.")
            setSelectedAcc("All Original Accessories as mentioned in the Box.")
            setSelectedWarnty("Under Brand Warranty.")
        } else if (condition === "Superb") {
            setSelectedDesc("Barely visible signs of usage if any. <br> No Dents/Cracks/Chips.")
            setSelectedAcc("Original Accessories may/may not be included.")
            setSelectedWarnty("May/May not be under Brand Warranty. If not under Brand Warranty HelloFi Warranty will be covered")
        }
        else if (condition === "Good") {
            setSelectedDesc("Few Visible Scratches.Barely visible dents if any. <br> No Cracks/Chips/Functional defects.")
            setSelectedAcc("Original Accessories may/may not be included.")
            setSelectedWarnty("3 Months Service Warranty from HelloFi.")
        }
        else if (condition === "Fair") {
            setSelectedDesc("More visible scratches. <br> Minor Dents/Cracks may be visible. <br>  No Functional defects")
            setSelectedAcc("Original Accessories may/may not be included.")
            setSelectedWarnty(" No Warranty.")
        }
        else if (condition === "Partially-Fair") {
            setSelectedDesc("Scratches/Dents/Cracks/ Chips. <br> Some Functional defects.")
            setSelectedAcc("Original Accessories may/may not be included.")
            setSelectedWarnty(" No Warranty.")
        }

    }
    const handleContinue = () => {
        let category = productCategory
        if(category === "Desktop/Imac"){
            category = "Desktop-Imac"
         }
        navigate(`/buying-products/${category}/${selectedCondition}`, {
            state: {
                productCondition: selectedCondition,
                productCategory: productCategory
            }
        })
        setSmShow(false)
    }
    return (
        <>
            <Modal show={showCondition} onHide={() => setShowCondition(false)} aria-labelledby="example-modal-sizes-title-lg" size='lg' centered>
                <Modal.Header closeButton style={{ marginTop: '-20px' }}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <div style={{ display: 'flex', alignItems: 'center' }} >
                            <div style={{ height: 30, width: 5, backgroundColor: '#44CE30', marginTop: 30 }}></div>
                            <Card.Text style={{ marginTop: 30, marginLeft: 10, fontWeight: 500 }}>Choose Your Preference</Card.Text>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginTop: '-30px', marginBottom: '15px' }}>
                    <Row className='mbtext'>
                        <Col xs={4} md={4} onClick={() => handleSelectCondition("Unboxed")} >
                            <Card className="cardShadow3 fs-3 fw-bold" style={{ height: '90%' }}   >
                                <Card.Body>
                                    <Image src={image1} className="modelImgCardcondition mx-auto d-block" />
                                    <Card.Text style={{ textAlign: 'center' }}>Unboxed</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={4} md={4} onClick={() => handleSelectCondition("Superb")} >
                            <Card className="cardShadow3 fs-3 fw-bold" style={{ height: '90%' }}   >
                                <Card.Body>
                                    <Image src={image2} className="modelImgCardcondition mx-auto d-block" />
                                    <Card.Text style={{ textAlign: 'center' }}>Superb</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={4} md={4} onClick={() => handleSelectCondition("Good")} >
                            <Card className="cardShadow2 fs-3 fw-bold" style={{ height: '90%' }}>
                                <Card.Body>
                                    <Image src={image3} className="modelImgCardcondition mx-auto d-block" fluid />
                                    <Card.Text style={{ textAlign: 'center' }}>Good</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='mbtext'>

                        <Col xs={4} md={4} >
                            <Card className="cardShadow1 fs-3 fw-bold" style={{ height: '90%' }}  >
                                <Card.Body onClick={() => handleSelectCondition("Fair")}>
                                    <Image src={image4} className="modelImgCardcondition mx-auto d-block" fluid />
                                    <Card.Text style={{ textAlign: 'center' }}>Fair</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={4} md={4} >
                            <Card className="cardShadow1 fs-3 fw-bold" style={{ height: '90%' }}  >
                                <Card.Body onClick={() => handleSelectCondition("Partially-Fair")}>
                                    <Image src={image5} className="modelImgCardcondition mx-auto d-block" fluid />
                                    <Card.Text className='lspara' style={{ textAlign: 'center' }}>Partially Fair and Usable</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>


                </Modal.Body>
            </Modal>
            <Modal
                size="lg"
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
                centered
                className='productdescmodal'
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Product  Description
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="my-3 d-flex ">
                        <div className="fw-bold pmodel" >Condition : </div>
                        <div className="pmodel-2" dangerouslySetInnerHTML={{ __html: selectedDesc }} />
                    </div>
                    <div className="my-3 d-flex " >
                        <div className="fw-bold pmodel" > Accessories : </div>
                        <div className="pmodel-2" dangerouslySetInnerHTML={{ __html: selectedAcce }} /></div>
                    <div className="my-3 d-flex" >
                        <div className="fw-bold pmodel"> Warranty : </div>
                        <div className="pmodel-2" dangerouslySetInnerHTML={{ __html: selectedWarnty }} /></div>
                    <div className="text-danger fw-bold" > Please read Product description and Warranty related terms on the Product Page. </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => handleContinue()} variant="success" > Continue </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default ConditionModal