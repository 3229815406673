import { Box } from "@mui/material";
import React, { useState } from "react";
import AddressCard from "./AddressCard";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import AddAddressModal from "../../../../buying/components/addAddress/modals/AddAddressModal";
import AddressCloseModal from "../modals/AddressCloseModal";
import { selectedAddressIdState } from "../../../../../store/atoms/addAddress/addAddressAtoms";
import { useRecoilState } from "recoil";

const AddressWrapper = ({ address }) => {
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useRecoilState(
    selectedAddressIdState
  );
  return (
    <>
      <Box
        onClick={() => setSelectedAddressId(address.id)}
        sx={{
          minHeight: "100px",
          width: "100%",
          background: `linear-gradient(120deg,#F6EDED 8%,#DFE8EA 76%)`,
          borderRadius: "0.2rem",
          position: "relative",
          boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
          paddingBottom: selectedAddressId === address.id ? "40px" : "0px",
          "&:hover": {
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          },
        }}
      >
        <AddressCard address={address} />
        {address.id === selectedAddressId && (
          <Box
            position={"absolute"}
            sx={{
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "row",
              borderTop: "1px solid #A9A9A9",
            }}
          >
            <Box
              flexBasis={"50%"}
              onClick={() => setModalOpen(true)}
              sx={{
                margin: "0.5rem",
                justifyContent: "center",
                display: "flex",
                borderRight: "1px solid #A9A9A9",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontWeight: 600, fontSize: "0.85rem" }}
              >
                Edit
              </CustomTypography>
            </Box>
            <Box
              flexBasis={"50%"}
              onClick={() => setAddressModalOpen(true)}
              sx={{
                padding: "0.5rem",
                justifyContent: "center",
                display: "flex",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontWeight: 600, fontSize: "0.85rem" }}
              >
                Remove
              </CustomTypography>
            </Box>
          </Box>
        )}
      </Box>
      <AddAddressModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        address={address}
        isEdit={true}
      />
      <AddressCloseModal
        address={address}
        modalOpen={addressModalOpen}
        setModalOpen={setAddressModalOpen}
        modalTitle={"Delete Confirmation"}
        modalDescription={"Are you sure you want to delete this address"}
      />
    </>
  );
};

export default AddressWrapper;
