import React from 'react';
import '../ComponentsCss/instruction.css'

const GadgetSalePreparation = () => {
    return (
        <div className="gadget-sale-preparation">
            <h1>Things to keep in mind before selling old electronic gadgets:</h1>

            <section>
                <h2>1. Erase All Personal Information</h2>
                <p>Before selling any electronic device be mindful about all the information concerning to you or people related to you need to be erased from the device memory. This will include deleting the media (pictures, videos and voice notes) , documents, notes, saved mails , messages, applications having confidential information like online banking and similar mobile phone and web applications and most importantly logout from all the Gmail/iOS accounts (iCloud id) linked to the device, delete the accounts from after logging out, and erasing other form of stored information form the device including the storage in recycle bin.</p>
            </section>

            <section>
                <h2>2. Backup all the data</h2>
                <p>While it is important to delete all the known data from the device one must remember to back up the data before doing so. Once the data is deleted it cannot be retrieved and may cause substantial loss in case an important information gets erased.
                    The backup should be kept in a reliable source / format that can be used to retrieve the data.
                </p>
            </section>

            <section>
                <h2>3. Associated Accessories</h2>
                <p>You should be mindful of the buyer and gather the electronic accessories that was provided to you along with the gadget(s) like charger, ear piece, stylus and other related accessories. Provide accurate information about the condition of the accessories before handing over the device(s) to the buyer. </p>
            </section>

            <section>
                <h2>4. Describe Your Device Accurately</h2>
                <p>Be precise when giving details regarding the condition of the device along with the accessories and keep the transaction transparent. Discrepancy regarding information can invite unwanted conflict , so provide all the detail that might be necessary for the buyer to assist them to get compatible with the device .</p>
                <ul>
                    <li>A)	Please select Apple Care Plus only if the Care Plus warranty is valid for a minimum of 6 months or more.</li>
                    <li>B)	If a device is purchased from abroad, a valid purchase bill is required to be produced for accurate pricing.</li>
                    <li>C)	Avoid duplication of selection. For example: If you select Charger Faulty from issues then avoid selecting Power Cable and Adapter from accessories, or vice versa.</li>
                </ul>
            </section>

            <section>
                <h2>5. Assemble All the Important Documents</h2>
                <p>Gather all the documents relevant to the device, like payment invoice, warranty/ guarantee card or document , any other document that will be helpful for the person who will hold the possession of the device in future.</p>
            </section>

            <section>
                <h2>6. Remove Physical Sim Cards/ Deactivate eSIM if any.</h2>
                <p>Remove any physical SIM cards and deactivate eSIMs if present.</p>
            </section>

            <section>
                <h2>7. Charge It to the Maximum</h2>
                <p>Charge the device fully before handing it over.</p>
            </section>
        </div>
    );
}

export default GadgetSalePreparation;
