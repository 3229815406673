import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import Calendar from "../../../../../Images/home/howwework/Calendar.png";
import Chat from "../../../../../Images/home/howwework/Chat.png";
import Handtouch from "../../../../../Images/home/howwework/Handtouch.png";
import Payment from "../../../../../Images/home/howwework/Payment.png";
import WorkCard from "./WorkCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";

const WorkCards = () => {
  const theme = useTheme();
  const isTabletScreen = useMediaQuery(theme.breakpoints.down("desktop"));
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const handleSlideChange = (swiper) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  const workCards = [
    {
      title: "Select Device",
      body: "Select the device you wish to sell and get the best price based on the current condition.",
      img: Handtouch,
    },
    {
      title: "Immediate quotation/quote",
      body: "Get the accurate price quote based on device model, age and condition and make the approval.",
      img: Chat,
    },
    {
      title: "Pickup slot",
      body: "Select a pickup slot as per your convenience, for free doorstep pickup.",
      img: Calendar,
    },
    {
      title: "Instant Payment",
      body: "Get paid on the spot at pickup, by cash or any other preferred medium of payment.",
      img: Payment,
    },
  ];
  return (
    <Box sx={{ flexGrow: 1 }}>
      {!isTabletScreen ? (
        <Grid
          container
          spacing={{ xs: 1, tablet: 2 }}
          sx={{ justifyContent: "center" }}
        >
          {workCards.map((obj, index) => (
            <Grid
              key={index}
              item
              xs={12}
              tabletMedium={6}
              tabletLarge={4}
              desktop={3}
            >
              <WorkCard img={obj.img} title={obj.title} body={obj.body} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          sx={{ position: "relative", height: "100%", width: "100%" }}
          className={"howwework-swiper-wrapper"}
        >
          <Swiper
            modules={[Navigation]}
            navigation={{
              nextEl: ".howwework-swiper-button-next",
              prevEl: ".howwework-swiper-button-prev",
            }}
            spaceBetween={10}
            slidesPerView={2}
            breakpoints={{
              0: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              600: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              821: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1025: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            onSlideChange={handleSlideChange}
            onInit={handleSlideChange}
            className="howwework-swiper"
            style={{
              "--swiper-navigation-color": "#000",
              "--swiper-pagination-color": "#000",
            }}
          >
            {workCards.map((obj, index) => (
              <SwiperSlide key={index}>
                <WorkCard img={obj.img} title={obj.title} body={obj.body} />
              </SwiperSlide>
            ))}
          </Swiper>
          <Box
            className="howwework-swiper-controls"
            sx={{
              position: "absolute",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              zIndex: 10,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <button
              className={`howwework-swiper-button-prev ${
                isBeginning ? "swiper-button-hidden" : ""
              }`}
              aria-label="Previous"
              style={{ border: "2px solid grey" }}
            >
              <ArrowBackIosNew fontSize="medium" sx={{ color: "grey" }} />
            </button>
            <button
              className={`howwework-swiper-button-next ${
                isEnd ? "swiper-button-hidden" : ""
              }`}
              aria-label="Next"
              style={{ border: "2px solid grey" }}
            >
              <ArrowForwardIos fontSize="medium" sx={{ color: "grey" }} />
            </button>
          </Box>
        </Box>
      )}
      <style jsx>{`
        .howwework-swiper {
          position: relative;
          width: 100%;
        }

        .howwework-swiper-controls {
          position: absolute;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          z-index: 10;
          top: 50%;
          transform: translateY(-50%);
        }

        .howwework-swiper-button-prev,
        .howwework-swiper-button-next {
          position: absolute;
          top: 50% !important;
          transform: translateY(-50%) !important;
          background-color: rgba(255, 255, 255, 0.75);
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.1s ease;
          z-index: 10;
          cursor: pointer;
        }

        .howwework-swiper-button-prev {
          left: 10px;
        }

        .howwework-swiper-button-next {
          right: 10px;
        }

        .swiper-button-hidden {
          visibility: hidden;
        }

        @media (max-width: 520px) {
          .howwework-swiper-button-prev,
          .howwework-swiper-button-next {
            display: flex !important;
          }
        }
      `}</style>
    </Box>
  );
};

export default WorkCards;
