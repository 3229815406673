import { Modal, Card, Button, Col, Row, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import '../../ComponentsCss/Exchange/modal.css'
import step3 from "../../Images/homepage/workflow/step3.png"

const ExchangeModal = ({ showExchangeModal, setShowExchangeModal }) => {
    const naviagte = useNavigate()
    const handleClick = (link) => {
        naviagte(link)
    }
    return (
        <>
            <Modal show={showExchangeModal} onHide={() => setShowExchangeModal(false)} aria-labelledby="example-modal-sizes-title-lg" centered>
                <Modal.Header closeButton style={{ marginTop: '-20px' }}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <div style={{ display: 'flex', alignItems: 'center' }} >
                            <div style={{ height: 30, width: 5, backgroundColor: '#44CE30', marginTop: 30 }}></div>
                            <Card.Text style={{ marginTop: 30, marginLeft: 10, fontWeight: 500 }}>Choose your preference</Card.Text>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginBottom: '15px' }} className='modalBody'>
                    <div>
                        <Button variant='success' className='exchangeBtn' onClick={() => handleClick("/exchange/outside")}  >
                            Outside Bangalore  <Image src={step3} style={{ width: 45, height: 45, marginLeft: "20px", marginBottom: "10px" }} fluid /> </Button>
                    </div>
                    <div>
                        <Button variant='success' className='exchangeBtn' onClick={() => handleClick("/exchange/inside")} > Within Bangalore <Image src={step3} style={{ width: 45, height: 45, marginLeft: "20px", marginBottom: "10px" }} fluid /></Button>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}
export default ExchangeModal