import {
  Box,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useRef, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import useGetAllOffers from "../../hooks/useGetAllOffers";
import {
  loadingStateAtom,
  productAtom,
  productDetailsAtom,
} from "../../../../store/atoms/productDetails/productAtom";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import Offer from "../../icons/Offer";
import OfferModal from "./OfferModal";
import OfferCard from "../../muiComponents/OfferCard";
import FlexCenter from "../../muiComponents/FlexCenter";

const AvailableOffers = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const isLoading = useRecoilValue(loadingStateAtom);
  const product = useRecoilValue(productDetailsAtom);
  const [modalOpen, setModalOpen] = useState(false);
  const { offers, loading, error } = useGetAllOffers();

  const scrollContainerRef = useRef(null);
  const handleClick = () => {
    setModalOpen(true);
  };

  const backgoundGradients = [
    "linear-gradient(-270deg, rgb(255, 245, 183) 0%, rgb(255, 251, 219) 100%)",
    "linear-gradient(-270deg, #accbee 0%, #e7f0fd 100%)",
    "linear-gradient(-270deg, #52a7c1 0%, #b3f6d8 100%)",
  ];

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;
    let isDown = false;
    let startX;
    let scrollLeft;

    const handleMouseDown = (e) => {
      isDown = true;
      startX = e.pageX - scrollContainer.offsetLeft;
      scrollLeft = scrollContainer.scrollLeft;
      scrollContainer.style.cursor = "grabbing";
    };

    const handleMouseUp = () => {
      isDown = false;
      scrollContainer.style.cursor = "grab";
    };

    const handleMouseMove = (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - scrollContainer.offsetLeft;
      const walk = (x - startX) * 2;
      scrollContainer.scrollLeft = scrollLeft - walk;
    };

    scrollContainer?.addEventListener("mousedown", handleMouseDown);
    scrollContainer?.addEventListener("mouseleave", handleMouseUp);
    scrollContainer?.addEventListener("mouseup", handleMouseUp);
    scrollContainer?.addEventListener("mousemove", handleMouseMove);

    return () => {
      scrollContainer?.removeEventListener("mousedown", handleMouseDown);
      scrollContainer?.removeEventListener("mouseleave", handleMouseUp);
      scrollContainer?.removeEventListener("mouseup", handleMouseUp);
      scrollContainer?.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      {!loading && offers && offers.length > 0 && (
        <Box display="flex" flexDirection="column" marginTop="1rem">
          <Box mb={"1rem"} display={"flex"} justifyContent={"space-between"}>
            {isLoading ? (
              <>
                <Skeleton variant="text" width={150} height={30} />
                <Skeleton variant="text" width={100} height={20} />
              </>
            ) : (
              <>
                <CustomTypography variant="h6" sx={{ fontWeight: "500" }}>
                  Available Offers
                </CustomTypography>
                <FlexCenter
                  sx={{
                    backgroundColor: "#FFFFFF",
                    padding: "0.2rem 0.5rem",
                    borderRadius: "0.5rem",
                    border: "1px solid #214C7B",
                  }}
                >
                  <CustomTypography
                    onClick={handleClick}
                    variant="subtitle2"
                    style={{
                      color: "#214C7B",
                      cursor: "pointer",
                      fontSize: isMobileScreen ? "0.8rem" : "0.875rem",
                    }}
                  >
                    See all{`(${offers.length})`}
                  </CustomTypography>
                </FlexCenter>
              </>
            )}
          </Box>
          <Box
            ref={scrollContainerRef}
            display="flex"
            flexDirection="row"
            gap="1rem"
            sx={{
              width: "100%",
              overflowX: "scroll",
              padding: "0.5rem",
              cursor: "grab",
              "&:active": {
                cursor: "grabbing",
              },
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            {isLoading ? (
              <></>
            ) : (
              <>
                <OfferCard
                  sx={{
                    background: backgoundGradients[0],
                    minWidth: { xs: "85%", sm: "70%", md: "45%", lg: "30%" },
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                    backgroundColor: "#f0f0f0",
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    transition: "box-shadow 0.3s ease",
                    "&:hover": {
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "16px",
                      left: "16px",
                    }}
                  >
                    <Offer style={{ fontSize: "1.5rem", color: "#069baa" }} />
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      pl: "48px",
                      pr: "16px",
                      py: "16px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CustomTypography
                      variant="body2"
                      sx={{
                        fontWeight: "400",
                        lineHeight: 1.6,
                        fontSize: "0.9rem",
                      }}
                    >
                      {offers[0].offerDesc}
                    </CustomTypography>
                  </Box>
                </OfferCard>
              </>
            )}
          </Box>
        </Box>
      )}

      {!loading && offers && (
        <OfferModal
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
          offers={offers}
        />
      )}
    </>
  );
};

export default AvailableOffers;
