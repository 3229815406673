import { Box, Button } from "@mui/material";
import React from "react";
import CustomTypography from "../../muicomponents/shared/CustomTypography";
import { useNavigate } from "react-router-dom";

const LogoutButton = () => {
  const navigate = useNavigate();
  function userlogoutHandle() {
    navigate("/");
    localStorage.removeItem("userToken");
    localStorage.removeItem("userId");
    window.location.reload();
  }
  return (
    <Box
      sx={{
        marginTop: "auto",
      }}
    >
      <Button
        onClick={userlogoutHandle}
        variant="contained"
        sx={{
          textTransform: "capitalize",
          width: "100%",
          borderRadius: "0.2rem",
          padding: "0.5rem",
          backgroundColor: "rgb(6, 155, 170)",
          "&:hover": {
            backgroundColor: "rgb(6, 155, 170)",
          },
        }}
      >
        <CustomTypography variant="subtitle1" sx={{ fontWeight: "500" }}>
          Logout
        </CustomTypography>
      </Button>
    </Box>
  );
};

export default LogoutButton;
