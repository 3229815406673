import { useState, useEffect } from "react";
import Footer from "../../Footer"
import Navigation from "../../Navigation"
import { Row, Col, Container, Button, Image, Card, Dropdown } from 'react-bootstrap'
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import '../../../ComponentsCss/LaptopCategory/laptopstep3.css'
import { useLocation, useNavigate } from "react-router-dom";

const DesktopStep3 = () => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const processorNames = [
        // Intel Core Processors
        "Intel Core i3 13th Gen",
        "Intel Core i5 13th Gen",
        "Intel Core i7 13th Gen",
        "Intel Core i9 13th Gen",
        "Intel Core i7 12th Gen",
        "Intel Core i9 12th Gen",

        // AMD Ryzen Processors
        "AMD Ryzen 3 2nd Gen",
        "AMD Ryzen 3 3rd Gen",
        "AMD Ryzen 3 4th Gen",
        "AMD Ryzen 3 5th Gen",
        "AMD Ryzen 3 6th Gen",
        "AMD Ryzen 3 7th Gen",
        "AMD Ryzen 5 2nd Gen",
        "AMD Ryzen 5 3rd Gen",
        "AMD Ryzen 5 4th Gen",
        "AMD Ryzen 5 5th Gen",
        "AMD Ryzen 5 6th Gen",
        "AMD Ryzen 5 7th Gen",
        "AMD Ryzen 7 2nd Gen",
        "AMD Ryzen 7 3rd Gen",
        "AMD Ryzen 7 4th Gen",
        "AMD Ryzen 7 5th Gen",
        "AMD Ryzen 7 6th Gen",
        "AMD Ryzen 7 7th Gen",
        "AMD Ryzen 9 2nd Gen",
        "AMD Ryzen 9 3rd Gen",
        "AMD Ryzen 9 4th Gen",
        "AMD Ryzen 9 5th Gen",
        "AMD Ryzen 9 6th Gen",
        "AMD Ryzen 9 7th Gen",

        // AMD Ryzen Pro Processors
        "AMD Ryzen 3 Pro",
        "AMD Ryzen 3 Pro 2nd Gen",
        "AMD Ryzen 3 Pro 3rd Gen",
        "AMD Ryzen 3 Pro 4th Gen",
        "AMD Ryzen 3 Pro 5th Gen",
        "AMD Ryzen 3 Pro 6th Gen",
        "AMD Ryzen 3 Pro 7th Gen",
        "AMD Ryzen 5 Pro 2nd Gen",
        "AMD Ryzen 5 Pro 3rd Gen",
        "AMD Ryzen 5 Pro 4th Gen",
        "AMD Ryzen 5 Pro 5th Gen",
        "AMD Ryzen 5 Pro 6th Gen",
        "AMD Ryzen 5 Pro 7th Gen",
        "AMD Ryzen 7 Pro 2nd Gen",
        "AMD Ryzen 7 Pro 3rd Gen",
        "AMD Ryzen 7 Pro 4th Gen",
        "AMD Ryzen 7 Pro 5th Gen",
        "AMD Ryzen 7 Pro 6th Gen",
        "AMD Ryzen 7 Pro 7th Gen",

        // Apple M-series Processors
        "Apple M3 Chip",
        "Apple M3 Pro Chip",
        "Apple M3 Max Chip",
        "Apple M1 Ultra Chip",
        "Apple M2 Ultra Chip"
    ];
    const [processorState, setProcessorState] = useState("Select Processor")
    const [laptopStorage, setLaptopStorage] = useState("Select Storage")
    const [laptopRam, setLaptopRam] = useState("Select Ram")
    const [graphicsState, setgraphicsState] = useState("Select")
    const [screenSizeState, setScreenSizeState] = useState("Select Screen Size")
    const navigate = useNavigate()
    const handleContinue = () => {
        navigate("/desktop-products-step4", {
            state: {
                id: location.state.id, isDeviceSwitch: location.state.isDeviceSwitch, issueWithDeviceDisplay: location.state.issueWithDeviceDisplay, deviceScreen: location.state.deviceScreen, deviceEverRepair: location.state.deviceEverRepair,
                keyBoardState: location.state.keyBoardState, trackpadState: location.state.trackpadState, SpeakerIssue: location.state.SpeakerIssue, MicIssue: location.state.MicIssue, usbPortState: location.state.usbPortState, CharginPortIssue: location.state.CharginPortIssue, webCamState: location.state.webCamState, BatteryIssue: location.state.BatteryIssue, hingeLooseState: location.state.hingeLooseState, WifiIssue: location.state.WifiIssue, BluetoothIssue: location.state.BluetoothIssue, chargerFaultyState: location.state.chargerFaultyState, AutoRestartIssue: location.state.AutoRestartIssue, EarphonePortIssue: location.state.EarphonePortIssue, graphicsCardState: location.state.graphicsCardState, hardDriveState: location.state.hardDriveState, ramIssueState: location.state.ramIssueState, productCategory: location.state.productCategory, productBrand: location.state.productBrand,
                productName: location.state.productName, imgurl: location.state.imgurl, processorState: processorState, laptopStorage: laptopStorage, laptopRam: laptopRam, graphicsState: graphicsState, screenSizeState: screenSizeState,
                fingerPrintIssueState : location.state.fingerPrintIssueState, faceSensorIssueState : location.state.faceSensorIssueState
            }
        })
    }
    const handleProcessorSelection = (processor) => {
        setProcessorState(processor)
    }

    const handleStorage = (storage) => {
        setLaptopStorage(storage)

    }
    const handleRamSelection = (ram) => {
        setLaptopRam(ram)
    }
    const handleGraphicsSelection = (grap) => {
        setgraphicsState(grap)
    }
    const handleSizeSelection = (s) => {
        setScreenSizeState(s)
    }
    return (
        <>
            <Navigation />
            <div className="banner">
                <h3>
                    <span onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                        <FaArrowLeft />
                    </span>
                    <span style={{ fontWeight: "bold", textAlign: "center", marginLeft: "5%" }}>
                        Steps - 3/5
                    </span>
                </h3>
            </div>
            <Container >
                <Card style={{ width: '100%', marginTop: "10px" }} >
                    <Card.Body   >
                        <Card.Title style={{ textAlign: "center", fontWeightL: "bold" }}>Select the system configuration of your device ?</Card.Title>
                        <Card.Text style={{ width: "50%", margin: 'auto' }} className="desk-confi" >
                            <div>
                                <Card.Text style={{ fontWeight: "bold", color: "black" }} > Processor : </Card.Text>
                                <Dropdown>
                                    <div>

                                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="processorBtn-desk" >
                                            {processorState}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}style={{ fontSize: "12px", fontWeight: "bold" }} className="dropdown-menu-step3"   >
                                            <div style={{ overflowY: 'scroll', height: '300px' }}>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i3 3rd Gen")}  > Intel Core i3 3rd Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i3 4th Gen")} >Intel Core i3 4th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i3 5th Gen")} >Intel Core i3 5th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("ntel Core i3 6th Gen")}>Intel Core i3 6th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i3 7th Gen")}>Intel Core i3 7th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i3 8th Gen")}>Intel Core i3 8th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i3 9th Gen")}>Intel Core i3 9th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i3 10th Gen")}>Intel Core i3 1th Gen	</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i3 11th Gen")}>Intel Core i3 11t Gen	</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i3 12th Gen")}>Intel Core i3 12th en	</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i5 3rd Gen")}>Intel Core i5 3rd Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i5 4th Gen")}>Intel Core i5 4th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i5 5th Gen")}>Intel Core i5 5th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i5 6th Gen")}>Intel Core i5 6th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i5 7th Gen")}>Intel Core i5 7th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i5 8th Gen")}>Intel Core i5 8th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i5 9th Gen")}>Intel Core i5 9th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i5 10th Gen")}>Intel Core i5 10th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i5 11th Gen")}>Intel Core i5 11th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i5 12th Gen")}>Intel Core i5 12th Gen	</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i7 3rd Gen")}>Intel Core i7 3rd Gen	</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i7 4th Gen")}>Intel Core i7 4th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i7 5th Gen")}>Intel Core i7 5th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i7 6th Gen")}>Intel Core i7 6th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i7 7th Gen")}>Intel Core i7 7th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i7 8th Gen")}>Intel Core i7 8th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i7 9th Gen")}>Intel Core i7 9th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i7 10th Gen")}>Intel Core i7 10th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i7 11th Gen")}>Intel Core i7 11th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i9 8th Gen")}>Intel Core i9 8th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i9 9th Gen")}>Intel Core i9 9th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i9 10th Gen")}>Intel Core i9 10th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core i9 11th Gen")}>Intel Core i9 11th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Apple M1 Chip")}>Apple M1 Chip</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Apple M1 Max Chip")}>Apple M1 Max Chip</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Apple M1 Pro Chip")}>Apple M1 Pro Chip</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Apple M1 Plus Chip")}>Apple M1 Plus Chip</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Apple M2 Chip")}>Apple M2 Chip</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Apple M2 Max Chip")}>Apple M2 Max Chip</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Apple M2 Pro Chip")}>Apple M2 Pro Chip</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Apple M2 Plus Chip")}>Apple M2 Plus Chip</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD A9")}>AMD A9</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD A10")}>AMD A10</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD A4")}>AMD A4</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD A6")}>AMD A6</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD A8")}>AMD A8</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Brazos Dual Core C-70")}>AMD Brazos Dual Core C-70</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Dual Core A6")}>AMD Dual Core A6</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Dual Core A9 7th Gen")}>AMD Dual Core A9 7th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Dual Core E1")}>AMD Dual Core E1</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Dual Core E2")}>AMD Dual Core E2</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD E Series")}>AMD E Series</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Dual E 1")}>AMD Dual E 1</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Quad Core")}>AMD Quad Core</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Quad Core A10")}>AMD Quad Core A10</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Quad Core A15")}>AMD Quad Core A15</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Quad Core A4")}>AMD Quad Core A4</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Quad Core A6")}>AMD Quad Core A6</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Quad Core A8")}>AMD Quad Core A8</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Quad Core E2 7th Gen")}>AMD Quad Core E2 7th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Quad Core E2 6th Gen")}>AMD Quad Core E2 6th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Ryzen 3")}>AMD Ryzen 3</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Ryzen 5")}>AMD Ryzen 5</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Ryzen 5 Pro")}>AMD Ryzen 5 Pro</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Ryzen 7")}>AMD Ryzen 7</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Ryzen 7 Pro")}>AMD Ryzen 7 Pro</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Ryzen 9")}>AMD Ryzen 9</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("AMD Ryzen Threadripper")}>AMD Ryzen Threadripper</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Celeron Dual Core 3rd Gen")}>Intel Celeron Dual Core 3rd Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Celeron Dual Core 4th Gen")}>Intel Celeron Dual Core 4th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Celeron Dual Core 5th Gen")}>Intel Celeron Dual Core 5th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Celeron Quad Core 4th Gen")}>Intel Celeron Quad Core 4th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core M3 6th Gen")}>Intel Core M3 6th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core M3 7th Gen")}>Intel Core M3 7th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core M3 8th Gen")}>Intel Core M3 8th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core M5 5th Gen")}>Intel Core M5 5th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core M5 6th Gen")}>Intel Core M5 6th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Core M7 6th Gen")}>Intel Core M7 6th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Pentium Dual Core 3rd Gen")}>Intel Pentium Dual Core 3rd Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Pentium Dual Core 4th Gen")}>Intel Pentium Dual Core 4th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Pentium Octa Core")}>Intel Pentium Octa Core</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Pentium Quad Core")}>Intel Pentium Quad Core</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Pentium Quad Core 3rd Gen")}>Intel Pentium Quad Core 3rd Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Pentium Quad Core 4th Gen")}>Intel Pentium Quad Core 4th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Pentium Quad Core 6th Gen")}>Intel Pentium Quad Core 6th Gen</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Intel Xeon")}>Intel Xeon</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Microsoft SQ1")}>Microsoft SQ1</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Microsoft SQ2")}>Microsoft SQ2</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleProcessorSelection("Quad Core")}>Quad Core</Dropdown.Item>
                                                {
                                                    processorNames.map((processor, index) => {
                                                        return <Dropdown.Item onClick={() => handleProcessorSelection(processor)} key={index}>{processor}</Dropdown.Item>
                                                    })
                                                }
                                            </div>
                                        </Dropdown.Menu>
                                    </div>
                                </Dropdown>

                            </div>
                        </Card.Text>
                        <Card.Text style={{ width: "50%", margin: 'auto' }} className="desk-confi">
                            <Card.Text style={{ fontWeight: "bold", color: "black" }}> Storage : </Card.Text>

                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="processorBtn-desk">{laptopStorage}</Dropdown.Toggle>
                                <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}style={{ fontSize: "12px", fontWeight: "bold" }} className="dropdown-menu-step3">
                                    <div style={{ overflowY: 'scroll', height: '300px' }}>

                                        <Dropdown.Item onClick={() => handleStorage("250 GB HDD")} >250 GB HDD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("320 GB HDD")} >320 GB HDD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("500 GB HDD")} >500 GB HDD </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("640 GB HDD")} >640 GB HDD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("750 GB HDD")} >750 GB HDD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("1 TB HDD")} >1 TB HDD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("1.5 TB HDD")} >1.5 TB HDD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("128 GB SSD")} >128 GB SSD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("256 GB SSD")} >256 GB SSD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("2 TB HDD")} >2 TB HDD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("500 GB HDD + 128 GB SSD")} >500 GB HDD + 128 GB SSD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("1 TB HDD + 128 GB SSD")} >1 TB HDD + 128 GB SSD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("512 GB SSD")} >512 GB SSD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("1 TB HDD + 256 GB SSD")} >1 TB HDD + 256 GB SSD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("1 TB HDD + 512 GB SSD")} >1 TB HDD + 512 GB SSD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("1 TB SSD")} >1 TB SSD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("2 TB SSD")} >2 TB SSD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("3 TB SSD")} >3 TB SSD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("4 TB SSD")} >4 TB SSD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("2 TB")} >2 TB</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("1TB HDD + 128GB SSD")} >1TB HDD + 128GB SSD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("2TB HDD + 128GB SSD")} >2TB HDD + 128GB SSD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("2TB HDD + 256GB SSD")} >2TB HDD + 256GB SSD</Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleStorage("2TB HDD + 512GB SSD")} >2TB HDD + 512GB SSD</Dropdown.Item>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Card.Text>
                        <Card.Text style={{ width: "50%", margin: 'auto' }} className="desk-confi">
                            <Card.Text style={{ fontWeight: "bold", color: "black" }}> Ram : </Card.Text>
                            <Dropdown >
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="processorBtn-desk">
                                    {laptopRam}
                                </Dropdown.Toggle>
                                <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}style={{ fontSize: "12px", fontWeight: "bold" }} className="dropdown-menu-step3" >
                                    <Dropdown.Item onClick={() => handleRamSelection("2 GB")} >2 GB</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleRamSelection("3 GB")} >3 GB</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleRamSelection("4 GB")} >4 GB</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleRamSelection("6 GB")}>6 GB</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleRamSelection("8 GB")}>8 GB</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleRamSelection("12 GB")}>12 GB</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleRamSelection("16 GB")}>16 GB</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleRamSelection("32 GB")}>32 GB</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleRamSelection("18 GB")}>18 GB</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleRamSelection("24 GB")}>24 GB</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleRamSelection("64 GB")}>64 GB</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleRamSelection("128 GB")}>128 GB</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Card.Text>
                        <Card.Text style={{ width: "50%", margin: 'auto' }} className="desk-confi" >
                            <Card.Text style={{ fontWeight: "bold", color: "black" }}> Graphics Card : </Card.Text>
                            <Dropdown >
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="processorBtn-desk">
                                    {graphicsState}
                                </Dropdown.Toggle>
                                <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}style={{ fontSize: "12px", fontWeight: "bold" }} className="dropdown-menu-step3" >
                                    <Dropdown.Item onClick={() => handleGraphicsSelection("Yes")} >Yes</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleGraphicsSelection("No")} >No</Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown>
                        </Card.Text>
                        <Card.Text style={{ width: "50%", margin: 'auto' }} className="desk-confi">
                            <Card.Text style={{ fontWeight: "bold", color: "black" }}> Screen Size : </Card.Text>
                            <Dropdown >
                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="processorBtn-desk">
                                    {screenSizeState}
                                </Dropdown.Toggle>
                                <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}style={{ fontSize: "12px", fontWeight: "bold" }} className="dropdown-menu-step3" >
                                    <Dropdown.Item onClick={() => handleSizeSelection("10-11 Inch")} >10-11 Inch</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleSizeSelection("12-13 Inch")} >12-13 Inch</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleSizeSelection("13-14 Inch")} >13-14 Inch</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleSizeSelection("14-15 Inch")} >14-15 Inch</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleSizeSelection("15-20 Inch")} >15-20 Inch</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleSizeSelection("20-30 Inch")} >20-30 Inch</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleSizeSelection("Above 30 Inch")} >Above 30 Inch</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Card.Text>
                        <Button variant="warning" onClick={() => handleContinue()} style={{ borderRadius: '0px', width: 150, color: '#fff', fontWeight: 'bold', fontSize: '20px', backgroundColor: "#40a737", border: "0px", borderRadius: '10px', marginTop: "1rem" }} className="mx-auto d-block" disabled={processorState != "Select Processor" && laptopStorage != "Select Storage" && laptopRam != "Select Ram" && graphicsState != "Select" && screenSizeState != "Select Screen Size" ? false : true} >Next <FaArrowRight /></Button>
                    </Card.Body>
                </Card>
            </Container >
        </>
    )
}
export default DesktopStep3