import { Box } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const FeatureCard = ({ text }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "1rem",
        backgroundColor: "red",
        borderRadius: "0.75rem",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        margin: "1rem 0",
        background:
          "linear-gradient(90deg, rgba(158, 202, 210, 0.58) 0%, rgba(249, 245, 245, 0.58) 51%, rgba(158, 202, 210, 0.58) 100%)",
      }}
    >
      <CustomTypography
        variant={"subtitle2"}
        sx={{
          fontSize: {
            mobile: "0.8rem",
            tablet: "1.3rem",
            laptop: "1.5rem",
            desktop: "1.8rem",
          },
        }}
      >
        {text}
      </CustomTypography>
    </Box>
  );
};

export default FeatureCard;
