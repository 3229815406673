import React, { useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import {MUTATION_CREATE_NEWS} from '../../graphql/Mutations';
import {MUTATION_DELETE_NEWS} from '../../graphql/Mutations';
import {GET_ALL_NEWS} from '../../graphql/Query';
import uniqid from "uniqid";

import { useMutation, useQuery } from '@apollo/client'
import swal from 'sweetalert';
import AWS from "aws-sdk";
const ID = "AKIA6GB4RFKTJXDPLPOH";
const SECRET = "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";
const BUCKET_NAME = "sanjaytestbucket";
const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET,
});

const AddNews = () => {
    const [newsItem, setNewsItem] = useState({ title: '', description: '', image: null, link: '' });
    const [createNews] = useMutation(MUTATION_CREATE_NEWS, { refetchQueries: [GET_ALL_NEWS] });
    const { data: getAllNews, loading: newsLoading } = useQuery(GET_ALL_NEWS);
    const [deleteNews] = useMutation(MUTATION_DELETE_NEWS, { refetchQueries: [GET_ALL_NEWS] });

    const handleInputChange = (e) => {
        if (e.target.name === 'image') {
            setNewsItem({ ...newsItem, image: e.target.files[0] });
        } else {
            setNewsItem({ ...newsItem, [e.target.name]: e.target.value });
        }
    };

    const uploadImageToS3 = async (file) => {
        const uniqueId = uniqid();
        const extension = file.name.split('.').pop();
        const uniqueFileName = `HElloFi-news-img-${uniqueId}.${extension}`;

        try {
            await s3.upload({
                Bucket: BUCKET_NAME,
                Key: uniqueFileName,
                Body: file,
            }).promise();
            return uniqueFileName;
        } catch (err) {
            console.error(err);
            swal("Error", "Failed to upload image", "error");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { title, description, image, link } = newsItem;
        if (!title || !description || !image || !link) {
            swal("Error", "Please fill all the fields", "error");
            return;
        }

        const imageKey = await uploadImageToS3(image);
        if (imageKey) {
            createNews({
                variables: {
                    newsInput: {
                        title,
                        description,
                        imageKey,
                        newsLink: link
                    }
                }
            }).then(() => {
                swal("Success", "Added successfully", "success");
                setNewsItem({ title: '', description: '', image: null, link: '' });
            }).catch((e) => swal("Error", e.message, "error"));
        }
    };

    const handleDelete = (id) => {
        deleteNews({
            variables: {
                newsId: id
            }
        }).then(() => {
            swal("Success", "Deleted successfully", "success");
        }).catch((e) => swal("Error", e.message, "error"));
    };

    let count = 1;
    return (
        <Container style={{ padding: 20 }} fluid>
            <Row className="justify-content-md-center">
                <Col md={6}>
                    <div style={{ padding: "1rem" }} className='box-shadow'>
                        <h3 style={{ textAlign: 'center' }}>Add News Item</h3>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Col} controlId="formTitle">
                                <Form.Control
                                    required
                                    type="text"
                                    className='txtinpt'
                                    placeholder="News Title"
                                    name="title"
                                    onChange={handleInputChange}
                                    value={newsItem.title}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formDescription">
                                <Form.Control
                                    required
                                    as="textarea"
                                    className='txtinpt'
                                    placeholder="News Description"
                                    name="description"
                                    onChange={handleInputChange}
                                    value={newsItem.description}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formImage">
                                <Form.Control
                                    type="file"
                                    className='txtinpt'
                                    name="image"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formLink">
                                <Form.Control
                                    type="text"
                                    className='txtinpt'
                                    placeholder="News Link"
                                    name="link"
                                    onChange={handleInputChange}
                                    value={newsItem.link}
                                />
                            </Form.Group>
                            <Button type='submit' variant='dark' className='mt-3'>Add News</Button>
                        </Form>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table responsive striped bordered hover className="mt-4">
                        <thead className='table-head'>
                            <tr>
                                <th>S.no.</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Image</th>
                                <th>Link</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                newsLoading ? <tr><td colSpan="4" className="text-center">Loading...</td></tr>
                                :
                                getAllNews && getAllNews.getAllNews.map((item, index) => (
                                    <tr key={index}>
                                        <td>{count++}</td>
                                        <td>{item.title}</td>
                                        <td>{item.description}</td>
                                        <td><img src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${item?.imageKey}`} alt="blog" style={{ width: 100, height: 100 }} /></td>
                                        <td>{item.newsLink}</td>
                                        <td><Button variant='danger' size='sm' onClick={()=>handleDelete(item.id)} >Delete</Button></td>
                                    </tr>
                                ))
                            }
                            
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default AddNews;
