import React, { useState } from "react";
import {
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
  Spinner,
  Table,
  Dropdown,
} from "react-bootstrap";
import { Edit, Delete } from "@mui/icons-material";
import { useMutation, useQuery } from "@apollo/client";
import {
  MUTATION_DELETE_SELL_PRODUCT,
  MUTATION_SELL_PRODUCT,
} from "../../graphql/Mutations";
import { QUERY_GET_ALL_BRANDS } from "../../graphql/Query";
import { QUERY_GET_SELL_PRODUCT } from "../../graphql/Query";
import { QUERY_GET_SERIES } from "../../graphql/Query";

import AWS from "aws-sdk";
import uniqid from "uniqid";
import swal from "sweetalert";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Button,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";

const ID = "AKIA6GB4RFKTJXDPLPOH";
const SECRET = "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";
const BUCKET_NAME = "sanjaytestbucket";

const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

export default function SellProctct() {
  const [age, setAge] = useState("");
  let count = 1;
  const [validated, setValidated] = useState(false);
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [variant, setVariant] = useState("");
  const [series, setSeries] = useState("");
  const [productType, setProductType] = useState("");
  const [category, setCategory] = useState("");
  const [productBrand, setProductBrand] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [variants, setVariants] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [isConstantRam, setIsConstantRam] = useState(false);
  const [constantRam, setConstantRam] = useState(null);
  const [variantData, setVariantData] = useState({
    ram: "",
    storage: "",
    price: "",
  });
  const [imageBox, setImageBox] = useState("");
  const [uploadDone, setUploadDone] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectImage, setSelectImage] = useState();
  const [multipleImgKey, setMultipleImgKey] = useState([]);

  const { data: getSeries, loading: allBrandLoading } =
    useQuery(QUERY_GET_SERIES);

  const { data: getAllBrands, loading: brandLoading } =
    useQuery(QUERY_GET_ALL_BRANDS);

  const { data: sellProData, loading: sellProLoading } = useQuery(
    QUERY_GET_SELL_PRODUCT
  );

  const [createSellProduct, { loading: sellLoading }] = useMutation(
    MUTATION_SELL_PRODUCT,
    {
      refetchQueries: [QUERY_GET_SELL_PRODUCT],
    }
  );

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setVariantData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleAddOrUpdateVariant = () => {
    const updatedVariantData = { ...variantData };

    if (isConstantRam && variants.length === 0 && constantRam === null) {
      setConstantRam(updatedVariantData.ram);
      delete updatedVariantData.ram;
    }
    if (isConstantRam) {
      delete updatedVariantData.ram;
    }

    if (editIndex !== null) {
      setVariants((prevVariants) =>
        prevVariants.map((variant, index) =>
          index === editIndex ? updatedVariantData : variant
        )
      );
      setEditIndex(null);
    } else {
      setVariants((prevVariants) => [...prevVariants, updatedVariantData]);
    }

    setVariantData({
      ram: "",
      storage: "",
      price: "",
    });
  };

  const handleDeleteRam = () => {
    setIsConstantRam(false);
    setConstantRam(null);
    setVariants([]);
  };

  const handleConstantRamChange = () => {
    setIsConstantRam(!isConstantRam);
    setConstantRam(null);
    setVariants([]);
    setEditIndex(null);
  };

  const handleEdit = (index) => {
    setVariantData(variants[index]);
    setEditIndex(index);
  };

  const handleDelete = (index) => {
    if (variants.length === 1) {
      setIsConstantRam(!isConstantRam);
      setConstantRam(null);
      setVariants([]);
      setEditIndex(null);
    } else {
      setVariants((prevVariants) => prevVariants.filter((_, i) => i !== index));
    }
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      await createSellProduct({
        variables: {
          sellProductInput: {
            ram: `${constantRam}`,
            isConstantRam: isConstantRam,
            variants: variants,
            productName: `${productName}`,
            variant: `${variant}`,
            series: `${series}`,
            category: `${category}`,
            productBrand: `${productBrand}`,
            ...(productPrice != "" && {
              productPrice: parseInt(productPrice),
            }),
          },
        },
      });
      swal({
        title: "Success",
        text: "Product Add successfully",
        icon: "success",
      });
      setProductBrand("");
      setProductName("");
      setProductDescription("");
      setVariant("");
      setVariantData({
        ram: "",
        storage: "",
        price: "",
      });
      setVariants([]);
      setConstantRam(null);
      setIsConstantRam(false);
      setSeries("");
      setProductType("");
      setCategory("");
      setSelectImage("");
      setProductPrice("");
    }
  };
  const [deleteSellProductByid, { loading: dltLoading }] = useMutation(
    MUTATION_DELETE_SELL_PRODUCT,
    {
      refetchQueries: [QUERY_GET_SELL_PRODUCT],
    }
  );

  return (
    <>
      <Container style={{ padding: 20 }} fluid>
        <Row>
          <Col md={4} className="mx-auto d-block">
            <div className=" box-shadow" style={{ padding: "1rem" }}>
              <h5 style={{ textAlign: "center" }}>Add Selling Product Here</h5>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                  <Form.Group as={Col} md="12" controlId="validationCustom02">
                    <Dropdown style={{ marginTop: "1rem" }}>
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        style={{ width: "100%" }}
                      >
                        {" "}
                        Category
                      </Dropdown.Toggle>
                      <span>{category}</span>
                      <Dropdown.Menu
                        popperConfig={{
                          modifiers: [
                            {
                              name: "flip",
                              enabled: false, // Disable flipping to prevent opening in top direction
                            },
                            {
                              name: "offset",
                              options: {
                                offset: [0, 10], // Adjust the offset as needed
                              },
                            },
                          ],
                        }}
                        style={{ width: "100%" }}
                      >
                        <div style={{ overflowY: "scroll", height: "200px" }}>
                          <Dropdown.Item onClick={(e) => setCategory("Phones")}>
                            Phones
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setCategory("Laptops")}
                          >
                            Laptops
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setCategory("Tablets")}
                          >
                            Tablets
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setCategory("Smart Watches")}
                          >
                            Smart Watches
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setCategory("Desktop/Imac")}
                          >
                            Desktop/Imac
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={(e) => setCategory("Other Accessories")}
                          >
                            Other Accessories
                          </Dropdown.Item>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Control
                      required
                      className="txtinpt"
                      type="text"
                      placeholder="Product name"
                      onChange={(e) => setProductName(e.target.value)}
                      value={productName}
                    />
                  </Form.Group>

                  {category !== "Tablets" && category !== "Phones" && (
                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                      <Form.Control
                        required
                        className="txtinpt"
                        type="number"
                        placeholder="Product Price"
                        onChange={(e) => setProductPrice(e.target.value)}
                        value={productPrice}
                      />
                    </Form.Group>
                  )}

                  {(category === "Tablets" || category === "Phones") && (
                    <>
                      <Box sx={{ marginTop: "0.5rem" }}>
                        <h6>
                          {editIndex !== null ? "Edit Variant" : "Add Variant"}
                        </h6>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap={"0.5rem"}
                        >
                          <Box>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isConstantRam}
                                    onChange={handleConstantRamChange}
                                    name="isConstantRam"
                                  />
                                }
                                label="Is Ram Constant?"
                              />
                            </FormGroup>
                          </Box>
                          <Box sx={{ display: "flex", gap: "1rem" }}>
                            {constantRam === null && (
                              <FormControl
                                fullWidth
                                variant="outlined"
                                size="small"
                              >
                                <InputLabel id="ram-select-label">
                                  Ram
                                </InputLabel>
                                <Select
                                  labelId="ram-select-label"
                                  id="ram-select"
                                  label="Ram"
                                  name="ram"
                                  value={variantData.ram}
                                  onChange={handleChange}
                                >
                                  <MenuItem value={"2GB"}>2GB</MenuItem>
                                  <MenuItem value={"3GB"}>3GB</MenuItem>
                                  <MenuItem value={"4GB"}>4GB</MenuItem>
                                  <MenuItem value={"6GB"}>6GB</MenuItem>
                                  <MenuItem value={"8GB"}>8GB</MenuItem>
                                  <MenuItem value={"12GB"}>12GB</MenuItem>
                                  <MenuItem value={"16GB"}>16GB</MenuItem>
                                  <MenuItem value={"32GB"}>32GB</MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            <FormControl
                              fullWidth
                              variant="outlined"
                              size="small"
                            >
                              <InputLabel id="storage-select-label">
                                Storage
                              </InputLabel>
                              <Select
                                labelId="storage-select-label"
                                id="storage-select"
                                label="Storage"
                                name="storage"
                                value={variantData.storage}
                                onChange={handleChange}
                              >
                                <MenuItem value={"16GB"}>16GB</MenuItem>
                                <MenuItem value={"32GB"}>32GB</MenuItem>
                                <MenuItem value={"64GB"}>64GB</MenuItem>
                                <MenuItem value={"128GB"}>128GB</MenuItem>
                                <MenuItem value={"256GB"}>256GB</MenuItem>
                                <MenuItem value={"512GB"}>512GB</MenuItem>
                                <MenuItem value={"1024GB"}>1024GB</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                          <Box>
                            <TextField
                              id="outlined-price"
                              label="Price"
                              size="small"
                              variant="outlined"
                              sx={{ width: "100%" }}
                              name="price"
                              value={variantData.price}
                              onChange={handleChange}
                              type="number"
                            />
                          </Box>
                          <Box>
                            <Button
                              onClick={handleAddOrUpdateVariant}
                              variant="contained"
                            >
                              {editIndex !== null ? "Update" : "Add"}
                            </Button>
                          </Box>
                        </Box>
                      </Box>

                      <Box sx={{ marginTop: "1rem" }}>
                        <h6>Variants List</h6>

                        {isConstantRam && variants.length > 0 && (
                          <>
                            {" "}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "0.5rem",
                              }}
                            >
                              <span>Constant Ram - {constantRam}</span>
                              <IconButton
                                onClick={() => handleDeleteRam()}
                                color="secondary"
                              >
                                <Delete />
                              </IconButton>
                            </Box>
                          </>
                        )}

                        {variants.map((variant, index) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "0.5rem",
                            }}
                          >
                            <span>
                              {constantRam === null && <>{`${variant.ram}/`}</>}
                              {variant.storage} - {variant.price}
                            </span>

                            <Box>
                              <IconButton
                                onClick={() => handleEdit(index)}
                                color="primary"
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                onClick={() => handleDelete(index)}
                                color="secondary"
                              >
                                <Delete />
                              </IconButton>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </>
                  )}
                </Row>
                <Row className="mb-3">
                  {/* <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Control
                                            required
                                            type="text"
                                            className='txtinpt'
                                            placeholder="Variant"
                                            onChange={(e) => setVariant(e.target.value)}
                                            value={variant}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group> */}
                  {/* <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Form.Control
                                            required
                                            type="text"
                                            className='txtinpt'
                                            placeholder="Series"
                                            onChange={(e) => setSeries(e.target.value)}
                                            value={series}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group> */}
                </Row>
                <Row className="mb-3">
                  <Form.Group></Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustom02"
                    style={{ marginTop: 20 }}
                  >
                    <Dropdown drop="down">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        style={{ width: "100%" }}
                      >
                        Select Brand
                      </Dropdown.Toggle>
                      <span>{productBrand}</span>
                      <Dropdown.Menu
                        popperConfig={{
                          modifiers: [
                            {
                              name: "flip",
                              enabled: false, // Disable flipping to prevent opening in top direction
                            },
                            {
                              name: "offset",
                              options: {
                                offset: [0, 10], // Adjust the offset as needed
                              },
                            },
                          ],
                        }}
                        style={{ width: "100%" }}
                      >
                        <div style={{ overflowY: "scroll", height: "300px" }}>
                          {getAllBrands &&
                            getAllBrands?.getAllBrands.map((brand) => {
                              return (
                                <Dropdown.Item
                                  onClick={() =>
                                    setProductBrand(brand.brandName)
                                  }
                                >
                                  {brand.brandName}
                                </Dropdown.Item>
                              );
                            })}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustom02"
                    style={{ marginTop: 20 }}
                  >
                    <Dropdown drop="down">
                      <Dropdown.Toggle
                        variant="success"
                        id="dropdown-basic"
                        style={{ width: "100%" }}
                      >
                        Select Series
                      </Dropdown.Toggle>
                      <span>{series}</span>
                      <Dropdown.Menu
                        popperConfig={{
                          modifiers: [
                            {
                              name: "flip",
                              enabled: false, // Disable flipping to prevent opening in top direction
                            },
                            {
                              name: "offset",
                              options: {
                                offset: [0, 10], // Adjust the offset as needed
                              },
                            },
                          ],
                        }}
                        style={{ width: "100%" }}
                      >
                        <div style={{ overflowY: "scroll", height: "300px" }}>
                          {getSeries &&
                            getSeries?.getSeries.map((s) => {
                              return (
                                <Dropdown.Item
                                  onClick={() => setSeries(s.seriesName)}
                                >
                                  {s.seriesName}
                                </Dropdown.Item>
                              );
                            })}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Form.Group>
                  {/* <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Form.Control
                                            required
                                            type="file"
                                            className='txtinpt'
                                            style={{ marginTop: 20 }}
                                            placeholder="Image"
                                            onChange={(e) => setSelectImage(e.target.files[0])}

                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group> */}
                </Row>
                <Button
                  type="submit"
                  className="mx-auto d-block"
                  style={{
                    backgroundColor: "#40a737",
                    border: "none",
                    width: "100%",
                  }}
                >
                  Add
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}
