import { useSnackbar } from "notistack";

const useSnackbarNotifier = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showSnackbar = (message, variant = "default") => {
    const options = {
      variant,
      autoHideDuration: 2000,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    };
    enqueueSnackbar(message, options);
  };

  return showSnackbar;
};

export default useSnackbarNotifier;
