import { Box, useTheme } from "@mui/material";
import React from "react";

import HomePageContainer from "../shared/HomePageContainer";
import FeatureCards from "./FeatureCards";

const FeaturePointsHome = () => {
  return (
    <HomePageContainer sx={{ margin: { xs: "0 0.5rem", laptop: 0 } }}>
      <FeatureCards />
    </HomePageContainer>
  );
};

export default FeaturePointsHome;
