import { Box } from "@mui/material";
import React from "react";
import AddressCard from "./AddressCard";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import AddressWrapper from "./AddressWrapper";

const DefaultAddress = ({ address }) => {
  return (
    <>
      <AddressWrapper address={address} />
    </>
  );
};

export default DefaultAddress;
