import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import { useNavigate } from "react-router-dom";

const ContactUs = ({ user }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      gap={"0.5rem"}
      sx={{ margin: isSmallScreen ? "1rem 0.75rem 0 0.75rem" : "1rem 0 0 0" }}
    >
      <Box>
        <CustomTypography variant={"subtitle1"} sx={{ fontWeight: "600" }}>
          Contact Us
        </CustomTypography>
        <CustomTypography variant={"subtitle2"}>
          Hi let us help you with your queries
        </CustomTypography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={isSmallScreen ? "column" : "row"}
        gap={"0.5rem"}
      >
        <Button
          variant="contained"
          sx={{
            width: "100%",
            borderRadius: "0.2rem",
            padding: "0.5rem",
            backgroundColor: "rgb(6, 155, 170)",
            boxShadow: "none",
            textTransform: "uppercase",

            "&:hover": {
              boxShadow: "none",
              backgroundColor: "rgb(6, 155, 170)",
            },
          }}
          onClick={() => (window.location.href = "mailto:contact@hellofi.in")}
        >
          <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "600" }}>
            Mail Us
          </CustomTypography>
        </Button>

        <Button
          variant="contained"
          sx={{
            width: "100%",
            color: "black",
            textTransform: "uppercase",
            border: "1px black solid",
            padding: "0.5rem",
            backgroundColor: "white",
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: "white",
            },
          }}
          onClick={() => (window.location.href = "tel:+918150835583")}
        >
          <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "600" }}>
            Call Us
          </CustomTypography>
        </Button>
      </Box>
      <Box>
        <CustomTypography variant={"subtitle2"}>
          For Return related queries 
          <span
            onClick={() => navigate("/privacy-policy")}
            style={{
              cursor: "pointer",
              fontFamily: '"Poppins", sans-serif',
              color: "#0866ff",
              textDecoration: "underline",
            }}
          >
            Click here
          </span>
        </CustomTypography>
      </Box>
    </Box>
  );
};

export default ContactUs;
