import { Box, useMediaQuery } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../../muicomponents/shared/CustomTypography";
import { useTheme } from "@emotion/react";

const EmiPlan = ({ isSmall = false, emiBasePrice }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("tablet"));
  const isxsSmallScreen = useMediaQuery(theme.breakpoints.down("351"));
  return (
    <Box sx={{ padding: isSmall ? "0" : "0 1rem" }}>
      <CustomTypography
        variant={"subtitle2"}
        fontWeight={"500"}
        sx={{
          fontSize: isxsSmallScreen
            ? "0.72rem"
            : isSmallScreen
            ? "0.75rem"
            : "",
        }}
      >
        Starting From ₹{emiBasePrice}/month
      </CustomTypography>
    </Box>
  );
};

export default EmiPlan;
