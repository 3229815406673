import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import Profile from "../components/profile/Profile";
import {
  profileAtom,
  userContactAtom,
  userEmailAtom,
  userNameAtom,
} from "../../../store/atoms/profile/profileAtoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_DETAIL_BY_ID } from "../../../graphql/Query";
import { cartPageBackdrop } from "../../../store/atoms/addToCart/addToCart";
import MuiBackdrop from "../../../muicomponents/shared/MuiBackdrop";

const ProfilePage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const [profile, setProfile] = useRecoilState(profileAtom);
  const setUserName = useSetRecoilState(userNameAtom);
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);
  const setUserEmail = useSetRecoilState(userEmailAtom);
  const setUserContact = useSetRecoilState(userContactAtom);
  const userId = localStorage.getItem("userId");
  const { data, loading, error, refetch } = useQuery(
    QUERY_GET_USER_DETAIL_BY_ID,
    {
      variables: { userId },
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data && data.getUserDetailById) {
      setProfile(data.getUserDetailById);
      setUserName({
        fName: data.getUserDetailById.fName || "",
        lName: data.getUserDetailById.lName || "",
        originalfName: data.getUserDetailById.fName || "",
        originallName: data.getUserDetailById.lName || "",
      });
      setUserEmail({
        email: data.getUserDetailById.email || "",
        originalEmail: data.getUserDetailById.email || "",
      });
      setUserContact({
        contact: data.getUserDetailById.contact || "",
        originalContact: data.getUserDetailById.contact || "",
      });
    }
  }, [data]);

  useEffect(() => {
    setBackdropOpen(loading);
  }, [loading]);
  if (error) {
    return <>Error....</>;
  }
  return (
    <>
      <MuiBackdrop />
      <Box
        flexBasis={isSmallScreen ? "100%" : "70%"}
        sx={{ minWidth: "300px" }}
      >
        {!loading && <Profile />}
      </Box>
    </>
  );
};

export default ProfilePage;
