import React, { useState } from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import '../ComponentsCss/HowWeWork.css'
import step1 from '../Images/homepage/howicon/selectphone.png'
import step2 from '../Images/homepage/howicon/instantprice.png'
import step3 from '../Images/homepage/howicon/schedule.png'
import step4 from '../Images/homepage/howicon/pay.png'
import CategoryModal from './HomePageModals/Category'

export default function HowWeWork() {
    const [showCategory, setShowCategory] = useState(false)   

    return (   
        <>
            <Container style={{ marginTop: '20px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ height: 40, width: 5, backgroundColor: '#44CE30', marginTop: 20 }}></div>
                    <h3 style={{ marginTop: 30, marginLeft: 10, fontWeight: 500 }}>How We Work</h3>
                </div>
                <Row className='how-we-work-responsive-tab'>
                    <Col md={3} className='how-we-work-responsive-tab-item'>
                        <div className='mx-auto d-block' style={{ width: '90%' }}>
                            <div className='cardwork'>
                                <div className='workcircle1'>
                                    <div className='iconworkcircle'>

                                        <Image src={step1} style={{ height: '50px', width: '50px' }}></Image>
                                    </div>
                                </div>
                                <h4 className='txtwork'>Select Device </h4>
                                <p className='txtwork2'>Select the device you wish to sell and get the best price based on the current condition. </p>
                                <Button className='workbtn' onClick={() => setShowCategory(true)} >Start Now</Button>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className='how-we-work-responsive-tab-item'>
                        <div className='mx-auto d-block' style={{ width: '90%' }}>
                            <div className='cardwork2'>
                                <div className='workcircle2'>
                                    <div className='iconworkcircle2'>
                                        <Image src={step2} style={{ height: '50px', width: '50px' }}></Image>
                                    </div>
                                </div>
                                <h4 className='txtwork'>Immediate quotation/quote</h4>
                                <p className='txtwork2'>Get the accurate price quote based on device model, age and condition and make the approval.</p>
                                <Button className='workbtn2' onClick={() => setShowCategory(true)} >Start Now</Button>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className='how-we-work-responsive-tab-item'>
                        <div className='mx-auto d-block' style={{ width: '90%' }}>
                            <div className='cardwork3'>
                                <div className='workcircle3'>
                                    <div className='iconworkcircle3'>

                                        <Image src={step3} style={{ height: '50px', width: '50px' }}></Image>
                                    </div>
                                </div>
                                <h4 className='txtwork'>Pickup slot</h4>
                                <p className='txtwork2'>Select a pickup slot as per your convenience, for free doorstep pickup. </p>
                                <Button className='workbtn3' onClick={() => setShowCategory(true)} >Start Now</Button>
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className='how-we-work-responsive-tab-item'>
                        <div className='mx-auto d-block' style={{ width: '90%' }}>
                            <div className='cardwork4'>
                                <div className='workcircle2'>
                                    <div className='iconworkcircle4'>

                                        <Image src={step4} style={{ height: '50px', width: '50px' }}></Image>
                                    </div>
                                </div>
                                <h4 className='txtwork'>Instant Payment</h4>
                                <p className='txtwork2'>Get paid on the spot at pickup, by cash or any other preferred medium of payment.</p>
                                <Button className='workbtn4' onClick={() => setShowCategory(true)} >Start Now</Button>
                            </div>
                        </div>
                    </Col>




                </Row>
            </Container>
            {
                showCategory ? <CategoryModal showCategory={showCategory} setShowCategory={setShowCategory} /> : ""
            }
        </>
    )
}
