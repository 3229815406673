import { useState, useEffect } from "react";
import { Row, Col, Container, Button, Image, Card } from 'react-bootstrap'
import Navigation from '../../Navigation'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import '../../../ComponentsCss/QuestionnariesCss/step2.css'
import fontIcon from '../../../Images/QuestionIcons/front_camera_issue.png'
import backCameraIcon from '../../../Images/QuestionIcons/back_camera_issue.png'
import speakerIcon from '../../../Images/QuestionIcons/speaker_issue.png'
import micIcon from '../../../Images/QuestionIcons/mic_issue.png'
import ringerIcon from '../../../Images/QuestionIcons/ringer_issue.png'
import charginPortIcon from '../../../Images/QuestionIcons/charging_port_issue.png'
import sensorIcon from '../../../Images/QuestionIcons/sensor_issue.png'
import batterIcon from '../../../Images/QuestionIcons/battery_issue.png'
import buttonIcon from '../../../Images/QuestionIcons/button_issue.png'
import wiffiicon from '../../../Images/QuestionIcons/wifi_issue.png'
import bluetoothIcon from '../../../Images/QuestionIcons/bluetooth_issue.png'
import flashLighIcon from '../../../Images/QuestionIcons/flash_light_issue.png'
import vibratorIcon from '../../../Images/QuestionIcons/vibrator_issue.png'
import autoRestartIcon from '../../../Images/QuestionIcons/auto_start_issue.png'
import earPhoneIcon from '../../../Images/QuestionIcons/earphone_port_issue.png'
import simCardIcon from '../../../Images/QuestionIcons/sim_issue.png'
import gpsIcon from '../../../Images/QuestionIcons/gps_issue.png'
import faceIssue from '../../../Images/QuestionIcons/faceissue.png'
import fingerIssue from '../../../Images/QuestionIcons/fingerissue.png'
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Footer from '../../Footer'

const Step2 = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const { productdetail } = useParams()
    const navigate = useNavigate();
    const [frontCamera, setFrontCamera] = useState(false);
    const [backCamera, setBackCamera] = useState(false);
    const [speaker, setSpeaker] = useState(false)
    const [sensorState, setSensorState] = useState(false)
    const [micState, setMicState] = useState(false)
    const [ringerState, setRingerState] = useState(false)
    const [chargingPortState, setChargingPort] = useState(false)
    const [batterState, setBatterState] = useState(false)
    const [buttonState, setButtonState] = useState(false)
    const [wiffiState, setWiffiState] = useState(false)
    const [bluetooth, setBluetoohState] = useState(false)
    const [flashLightState, setFlashState] = useState(false)
    const [vibrator, setVibrator] = useState(false)
    const [autoRestartState, setAutoRestartState] = useState(false)
    const [earphoneState, setearphoneState] = useState(false)
    const [simCardState, setSimCardState] = useState(false)
    const [gpsIssueState, setGpsIssueState] = useState(false)
    const [fingerPrintIssueState, setFingerPrintIssue] = useState(false)
    const [faceSensorIssueState, setFaceSensorIssue] = useState(false)

    // getting previous selection 
    const location = useLocation()

    const handleContinue = () => {

        navigate(`/products/step3/${productdetail}`, {
            state: {
                id: location.state.id, ram: location.state.ram, storage: location.state.storage, isDeviceSwitch: location.state.isDeviceSwitch, ableToMakeReceiveCall: location.state.ableToMakeReceiveCall, issueWithDeviceDisplay: location.state.issueWithDeviceDisplay, deviceScreen: location.state.deviceScreen, deviceEverRepair: location.state.deviceEverRepair,
                FrontCameraIssue: frontCamera, BackCameraIssue: backCamera, SpeakerIssue: speaker, MicIssue: micState, RingerIssue: ringerState, CharginPortIssue: chargingPortState, SensorIssue: sensorState, BatteryIssue: batterState, ButtonIssue: buttonState, WifiIssue: wiffiState, BluetoothIssue: bluetooth,
                FlashLightIssue: flashLightState, AutoRestartIssue: autoRestartState, EarphonePortIssue: earphoneState, GpsIssue: gpsIssueState, VibratorIssue: vibrator, SimCardIssue: simCardState, productCategory: location.state.productCategory, fingerPrintIssueState: fingerPrintIssueState, faceSensorIssueState: faceSensorIssueState,
                productBrand: location.state.productBrand,
                productName: location.state.productName, imgurl: location.state.imgurl
            }
        })

    }
    return (
        <>
            <Navigation />
            <div className="banner">
                <h3  >
                    <span onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                        <FaArrowLeft />
                    </span>
                    <span style={{ fontWeight: "bold", textAlign: "center", marginLeft: "5%" }}>
                        Steps - 2/4
                    </span>
                </h3>            </div>
            <Container fluid>
                <h3 style={{ textAlign: "center", marginTop: "1rem", fontSize: "25px", fontWeight: "bold" }} > Want to know the exact value of your device? </h3>
                <div style={{ textAlign: "center", fontWeight: "600", margin: "1rem" }} > Select the appropriate defects of your device </div>
                <Row>
                    <Col md={2} xs={6}>
                        <Card className={frontCamera ? "step2-container-checked" : 'step2-container'} onClick={() => frontCamera ? setFrontCamera(false) : setFrontCamera(true)}>
                            <Card.Body>
                                <Image src={fontIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Front Camera
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>

                        <Card className={backCamera ? "step2-container-checked" : 'step2-container'} onClick={() => backCamera ? setBackCamera(false) : setBackCamera(true)}>
                            <Card.Body>
                                <Image src={backCameraIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Back Camera
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>
                        <Card className={speaker ? "step2-container-checked" : 'step2-container'} onClick={() => speaker ? setSpeaker(false) : setSpeaker(true)} >
                            <Card.Body>
                                <Image src={speakerIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Speaker
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>
                        <Card className={sensorState ? "step2-container-checked" : 'step2-container'} onClick={() => sensorState ? setSensorState(false) : setSensorState(true)} >
                            <Card.Body>
                                <Image src={sensorIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Sensor
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>

                        <Card className={micState ? "step2-container-checked" : 'step2-container'} onClick={() => micState ? setMicState(false) : setMicState(true)}>
                            <Card.Body>
                                <Image src={micIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Mic
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>

                        <Card className={ringerState ? "step2-container-checked" : 'step2-container'} onClick={() => ringerState ? setRingerState(false) : setRingerState(true)}>
                            <Card.Body>
                                <Image src={ringerIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Ringer
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={2} xs={6}>

                        <Card className={chargingPortState ? "step2-container-checked" : 'step2-container'} onClick={() => chargingPortState ? setChargingPort(false) : setChargingPort(true)}>
                            <Card.Body>
                                <Image src={charginPortIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Charging Port (Loose/Not Working)
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>
                        <Card className={batterState ? "step2-container-checked" : 'step2-container'} onClick={() => batterState ? setBatterState(false) : setBatterState(true)}>
                            <Card.Body>
                                <Image src={batterIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Battery (Power/Low Backup/Bulge)
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>

                        <Card className={buttonState ? "step2-container-checked" : 'step2-container'} onClick={() => buttonState ? setButtonState(false) : setButtonState(true)} >
                            <Card.Body>
                                <Image src={buttonIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Button (Power/Volume Up/Volume Down)
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>

                        <Card className={wiffiState ? "step2-container-checked" : 'step2-container'} onClick={() => wiffiState ? setWiffiState(false) : setWiffiState(true)}>
                            <Card.Body>
                                <Image src={wiffiicon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Wifi
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>

                        <Card className={bluetooth ? "step2-container-checked" : 'step2-container'} onClick={() => bluetooth ? setBluetoohState(false) : setBluetoohState(true)}>
                            <Card.Body>
                                <Image src={bluetoothIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Bluetooth
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>

                        <Card className={flashLightState ? "step2-container-checked" : 'step2-container'} onClick={() => flashLightState ? setFlashState(false) : setFlashState(true)}>
                            <Card.Body>
                                <Image src={flashLighIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Flash Light
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={2} xs={6}>

                        <Card className={vibrator ? "step2-container-checked" : 'step2-container'} onClick={() => vibrator ? setVibrator(false) : setVibrator(true)}>
                            <Card.Body>
                                <Image src={vibratorIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Vibrator
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>

                        <Card className={autoRestartState ? "step2-container-checked" : 'step2-container'} onClick={() => autoRestartState ? setAutoRestartState(false) : setAutoRestartState(true)}>
                            <Card.Body>
                                <Image src={autoRestartIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Auto Restart/Rooted/Hanging/Heating
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>

                        <Card className={earphoneState ? "step2-container-checked" : 'step2-container'} onClick={() => earphoneState ? setearphoneState(false) : setearphoneState(true)}>
                            <Card.Body>
                                <Image src={earPhoneIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Earphone Port
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>

                        <Card className={simCardState ? "step2-container-checked" : 'step2-container'} onClick={() => simCardState ? setSimCardState(false) : setSimCardState(true)}>
                            <Card.Body>
                                <Image src={simCardIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Sim Card (Network Issue/Tray Missing/Sim Tray Broken)
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={2} xs={6}>

                        <Card className={gpsIssueState ? "step2-container-checked" : 'step2-container'} onClick={() => gpsIssueState ? setGpsIssueState(false) : setGpsIssueState(true)}>
                            <Card.Body>
                                <Image src={gpsIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    GPS Issue
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={2} xs={6}>

                        <Card className={fingerPrintIssueState ? "step2-container-checked" : 'step2-container'} onClick={() => fingerPrintIssueState ? setFingerPrintIssue(false) : setFingerPrintIssue(true)}>
                            <Card.Body>
                                <Image src={fingerIssue} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Finger Print Issue
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={2} xs={6}>
                        <Card className={faceSensorIssueState ? "step2-container-checked" : 'step2-container'} onClick={() => faceSensorIssueState ? setFaceSensorIssue(false) : setFaceSensorIssue(true)}>
                            <Card.Body>
                                <Image src={faceIssue} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    Face Sensor Issue
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* <Col md={2} xs={6}>

                        <Card style={{ visibility: "hidden" }} id='last-div' className={gpsIssueState ? "step2-container-checked" : 'step2-container'} onClick={() => gpsIssueState ? setGpsIssueState(false) : setGpsIssueState(true)}>
                            <Card.Body>
                                <Image src={gpsIcon} style={{ width: '80%' }} className="question-icon mx-auto d-block" />
                                <p className='mx-auto d-block' style={{ color: '#000' }}>
                                    GPS Issue
                                </p>
                            </Card.Body>
                        </Card>
                    </Col> */}
                </Row>
                <div style={{ display: "flex", justifyContent: "center", margin: "1rem", marginTop: "3rem" }}>
                    <Button variant="warning" onClick={() => handleContinue()} style={{ borderRadius: '0px', width: 150, color: '#fff', fontWeight: 'bold', fontSize: '20px', backgroundColor: "#40a737", border: "0px", borderRadius: '10px' }}>Next <FaArrowRight /></Button>

                    {/* <Button variant="info " style={style={{ borderRadius: '0px', width: 150, color: '#fff', fontWeight: 'bold', fontSize: '20px' }} >Next <FaArrowRight } onClick={() => handleContinue()}>Next...</Button> */}
                </div>
            </Container>
        </>
    )
}
export default Step2