import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import { DescriptionOutlined } from "@mui/icons-material/";
import useSnackbarNotifier from "../../../shared/hooks/useSnackbarNotifier";
import { cartPageBackdrop } from "../../../../store/atoms/addToCart/addToCart";
import { useSetRecoilState } from "recoil";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import s3Client from "../../../../config/s3Config";

const InvoiceMobile = ({ currentItem, order }) => {
  const currentStatus = currentItem?.unit?.status?.at(-1);
  const showSnackbar = useSnackbarNotifier();
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));

  const handleDownloadClick = async () => {
    if (order.invoiceAvailable) {
      try {
        setBackdropOpen(true);
        if (!order.invoice) {
          console.error("Invoice path is empty or invalid.");
          return;
        }
        const params = {
          Bucket: "hellofi",
          Key: order.invoice,
        };
        const command = new GetObjectCommand(params);
        const data = await s3Client.send(command);

        const blob = await new Response(data.Body).blob();

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = order.invoice.split("/").pop() || "invoice.pdf";

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error in download process:", error);
        alert("Failed to download invoice");
      } finally {
        setBackdropOpen(false);
      }
    } else {
      showSnackbar(
        "Invoice will be available for download 24 hours after delivery.",
        "info"
      );
    }
  };

  return (
    <>
      {currentStatus?.status !== "failed" &&
        currentStatus?.status !== undefined &&
        currentStatus?.status !== "cancelled" && (
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap="0.5rem"
            sx={{
              padding: "0.75rem",
              backgroundColor: "white",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              cursor:
                currentStatus?.status === "delivered" ? "pointer" : "default",
            }}
            onClick={handleDownloadClick}
          >
            <Box display={"flex"} gap={"0.5rem"} alignItems="center">
              <DescriptionOutlined />
              <CustomTypography
                variant={"subtitle1"}
                sx={{ fontWeight: "600" }}
              >
                Invoice Download
              </CustomTypography>
            </Box>
            {!order.invoiceAvailable && (
              <Box>
                <CustomTypography variant={"subtitle2"}>
                  Invoice can be downloaded 24 hours after delivery.
                </CustomTypography>
              </Box>
            )}
          </Box>
        )}
    </>
  );
};

export default InvoiceMobile;
