import React, { useEffect, useState } from 'react';
import '../ComponentsCss/BlogLayout.css';
import Navigation from './Navigation';
import Footer from './Footer';
import { GET_ALL_BLOG } from '../graphql/Query';
import { useQuery } from '@apollo/client';
import { Image } from 'react-bootstrap';

const BlogLayout = () => {
    const { data: getAllBlog, loading: BlogLoading } = useQuery(GET_ALL_BLOG);
    const [mainBlog, setMainBlog] = useState(null);

    // Update the main blog based on the clicked blog
    const handleBlogClick = (blog) => {
        setMainBlog(blog);
    };

    // Set the first blog as the main blog initially when data is loaded
    useEffect(() => {
        window.scrollTo(0, 0);
        if (getAllBlog && getAllBlog.getAllBlog.length > 0 && !mainBlog) {
            setMainBlog(getAllBlog.getAllBlog[0]);
        }
    }, [getAllBlog, mainBlog]);

    if (BlogLoading) {
        return <div>Loading...</div>; // Or any other loading spinner
    }

    if (!mainBlog) {
        return <div>No blog posts found.</div>;
    }

    return (
        <>
            <Navigation />
            <div className="container blog-layout">
                {/* Main blog post */}
                <div className="main-blog">
                    <Image src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${mainBlog.imageKey}`} alt={mainBlog.title} className="main-image" />
                    <h3 className='mt-3'>{mainBlog.title}</h3>
                    <p>{mainBlog.description}</p>
                </div>

                {/* Other blog posts */}
                <div className="row">
                    {getAllBlog?.getAllBlog.filter(blog => blog.id !== mainBlog.id).map((blog, index) => (
                        <div key={index} className="col-md-3 sub-blog" onClick={() => handleBlogClick(blog)}>
                            <Image src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${blog.imageKey}`} alt={blog.title} className="sub-image" />
                            <h4>{blog.title}</h4>
                            <p>{blog.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default BlogLayout;
