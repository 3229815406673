import { Box } from "@mui/material";
import React from "react";
import Heading from "../shared/Heading";
import SellingServices from "./SellingServices";
import HomePageContainer from "../shared/HomePageContainer";

const SellingAccessories = () => {
  return (
    <HomePageContainer sx={{ margin: { xs: "0 0.5rem", laptop: 0 } }}>
      <Heading text={"Sell your old devices today"} />
      <SellingServices />
    </HomePageContainer>
  );
};

export default SellingAccessories;
