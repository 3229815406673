import Navigation from "../Navigation"

import { Col, Container, Button, Image, Row, Spinner, Card, Breadcrumb, Dropdown } from 'react-bootstrap'
import { useQuery } from '@apollo/client';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Footer from "../Footer";
import { FaArrowRight } from 'react-icons/fa'
import { QUERY_GET_QUESTIONS } from '../../graphql/Query'
import { GET_TABLET_QUESTIONS } from '../../graphql/Query'


const ShowPhoneMaxPrice = () => {

    const location = useLocation()

    const { data: getQuestions, loading: questionLoading } = useQuery(QUERY_GET_QUESTIONS);
    const { data: getTabletQuestions, loading: questionLoading2 } = useQuery(GET_TABLET_QUESTIONS);
    const [additionalPrice, setAdditionalPrice] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0)
        if (location.state.productCategory == "Phones") {
            let price = 0
            let questionPer = getQuestions?.getQuestions[0]

            if (location.state.ram == "2 GB") {
                price += parseInt(questionPer?.ram2gb)
            }
            if (location.state.ram == "3 GB") {
                price += parseInt(questionPer?.ram3gb)
            }
            if (location.state.ram == "4 GB") {
                price += parseInt(questionPer?.ram4gb)
            }
            if (location.state.ram == "6 GB") {
                price += parseInt(questionPer?.ram6gb)
            }
            if (location.state.ram == "8 GB") {
                price += parseInt(questionPer?.ram8gb)
            }
            if (location.state.ram == "12 GB") {
                price += parseInt(questionPer?.ram12gb)
            }
            if (location.state.ram == "16 GB") {
                price += parseInt(questionPer?.ram16gb)
            }
            if (location.state.ram == "32 GB") {
                price += parseInt(questionPer?.ram32gb)
            }
            // calculating Storage
            if (location.state.storage == "16 GB") {
                price += parseInt(questionPer?.storage16gb)
            }
            if (location.state.storage == "32 GB") {
                price += parseInt(questionPer?.storage32gb)
            }
            if (location.state.storage == "64 GB") {
                price += parseInt(questionPer?.storage64gb)
            }
            if (location.state.storage == "128 GB") {
                price += parseInt(questionPer?.storage128gb)
            }
            if (location.state.storage == "256 GB") {
                price += parseInt(questionPer?.storage256gb)
            }
            if (location.state.storage == "1 TB") {
                price += parseInt(questionPer?.storage1tb)
            }
            setAdditionalPrice(price)
        }
        if (location.state.productCategory == "Tablets") {
            let price = 0
            let questionPer = getTabletQuestions?.getTabletQuestions[0]

            if (location.state.ram == "2 GB") {
                price += parseInt(questionPer?.ram2gb)
            }
            if (location.state.ram == "3 GB") {
                price += parseInt(questionPer?.ram3gb)
            }
            if (location.state.ram == "4 GB") {
                price += parseInt(questionPer?.ram4gb)
            }
            if (location.state.ram == "6 GB") {
                price += parseInt(questionPer?.ram6gb)
            }
            if (location.state.ram == "8 GB") {
                price += parseInt(questionPer?.ram8gb)
            }
            if (location.state.ram == "12 GB") {
                price += parseInt(questionPer?.ram12gb)
            }
            if (location.state.ram == "16 GB") {
                price += parseInt(questionPer?.ram16gb)
            }
            if (location.state.ram == "32 GB") {
                price += parseInt(questionPer?.ram32gb)
            }
            // calculating Storage
            if (location.state.storage == "16 GB") {
                price += parseInt(questionPer?.storage16gb)
            }
            if (location.state.storage == "32 GB") {
                price += parseInt(questionPer?.storage32gb)
            }
            if (location.state.storage == "64 GB") {
                price += parseInt(questionPer?.storage64gb)
            }
            if (location.state.storage == "128 GB") {
                price += parseInt(questionPer?.storage128gb)
            }
            if (location.state.storage == "256 GB") {
                price += parseInt(questionPer?.storage256gb)
            }
            if (location.state.storage == "1 TB") {
                price += parseInt(questionPer?.storage1tb)
            }
            setAdditionalPrice(price)

        }

    }, [getQuestions, getTabletQuestions])

    const navigate = useNavigate()


    let prevlink = `/allbrands/${location.state?.productBrand}`
    const handleContinue = () => {

        if (location.state.productCategory == "Phones") {
            navigate(`/product/phone-step1`, {
                state: {
                    id: location.state.id, ram: location.state.ram, storage: location.state.storage
                    , productCategory: location.state.productCategory,
                    productBrand: location.state?.productBrand,
                    productName: location.state?.productName, imgurl: location.state.imgurl
                }
            })
        } else if (location.state.productCategory == "Tablets") {

            navigate(`/tablet-product-step1`, {
                state: {
                    id: location.state.id, ram: location.state.ram, storage: location.state.storage
                    , productCategory: location.state.productCategory,
                    productBrand: location.state?.productBrand,
                    productName: location.state?.productName, imgurl: location.state.imgurl
                }
            })
        } 
    }
    const finalPrice = parseInt(location.state.productPrice) + (parseInt(additionalPrice) || 0);
    const handleSelectBrand = () => {
    navigate(`/selling/${location.state?.productCategory}`, )
    }
    const handleSelectProduct = () => {
        navigate(`/allbrands/${location.state?.productBrand}`, {
            state: {
                productCategory: location.state?.productCategory, productBrand: location.state?.productBrand,
            }
        })
    }
    return (
        <>
            <Navigation />
            <div className="banner">
                <h2 style={{ color: '#000' }}>
                    Sell your Old {location.state.productCategory}
                </h2>
                {/* <div className="search-bar">
                    <input type="text" placeholder="Search..." className="search-input" />
                </div> */}
                <Breadcrumb>
                    <Breadcrumb.Item ><Link to="/" style={{ fontWeight: 'bold', color: '#2ecc71' }}>Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item> <a onClick={handleSelectBrand} style={{ fontWeight: 'bold', color: '#2ecc71' }}>Select Brand</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item> <a onClick={handleSelectProduct} style={{ fontWeight: 'bold', color: '#2ecc71' }}>Select Product</a>
                    </Breadcrumb.Item>
                    {/* <Breadcrumb.Item active style={{ fontWeight: 'bold', color: '#7f8c8d' }}>Choose variant</Breadcrumb.Item> */}
                </Breadcrumb>
            </div>

            {
                // productLoading && ImageLoading ? <Spinner animation="border" variant="success" className='mx-auto d-block' /> :
                <Container>
                    <h3 style={{ textAlign: "center", margin: "1rem", fontWeight: "700", color: '#000' }}>Sell Old {location.state?.productName}</h3>
                    <Card className="specific-product-card" style={{ padding: 10, borderRadius: "25px", width: "100%" }}  >
                        <Row>
                            <Col >
                                <Image src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${location.state.imgurl}`} className="brandImgShadow mx-auto d-block" fluid style={{ width: "20rem" }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col >
                                <h1 className="productNameCss" style={{ textAlign: 'center', fontSize: "25px" }}>{location.state?.productName}</h1>
                                <div style={{ fontWeight: "500", color: "grey", textAlign: "center" }}  > Get Upto</div>
                                {
                                    questionLoading  ? <Spinner animation="border" variant="success" className='mx-auto d-block' /> :
                                        <div style={{ color: "red", fontWeight: "bold", fontSize: "42px ", textAlign: "center" }} > Rs. {finalPrice} </div>
                                }
                                <Button variant="warning" className="nextBtn mx-auto d-block " onClick={() => handleContinue()} >Get Exact Value <FaArrowRight style={{ marginTop: '-4px' }} /></Button>
                                <p className="text-danger fw-bold text-center mt-3 " >The above price is purely based on your inputs. Please provide the correct information and continue until the final page for more accurate pricing </p>
                            </Col>
                        </Row>

                    </Card>
                </Container >
            }
        </>
    )
}

export default ShowPhoneMaxPrice 