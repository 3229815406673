import React from "react";
import HomePageContainer from "../shared/HomePageContainer";
import Heading from "../shared/Heading";
import TrustBenefitsCards from "./TrustBenefitsCards";

const WhyPeopleTrustUs = () => {
  return (
    <HomePageContainer sx={{ margin: { xs: "0 0.5rem", laptop: 0 } }}>
      <Heading text={"Why People Trust Us"} />
      <TrustBenefitsCards />
    </HomePageContainer>
  );
};

export default WhyPeopleTrustUs;
