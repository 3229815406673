import React from "react";
import { Box } from "@mui/material";

const BrowGradient = ({ children, sx, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        ...sx,
        position: "relative",
        borderRadius: "0.5rem",
        padding: "1rem",
        width: "100%",
        background:
          "linear-gradient(135deg, rgba(41, 22, 22, 0.3) 0%, rgba(242, 212, 212, 0.5) 66%)",
      
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          zIndex: 1,
          width: "150px",
          height: "150px",
          top: "-75px",
          right: "-75px",
          border: "10px solid",
          borderColor: "#d3cbcb transparent transparent transparent",
          borderRadius: "50%",
          transform: "rotate(225deg)",
        }}
      />
      {children}
    </Box>
  );
};

export default BrowGradient;
