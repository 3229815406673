import Navigation from "../Navigation"
import { Form, Button, Dropdown, Container, Breadcrumb, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import '../../ComponentsCss/Forms/forms.css'
import swal from 'sweetalert';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CorporateBuyForm from "./corporateBuyForm";
import CorporateSellForm from "./corporateSellForm";

const CorporateForm = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [sellForm, setSellForm] = useState(true)
    const handleClick = (Bool) => {
        setSellForm(Bool)
    }

    return (
        <>
            <Navigation />
            <div className="banner">
                <h3 className=" py-4 mb-3" >  SELL/BUY Preowned Devices</h3>
                <Breadcrumb className="breadcrumb">
                    <Breadcrumb.Item><Link to="/" style={{ fontWeight: 'bold', color: '#2ecc71' }}>Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item active style={{ fontWeight: 'bold', color: '#7f8c8d' }}> SELL-BUY Preowned Devices</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <h3 style={{ textAlign: "center" }} className=" py-4 mb-3 getQuoteheader" >Fill up and <span className="text-success" > Get a Quote  </span>  within 24 hours.</h3>
            <div className="text-center mb-4">
                <ToggleButtonGroup  type="radio" name="options" defaultValue={1}>

                    <ToggleButton id="tbg-radio-2"  value={2} variant="success" style={{ padding: "10px 30px" }} onClick={() => handleClick(true)} className={sellForm ? "sellbuybtn" : ""} >
                        Sell
                    </ToggleButton>
                    <ToggleButton id="tbg-radio-3" value={3} variant="success" style={{ padding: "10px 30px" }} onClick={() => handleClick(false)} className={sellForm ? "" : "sellbuybtn"} >
                        Buy
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            {
                sellForm ?
                <CorporateSellForm/>
                : 
                <CorporateBuyForm />
            }

        </>
    )
}
export default CorporateForm