import { Box } from "@mui/material";
import React from "react";

const HomePageContainer = ({ children, sx, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        ...sx,
        display: "flex",
        flexDirection: "column",
        gap: {
          mobile: "0.5rem",
          tablet: "1rem",
          laptop: "1rem",
          desktop: "1rem",
        },
      }}
    >
      {children}
    </Box>
  );
};

export default HomePageContainer;
