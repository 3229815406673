import React, { useState } from "react";
import { Box } from "@mui/material";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
const CustomPinCodeInput = ({ onValidPincode }) => {
  const [pincode, setPincode] = useState("");
  const [isValid, setIsValid] = useState(true);

  const validatePincode = (code) => {
    const pincodeRegex = /^[1-9][0-9]{5}$/;
    return pincodeRegex.test(code);
  };

  const handleChange = (e) => {
    const newPincode = e.target.value;
    setPincode(newPincode);
    setIsValid(true);
  };

  const handleCheck = () => {
    const valid = validatePincode(pincode);
    setIsValid(valid);
    if (valid && onValidPincode) {
      onValidPincode(pincode);
    }
  };

  const placeholderStyle = `
    .custom-input::placeholder {
      font-family: 'Poppins', sans-serif;
    }
  `;

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <style>{placeholderStyle}</style>
      <input
        className="custom-input"
        style={{
          width: "100%",
          borderRadius: "5px",
          fontFamily: '"Poppins",sans-serif',
          padding: "8px",
          paddingLeft: "10px",
          paddingRight: "64px",
          border: "1px solid rgb(119, 119, 119)",
          backgroundColor: "transparent",
          outline: "none",
        }}
        placeholder="Enter Delivery Pincode"
        value={pincode}
        onChange={handleChange}
      />
      <CustomTypography
        variant="body2"
        sx={{
          position: "absolute",
          right: "8px",
          top: isValid ? "50%" : "32%",
          transform: "translateY(-50%)",
          fontSize: "14px",
          fontWeight: "300",
          color: "#0866ff",
          cursor: "pointer",
          fontFamily: '"Poppins", sans-serif',
        }}
        onClick={handleCheck}
      >
        Check
      </CustomTypography>
      {!isValid && (
        <CustomTypography
          variant="caption"
          sx={{
            color: "red",
            fontSize: "12px",
            marginTop: "4px",
          }}
        >
          Please enter a valid 6-digit Indian pincode
        </CustomTypography>
      )}
    </Box>
  );
};

export default CustomPinCodeInput;
