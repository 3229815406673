import React, { useEffect } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import ShippingDetailsSection from "./shippingDetails/ShippingDetailsSection";
import OrderSummary from "./orderSummary/OrderSummary";
import { useParams } from "react-router-dom";
import { ORDER_SUMMARY } from "../../../../graphql/Mutations";
import { useQuery } from "@apollo/client";
import useGetOrderSummary from "../../hooks/productDetails/useGetOrderSummary";
import MuiBackdrop from "../../../../muicomponents/shared/MuiBackdrop";
import { useSetRecoilState } from "recoil";
import { cartPageBackdrop } from "../../../../store/atoms/addToCart/addToCart";

const OrderDetail = () => {
  const { orderId } = useParams();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const { orderSummary, loading, error, refetch } = useGetOrderSummary(orderId);
  const setBackDropOpen = useSetRecoilState(cartPageBackdrop);

  useEffect(() => {
    refetch();
  }, [orderId]);

  useEffect(() => {
    setBackDropOpen(loading);
  }, [loading]);

  if (error) {
    return <>Error {error.message}</>;
  }

  return (
    <>
      <MuiBackdrop />
      {!loading && orderSummary && (
        <Box display={"flex"} flexDirection={isSmallScreen ? "column" : "row"}>
          <Box
            flexBasis={"55%"}
            sx={{
              borderRight: isSmallScreen ? "none" : "1px solid #A2A2A2",
              padding: "1rem ",
              minWidth: "300px",
            }}
          >
            <ShippingDetailsSection />
          </Box>
          <Box
            flexBasis={"45%"}
            sx={{
              padding: isSmallScreen ? "0 1rem 1rem 1rem" : "1rem",
              minWidth: "300px",
            }}
          >
            <OrderSummary />
          </Box>
        </Box>
      )}
    </>
  );
};

export default OrderDetail;
