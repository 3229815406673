import { Box, CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import { useQuery } from "@apollo/client";
import { QUERY_GET_BUYING_ORDERS_USERID } from "../../../../graphql/Query";
import OrderCard from "./buying/OrderCard";
import { cartPageBackdrop } from "../../../../store/atoms/addToCart/addToCart";
import { useSetRecoilState } from "recoil";
import MuiBackdrop from "../../../../muicomponents/shared/MuiBackdrop";

const Orders = () => {
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);
  const userId = localStorage.getItem("userId");
  const [allBuyOrders, setAllBuyOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(3);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const [isBuyPage, setIsBuyPage] = useState(true);
  const observer = useRef();

  const [firstLoading, setFirstLoading] = useState(false);

  const { data, loading, error, refetch } = useQuery(
    QUERY_GET_BUYING_ORDERS_USERID,
    {
      variables: {
        userId: userId,
        page: page,
        limit: limit,
      },
    }
  );

  useEffect(() => {
    if (data?.getBuyOrdersByUserId) {
      if (page === 1 && Array.isArray(data?.getBuyOrdersByUserId)) {
        setAllBuyOrders([...data.getBuyOrdersByUserId]);
      } else if (Array.isArray(data?.getBuyOrdersByUserId)) {
        setAllBuyOrders((prev) => [...prev, ...data?.getBuyOrdersByUserId]);
      }
      if (data?.getBuyOrdersByUserId.length < limit) {
        setHasMore(false);
      }
    }
  }, [data, page, limit]);

  const handleTogglePage = (isBuy) => {
    setIsBuyPage(isBuy);
    setPage(1);
    setHasMore(true);
  };

  const lastOrderElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (page > 1) {
      refetch({ page, limit });
    }
  }, [page, refetch, limit]);

  useEffect(() => {
    if (page === 1) {
      setBackdropOpen(loading);
      setFirstLoading(loading);
    }
  }, [loading]);

  return (
    <>
      <MuiBackdrop />
      {!firstLoading && (
        <Box
          sx={{
            minHeight: "calc(100vh - 115px)",
            width: "100%",
            backgroundColor: isSmallScreen ? "white" : "#D1E5E7",
            borderRadius: "1rem",
            overflowY: "auto",
          }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={{ width: "100%", height: "100%" }}
          >
            <Box
              display={"flex"}
              sx={{ padding: isSmallScreen ? "0 1rem" : "", gap: "0.75rem" }}
            >
              <Box
                flexBasis={isSmallScreen ? "" : "50%"}
                onClick={() => handleTogglePage(true)}
                sx={{
                  borderRadius: isSmallScreen && "1rem",
                  backgroundColor: isSmallScreen
                    ? isBuyPage
                      ? "#D1E5E7"
                      : "#D3D3D3"
                    : !isBuyPage && "#F7EEEE",
                  padding: isSmallScreen ? "0.5rem 2rem" : "1rem",
                  borderTopLeftRadius: isSmallScreen ? "none" : "1rem",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <CustomTypography
                  varaint={"h6"}
                  sx={{ textAlign: "center", fontWeight: "600" }}
                >
                  Buy
                </CustomTypography>
              </Box>
              <Box
                flexBasis={isSmallScreen ? "" : "50%"}
                onClick={() => handleTogglePage(false)}
                sx={{
                  borderRadius: isSmallScreen && "1rem",
                  padding: isSmallScreen ? "0.5rem 2rem" : "1rem",
                  backgroundColor: isSmallScreen
                    ? !isBuyPage
                      ? "#D1E5E7"
                      : "#D3D3D3"
                    : isBuyPage && "#F7EEEE",
                  borderTopRightRadius: "1rem",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <CustomTypography
                  varaint={"h6"}
                  sx={{ fontWeight: "600", textAlign: "center" }}
                >
                  Sell
                </CustomTypography>
              </Box>
            </Box>
            <Box
              sx={{
                padding: isSmallScreen ? "1rem" : "1.5rem",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              {isBuyPage ? (
                allBuyOrders.length > 0 ? (
                  allBuyOrders?.map((item, index) => {
                    // Attach the observer to the last item in the list

                    if (index === allBuyOrders.length - 1) {
                      return (
                        <div ref={lastOrderElementRef} key={item.id}>
                          <OrderCard
                            product={item.product}
                            order={item.order}
                            quantity={item.quantity}
                            unit={item.unit}
                            payment={
                              item.order.payments[
                                item.order.payments.length - 1
                              ]
                            }
                            itemId={item.itemId}
                          />
                        </div>
                      );
                    } else {
                      return (
                        <OrderCard
                          key={item.id}
                          product={item.product}
                          order={item.order}
                          quantity={item.quantity}
                          unit={item.unit}
                          payment={
                            item.order.payments[item.order.payments.length - 1]
                          }
                          itemId={item.itemId}
                        />
                      );
                    }
                  })
                ) : (
                  <CustomTypography
                    varaint={"h6"}
                    sx={{ textAlign: "center", fontWeight: "600" }}
                  >
                    No Orders
                  </CustomTypography>
                )
              ) : (
                <CustomTypography
                  varaint={"h6"}
                  sx={{ textAlign: "center", fontWeight: "600" }}
                >
                  No Sell Orders
                </CustomTypography>
              )}

              {loading && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  padding="1rem"
                >
                  <CircularProgress />
                </Box>
              )}

              {!hasMore && allBuyOrders.length > 0 && (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  padding="1rem"
                >
                  <span>No more Buying Orders</span>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Orders;
