import React from 'react';
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap';
import '../../ComponentsCss/AdminCss/DashboardChart.css';
import { FaCoins, FaShopify, FaShoppingCart, FaUserAlt } from 'react-icons/fa';
import { Chart } from 'react-charts';
import { useQuery } from '@apollo/client';
import {
  QUERY_GET_ALL_USER,
  QUERY_All_BUY_ORDERS,
  QUERY_GET_All_SELLING_ORDERS,
  QUERY_GET_ALL_REQ,
} from '../../graphql/Query';

export default function DashboardChart() {
  const { data: getAllUser, loading: userLoading } = useQuery(QUERY_GET_ALL_USER);
  const { data: buyOrderData, loading: buyProLoading } = useQuery(QUERY_All_BUY_ORDERS);
  const { data: sellOrdersData, loading: sellOrderLoading } = useQuery(QUERY_GET_All_SELLING_ORDERS);
  const { data: getFormRequest, loading: reqLoading } = useQuery(QUERY_GET_ALL_REQ);

  const data = React.useMemo(
    () => [
      {
        label: 'Series 1',
        data: [[0, 1], [1, 2], [2, 4], [3, 2], [4, 7]],
      },
      {
        label: 'Series 2',
        data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]],
      },
      {
        label: 'Series 2',
        data: [[0, 5], [1, 3], [2, 1], [3, 4], [4, 1]],
      },
    ],
    []
  );

  const data2 = React.useMemo(
    () => [
      {
        label: 'Series 1',
        data: [[0, 1], [1, 2], [2, 4], [3, 2], [4, 7]],
      },
      {
        label: 'Series 2',
        data: [[0, 3], [1, 1], [2, 5], [3, 6], [4, 4]],
      },
      {
        label: 'Series 2',
        data: [[0, 5], [1, 3], [2, 1], [3, 4], [1, 4]],
      },
    ],
    []
  );

  const axes = React.useMemo(
    () => [
      { primary: true, type: 'linear', position: 'bottom' },
      { type: 'linear', position: 'left' },
    ],
    []
  );

  return (
    <>
      {getAllUser && buyOrderData && sellOrdersData && getFormRequest ? (
        <Container className='chjartmain' fluid>
          <Row>
            <Col md={3}>
              <Card className='chartcard'>
                <div className='tx'>
                  <h3>{getAllUser?.getAllUser?.length}</h3>
                  <p>Active Users</p>
                </div>
                <div className='txticon'>
                  <FaUserAlt />
                </div>
              </Card>
            </Col>
            <Col md={3}>
              <Card className='chartcard2'>
                <div className='tx'>
                  <h3>{sellOrdersData?.getSellOrders.length}</h3>
                  <p>Total Orders</p>
                </div>
                <div className='txticon'>
                  <FaShoppingCart />
                </div>
              </Card>
            </Col>
            <Col md={3}>
              <Card className='chartcard3'>
                <div className='tx'>
                  <h3>{buyOrderData?.getAllBuyingProduct?.length}</h3>
                  <p>Products Sell</p>
                </div>
                <div className='txticon'>
                  <FaShopify />
                </div>
              </Card>
            </Col>
            <Col md={3}>
              <Card className='chartcard4'>
                <div className='tx'>
                  <h3>{getFormRequest?.getFormRequest.length}</h3>
                  <p>Total Enquiries</p>
                </div>
                <div className='txticon'>
                  <FaCoins />
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div style={{ height: 300, marginTop: 30 }}>
                <Chart data={data} axes={axes} />
              </div>
            </Col>
            <Col md={6}>
              <div style={{ height: 300, marginTop: 30 }}>
                <Chart
                  data={data2}
                  series={{ type: 'bar' }}
                  axes={[
                    {
                      primary: true,
                      position: 'bottom',
                      type: 'ordinal',
                    },
                    {
                      position: 'left',
                      type: 'linear',
                    },
                  ]}
                />
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <div className='loading-spinner'>
          <Spinner animation='border' role='status' className='mx-auto d-block'>
          </Spinner>
        </div>
      )}
    </>
  );
}
