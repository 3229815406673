import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const PreownedBox = ({ title }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <CustomTypography
        varaint={"subtitle2"}
        sx={{ fontSize: { xs: "0.65rem", laptop: "1rem" } }}
      >
        <span style={{ color: "#285B84", fontWeight: "600" }}>Buy</span>{" "}
        Pre-Owned
      </CustomTypography>
      <CustomTypography
        varaint={"subtitle2"}
        sx={{
          fontSize: { xs: "0.65rem", laptop: "1rem" },
          color: "#285B84",
          fontWeight: "600",
        }}
      >
        {title}
      </CustomTypography>
    </Box>
  );
};

export default PreownedBox;
