import React, { useEffect } from "react";
import Footer from "../Footer";
import Navigation from "../Navigation";

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navigation />
            <div className="term-container">
                <div className="contact-content px-3" style={{ textAlign: "justify" }}>
                    <h1>Contact Us</h1>

                    <p>You can reach out to us regarding any concern and/or information via phone at <a href="tel:8150835583">8150835583</a>, you can also write to us at <a href="mailto:contact@hellofi.in">contact@hellofi.in</a>.</p>

                    <p>We have our office situated at:</p>
                    <address>
                        28, 1st Main, 6th Cross Road, 1st Block Koramangala,<br />
                        Bangalore, India, Karnataka.<br />
                        Open Mon-Sun (11am -8pm).
                    </address>

                    <p>Updates regarding holidays are actively posted on our Google Business profile.</p>

                    <p>Technical operations are closed on Saturday and Sunday and open for offline in-house sales and purchase only.</p>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Contact;
