import { useQuery } from "@apollo/client";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { QUERY_GET_ORDER_INFO_BY_ORDERID } from "../../../../graphql/Query";
import ShippingAddressComponent from "./ShippingAddressComponent";
import CurrentItem from "./CurrentItem";
import OtherItems from "./OtherItems";
import { format } from "date-fns";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import OrderNumber from "./OrderNumber";
import PaymentDetails from "./PaymentDetails";
import { cartPageBackdrop } from "../../../../store/atoms/addToCart/addToCart";
import { useSetRecoilState } from "recoil";
import MuiBackdrop from "../../../../muicomponents/shared/MuiBackdrop";
import ShowCancellationRequest from "./ShowCancellationRequest";
import ContactUs from "./ContactUs";
import InvoiceMobile from "./InvoiceMobile";

const ProfileOrderDetails = () => {
  const theme = useTheme();
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("order_id");
  const itemId = searchParams.get("item_id");
  const [currentItem, setCurrentItem] = useState(null);
  const [otherItems, setOtherItems] = useState([]);
  const { data, loading, error, refetch } = useQuery(
    QUERY_GET_ORDER_INFO_BY_ORDERID,
    {
      variables: { orderId },
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (data && data.getOrderDetailsByOrderId) {
      const { items } = data.getOrderDetailsByOrderId;
      if (items && items.length > 0) {
        if (itemId) {
          const current = items.find((item) => item.itemId === itemId);
          if (current) {
            setCurrentItem(current);
            setOtherItems(items.filter((item) => item.itemId !== itemId));
          } else {
            setCurrentItem(items[0]);
            setOtherItems(items.slice(1));
          }
        } else {
          setCurrentItem(items[0]);
          setOtherItems(items.slice(1));
        }
      }
    }
  }, [data, itemId]);

  useEffect(() => {
    setBackdropOpen(loading);
  }, [loading]);

  if (error) {
    return <>Error....</>;
  }

  return (
    <>
      <MuiBackdrop />
      {!loading && (
        <Box
        // sx={{
        //   margin: isSmallScreen ? "1rem 0.75rem" : "1rem 0",
        // }}
        >
          <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
            {data && data.getOrderDetailsByOrderId && (
              <OrderNumber
                orderType={data.getOrderDetailsByOrderId.orderType}
                orderNo={data.getOrderDetailsByOrderId.orderNo}
                createdAt={data.getOrderDetailsByOrderId.createdAt}
              />
            )}
            {currentItem && (
              <CurrentItem
                product={currentItem}
                orderType={data.getOrderDetailsByOrderId.orderType}
                payment={
                  data.getOrderDetailsByOrderId.payments[
                    data.getOrderDetailsByOrderId.payments.length - 1
                  ]
                }
                status={currentItem?.unit?.status}
                orderTimelineName={currentItem?.orderTimelineName}
              />
            )}
            {data && data.getOrderDetailsByOrderId.address && (
              <ShippingAddressComponent
                address={data.getOrderDetailsByOrderId.address}
                orderType={data.getOrderDetailsByOrderId.orderType}
                currentItem={currentItem}
                order={data.getOrderDetailsByOrderId}
              />
            )}

            {currentItem && (
              <PaymentDetails
                orderDetails={data.getOrderDetailsByOrderId}
                currentItem={currentItem}
                orderType={data.getOrderDetailsByOrderId.orderType}
              />
            )}
            {currentItem?.showCancelRequest && (
              <ShowCancellationRequest
                currentItem={currentItem}
                itemId={currentItem.id}
                status={currentItem.cancellationStatus}
              />
            )}
            {isSmallScreen && (
              <InvoiceMobile
                currentItem={currentItem}
                order={data.getOrderDetailsByOrderId}
              />
            )}
            {data.getOrderDetailsByOrderId && (
              <ContactUs user={data.getOrderDetailsByOrderId.user} />
            )}

            {otherItems.length > 0 && (
              <OtherItems
                products={otherItems}
                orderType={data.getOrderDetailsByOrderId.orderType}
                orderNo={data.getOrderDetailsByOrderId.orderNo}
                payment={
                  data.getOrderDetailsByOrderId.payments[
                    data.getOrderDetailsByOrderId.payments.length - 1
                  ]
                }
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProfileOrderDetails;
