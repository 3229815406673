import Footer from "../Footer"
import Navigation from "../Navigation"
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import '../../ComponentsCss/DealerCard.css'
import { HiShieldCheck } from "react-icons/hi";
import { FaMapMarkedAlt } from "react-icons/fa";
import step1 from "../../Images/homepage/workflow/sell.png"
import step2 from "../../Images/homepage/workflow/inspection.png"
import step3 from "../../Images/homepage/workflow/time.png"
import step4 from "../../Images/homepage/workflow/wishlist.png"
import step5 from "../../Images/homepage/workflow/pay.png"
import step6 from "../../Images/homepage/workflow/week.png"
import business from "../../Images/homepage/workflow/ex.png"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const FlowChart1 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const navigate = useNavigate();
    const handleClickService = () => {
        navigate("/")
    }

    return (
        <>
            <Navigation />
            <Container className='cardbgservice2 flchart' fluid>
                <Container>

                    <h1 class="choosePrice divider line glow " contenteditable> Outside Bangalore </h1>

                    <Row>
                        <Col md={7} >
                            <div style={{ display: "flex", marginTop: 10 }}>
                                <div style={{ marginTop: '-45px' }}>
                                    <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 1</h1>
                                    <div className='SellBox'>
                                        <Image src={step1} style={{ width: 50, height: 50 }} />
                                    </div>
                                    <div className='lineArrow1'></div>
                                    <div id="dialog2" class="triangle_down1"></div>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <h5 style={{ marginTop: 10, fontSize: 18, fontWeight: 600 }}>Check the Price of the Product you wish to Sell with us.
                                    </h5>
                                    {/* <p style={{ fontSize: 13, fontWeight: 500 }}>Pack your products safely to avoid damage in transit. Use Bubblewraps, thermocols. </p> */}
                                </div>
                            </div>

                            <div style={{ display: "flex", marginTop: 10 }}>
                                <div style={{ marginTop: '-25px' }}>
                                    <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 2</h1>
                                    <div className='SellBox'>
                                        <Image src={step2} style={{ width: 50, height: 50 }} />
                                    </div>
                                    <div className='lineArrow2'></div>
                                    <div id="dialog2" class="triangle_down2"></div>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <h5 style={{ marginTop: 20, fontSize: 18, fontWeight: 600 }}>We will bring your product to our Corporate office for inspection through our Logistics Partner.</h5>
                                    {/* <p style={{ fontSize: 13, fontWeight: 500 }}>Handover the package to the renouned logistics partner assigned for the job. You can track your device with the tracking number provided to you. </p> */}
                                </div>
                            </div>


                            <div style={{ display: "flex", marginTop: 10 }}>
                                <div style={{ marginTop: '-25px' }}>
                                    <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 3</h1>
                                    <div className='SellBox'>
                                        <Image src={step3} style={{ width: 50, height: 50 }} />
                                    </div>
                                    <div className='lineArrow3'></div>
                                    <div id="dialog2" class="triangle_down3"></div>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <h5 style={{ marginTop: 10, fontSize: 18, fontWeight: 600 }}>After inspection we will notify the exact value of your device within 24 hours. (Price remains unchanged if it matches the condition as described by you while placing the order).</h5>
                                    {/* <p style={{ fontSize: 13, fontWeight: 500 }}> Once product reached our Office you will be notified through Email/SMS. </p> */}
                                </div>
                            </div>
                            <div style={{ display: "flex", marginTop: 10 }}>
                                <div style={{ marginTop: '-25px' }} className="mbpart3">
                                    <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 4</h1>
                                    <div className='SellBox'>
                                        <Image src={step4} style={{ width: 50, height: 50 }} />
                                    </div>
                                    <div className='lineArrow4'></div>
                                    <div id="dialog2" class="triangle_down4"></div>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <h5 style={{ marginTop: 30, fontSize: 18, fontWeight: 600 }}> You can Wishlist the Product you wish to Purchase from our Website.</h5>
                                    {/* <p style={{ fontSize: 13, fontWeight: 500 }}>We will do the Inspection of you device and the result will be notified through Email. Usually it will be completed on the same business day. </p> */}
                                </div>
                            </div>
                            <div style={{ display: "flex", marginTop: 10 }}>
                                <div style={{ marginTop: '-25px' }}>
                                    <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 5</h1>
                                    <div className='SellBox'>
                                        <Image src={step5} style={{ width: 50, height: 50 }} />
                                    </div>
                                    <div className='lineArrow4'></div>
                                    <div id="dialog2" class="triangle_down4"></div>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <h5 style={{ marginTop: 30, fontSize: 18, fontWeight: 600 }}> Pay only the differential amount.</h5>
                                    {/* <p style={{ fontSize: 13, fontWeight: 500 }}>We will do the Inspection of you device and the result will be notified through Email. Usually it will be completed on the same business day. </p> */}
                                </div>
                            </div>

                            <div style={{ display: "flex", marginTop: 10 }}>
                                <div style={{ marginTop: '-25px' }}>
                                    <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 6</h1>
                                    <div className='SellBox'>
                                        <Image src={step6} style={{ width: 50, height: 50 }} />
                                    </div>

                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <h5 style={{ marginTop: 30, fontSize: 18, fontWeight: 600 }}>You will receive the product within One week (Maximum).</h5>
                                    {/* <p style={{ fontSize: 13, fontWeight: 500 }}>Once Inspection is completed Payment wil be done on the same day based on Inspection results. </p> */}
                                </div>
                            </div>

                        </Col>
                        <Col md={5}>

                            {/* <Lottie options={defaultOptions} style={{ width: "80%", height: "80%", marginTop: 20 }} className="mx-auto d-block" /> */}
                            <Image src={business} fluid />

                        </Col>
                        <div className="d-flex justify-content-center mt-3" >
                            <Button onClick={handleClickService} variant="success" >Continue</Button>
                        </div>
                    </Row>
                    {/* <Row>
                        <Col md={6} xs={6}>
                            <div className='whycardNew' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='imgwhyNew'>
                                    <HiShieldCheck color="#fff" className='imgchooseNew' />
                                </div>
                                <div style={{ textAling: 'left' }}>
                                    <h4 className='txtchooseNew'>100% Safe and Secure</h4>
                                </div>
                            </div>
                        </Col>
                        <Col md={6} xs={6}>
                            <div className='whycardNew' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='imgwhyNew'>
                                    <FaMapMarkedAlt color="#fff" className='imgchooseNew' />
                                </div>
                                <div style={{ textAling: 'left' }}>
                                    <h4 className='txtchooseNew'>Real Time Tracking</h4>
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                </Container>
            </Container>
            <Footer />
        </>
    )
}

export default FlowChart1