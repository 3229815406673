import React from "react";
import { Box } from "@mui/material";
import HomePageContainer from "../shared/HomePageContainer";
import Heading from "../shared/Heading";
import WorkCards from "./WorkCards";

const HowWeWork = () => {
  return (
    <HomePageContainer sx={{ margin: { xs: "0 0.5rem", laptop: 0 } }}>
      <Heading text={"How we work"} />
      <WorkCards />
    </HomePageContainer>
  );
};

export default HowWeWork;
