import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Row, Table, Spinner, Image, Dropdown, Modal } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'

import '../../ComponentsCss/AdminCss/AddProduct.css'
import { MUTATION_CREATE_BUY_BRAND } from '../../graphql/Mutations'
import { MUTATION_DELETE_BUY_BRAND } from '../../graphql/Mutations'
import { MUTATION_UPDATE_BRAND } from '../../graphql/Mutations'
import { MUTATION_UPDATE_BRAND_IMAGE } from '../../graphql/Mutations'
import { QUERY_GET_BUY_ALL_BRANDS } from '../../graphql/Query'
import { FaPencilAlt } from "react-icons/fa";

import AWS from "aws-sdk";
import uniqid from "uniqid";
import swal from 'sweetalert';

const ID = "AKIA6GB4RFKTJXDPLPOH";
const SECRET = "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";
const BUCKET_NAME = "sanjaytestbucket";

const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET,
});

export default function ExploreByBrand() {
    let count = 1
    const [validated, setValidated] = useState(false);

    const { data: getAllBuyBrands, loading: allBrandLoading } = useQuery(QUERY_GET_BUY_ALL_BRANDS);
    const [brandName, setBrandName] = useState("")
    const [image, setImage] = useState("");
    const [brandCategory, setBrandCategory] = useState("")
    const [selectedBrand, setSelectedBrand] = useState("")
    const [brandNameEdit, setBrandNameEdit] = useState("")
    const [brandCategoryEdit, setBrandCategoryEdit] = useState("")
    const [imageEdit, setImageEdit] = useState("");

    const [smShow, setSmShow] = useState(false);
    const [brandId, setBrandId] = useState("")
    const [deleteBuyBrandById, { loading: dltLoading }] = useMutation(MUTATION_DELETE_BUY_BRAND, {
        refetchQueries: [
            QUERY_GET_BUY_ALL_BRANDS
        ]
    })
    const [createBuyBrand, { loading: sellLoading }] = useMutation(MUTATION_CREATE_BUY_BRAND, {
        refetchQueries: [
            QUERY_GET_BUY_ALL_BRANDS
        ]
    })
    const [updateBrandById] = useMutation(MUTATION_UPDATE_BRAND, {
        refetchQueries: [
            QUERY_GET_BUY_ALL_BRANDS
        ]
    })
    const [updateBrandImage] = useMutation(MUTATION_UPDATE_BRAND_IMAGE, {
        refetchQueries: [
            QUERY_GET_BUY_ALL_BRANDS
        ]
    })

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        } else {
            setValidated(false);
            event.preventDefault();
            try {
                const uniqueId = uniqid();
                var regex = new RegExp('[^.]+$');
                var extension = image.name.match(regex);
                var uniqueFileName = 'hellofi-img' + uniqueId + '.' + extension[0];
                const params = {
                    Bucket: BUCKET_NAME,
                    Key: uniqueFileName,
                    Body: image,
                };
                s3.upload(params, function (err, data) {
                    if (err) {
                        throw err;
                    } else {
                        createBuyBrand({
                            variables: {
                                "buyBrandInput": {
                                    "brandName": `${brandName}`,
                                    "category": `${brandCategory}`,
                                    "brandImage": `${uniqueFileName}`
                                }
                            }
                        })
                        swal({
                            title: "Success",
                            text: "Brand Add successfully",
                            icon: "success",
                        })

                        setImage('')
                        setBrandCategory('')
                        setBrandName('')
                    }

                });
            } catch (err) {
                console.log(err);
            }
        }


    };
    const showDelModal = (id) => {
        setSmShow(true)
        setBrandId(id)

    }
    const handleDeleteBrand = () => {

        deleteBuyBrandById({
            variables: {
                "brandId": `${brandId}`
            }
        })
        swal({
            title: "Deleted",
            text: "Brand Delete successfully",
            icon: "success",
        })
        setSmShow(false)
    }
    const [editModal, setEditModal] = useState(false)
    const handleclose = () => {
        setEditModal(false)
        setBrandCategoryEdit("")
    }
    const showEditModal = (selectedbrand) => {
        setEditModal(true)
        setSelectedBrand(selectedbrand)
        setBrandNameEdit(selectedbrand.brandName)
        setBrandCategoryEdit(selectedbrand.category)
    }
    const handleEdit = () => {
        updateBrandById({

            variables: {
                "brandId": `${selectedBrand.id}`,
                "brandName": `${brandNameEdit}`,
                "brandCategory": `${brandCategoryEdit}`,
            }
        }).then((data) => {
            setEditModal(false)
        }).catch((e) => console.log(e))
    }
    const [imageModal, setImageModal] = useState(false);
    const handleCloseImageModal = () => {
        setImageModal(false)
    }
    const handleChangeImage = () => {

        try {
            const uniqueId = uniqid();
            var regex = new RegExp('[^.]+$');
            var extension = imageEdit.name.match(regex);
            var uniqueFileName = 'hellofi-buybrand-img' + uniqueId + '.' + extension[0];
            const params = {
                Bucket: BUCKET_NAME,
                Key: uniqueFileName,
                Body: imageEdit,
            };
            s3.upload(params, function (err, data) {
                if (err) {
                    throw err;
                } else {
                    swal({
                        title: 'Successfull!!!',
                        text: ' Uploaded Successfully',
                        icon: 'success',
                    });

                }

            });
        } catch (err) {
            swal({
                title: 'Error!!!',
                text: 'Error please try again',
                icon: 'error',
            });
            console.log(err);
        }
        updateBrandImage({
            variables: {
                "brandId": `${brandId}`,
                "brandImage": `${uniqueFileName}`
            }
        }).then(() => {
            setImageModal(false)
        }).catch((e) => console.log(e))


    }
    const handleImageEdit = (id) => {
        setBrandId(id)
        setImageModal(true)
    }
    return (
        <>
            <Container style={{ padding: 20 }} fluid>
                <Row>
                    <Col md={4}>
                        <div style={{ padding: "1rem" }} className='box-shadow'>
                            <h3 style={{ textAlign: 'center' }}>Add Brand Here</h3>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Control
                                            required
                                            type="text"
                                            className='txtinpt'
                                            placeholder="Brand Name "
                                            onChange={(e) => setBrandName(e.target.value)}
                                            value={brandName}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Dropdown style={{ marginTop: "1rem" }}>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" > Category</Dropdown.Toggle>
                                            <span>{brandCategory}</span>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={(e) => setBrandCategory("Phones")}>Phones</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => setBrandCategory("Laptops")}>Laptops</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => setBrandCategory("Tablets")}>Tablets</Dropdown.Item>
                                                {/* <Dropdown.Item onClick={(e) => setBrandCategory("Smart Watches")}>Smart Watches</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => setBrandCategory("Desktop/Imac")}>Desktop/Imac</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => setBrandCategory("Other Accessories")}>Other Accessories</Dropdown.Item> */}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Form.Control
                                            required
                                            type="file"
                                            className='txtinpt'
                                            style={{ marginTop: 20 }}
                                            placeholder="Image"
                                            onChange={(e) => setImage(e.target.files[0])}
                                        />
                                    </Form.Group>

                                </Row>
                                <Button type="submit" className='mx-auto d-block'>Add Brand</Button>
                            </Form>
                        </div>
                    </Col>



                    <Col>
                        <Table responsive striped bordered hover>
                            <thead className='table-head'>
                                <tr>
                                    <th>s.no.</th>
                                    <th>Brand Name</th>
                                    <th>Brand Category</th>
                                    <th>Brand Image</th>
                                    <th>Remove Brand</th>
                                    {/* <th>Edit Brand</th> */}

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allBrandLoading ?
                                        <Spinner animation="border" variant="success" className='mx-auto d-block' />

                                        :
                                        getAllBuyBrands && getAllBuyBrands.getAllBuyBrands.slice(0).reverse().map(data =>
                                            <tr className='table-data'>
                                                <td>
                                                    {count++}
                                                </td>
                                                <td>
                                                    {data.brandName}
                                                </td>
                                                <td>
                                                    {data.category}
                                                </td>
                                                <td>
                                                    <Image fluid src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${data.brandImage}`} style={{ height: "80%", width: 80 }}></Image>
                                                    {/* <FaPencilAlt className="edit-icon" onClick={() => { handleImageEdit(data.id) }} /> */}

                                                </td>
                                                <td><Button variant='danger' className='mx-auto d-block' onClick={() => showDelModal(data.id)}>-</Button></td>
                                                {/* <td><Button variant='success' className='mx-auto d-block' onClick={() => showEditModal(data)}>Edit</Button></td> */}
                                            </tr>

                                        )

                                }
                            </tbody>

                        </Table>
                    </Col>
                </Row >

                <Modal
                    size="sm"
                    show={smShow}
                    onHide={() => setSmShow(false)}
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Body style={{ textAlign: 'center' }}><h5>Are you sure you want to delete this Brand ?</h5>
                        {
                            dltLoading ?
                                <Spinner animation="border" variant="danger" />
                                :
                                <Button onClick={() => handleDeleteBrand()} style={{ backgroundColor: '#40a737', border: 'none', width: '100%' }}>Yes</Button>

                        }
                    </Modal.Body>
                </Modal>

                <Modal show={editModal} onHide={handleclose} >
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Brand</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <Form.Control
                                required
                                type="text"
                                value={brandNameEdit}
                                className='txtinpt'
                                onChange={(e) => setBrandNameEdit(e.target.value)}
                            />
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="12" controlId="validationCustom02">

                            <Dropdown style={{ marginTop: "1rem" }}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    Category
                                </Dropdown.Toggle>
                                <span> {brandCategoryEdit} </span>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={(e) => setBrandCategoryEdit("Phones")}>Phones </Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => setBrandCategoryEdit("Laptops")}>Laptops </Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => setBrandCategoryEdit("Tablets")}>Tablets </Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => setBrandCategoryEdit("Smart Watches")}> Smart Watches </Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => setBrandCategoryEdit("Desktop/Imac")}> Desktop/Imac </Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => setBrandCategoryEdit("Other Accessories")}> Other Accessories </Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown>
                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleclose} >
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleEdit}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={imageModal} onHide={handleCloseImageModal}>
                    <Modal.Header closeButton>
                        <Modal.Title> Choose Image </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group as={Col} md="12" controlId="validationCustom02">
                            <Form.Control
                                required
                                type="file"
                                className='txtinpt'
                                style={{ marginTop: 20 }}
                                placeholder="Image"
                                onChange={(e) => setImageEdit(e.target.files[0])}

                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="primary" onClick={() => handleChangeImage()}>
                            Change Image
                        </Button>
                        <Button variant="secondary" onClick={handleCloseImageModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container >
        </>
    )
}
