import { QUERY_GET_SMARTWATCH_QUESTION } from '../../graphql/Query'
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Table, Spinner, Modal, Button, Form } from 'react-bootstrap'
import { FaPen } from 'react-icons/fa'

import { MUTATION_UPDATE_SMARTWATCH_QUESTION } from '../../graphql/Mutations'





const AddWatchesQuestionariesPer = () => {

    const [updateWatchQuestions] = useMutation(MUTATION_UPDATE_SMARTWATCH_QUESTION, {
        refetchQueries: [
            QUERY_GET_SMARTWATCH_QUESTION
        ]
    })
    

    const { data: getSmartWatchQuestion, loading: questionLoading } = useQuery(QUERY_GET_SMARTWATCH_QUESTION);
    // const [editQuestion, setEditQuestion] = useState("");
    const [editPercentage, setEditPercentage] = useState("");
    const [questionNameState, setQuestionNameState] = useState("");


    const [showEditModal, setEditModal] = useState(false)

    const handleClickEdit = (que) => {
        setEditModal(true)
    }
    const handleEdit1 = () => {

        
         if (questionNameState === "ConditionDeviceScreenPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ConditionDeviceBodyPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveBoxWithIMEIPer") {
            
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveDataCablePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveAdapterPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveEarphonesPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "AdditionalInsurancePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "PurchasedPlacePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "WarrantyPeriodPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveBoxWithIMEIPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
       
        else if (questionNameState === "deviceScreenOriginPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenOriginalChangedPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenOriginPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceRepairAuthCenterPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceRepairUnAuthCenterPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceRepairNoPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceConditionMinorPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceConditionMajorPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceConditionNoScratchPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceBodyConditionExcellentPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceBodyConditionGoodPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceBodyConditionAveragePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceBodyConditionPoorPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "addInsAppleCarePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "addInsSamsungCarePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "addInsThirdPartyPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "addInsNotApplicablePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "purchasePlaceIndiaPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "purchasePlaceAbroadPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billBelowOneMonthPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billOneToThreeMonthPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billThreeToSixMonthPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billSixToNineMonthPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billNineToTwelveMonthPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billAboveOneYear") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenChangesPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "isDeviceSwitchPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "issueWithDeviceDisplayPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "SpeakerIssuePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "MicIssuePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "SensorIssuePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "BatteryIssuePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ButtonIssuePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "WifiIssuePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "BluetoothIssuePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "AutoRestartIssuePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "GpsIssuePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HavePowerCablePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveStrapPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
  
        else if (questionNameState === "MagneticCharginPortPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
  
        else if (questionNameState === "DigitalCrownIssuePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "CellularConIssue") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }else if (questionNameState === "HaveBillPer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        
        }else if (questionNameState === "FaceSensorIssuePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        
        }else if (questionNameState === "FingerPrintIssuePer") {
            updateWatchQuestions({
                variables: {
                    id: `${getSmartWatchQuestion?.getSmartWatchQuestion[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
  


    }

    function setQuestionName(qName) {
        setQuestionNameState(qName)
        handleClickEdit()
    }


    return (
        <>
            {
                questionLoading ?
                    <Spinner animation="border" variant="success" className='mx-auto d-block' />
                    :

                    <>
                        <Table responsive striped bordered hover>
                            <thead className='table-head table-head-question' >
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>Tell us about the Device Condition</th>
                                </tr>
                                <tr>
                                    <th>Questions</th>
                                    <th>Percentage deduction</th>
                                    <th>Edit Percentage</th>
                                </tr>
                                <tr>
                                    <td>Device Switching</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.isDeviceSwitchPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("isDeviceSwitchPer")}><FaPen /></i></td>
                                </tr>
                             
                                <tr>
                                    <td>Device Display Issue </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.issueWithDeviceDisplayPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("issueWithDeviceDisplayPer")}><FaPen /></i> </td>
                                </tr>
                                <tr>
                                    <td>Device Screen Isuue (original) </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.deviceScreenOriginPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceScreenOriginPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Screen Changed (Compatible) </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.deviceScreenChangesPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceScreenChangesPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Screen Changed with Original Bill </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.deviceScreenOriginalChangedPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceScreenOriginalChangedPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Repaired in Authorised Service Center</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.deviceRepairAuthCenterPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceRepairAuthCenterPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Repaired in UnAuthorised Service Center</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.deviceRepairUnAuthCenterPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceRepairUnAuthCenterPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> No Device Repaired </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.deviceRepairNoPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceRepairNoPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>Want to know the exact value of your device?</th>
                                </tr>

                              
                                <tr>
                                    <td>Speaker Issue</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.SpeakerIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("SpeakerIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Mic Issue</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.MicIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("MicIssuePer")}><FaPen /></i></td>
                                </tr>
                               <tr>
                                    <td>Senser Issue</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.SensorIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("SensorIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Battery Issue</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.BatteryIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("BatteryIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Button Issue</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.ButtonIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ButtonIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Wiffi Issue</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.WifiIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("WifiIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Bluetooth Isuue</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.BluetoothIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("BluetoothIssuePer")}><FaPen /></i></td>
                                </tr>
                                
                                <tr>
                                    <td>Auto Restart Issue</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.AutoRestartIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("AutoRestartIssuePer")}><FaPen /></i></td>
                                </tr>
                            
                                <tr>
                                    <td>GPS Isuue</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.GpsIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("GpsIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Magnetic Charging port issue </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.MagneticCharginPortPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("MagneticCharginPortPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Digital Crown issue  </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.DigitalCrownIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("DigitalCrownIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Cellular connectivity issue  </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.CellularConIssue}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("CellularConIssue")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Finger Print Issue</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.FingerPrintIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("FingerPrintIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Face Sensor Issue</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.FaceSensorIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("FaceSensorIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>Tell us about the cosmetic/physical condition of your device</th>
                                </tr>
                                <tr>
                                    <td>Condition of Device Screen (Minor)</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.deviceConditionMinorPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceConditionMinorPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Screen (Major)</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.deviceConditionMajorPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceConditionMajorPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Screen (No Scratches)</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.deviceConditionNoScratchPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceConditionNoScratchPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Body (Excellent)</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.deviceBodyConditionExcellentPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceBodyConditionExcellentPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Body (Good)</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.deviceBodyConditionGoodPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceBodyConditionGoodPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Body (Average)</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.deviceBodyConditionAveragePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceBodyConditionAveragePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Body (Poor)</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.deviceBodyConditionPoorPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceBodyConditionPoorPer")}><FaPen /></i></td>
                                </tr>

                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>  Select the Original Accessories available with your Device.</th>
                                </tr>
                                <tr>
                                    <td>   Box (with same IMEI/Serial number)</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.HaveBoxWithIMEIPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveBoxWithIMEIPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Power cable</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.HavePowerCablePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HavePowerCablePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Strap</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.HaveStrapPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveStrapPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Have Bill </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.HaveBillPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveBillPer")}><FaPen /></i></td>
                                </tr>

                                <tr>
                                    <td> Insurance AppleCare </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.addInsAppleCarePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("addInsAppleCarePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Insurance SamsungCare </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.addInsSamsungCarePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("addInsSamsungCarePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Insurance 3rd Prty </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.addInsThirdPartyPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("addInsThirdPartyPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Insurance Not Applicable </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.addInsNotApplicablePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("addInsNotApplicablePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Purchased Place (India) </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.purchasePlaceIndiaPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("purchasePlaceIndiaPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Purchased Place (Abroad) </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.purchasePlaceAbroadPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("purchasePlaceAbroadPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (below 1 month) </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.billBelowOneMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billBelowOneMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (1 to 3 month) </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.billOneToThreeMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billOneToThreeMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (3 to 6 month)</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.billThreeToSixMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billThreeToSixMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (6 to 9 month)</td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.billSixToNineMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billSixToNineMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (9to 12 month) </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.billNineToTwelveMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billNineToTwelveMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period ( Above 1 year) </td>
                                    <td>{getSmartWatchQuestion?.getSmartWatchQuestion[0]?.billAboveOneYear}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billAboveOneYear")}><FaPen /></i></td>
                                </tr>
                              
                          
                            </thead>
                        </Table>
                    </>
            }
            {

            }
            <Modal show={showEditModal} onHide={() => setEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Percentage</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="number" placeholder="Enter value" onChange={(e) => setEditPercentage(e.target.value)} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => { handleEdit1(); }}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default AddWatchesQuestionariesPer