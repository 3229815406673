import { Box, Button } from "@mui/material";
import React from "react";
import CustomTypography from "../../muicomponents/shared/CustomTypography";
import { useNavigate } from "react-router-dom";

const ProfileCustomButton = ({ text, sx, buttonSx, link }) => {
  const naviagte = useNavigate();
  return (
    <Box sx={{ ...sx }}>
      <Button
        variant="contained"
        size="large"
        onClick={() => naviagte(link)}
        sx={{
          ...buttonSx,
          width: "100%",
          backgroundColor: "#F7EEEE",
          color: "black",
          textTransform: "capitalize",
          "&:hover": {
            backgroundColor: "#F7EEEE",
            border: "2px solid rgb(6, 155, 170)",
          },
        }}
      >
        <CustomTypography variant="subtitle1" sx={{ fontWeight: "500" }}>
          {text}
        </CustomTypography>
      </Button>
    </Box>
  );
};

export default ProfileCustomButton;
