import React from "react";
import { MUTATION_UNSELECT_OUT_OF_STOCK_ITEMS } from "../../../../graphql/Mutations";
import { useSetRecoilState } from "recoil";
import {
  cartItems,
  cartPageBackdrop,
} from "../../../../store/atoms/addToCart/addToCart";
import useSnackbarNotifier from "../../../shared/hooks/useSnackbarNotifier";
import { useMutation } from "@apollo/client";

const useDeselectOutOfStock = () => {
  const [unselectCartItems, { loading, error }] = useMutation(
    MUTATION_UNSELECT_OUT_OF_STOCK_ITEMS
  );
  const setCartItems = useSetRecoilState(cartItems);
  const showSnackbar = useSnackbarNotifier();
  const userId = localStorage.getItem("userId");
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);

  const deSelectOutOfStockItems = async () => {
    try {
      setBackdropOpen(true);
      const { data } = await unselectCartItems({
        variables: { userId: userId },
      });

      if (data.unselectCartItems) {
        const {
          updatedOutOfStockItems,
          totalAmount,
          totalMRP,
          discountOnMRP,
          couponDiscount,
          coupon,
          count,
          message,
        } = data.unselectCartItems;
        setCartItems((prevState) => {
          const updatedItems = prevState.items.map((item) => {
            if (updatedOutOfStockItems.includes(item.id)) {
              return {
                ...item,
                isCheckedForCheckout: false,
              };
            }
            return item;
          });

          return {
            ...prevState,
            items: updatedItems,
            totalAmount,
            totalMRP,
            discountOnMRP,
            couponDiscount,
            coupon,
            count,
          };
        });

        setBackdropOpen(false);
        showSnackbar(message, "success");
      }
    } catch (error) {
      console.error("Error updating cart item checkout:", error);
      setBackdropOpen(false);
    }
  };
  return { deSelectOutOfStockItems };
};

export default useDeselectOutOfStock;
