import { Box, Grid, Table, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import SellingCard from "./SellingCard";
import Laptops from "../../../../../Images/home/sellingaccesories/Laptops.png";
import Earbuds from "../../../../../Images/home/sellingaccesories/Earbuds.png";
import Headphones from "../../../../../Images/home/sellingaccesories/headphones.png";
import Imac from "../../../../../Images/home/sellingaccesories/Imac.png";
import Phones from "../../../../../Images/home/sellingaccesories/Phones.png";
import Smart_Watches from "../../../../../Images/home/sellingaccesories/Smart_Watches.png";
import Tablets from "../../../../../Images/home/sellingaccesories/Tablets.png";

const SellingServices = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box sx={{ flexGrow: "1" }}>
      <Grid
        container
        spacing={isMobileScreen ? 0.5 : 2}
        sx={{ justifyContent: "center" }}
      >
        <Grid item xs={4} tabletMedium={3} desktop={2}>
          <SellingCard title="Laptops" img={Laptops} />
        </Grid>
        <Grid item xs={4} tabletMedium={3} desktop={2}>
          <SellingCard title="Phones" img={Phones} />
        </Grid>
        <Grid item xs={4} tabletMedium={3} desktop={2}>
          <SellingCard title={"Tablets"} img={Tablets} />
        </Grid>
        <Grid item xs={4} tabletMedium={3} desktop={2}>
          <SellingCard title={"Smart watches"} img={Smart_Watches} />
        </Grid>
        <Grid item xs={4} tabletMedium={3} desktop={2}>
          <SellingCard title={"IMacs"} img={Imac} />
        </Grid>
        <Grid item xs={4} tabletMedium={3} desktop={2}>
          <SellingCard
            title={"Earbuds Headphones"}
            img={Earbuds}
            img2={Headphones}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SellingServices;
