import React, { useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Navigation from '../Navigation';
import Footer from '../Footer';
import faq1 from '../../Images/faq1.jpg';
import faq2 from '../../Images/faq2.jpg';
const FAQs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });
    return (
        <>
            <Navigation />
            <div className="term-container">
                <h2>FAQ’s</h2>
                <Accordion defaultActiveKey="0">
                    {/* General Queries */}
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>GENERAL QUERIES</Accordion.Header>
                        <Accordion.Body>
                            <p><strong>1. Is HelloFi a genuine website? Can I trust it?</strong><br />
                                We are a registered company and all the products that are available on the HelloFi website for sale are after quality inspections by advanced technological means. People trust HelloFi for its one-step solution, its safety, hassle-free dealing, and 24-hour support system.</p>

                            <p><strong>2. Is my product safe when I send it to HelloFi? Is there any guarantee I can get from HelloFi?</strong><br />
                                Your product is 100 % safe when in the care of HelloFi and its delivery partner, We have a wide range of happy customers and you can go through the feedback (details on the guarantee provided by the company).</p>

                            <p><strong>3. Where is HelloFi located?</strong><br />
                                The company has offices at various locations and the head office is located in Bangalore at #28, 1st Main, 6th Cross Road, 1st Block Koramangala Bangalore, Karnataka, India 560034.</p>

                            <p><strong>4. What is the customer care number of HelloFi?</strong><br />
                                You can reach us at +91 8150835583 or mail us on contact@hellofi.in.</p>

                            <p><strong>5. What is the exchange program? How can I exchange my device?</strong><br />
                                For customers located in Bangalore you can visit the HelloFi facility in Bangalore and get the product exchanged for its true value on the spot by paying the differential amount if any the detailed process is there on the exchange page of the site. For customers located outside Bangalore, send your old phone /device to us and Wishlist the new phone /device. After inspection, we will quote the price and you have to pay only the differential amount. Receive the new phone /device within one week. Detailed steps on the exchange page of the website.</p>

                            <p><strong>6. What will HelloFi do with my device?</strong><br />
                                Hellofi is a recommerce platform, we get your preloved devices and after a thorough inspection we sell the product to people who find it a fit for them.</p>

                            <p><strong>7. Are the products from HelloFi good?</strong><br />
                                All the products listed on the website have gone through a thorough inspection and industry standard technical tests.</p>

                            <p><strong>8. Does HelloFi offer service outside India?</strong><br />
                                Currently, we only service Indian pin codes.</p>
                        </Accordion.Body>
                    </Accordion.Item>

                    {/* Sell Order */}
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>SELL ORDER</Accordion.Header>
                        <Accordion.Body>
                            <p><strong>1. How do I know if I am eligible for spot cash payment? Where does HelloFi offer spot cash payment?</strong><br />
                                To know if you are eligible for spot cash payment, enter your Pincode and the website will automatically display if your location is eligible for spot cash payment. Please refer to the T&C for further spot cash-related details.</p>

                            <p><strong>2. What to do if my location is not available for spot cash payment by HelloFi?</strong><br />
                                For locations where spot cash service is not offered by HelloFi, follow these steps: A) Pack your products and hand over the package to the assigned Courier Partner. B) After your product reaches the HelloFi facility, it will go through an inspection based on the details filled in by you. C) Post verification, the amount will be credited to your bank account through your preferred payment mode on the same day or within 2 working days. For further details, please refer to the T&C.</p>

                            <p><strong>3. My item got damaged in transit, what to do now?</strong><br />
                                For details on damage in transit, please go through Appendix G of the T&C of the service.</p>

                            <p><strong>4. Can I sell a product to HelloFi that is not listed on the website?</strong><br />
                                Yes, if the product you want to sell is not listed with us, you can still request a quote by filling out the form available on the site after clicking 'Sell Now' on our service page.</p>

                            <p><strong>5. What devices does HelloFi accept?</strong><br />
                                We deal in electronic gadgets like laptops, mobile phones, tablets, smartwatches, desktops, and other electronic accessories.</p>

                            <p><strong>6. How to sell my old phone/device to HelloFi?</strong><br />
                                To sell your phone or any other electronics: A) Select the phone. B) You will get an instant quote. C) Schedule pickup with us and get paid instantly depending on the location. Please refer to T&C with regards to details on selling.</p>

                            <p><strong>7. Before selling my device to HelloFi, what shall I do?</strong><br />
                                Before handing over your product to the HelloFi delivery associate, delete any existing and past data on the phone and remove the SIM cards and Accounts.</p>

                            <p><strong>8. Can I sell corporate devices on HelloFi?</strong><br />
                                Yes, we deal in Pre Owned Corporate Devices. You can fill out the form available on the home page under the 'Buy/Sell as Corporate' section and request a quotation. We will contact you.</p>

                            <p><strong>9. Can the quoted price of the product be changed? What to do if I don't agree with the revised price of the product?</strong><br />
                                The prices quoted by HelloFi are subject to change under different circumstances, and a revised price for the product will be offered under such circumstances. For details, please read through the T&C appendix (6) point (D). In case you don’t agree with the price, we will return the product back to you for an additional shipping cost (if your location does not support our Instant cash policy).</p>

                            <p><strong>10. How much time will it take to complete the transaction if my location is not for spot payment?</strong><br />
                                The company has a Same day to 2 business day transaction completion policy under general circumstances. For other unforeseen circumstances like force majeure and issues in the condition of the product, please refer to the <a href='terms-condition'>T&C</a> .</p>
                        </Accordion.Body>
                    </Accordion.Item>
                    {/* Buy Order */}
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>BUY ORDER</Accordion.Header>
                        <Accordion.Body>
                            <p><strong>1. Can I check the product before buying from HelloFi?</strong><br />
                                We have a “Real Time Video” option available for the products, you can see the same on the website itself when you get to the specific product page. You can also video call us by clicking the 'Ask on Video Call’ option within working hours.</p>

                            <p><strong>2. How to know the condition of the product before buying? Will I get any e-accessories with it?</strong><br />
                                The condition of the product will be available in the details and specification section of the product on the web page. Information regarding whether your device has any e-accessories will be provided in the description of the product.
                                <br />
                                <img className='w-50' src={faq1} alt="FAQs_1" />
                                <br />
                                <p className='pt-2'>Click on the desired Condition to get a pop up box explaining you the respective definition.</p>                                <br />
                                <img className='w-50' src={faq2} alt="FAQs_2" />
                            </p>

                            <p><strong>3. How to know the device is under warranty? How can I claim the warranty?</strong><br />
                                The availability of the Warranty will be available in the details and Product Description section of the product on the web page. All details related to the warranty will be available on the warranty policy section of the website, to claim the warranty you have to follow the instructions, or by simply visiting the brand outlet in India if under brand warranty. Refer to the warranty policy for warranty by HelloFi.</p>

                            <p><strong>4. What to do if the product I received from HelloFi is damaged?</strong><br />
                                The return and replacement policy for all products will be available in the detailed section of the specific product on the web page and also under Return Policy on the Home page. However, in case you received a damaged/defective item that does not match the description, we should be notified within 12 hours of delivery. Also, we request you to email us the unboxing video of the said product from your registered contact details. Any third-party raising return/exchange request will be discarded.</p>

                            <p><strong>5. Can I place a bulk order with HelloFi for my office?</strong><br />
                                Yes, HelloFi accepts bulk orders for office needs. You can fill out the form available under the section of 'buy/sell as corporate' and request a quotation. We will contact you.</p>

                            <p><strong>6. My device is under warranty but I don't have the original bill. What to do?</strong><br />
                                You can still ask us for a quotation, in the absence of original documents either in the form of soft/hard copy, the authenticity of the product(s) will be checked by us and in that case, we will take all sorts of confirmation from you to validate the purchase.</p>

                            <p><strong>7. Does HelloFi have (COD) cash on delivery?</strong><br />
                                Yes, cash on delivery option is available for all orders below INR 20000 on serviceable PIN codes.</p>

                            <p><strong>8. Is there any additional return or exchange charges?</strong><br />
                                HelloFi does not charge you anything in access to delivery charges for return and exchange. If you wish to exchange with an item of higher value then only the differential price will be charged.</p>

                            <p><strong>9. When will my HelloFi delivery come?</strong><br />
                                We try to deliver within 7 working days under normal circumstances, however, the delivery date will be different for some Pincodes and bulk/corporate orders. Information regarding it will be communicated to you via email address or any other preferred medium of communication by you.</p>

                            <p><strong>10. How much are the shipping charges?</strong><br />
                                No Shipping Charges. May be applied for bulk orders.</p>

                            <p><strong>11. I want to cancel my order. What should I do?</strong><br />
                                You can cancel your order any time before dispatch by reaching us on 8150835583 or email us at contact@hellofi.in</p>

                            <p><strong>12. How can I return or exchange my HelloFi order?</strong><br />
                                You have to return the product to the courier partner in the way the package was handed to you i.e., with the package and all the additional documents and accessories that came along with the product. The return amount will be initiated within 5 days of confirmation. The return/exchange will only be accepted after proper validation.</p>

                            <p><strong>13. I received an item different from what I ordered. What to do?</strong><br />
                                The chances of you receiving the wrong product are very slim, however, if it happens you have to reach us out either through mail or call from the registered mail id or Contact number. We will validate it first.</p>

                            <p><strong>14. How long will my replacement order take?</strong><br />
                                You will receive order within 7 -10 working days of verification after the collection of the previous product.</p>

                            <p><strong>15. How long is the return and exchange policy of HelloFi?</strong><br />
                                We have a 7-day return policy. You can raise a return/exchange request anytime within 7 days of receiving the product(s).</p>

                            <p><strong>16. Can I return multiple products?</strong><br />
                                Yes, you can return more than one product. However, a minimum handling charge of INR 100 will be applicable. We will first validate the reason behind returning before accepting the return request.</p>

                            <p><strong>17. How long will a refund take?</strong><br />
                                The refund will be initiated within 5 working days from the date of confirmation of the product.</p>

                            <p><strong>18. What is the refund process for prepaid orders?</strong><br />
                                The refund process will be the same if you choose to return the order after delivery. In case of cancellation of a prepaid order, a return will be initiated 5 working days from the date of cancellation. If any transaction, be it a refund or while accepting online payments involves any sort of Bank Commission, the buyer will be charged the same in addition to the normal cost.</p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <Footer />
        </>
    );
};

export default FAQs;
