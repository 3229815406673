import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import BrowGradient from "../shared/BrowGradient";
import { ArrowForward } from "@mui/icons-material";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const CorporateVendorCard = ({ img, title }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("tabletMedium"));
  return (
    <BrowGradient
      sx={{
        maxHeight: "250px",
        height: { xs: "175px", tabletMedium: "175px", desktop: "190px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: isMobileScreen ? "0" : "1rem",
          height: "100%",
        }}
      >
        <Box
          flexBasis={isMobileScreen ? "50%" : "60%"}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            zIndex: 1,
          }}
        >
          <Box>
            <CustomTypography
              variant={"subtitle1"}
              sx={{
                color: "#285B84",
                fontSize: {
                  xs: "1rem",
                  desktop: "1.25rem",
                },
              }}
              fontWeight={"600"}
            >
              Sell / Buy as{" "}
            </CustomTypography>
          </Box>
          <Box>
            <CustomTypography
              variant={"subtitle1"}
              sx={{
                color: "#285B84",
                fontSize: {
                  mobile: "1rem",
                  desktop: "1.25rem",
                },
              }}
              fontWeight={"600"}
            >
              {title}
            </CustomTypography>
          </Box>
          <Box>
            <Button
              variant="contained"
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#F1E8E8",
                textTransform: "none",
                color: "black",
                marginTop: "2rem",
                padding: "0.2rem 0.75rem",
                borderRadius: "0.5rem",
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: "#F1E8E8",
                },
              }}
            >
              <CustomTypography
                variant={"subtitle2"}
                sx={{
                  fontWeight: "600",
                  fontSize: { xs: "0.80rem", tablet: "0.875rem" },
                  color: "#285B84",
                }}
              >
                {"Sell it now"}
                <ArrowForward
                  sx={{ fontSize: "1.25rem", marginLeft: "1rem" }}
                />
              </CustomTypography>
            </Button>
          </Box>
        </Box>
        <Box
          flexBasis={isMobileScreen ? "50%" : "40%"}
          sx={{
            position: "relative",
            height: "100%",
            borderRadius: "0.5rem",
            zIndex: 2,
          }}
        >
          <img
            src={img}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        </Box>
      </Box>
    </BrowGradient>
  );
};

export default CorporateVendorCard;
