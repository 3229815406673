

const SellOtherAccQuestionaries=()=>{


    return (
        <>
         need to work 
        </>
    )
}
export default SellOtherAccQuestionaries 