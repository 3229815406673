import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import Address from "../components/address/Address";
import ProfileLayout from "../../../layout/ProfileLayout";

const AddressPage = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <Box flexBasis={isSmallScreen ? "100%" : "70%"} sx={{ minWidth: "300px" }}>
      <Address />
    </Box>
  );
};

export default AddressPage;
