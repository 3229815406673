import { Modal, Box, Button, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { Close } from "@mui/icons-material";
import Form from "../addressForm/Form";

const AddAddressModal = ({
  modalOpen,
  setModalOpen,
  isEdit = false,
  address = {},
}) => {
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isSmallScreen ? "100%" : "35%",
          minHeight: isSmallScreen ? "100vh" : "50vh",
          maxHeight: isSmallScreen ? "100%" : "90vh",
          backgroundColor: "white",
          borderRadius: isSmallScreen ? "0px" : "8px",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            padding: "20px",
            borderBottom: "1px solid black",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <CustomTypography variant={"subtitle2"}>
            {isEdit ? "Edit Address" : "ADD NEW ADDRESS"}
          </CustomTypography>
          <Close
            sx={{
              cursor: "pointer",
            }}
            onClick={handleModalClose}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form
            isModal={true}
            handleModalClose={handleModalClose}
            isEdit={isEdit}
            address={address}
          />
        </Box>
      </Box>
    </Modal>
  );
};
export default AddAddressModal;
