import { Modal, Card, Button, Col, Row, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import MobileCategory from '../../Images/mobileC.png'
import lap from '../../Images/laptopC.png'
import tab from '../../Images/Downloads/tab.png'
import watch from '../../Images/Downloads/watch.png'
// import desk from '../../Images/Downloads/desk.png'
import desk from '../../Images/Downloads/3.png'
// import other from '../../Images/Downloads/other.png'
import other from '../../Images/Downloads/other.png'
import '../../ComponentsCss/SellModal.css'
import { useState } from 'react';

const CategoryModal = ({ showCategory, setShowCategory }) => {

    const navigate = useNavigate();
    const [categoryState, setCategoryState] = useState("")

    const handleSelectCategory = (cat) => {
        if (cat === "Desktop/Imac") {
            cat = "Desktop-Imac"
        }
        navigate(`/selling/${cat}`, )

        setShowCategory(false)
    }
    const handleOtherCategory = () => {
        navigate('/otheraccessories')
    }
    return (
        <>
            <Modal show={showCategory} onHide={() => setShowCategory(false)} aria-labelledby="example-modal-sizes-title-lg" centered>
                <Modal.Header closeButton style={{ marginTop: '-20px' }}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        <div style={{ display: 'flex', alignItems: 'center' }} >
                            <div style={{ height: 30, width: 5, backgroundColor: '#44CE30', marginTop: 30 }}></div>
                            <Card.Text style={{ marginTop: 30, marginLeft: 10, fontWeight: 500 }}>Choose your preference</Card.Text>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ marginTop: '-30px', marginBottom: '15px' }}>
                    <Row >
                        <Col xs={4} md={4} >
                            <Card className="cardShadow1 categoryModel" style={{ height: '90%' }}  >
                                <Card.Body onClick={() => handleSelectCategory("Phones")}>
                                    <Image src={MobileCategory} className=" mx-auto d-block" fluid />
                                    <Card.Text style={{ textAlign: 'center', fontSize: 10, fontWeight: "bold" }}>Sell Phone</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={4} md={4}  onClick={() => handleSelectCategory("Laptops")} >
                            <Card className="cardShadow2 categoryModel" style={{ height: '90%' }}>
                                <Card.Body>
                                    <Image src={lap} className="modelImgCard mx-auto d-block" fluid />
                                    <Card.Text style={{ textAlign: 'center', fontSize: 10, fontWeight: "bold" }}>Sell Laptop</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={4} md={4} onClick={() => handleSelectCategory("Tablets")} >
                            <Card className="cardShadow3 categoryModel" style={{ height: '90%' }}   >
                                <Card.Body>
                                    <Image src={tab} className="modelImgCard mx-auto d-block" />
                                    <Card.Text style={{ textAlign: 'center', fontSize: 10, fontWeight: "bold" }}>Sell Tablet</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4} md={4}  onClick={() => handleSelectCategory("Smart Watches")}>
                            <Card className="cardShadow4 categoryModel" style={{ height: '90%' }} >
                                <Card.Body>
                                    <Image src={watch} className="modelImgCard mx-auto d-block" />
                                    <Card.Text style={{ textAlign: 'center', fontSize: 10, fontWeight: "bold" }}>Sell Smart Watch</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={4} md={4} onClick={() => handleSelectCategory("Desktop/Imac")} >
                            <Card className="cardShadow5 categoryModel" style={{ height: '90%' }}>
                                <Card.Body>
                                    <Image src={desk} className="modelImgCard mx-auto d-block" />
                                    <Card.Text style={{ textAlign: 'center', fontSize: 10, fontWeight: "bold" }}>Sell Desktop/iMac</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={4} md={4} onClick={() => setCategoryState("Other")}>
                            <Card className="cardShadow6 categoryModel" style={{ height: '90%' }} onClick={() => handleOtherCategory()}>
                                <Card.Body>
                                    <Image src={other} className="modelImgCard mx-auto d-block" />
                                    <Card.Text style={{ textAlign: 'center', fontSize: 10, fontWeight: "bold" }}>Sell Other Accessories</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </Modal.Body>
            </Modal>
        </>
    )
}
export default CategoryModal