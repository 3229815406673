import React, { useState } from 'react'

import { Spinner, Table, Image, Button, Modal, Card, Form, Dropdown } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import swal from 'sweetalert';
import { QUERY_GET_ALL_REQ } from '../../graphql/Query'

const PartnerReq = () => {
    const { data: getFormRequest, loading: reqLoading } = useQuery(QUERY_GET_ALL_REQ);
    let count = 1
    return (
        <>
            <Table responsive striped bordered hover style={{ fontSize: "14px", fontWeight: "bold" }}>
                <thead className='table-head'>
                    <tr>
                        <th>s.no.</th>
                        <th>Category</th>
                        <th>Organization/Shop</th>
                        <th>Contact</th>
                        <th>Email</th>
                        <th>Address</th>
                        <th>Device Detail/Requirement</th>
                       
                    </tr>
                </thead>
                <tbody>

                    {
                        reqLoading ?
                            <Spinner animation="border" variant="success" className='mx-auto d-block' />
                            : getFormRequest && getFormRequest?.getFormRequest.map((req) =>
                                <tr>
                                    <td>
                                        {count++}
                                    </td>
                                    <td>
                                        {req.reqCategory}
                                    </td>
                                    <td>
                                        {req.title}
                                    </td>
                                    <td>
                                        {req.phone}
                                    </td>
                                    <td>
                                        {req.email}
                                    </td>
                                    <td>
                                        {req.address}
                                    </td>
                                    <td>
                                        {req.reqDetails}
                                    </td>
                                </tr>
                            )
                    }
                </tbody>
            </Table>

        </>
    )
}

export default PartnerReq