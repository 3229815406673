import React, { useState } from "react";
import Corporate from "../../../../../Images/home/corporateVendor/Corporate.png";
import Vendor from "../../../../../Images/home/corporateVendor/Vendor.png";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import CorporateVendorCard from "./CorporateVendorCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import ArrowBack from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";

const CorporateVendorCards = () => {
  const theme = useTheme();
  const isTabletMedium = useMediaQuery(theme.breakpoints.down("tabletMedium"));
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const handleSlideChange = (swiper) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  const corporateVendor = [
    { key: 1, title: "Corporate", img: Corporate },
    { key: 2, title: "Vendor", img: Vendor },
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} sx={{ justifyContent: "center" }}>
        {!isTabletMedium ? (
          corporateVendor.map((obj) => (
            <Grid key={obj.key} item xs={12} tabletMedium={6}>
              <CorporateVendorCard img={obj.img} title={obj.title} />
            </Grid>
          ))
        ) : (
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{ flexGrow: "1", width: "100%" }}
              className={"corporate-swiper-wrapper"}
            >
              <Swiper
                modules={[Navigation]}
                navigation={{
                  nextEl: ".corporate-swiper-button-next",
                  prevEl: ".corporate-swiper-button-prev",
                }}
                spaceBetween={10}
                slidesPerView={1}
                onSlideChange={handleSlideChange}
                onInit={handleSlideChange}
                className="corporate-swiper"
                style={{
                  "--swiper-navigation-color": "#000",
                  "--swiper-pagination-color": "#000",
                }}
              >
                {corporateVendor.map((obj) => (
                  <SwiperSlide key={obj.key}>
                    <CorporateVendorCard img={obj.img} title={obj.title} />
                  </SwiperSlide>
                ))}
              </Swiper>
              <Box
                className="swiper-controls"
                sx={{
                  position: "absolute",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  zIndex: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <button
                  style={{ border: "2px solid grey" }}
                  className={`corporate-swiper-button-prev ${
                    isBeginning ? "swiper-button-hidden" : ""
                  }`}
                  aria-label="Previous"
                >
                  <ArrowBackIosNew fontSize="medium" sx={{ color: "grey" }} />
                </button>
                <button
                  className={`corporate-swiper-button-next ${
                    isEnd ? "swiper-button-hidden" : ""
                  }`}
                  aria-label="Next"
                  style={{ border: "2px solid grey" }}
                >
                  <ArrowForwardIos fontSize="medium" sx={{ color: "grey" }} />
                </button>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
      <style jsx>{`
        .corporate-swiper-wrapper {
          position: relative;
          height: 100%;
          width: 100%;
        }

        .corporate-swiper {
          position: relative;
          width: 100%;
        }

        .swiper-controls {
          position: absolute;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          z-index: 10;
          top: 50%;
          transform: translateY(-50%);
        }

        .corporate-swiper-button-prev,
        .corporate-swiper-button-next {
          position: absolute;
          top: 50% !important;
          transform: translateY(-50%) !important;
          background-color: rgba(255, 255, 255, 0.75);
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease;
          z-index: 10;
          cursor: pointer;
        }

        .corporate-swiper-button-prev {
          left: 10px;
        }

        .corporate-swiper-button-next {
          right: 10px;
        }

        .swiper-button-hidden {
          visibility: hidden;
        }

        @media (max-width: 520px) {
          .corporate-swiper-button-prev,
          .corporate-swiper-button-next {
            display: flex !important;
          }
        }
      `}</style>
    </Box>
  );
};

export default CorporateVendorCards;
