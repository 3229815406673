import Footer from "../Footer"
import Navigation from "../Navigation"
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import '../../ComponentsCss/DealerCard.css'
import { HiShieldCheck } from "react-icons/hi";
import { FaMapMarkedAlt, FaShoppingCart } from "react-icons/fa";
import step1 from "../../Images/homepage/workflow/wishlist.png"
import step2 from "../../Images/homepage/workflow/sell.png"
import step3 from "../../Images/homepage/workflow/exchange.png"
import step4 from "../../Images/homepage/workflow/pay.png"
import step5 from "../../Images/homepage/workflow/inspection.png"
import business from "../../Images/homepage/workflow/ex.png"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const FlowChart2 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const navigate = useNavigate();
    const handleClickService = () => {
        navigate("/")
    }
    return (
        <>
            <Navigation />
            <Container className='cardbgservice2 flchart' fluid>
                <Container>

                    <h1 class="choosePrice divider line glow " contenteditable> Within Bangalore </h1>

                    <Row>
                        <Col md={7} >
                            <div style={{ display: "flex", marginTop: 10 }}>
                                <div style={{ marginTop: '-45px' }} className="mbpart1">
                                    <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 1</h1>
                                    <div className='SellBox'>
                                        <Image src={step1} style={{ width: 50, height: 50 }} />
                                    </div>
                                    <div className='lineArrow1'></div>
                                    <div id="dialog2" class="triangle_down1"></div>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <h5 style={{ marginTop: 10, fontSize: 18, fontWeight: 600 }}>You can Wishlist the Product you want to Purchase from our Website (Buy Section)
                                    </h5>
                                    {/* <p style={{ fontSize: 13, fontWeight: 500 }}>Pack your products safely to avoid damage in transit. Use Bubblewraps, thermocols. </p> */}
                                </div>
                            </div>

                            <div style={{ display: "flex", marginTop: 10 }}>
                                <div style={{ marginTop: '-25px' }} >
                                    <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 2</h1>
                                    <div className='SellBox'>
                                        <Image src={step2} style={{ width: 50, height: 50 }} />
                                    </div>
                                    <div className='lineArrow2'></div>
                                    <div id="dialog2" class="triangle_down2"></div>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <h5 style={{ marginTop: 20, fontSize: 18, fontWeight: 600 }}>You can check the price of the device you wish to SELL with us.</h5>
                                    {/* <p style={{ fontSize: 13, fontWeight: 500 }}>Handover the package to the renouned logistics partner assigned for the job. You can track your device with the tracking number provided to you. </p> */}
                                </div>
                            </div>


                            <div style={{ display: "flex", marginTop: 10 }}>
                                <div style={{ marginTop: '-25px' }} className="mbpart4">
                                    <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 3</h1>
                                    <div className='SellBox'>
                                        <Image src={step3} style={{ width: 50, height: 50 }} />
                                    </div>
                                    <div className='lineArrow3'></div>
                                    <div id="dialog2" class="triangle_down3"></div>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <h5 style={{ marginTop: 30, fontSize: 18, fontWeight: 600 }}>Visit our Office for Easy Exchange process.</h5>
                                    {/* <p style={{ fontSize: 13, fontWeight: 500 }}> Once product reached our Office you will be notified through Email/SMS. </p> */}
                                </div>
                            </div>
                            <div style={{ display: "flex", marginTop: 10 }}>
                                <div style={{ marginTop: '-25px' }} className="mbpart3">
                                    <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 4</h1>
                                    <div className='SellBox'>
                                        <Image src={step5} style={{ width: 50, height: 50 }} />
                                    </div>
                                    <div className='lineArrow3'></div>
                                    <div id="dialog2" class="triangle_down3"></div>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <h5 style={{ marginTop: 20, fontSize: 18, fontWeight: 600 }}>We will do the inspection of your device instantly and in the mean time you can check the device you wish to buy.</h5>
                                    {/* <p style={{ fontSize: 13, fontWeight: 500 }}> Once product reached our Office you will be notified through Email/SMS. </p> */}
                                </div>
                            </div>

                            <div style={{ display: "flex", marginTop: 10 }}>
                                <div style={{ marginTop: '-25px' }}>
                                    <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 5</h1>
                                    <div className='SellBox'>
                                        <Image src={step4} style={{ width: 50, height: 50 }} />
                                    </div>

                                </div>
                                <div style={{ marginLeft: 20 }} className="mbpart4">
                                    <h5 style={{ marginTop: 30, fontSize: 18, fontWeight: 600 }}>Pay the Differential amount and Enjoy.</h5>
                                    {/* <p style={{ fontSize: 13, fontWeight: 500 }}>Once Inspection is completed Payment wil be done on the same day based on Inspection results. </p> */}
                                </div>
                            </div>
                        </Col>
                        <Col md={5}>

                            {/* <Lottie options={defaultOptions} style={{ width: "80%", height: "80%", marginTop: 20 }} className="mx-auto d-block" /> */}
                            <Image src={business} fluid />

                        </Col>
                    </Row>
                    <Row>
                        {/* <Col md={6} xs={6}>
                            <div className='whycardNew' style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                <div className='imgwhyNew'>
                                    <HiShieldCheck color="#fff" className='imgchooseNew' />
                                </div>
                                <div style={{ textAling: 'left', marginLeft: 15 }}>
                                    <h4 className='txtchooseNew'>Pay the Differential amount and Enjoy.</h4>
                                </div>

                            </div>

                        </Col> */}
                        <h4 className="text-danger  mt-5 ps-5 text-center notes"><em>Note: We offer Home Delivery Exchange Program as well within 5kms from Koramangala, Bengaluru.</em></h4>

                        {/* <Col md={6} xs={6}>
                            <div className='whycardNew' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <div className='imgwhyNew'>
                                    <FaMapMarkedAlt color="#fff" className='imgchooseNew' />
                                </div>
                                <div style={{ textAling: 'left' }}>
                                    <h4 className='txtchooseNew'>Real Time Tracking</h4>
                                </div>
                            </div>
                        </Col> */}

                    </Row>
                    <div className="d-flex justify-content-center mt-3" >
                        <Button onClick={handleClickService} variant="success" >Continue</Button>
                    </div>

                </Container>
            </Container>
            <Footer />
        </>
    )
}

export default FlowChart2