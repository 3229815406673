import { Box } from "@mui/material";
import React from "react";

const SmallCard = ({ children, sx, ...props }) => {
  return (
    <Box
      {...props}
      sx={{
        ...sx,
        background:
          "linear-gradient(135deg, rgba(229, 221, 221, 0.2) 0%, rgba(33, 76, 123, 0.3) 71%)",
      }}
    >
      {children}
    </Box>
  );
};

export default SmallCard;
