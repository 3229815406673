import React, { useState, useEffect, useMemo } from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import { useQuery } from "@apollo/client";
import { QUERY_GET_ORDERTIMELINE } from "../../../../graphql/Query";

const OrderTimeline = ({ currentStatus, orderTimelineName }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const { data, loading, error } = useQuery(QUERY_GET_ORDERTIMELINE, {
    variables: { timelineName: orderTimelineName },
  });

  const steps = useMemo(() => {
    return data ? data.getOrderTimeline.steps.map((step) => step.status) : [];
  }, [data]);

  const [progress, setProgress] = useState(0);
  const [animationStarted, setAnimationStarted] = useState(false);

  const animationDuration = 3;

  const getColor = (dotIndex, step) => {
    if (!animationStarted) return "grey";

    const lineProgress = progress;
    const dotChangePoint = (dotIndex * 100) / (steps.length - 1);

    const shouldChangeDot = lineProgress >= dotChangePoint;

    return shouldChangeDot
      ? step === "cancelled"
        ? "#f16565"
        : "rgb(6, 155, 170)"
      : "grey";
  };

  useEffect(() => {
    if (steps.length > 0) {
      const currentIndex = steps.indexOf(currentStatus);

      if (currentIndex !== -1) {
        const targetProgress = currentIndex * (100 / (steps.length - 1));

        setProgress(0);
        setAnimationStarted(false);

        const timer = setTimeout(() => {
          setAnimationStarted(true);
          const startTime = Date.now();
          const animate = () => {
            const elapsed = Date.now() - startTime;
            const duration = animationDuration * 1000;

            if (elapsed < duration) {
              const currentProgress = (elapsed / duration) * targetProgress;
              setProgress(currentProgress);
              requestAnimationFrame(animate);
            } else {
              setProgress(targetProgress);
            }
          };

          requestAnimationFrame(animate);
        }, 100);

        return () => clearTimeout(timer);
      }
    }
  }, [currentStatus, steps, animationDuration]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading timeline</p>;

  const dynamicWidth = steps.length > 1 ? 100 / (steps.length - 1) : 100;

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          background: "grey",
          position: "relative",
          height: "3px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            width: animationStarted ? `calc(${progress}%)` : "0%",
            height: "3px",
            backgroundColor: "rgb(6, 155, 170)",
            transition: "none",
            maxWidth: "100%",
          }}
        ></Box>
      </Box>
      <Box display={"block"} sx={{ position: "relative", bottom: "15px" }}>
        {steps.map((step, index) => (
          <Box
            key={index}
            sx={{
              width:
                steps.length - 1 === index ? "0" : `calc(${dynamicWidth}%)`,
              height: "auto",
              display: "inline-block",
              position: "relative",
            }}
          >
            <Box
              sx={{
                height: "10px",
                width: "10px",
                backgroundColor: getColor(index, step),
                borderRadius: "50%",
                transition: "background-color 0.2s ease",
              }}
            ></Box>
            <CustomTypography
              variant={"subtitle2"}
              sx={{
                textAlign: "left",
                fontSize: "0.75rem",
                position: "absolute",
              }}
            >
              {data.getOrderTimeline.steps[index].label}
            </CustomTypography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default OrderTimeline;
