import '../../ComponentsCss/OtherAccessories/otherAcess.css'
import { MUTATION_CREATE_FORM_REQUEST } from '../../graphql/Mutations'
import { QUERY_GET_ALL_REQ } from '../../graphql/Query'
import { useMutation, useQuery } from '@apollo/client';
import Navigation from "../Navigation"
import { Form, Button, Dropdown, Container, Breadcrumb, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

import swal from 'sweetalert';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const CorporateSellForm = () => {
    const userId = localStorage.getItem('userId')

    const [businessOrgState, setBusinessOrgState] = useState("")
    const [phoneNoState, setPhoneNoState] = useState("")

    const [emailState, setEmailState] = useState("")
    const [addreeState, setAddState] = useState("")

    const [productReq, setProductReq] = useState("")

    const [createRequest] = useMutation(MUTATION_CREATE_FORM_REQUEST
        , {
            refetchQueries: [
                QUERY_GET_ALL_REQ
            ]
        }
    )

    const handleSubmit = (e) => {
        e.preventDefault();
        const isValidPhoneNumber = /^\d{10}$/.test(phoneNoState);
        if (!isValidPhoneNumber) {
            swal({
                title: 'Invalid Phone Number',
                text: 'Please enter a 10-digit phone number.',
                icon: 'error',
            });
            return;
        }
        createRequest({
            variables: {
                "formRequestInput": {
                    "userId": userId,
                    "reqCategory": "Corporate-Sell",
                    "title": `${businessOrgState}`,
                    "phone": `${phoneNoState}`,
                    "email": `${emailState}`,
                    "address": `${addreeState}`,
                    "reqDetails": `${productReq}`
                }
            }
        }).then(() => {
            swal({
                title: 'Successfull!!!',
                text: 'Request Successfully Send',
                icon: 'success',
            });
            setBusinessOrgState("")
            setPhoneNoState("")
            setEmailState("")
            setAddState("")
            setProductReq("")
        }

        ).catch((e) => {
            swal("Internal Server Error", "", "warning")

        })

    }

    return (
        <>
            <Container style={{ padding: "0px" }} >
                <Form style={{ width: "90%", padding: "1rem", boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px" }} className="mx-auto d-block " onSubmit={handleSubmit} >
                    <Form.Group className="mb-3" controlId="formBasicEmail" >
                        <Form.Label className="other-form-lable" >Your Business/Organization</Form.Label> <span style={{ color: "red" }} >*</span>
                        <Form.Control type="text" placeholder="Eg: ABC Ltd" value={businessOrgState} onChange={(e) => setBusinessOrgState(e.target.value)}
                        />

                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail" >
                        <Form.Label className="other-form-lable" >Phone Number:</Form.Label> <span style={{ color: "red" }} >*</span>
                        <Form.Control type="number" placeholder="Eg: +91" value={phoneNoState} onChange={(e) => setPhoneNoState(e.target.value)}
                        />

                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail" >
                        <Form.Label className="other-form-lable" >Email Address: </Form.Label> <span style={{ color: "red" }} >*</span>
                        <Form.Control type="email" placeholder="Eg: your Official Email id" value={emailState} onChange={(e) => setEmailState(e.target.value)}
                        />

                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail" >
                        <Form.Label className="other-form-lable" >Corporate Address: </Form.Label> <span style={{ color: "red" }} >*</span>
                        <Form.Control type="text" placeholder="Eg: Valid Pickup/Official Address" value={addreeState} onChange={(e) => setAddState(e.target.value)}
                        />

                    </Form.Group>



                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="other-form-lable">Tell us about the device</Form.Label> <span style={{ color: "red" }} >*</span>
                        <Form.Control type="textarea" style={{ height: "100px" }} onChange={(e) => setProductReq(e.target.value)} value={productReq} />

                    </Form.Group>
                    <Button variant="success" type="submit" className="mx-auto d-block" disabled={businessOrgState && phoneNoState && emailState && addreeState && productReq ? false : true}>
                        REQUEST A QUOTE
                    </Button>
                </Form>
            </Container>
        </>
    )
}

export default CorporateSellForm