import { Close } from "@mui/icons-material";
import {
  Slide,
  Modal,
  Box,
  Typography,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import ReactHtmlParser from "react-html-parser";

import React from "react";
import { ThumbUpOutlined } from "@mui/icons-material";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
const ConditionModal = ({
  setModalOpen,
  modalOpen,
  modalData,
  isCondition = false,
}) => {
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const renderModalContent = () => {
    return Object.entries(modalData.description).map(([key, value]) => (
      <Box key={key} sx={{ marginBottom: "1rem" }}>
        <CustomTypography
          variant="subtitle1"
          sx={{
            fontWeight: "500",
            marginBottom: "0.5rem",
          }}
        >
          {key.charAt(0).toUpperCase() + key.slice(1)}{" "}
        </CustomTypography>
        {Array.isArray(value) ? (
          <CustomTypography
            variant="subtitle2"
            sx={{
              color: "#818589",
              fontSize: "0.85rem",
            }}
          >
            {value.map((item, index) => (
              <div key={index}>
                {item}
                <br />
              </div>
            ))}
          </CustomTypography>
        ) : (
          <CustomTypography
            variant="subtitle2"
            sx={{
              fontSize: "0.85rem",
              color: "#818589",
            }}
          >
            {ReactHtmlParser(value)}
          </CustomTypography>
        )}
      </Box>
    ));
  };

  return (
    <Modal open={modalOpen} onClose={handleModalClose} closeAfterTransition>
      <Slide
        direction={isSmallScreen ? "up" : "left"}
        in={modalOpen}
        mountOnEnter
        unmountOnExit
      >
        <Box
          sx={{
            position: "fixed",
            right: isSmallScreen ? "auto" : 0,
            top: isSmallScreen ? "auto" : 0,
            bottom: isSmallScreen ? 0 : "auto",
            width: isSmallScreen ? "100%" : "auto",
            height: isSmallScreen ? "100%" : "100%",
            minHeight: isSmallScreen ? "50%" : "100%",
            minWidth: !isSmallScreen ? "40%" : "100%",
            maxWidth: isSmallScreen ? "100%" : "60%",
            bgcolor: "background.paper",
            p: isSmallScreen ? "1.5rem" : "4rem",
            overflowY: "auto",
            transform: "translateX(0)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              cursor: "pointer",
              borderBottom: isSmallScreen ? "1px solid 	#B2BEB5" : "",
              paddingBottom: "0.3rem",
            }}
          >
            <Box display={"flex"} gap={"0.2rem"}>
              <Box>
                {isSmallScreen && (
                  <CustomTypography
                    variant="h6"
                    component="h2"
                    sx={{
                      fontWeight: "500",
                    }}
                  >
                    {modalData.title}
                  </CustomTypography>
                )}
              </Box>
            </Box>

            <Box>
              <IconButton onClick={handleModalClose}>
                <Close />
              </IconButton>
            </Box>
          </Box>
          {!isSmallScreen && (
            <CustomTypography
              variant="h6"
              component="h2"
              sx={{
                fontWeight: "500",
              }}
            >
              {modalData.title}
            </CustomTypography>
          )}
          {isCondition && renderModalContent()}

          {!isCondition && (
            <CustomTypography
              variant="body1"
              sx={{
                marginTop: "1rem",
                padding: "0.5rem",
                color: "#818589",
              }}
            >
              {ReactHtmlParser(modalData.description)}
            </CustomTypography>
          )}
        </Box>
      </Slide>
    </Modal>
  );
};

export default ConditionModal;
