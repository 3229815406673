import { Box, Button } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const WorkCard = ({ img, title, body }) => {
  return (
    <Box
      sx={{
        background:
          "linear-gradient(to bottom right, rgba(33, 76, 123, 0.3), rgba(142, 228, 242, 0.3))",
        width: "100%",
        minHeight: "150px",
        height: { xs: "285px", tabletLarge: "325px", desktop: "335px" },
        borderRadius: "1rem",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "1rem",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <Box
          display={"flex"}
          sx={{
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "80px",
              height: "80px",
              backgroundColor: "white",
              borderRadius: "50%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#D4DBE2",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "70px",
                height: "70px",
                borderRadius: "50%",
                border: "1px solid #214C7B",
              }}
            >
              <img src={img} style={{ width: "45px", height: "45px" }} />
            </Box>
          </Box>
        </Box>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <CustomTypography
            variant={"subtitle1"}
            sx={{
              fontWeight: "600",
              fontSize: {
                xs: "0.95rem",
                desktop: "1rem",
              },
            }}
          >
            {title}
          </CustomTypography>
        </Box>
        <Box display={"flex"}>
          <CustomTypography
            variant={"subtitle2"}
            sx={{
              textAlign: "justify",
              fontSize: {
                xs: "0.875rem",
                desktop: "0.90rem",
              },
            }}
          >
            {body}
          </CustomTypography>
        </Box>
      </Box>

      <Box display={"flex"} justifyContent={"center"}>
        <Button
          sx={{
            backgroundColor: "white",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "white",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;",
            },
          }}
        >
          <CustomTypography
            variant={"subtitle2"}
            sx={{ fontWeight: "600", color: "#7DC1C9" }}
          >
            Start now
          </CustomTypography>
        </Button>
      </Box>
    </Box>
  );
};

export default WorkCard;
