import { useTheme } from "@emotion/react";
import { Box, Modal, useMediaQuery } from "@mui/material";
import React, { useMemo } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import { Close, ShoppingCartOutlined } from "@mui/icons-material";
import { useRecoilValue } from "recoil";
import { cartItems } from "../../../../../store/atoms/addToCart/addToCart";
import useDeselectOutOfStock from "../../../hooks/addToCart/useDeselectOutOfStock";

const UnselectSelectedItems = ({ modalOpen, setModalOpen }) => {
  const cartData = useRecoilValue(cartItems);
  const { deSelectOutOfStockItems } = useDeselectOutOfStock();

  const handleUnselect = async () => {
    await deSelectOutOfStockItems();
    setModalOpen(false);
  };

  const outOfStockItems = useMemo(() => {
    return cartData?.items?.filter(
      (item) =>
        item.product.quantity === 0 && item.isCheckedForCheckout === true
    );
  }, [cartData]);
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  const style = {
    position: "fixed",
    top: isSmallScreen ? "" : "50%",
    left: isSmallScreen ? "0" : "50%",
    bottom: isSmallScreen ? "0" : "",
    maxHeight: "400px",
    maxWidth: isSmallScreen ? "100%" : "400px",
    width: "100%",
    transform: isSmallScreen ? "" : "translate(-50%, -50%)",
    bgcolor: "white",
    border: "none",
    borderRadius: "0.2rem",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
  };

  return (
    <Modal
      open={modalOpen}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          flexGrow={1}
          sx={{ position: "relative" }}
        >
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={"1rem"}
            sx={{ padding: "1rem 1rem 0 1rem" }}
          >
            <CustomTypography variant={"subtitle1"} sx={{ fontWeight: "600" }}>
              <ShoppingCartOutlined />
              <span style={{ padding: "0.5rem" }}>
                Remove Out of Stock Items
              </span>
            </CustomTypography>
            <CustomTypography>
              <Close
                onClick={handleModalClose}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              />
            </CustomTypography>
          </Box>

          {/* Subheading */}
          <Box
            sx={{
              padding: "0.5rem 1rem 0 1rem",
              borderBottom: "1px solid #eaeaec",
            }}
          >
            <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "500" }}>
              Items in your cart are out of stock. Please move them from cart to
              proceed{" "}
            </CustomTypography>
          </Box>

          {/* Scrollable Product List */}
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap="0.5rem"
            flexGrow={1}
            sx={{
              padding: "0.5rem 1rem 0.5rem 1rem",
              overflowY: "auto",
              maxHeight: "200px", // Adjust the height for the scrollable area
            }}
          >
            {outOfStockItems.map((item, index) => (
              <Product key={index} product={item.product} />
            ))}
          </Box>

          {/* Footer with Unselect Items Button */}
          <Box
            sx={{
              padding: "1rem",
              borderTop: "1px solid #eaeaec",
              display: "flex",
            }}
          >
            <Box
              flexBasis={"50%"}
              sx={{ borderRight: "1px solid #eaeaec" }}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {" "}
              <CustomTypography
                variant={"subtitle2"}
                onClick={handleUnselect}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                Unselect Items
              </CustomTypography>
            </Box>

            <Box
              flexBasis={"50%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CustomTypography
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={handleModalClose}
                variant={"subtitle2"}
              >
                Cancel
              </CustomTypography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const Product = ({ product }) => {
  return (
    <Box display="flex" alignItems="center" gap="1rem">
      <Box>
        <img
          src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${product.image[0]}`}
          style={{
            width: "80px",
            height: "80px",
            objectFit: "cover",
            borderRadius: "0.5rem",
          }}
          alt={product.name}
        />
      </Box>
      <Box>
        {" "}
        <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "600" }}>
          {product.productName}
        </CustomTypography>
        <CustomTypography
          variant={"subtitle2"}
          sx={{ fontWeight: "500", fontSize: "0.80rem" }}
        >
          Condition:
          <span style={{ fontWeight: "600", color: "rgb(6, 155, 170)" }}>
            {product.condition}
          </span>
        </CustomTypography>
      </Box>
    </Box>
  );
};

export default UnselectSelectedItems;
