import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import SmallCard from "../shared/SmallCard";

const SellingCard = ({ title, img, img2 }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("laptop"));
  return (
    <SmallCard
      display={"flex"}
      flexDirection={"column"}
      sx={{
        justifyContent: "space-between",
        minHeight: isMobileScreen ? "130px" : "180px",
        height: isMobileScreen ? "130px" : "200px",
        padding: isMobileScreen ? "0.5rem" : "1rem",
        borderRadius: "0.5rem",
      }}
    >
      <Box>
        <CustomTypography
          variant={"subtitle2"}
          sx={{
            lineHeight: "1.25",
            fontWeight: "600",
            fontSize: {
              xs: "0.875rem",
              tablet: "0.95rem",
              laptop: "1rem",
              desktop: "1rem",
            },
            color: "#285B84",
          }}
        >
          Sell <span style={{ fontWeight: "500", color: "black" }}>your</span>{" "}
        </CustomTypography>
        <CustomTypography
          variant="subtitle2"
          sx={{
            color: "#285B84",
            lineHeight: "1.25",
            fontWeight: "600",
            fontSize: {
              xs: "0.875rem",
              tablet: "0.95rem",
              laptop: "1rem",
              desktop: "1rem",
            },
            color: "#285B84",
          }}
        >
          {title}
        </CustomTypography>
      </Box>
      <Box display={"flex"} sx={{ alignItems: "center" }}>
        <img
          src={img}
          style={{
            width: "100%",
            height: isMobileScreen
              ? img2 !== undefined
                ? "50px"
                : "75px"
              : img2 !== undefined
              ? "75px"
              : "110px",
            objectFit: "contain",
          }}
        />
        {img2 !== undefined && (
          <img
            src={img2}
            style={{
              width: "100%",
              height: isMobileScreen ? "50px" : "85px",
              objectFit: "contain",
            }}
          />
        )}
      </Box>
    </SmallCard>
  );
};

export default SellingCard;
