
import { GET_ADDRESS_BY_USERID } from '../../graphql/Query'

import { MUTATION_DELETE_ADDRESS_BY_ID } from '../../graphql/Mutations'
import {MUTATION_UPDATE_ADD_ADDRESS} from '../../graphql/Mutations'
import { useMutation, useQuery } from '@apollo/client'
import { Col, Container, Button, Image, Row, Spinner, Card, Breadcrumb, Dropdown, Table, Modal, Form } from 'react-bootstrap'
import swal from 'sweetalert';
import { useState } from 'react';

const UserAddress = () => {
    const userId = localStorage.getItem('userId');

    const [selectedAdd, setSelectedAdd] = useState();

    const { data: getAddressByUserID, loading: userAddressLoading } = useQuery(GET_ADDRESS_BY_USERID,
        {
            variables:
            {
                "userId": userId

            }
        });
    const [deleteAddressByID] = useMutation(MUTATION_DELETE_ADDRESS_BY_ID, {
        refetchQueries: [
            GET_ADDRESS_BY_USERID
        ]
    })

    const [editAddressById] = useMutation(MUTATION_UPDATE_ADD_ADDRESS, {
        refetchQueries: [
            GET_ADDRESS_BY_USERID
        ]
    })
    const handleDelete = (addId) => {
        deleteAddressByID({
            variables: {
                "addressId": `${addId}`
            }
        }).then(() => {
            swal({
                title: 'Successfull!!!',
                text: ' Address Deleted Successfully',
                icon: 'success',
            });
        }).catch((e) => console.log(e))
    }
    // edit Funtionality
    const [nameState, setNameState] = useState()
    const [emailState, setEmailState] = useState()
    const [contactState, setContactState] = useState()
    const [flateState, setFlateState] = useState()
    const [stateState, setStateState] = useState()
    const [cityState, setCityState] = useState()
    const [landmarkState, setLandmarkState] = useState()
    const [pincodeState, setPincodeState] = useState()
    const [alternativeMobState, setAlternativeMobState] = useState()
    const [saveAsState, setSaveAsState] = useState()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleEdit = (add) => {
        handleShow()
        setSelectedAdd(add)
        setNameState(add.sellername)
        setFlateState(add.address)
        setEmailState(add.email)
        setEmailState(add.email)
        setStateState(add.state)
        setCityState(add.city)
        setLandmarkState(add.landmark)
        setPincodeState(add.pincode)
        setContactState(add.contactNo)
        setAlternativeMobState(add?.alternativeContactNo)
        setSaveAsState(add.addressType)
       
       
    }
    const saveEditChanges=()=>{
        editAddressById({
            variables: {
                "saveAddressInput": {
                    "id" : `${selectedAdd.id}`,
                    "address": `${flateState}`,
                    "sellername": `${nameState}`,
                    "email": `${emailState}`,
                    "contactNo": `${contactState}`,
                    "state": `${stateState}`,
                    "city": `${cityState}`,
                    "landmark": `${landmarkState}`,
                    "pincode": `${pincodeState}`,
                    "alternativeContactNo": `${alternativeMobState}`,
                    "addressType": `${saveAsState}`,
                    "userId": userId
                }
            }
        }).then(() => {
            swal({
                title: 'Successfull!!!',
                text: ' Address Edited Successfully',
                icon: 'success',
            });
            handleClose()
        }).catch((e) => console.log(e))
    }
    return (
        <>
            {
                userAddressLoading ?
                    <Spinner animation="border" variant="success" className='mx-auto d-block' />
                    :
                    getAddressByUserID && getAddressByUserID?.getAddressByUserID.map((add) => {
                        return (


                            <Card style={{ width: '80%', marginTop: "1rem", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", border: "0px" }} className='mx-auto d-block'>
                                <Card.Body>
                                    <Card.Title> {add.addressType} </Card.Title>
                                    <Card.Text style={{ paddingBottom: "10px", fontWeight: "500" }}>
                                        {add.sellername} , {add.email}
                                        <br />
                                        {add.address}, {add.state} , {add.city}, {add.landmark}
                                        , {add.pincode}, {add.contactNo} , {add?.alternativeContactNo}

                                    </Card.Text>
                                    <Card.Link style={{ color: "red", textDecoration: "underline", cursor: "pointer" }} onClick={() => handleEdit(add)}>Edit </Card.Link>
                                    <Card.Link style={{ color: "red", textDecoration: "underline", cursor: "pointer" }} onClick={() => handleDelete(add.id)}>Delete</Card.Link>
                                </Card.Body>
                            </Card>

                            //         <Card style={{ marginTop: "1rem" }}>

                            //     <Table striped bordered hover responsive width="100%" >
                            //         <tbody style={{ fontSize: "16px" }} >
                            //             <tr>
                            //                 <th>Name</th>
                            //                 <td>  {add.sellername} </td>
                            //                 <th>Email</th>
                            //                 <td> {add.email}</td>
                            //                 <th>Flat No/Office </th>
                            //                 <td>  {add.address}</td>
                            //             </tr>
                            //             <tr>

                            //                 <th>State </th>
                            //                 <td>   {add.state} </td>
                            //                 <th>City </th>
                            //                 <td>  {add.city} </td>
                            //                 <th>Landmark </th>
                            //                 <td> {add.landmark} </td>
                            //             </tr>
                            //             <tr>

                            //                 <th>Pincode </th>
                            //                 <td>  {add.pincode} </td>
                            //                 <th>Contact </th>
                            //                 <td> {add.contactNo} </td>
                            //                 {
                            //                     add.alternativeContactNo ?
                            //                         <>
                            //                             <th>
                            //                                 Alternate Contact
                            //                             </th>
                            //                             <td>
                            //                                 {add.alternativeContactNo}
                            //                             </td>
                            //                         </>

                            //                         : ""
                            //                 }
                            //             </tr>
                            //         </tbody>
                            //         <div style={{ display: "flex", justifyContent: "space-around", margin: "1rem" }}>
                            //             <Button variant="success" onClick={() => handleDelete(add.id)}> Delete </Button>
                            //             <Button > Edit Address  </Button>
                            //         </div>
                            //     </Table>
                            // </Card>

                        )

                    })
            }
            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3"  >
                        <Form.Label style={{ fontWeight: "500", fontSize: "12px", color: "grey" }}> Seller Name : </Form.Label>
                        <Form.Control type="text" value={nameState} style={{ fontWeight: "bold" }} onChange={(e) => setNameState(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ fontWeight: "500", fontSize: "12px", color: "grey" }}> Email: </Form.Label>
                        <Form.Control type="email" value={emailState} style={{ fontWeight: "bold" }} onChange={(e) => setEmailState(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label style={{ fontWeight: "500", fontSize: "12px", color: "grey" }}> Contact : </Form.Label>

                        <Form.Control type="number" value={contactState} style={{ fontWeight: "bold" }} onChange={(e) => setContactState(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label style={{ fontWeight: "500", fontSize: "12px", color: "grey" }}> Flat no/ Office </Form.Label>

                        <Form.Control type="text" value={flateState} style={{ fontWeight: "bold" }} onChange={(e) => setFlateState(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label style={{ fontWeight: "500", fontSize: "12px", color: "grey" }}> State : </Form.Label>

                        <Form.Control type="text" value={stateState} style={{ fontWeight: "bold" }} onChange={(e) => setStateState(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label style={{ fontWeight: "500", fontSize: "12px", color: "grey" }}> City : </Form.Label>

                        <Form.Control type="text" value={cityState} style={{ fontWeight: "bold" }} onChange={(e) => setCityState(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label style={{ fontWeight: "500", fontSize: "12px", color: "grey" }}> Landmark : </Form.Label>

                        <Form.Control type="text" value={landmarkState} style={{ fontWeight: "bold" }} onChange={(e) => setLandmarkState(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label style={{ fontWeight: "500", fontSize: "12px", color: "grey" }}> Pincode : </Form.Label>

                        <Form.Control type="number" value={pincodeState} style={{ fontWeight: "bold" }} onChange={(e) => setPincodeState(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label style={{ fontWeight: "500", fontSize: "12px", color: "grey" }}> Alternative Contact : </Form.Label>

                        <Form.Control type="number" value={alternativeMobState} style={{ fontWeight: "bold" }} onChange={(e) => setAlternativeMobState(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label as={Col} column sm={2} style={{ fontWeight: "bold" }}>
                            Save as
                        </Form.Label>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Col sm={10} style={{ fontWeight: "bold" }}>
                            <Form.Check
                                type="radio"
                                label="Home"
                                name="radioGroup"
                                value="Home"
                                onChange={(e) => setSaveAsState(e.target.value)}
                                inline
                            />
                            <Form.Check
                                type="radio"
                                label="Office"
                                name="radioGroup"
                                value="Office"
                                onChange={(e) => setSaveAsState(e.target.value)}
                                inline
                            />
                            <Form.Check
                                type="radio"
                                label="Other"
                                name="radioGroup"
                                value="Other"
                                onChange={(e) => setSaveAsState(e.target.value)}
                                inline
                            />
                        </Col>
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" onClick={()=>saveEditChanges()}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default UserAddress