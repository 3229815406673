import { Container, Form, Button, Card, Row, Col, Breadcrumb, Image, Modal } from "react-bootstrap"
import Footer from "../../Footer"
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { FaArrowRight, FaDotCircle } from "react-icons/fa";
import { MUTATION_ADD_ADDRESS } from '../../../graphql/Mutations'
import { useMutation, useQuery } from '@apollo/client'
import { FaSearch, FaArrowCircleRight } from 'react-icons/fa'
import Navigation from "../../Navigation";
import { useEffect, useState } from "react";
import swal from 'sweetalert';
import { GET_ALL_PINCODE } from '../../../graphql/Query'
import WorkFlow from "../../WorkFlow";
import { HiShieldCheck } from "react-icons/hi";
import { FaMapMarkedAlt } from "react-icons/fa";
import step1 from "../../../Images/homepage/workflow/step1.png"
import step2 from "../../../Images/homepage/workflow/step2.png"
import step3 from "../../../Images/homepage/workflow/step3.png"
import step4 from "../../../Images/homepage/workflow/step4.png"
import step5 from "../../../Images/homepage/workflow/step5.png"

const AddAddress = () => {
    const { data: getAllPincode, loading: allBrandLoading } = useQuery(GET_ALL_PINCODE);
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const userId = localStorage.getItem('userId')
    const navigate = useNavigate()
    const location = useLocation()
    const [nameState, setNameState] = useState()
    const [emailState, setEmailState] = useState()
    const [contactState, setContactState] = useState()
    const [flateState, setFlateState] = useState()
    const [stateState, setStateState] = useState()
    const [cityState, setCityState] = useState()
    const [landmarkState, setLandmarkState] = useState()
    const [pincodeState, setPincodeState] = useState()
    const [alternativeMobState, setAlternativeMobState] = useState()
    const [saveAsState, setSaveAsState] = useState()
    const [createSaveAddress, { loading: addressAddLoading }] = useMutation(MUTATION_ADD_ADDRESS)
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState({}); // State to store validation errors

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handlePincodeCheck = () => {
        if (validateForm()) {

            if (getAllPincode?.getAllPincode) {
                const pincodes = getAllPincode?.getAllPincode.map((cityPincode) => cityPincode.pincode);
                // Check if the targetPincode is in the pincodes array
                const containsPincode = pincodes.includes(pincodeState);

                if (containsPincode) {
                    handleProceed()
                } else {
                    handleShow()

                }
            }
        }

    }

    const handleProceed = () => {

        let addressId = ""
        createSaveAddress({
            variables: {
                "saveAddressInput": {
                    "address": `${flateState}`,
                    "sellername": `${nameState}`,
                    "email": `${emailState}`,
                    "contactNo": `${contactState}`,
                    "state": `${stateState}`,
                    "city": `${cityState}`,
                    "landmark": `${landmarkState}`,
                    "pincode": `${pincodeState}`,
                    "alternativeContactNo": `${alternativeMobState}`,
                    "addressType": `${saveAsState}`,
                    "userId": userId
                }
            }
        }).then((data) => {
            addressId = data.data.createSaveAddress.id
            swal({
                title: "Success",
                text: "Address Added Successfully",
                icon: "success",
            })
            navigate('/checkout/pick-slot', {
                state: {
                    id: location.state.id, basePrice: location.state?.basePrice, imgurl: location.state.imgurl, flateState: flateState, nameState: nameState, emailState: emailState, contactState: contactState, stateState: stateState
                    , cityState: cityState, landmarkState: landmarkState, pincodeState: pincodeState, alternativeMobState: alternativeMobState, saveAsState: saveAsState, ram: location.state.ram, storage: location.state.storage, isDeviceSwitch: location.state.isDeviceSwitch, ableToMakeReceiveCall: location.state.ableToMakeReceiveCall, issueWithDeviceDisplay: location.state.issueWithDeviceDisplay, deviceScreen: location.state.deviceScreen, deviceEverRepair: location.state.deviceEverRepair,
                    FrontCameraIssue: location.state.FrontCameraIssue, BackCameraIssue: location.state.BackCameraIssue, SpeakerIssue: location.state.SpeakerIssue, MicIssue: location.state.MicIssue, RingerIssue: location.state.RingerIssue, CharginPortIssue: location.state.CharginPortIssue, SensorIssue: location.state.SensorIssue, BatteryIssue: location.state.BatteryIssue,
                    ButtonIssue: location.state.ButtonIssue, WifiIssue: location.state.WifiIssue, BluetoothIssue: location.state.BluetoothIssue, FlashLightIssue: location.state.FlashLightIssue, AutoRestartIssue: location.state.AutoRestartIssue, EarphonePortIssue: location.state.EarphonePortIssue, GpsIssue: location.state.GpsIssue, VibratorIssue: location.state.VibratorIssue,
                    SimCardIssue: location.state.SimCardIssue, ConditionDeviceScreen: location.state.ConditionDeviceScreen, ConditionDeviceBody: location.state.ConditionDeviceBody, HaveBoxWithIMEI: location.state.HaveBoxWithIMEI, HaveDataCable: location.state.HaveDataCable, HaveAdapter: location.state.HaveAdapter, HaveEarphones: location.state.HaveEarphones, PurchasedPlace: location.state.PurchasedPlace, AdditionalInsurance: location.state.AdditionalInsurance, HaveBill: location.state.HaveBill
                    , WarrantyPeriod: location.state.WarrantyPeriod, productCategory: location.state.productCategory,
                    productBrand: location.state.productBrand,
                    productName: location.state.productName, defects: location.state.defects, HaveApplePencil: location.state.HaveApplePencil,

                    id: location.state.id, isDeviceSwitch: location.state.isDeviceSwitch, issueWithDeviceDisplay: location.state.issueWithDeviceDisplay, deviceScreen: location.state.deviceScreen, deviceEverRepair: location.state.deviceEverRepair,
                    keyBoardState: location.state.keyBoardState, trackpadState: location.state.trackpadState, SpeakerIssue: location.state.SpeakerIssue, MicIssue: location.state.MicIssue, usbPortState: location.state.usbPortState, CharginPortIssue: location.state.CharginPortIssue, webCamState: location.state.webCamState, BatteryIssue: location.state.BatteryIssue, hingeLooseState: location.state.hingeLooseState, WifiIssue: location.state.WifiIssue, BluetoothIssue: location.state.BluetoothIssue, chargerFaultyState: location.state.chargerFaultyState, AutoRestartIssue: location.state.AutoRestartIssue, EarphonePortIssue: location.state.EarphonePortIssue, graphicsCardState: location.state.graphicsCardState, hardDriveState: location.state.hardDriveState, ramIssueState: location.state.ramIssueState, productCategory: location.state.productCategory, productBrand: location.state.productBrand,
                    productName: location.state.productName, imgurl: location.state.imgurl, processorState: location.state.processorState, laptopStorage: location.state.laptopStorage, laptopRam: location.state.laptopRam, graphicsState: location.state.graphicsState, screenSizeState: location.state.screenSizeState, ConditionDeviceScreen: location.state.ConditionDeviceScreen, ConditionDeviceBody: location.state.ConditionDeviceBody, boxClick: location.state.boxClick, powerCableClick: location.state.powerCableClick, adapterClick: location.state.adapterClick, purchasedPlaceClick: location.state.purchasedPlaceClick, additionalClick: location.state.additionalClick, warrantyPeriod: location.state.warrantyPeriod, defects: location.state.defects, funcKeyboardClick: location.state.funcKeyboardClick, funcMouseClick: location.state.funcMouseClick,
                    strapClick: location.state.strapClick, financeClick: location.state.financeClick, basePrice: location.state.basePrice
                    , addressId: data.data.createSaveAddress.id, financeClick: location.state.financeClick, basePrice: location.state.basePrice
                }
            })

        }).catch((error) => {
            console.log(error)
        })
        navigate('/checkout/pick-slot', {
            state: {
                id: location.state.id, basePrice: location.state?.basePrice, imgurl: location.state.imgurl, flateState: flateState, nameState: nameState, emailState: emailState, contactState: contactState, stateState: stateState
                , cityState: cityState, landmarkState: landmarkState, pincodeState: pincodeState, alternativeMobState: alternativeMobState, saveAsState: saveAsState, ram: location.state.ram, storage: location.state.storage, isDeviceSwitch: location.state.isDeviceSwitch, ableToMakeReceiveCall: location.state.ableToMakeReceiveCall, issueWithDeviceDisplay: location.state.issueWithDeviceDisplay, deviceScreen: location.state.deviceScreen, deviceEverRepair: location.state.deviceEverRepair,
                FrontCameraIssue: location.state.FrontCameraIssue, BackCameraIssue: location.state.BackCameraIssue, SpeakerIssue: location.state.SpeakerIssue, MicIssue: location.state.MicIssue, RingerIssue: location.state.RingerIssue, CharginPortIssue: location.state.CharginPortIssue, SensorIssue: location.state.SensorIssue, BatteryIssue: location.state.BatteryIssue,
                ButtonIssue: location.state.ButtonIssue, WifiIssue: location.state.WifiIssue, BluetoothIssue: location.state.BluetoothIssue, FlashLightIssue: location.state.FlashLightIssue, AutoRestartIssue: location.state.AutoRestartIssue, EarphonePortIssue: location.state.EarphonePortIssue, GpsIssue: location.state.GpsIssue, VibratorIssue: location.state.VibratorIssue,
                SimCardIssue: location.state.SimCardIssue, ConditionDeviceScreen: location.state.ConditionDeviceScreen, ConditionDeviceBody: location.state.ConditionDeviceBody, HaveBoxWithIMEI: location.state.HaveBoxWithIMEI, HaveDataCable: location.state.HaveDataCable, HaveAdapter: location.state.HaveAdapter, HaveEarphones: location.state.HaveEarphones, PurchasedPlace: location.state.PurchasedPlace, AdditionalInsurance: location.state.AdditionalInsurance, HaveBill: location.state.HaveBill
                , WarrantyPeriod: location.state.WarrantyPeriod, productCategory: location.state.productCategory,
                productBrand: location.state.productBrand,
                productName: location.state.productName, defects: location.state.defects, HaveApplePencil: location.state.HaveApplePencil,

                id: location.state.id, isDeviceSwitch: location.state.isDeviceSwitch, issueWithDeviceDisplay: location.state.issueWithDeviceDisplay, deviceScreen: location.state.deviceScreen, deviceEverRepair: location.state.deviceEverRepair,
                keyBoardState: location.state.keyBoardState, trackpadState: location.state.trackpadState, SpeakerIssue: location.state.SpeakerIssue, MicIssue: location.state.MicIssue, usbPortState: location.state.usbPortState, CharginPortIssue: location.state.CharginPortIssue, webCamState: location.state.webCamState, BatteryIssue: location.state.BatteryIssue, hingeLooseState: location.state.hingeLooseState, WifiIssue: location.state.WifiIssue, BluetoothIssue: location.state.BluetoothIssue, chargerFaultyState: location.state.chargerFaultyState, AutoRestartIssue: location.state.AutoRestartIssue, EarphonePortIssue: location.state.EarphonePortIssue, graphicsCardState: location.state.graphicsCardState, hardDriveState: location.state.hardDriveState, ramIssueState: location.state.ramIssueState, productCategory: location.state.productCategory, productBrand: location.state.productBrand,
                productName: location.state.productName, imgurl: location.state.imgurl, processorState: location.state.processorState, laptopStorage: location.state.laptopStorage, laptopRam: location.state.laptopRam, graphicsState: location.state.graphicsState, screenSizeState: location.state.screenSizeState, ConditionDeviceScreen: location.state.ConditionDeviceBody, ConditionDeviceBody: location.state.ConditionDeviceBody, boxClick: location.state.boxClick, powerCableClick: location.state.powerCableClick, adapterClick: location.state.adapterClick, purchasedPlaceClick: location.state.purchasedPlaceClick, additionalClick: location.state.additionalClick, warrantyPeriod: location.state.warrantyPeriod, defects: location.state.defects, funcKeyboardClick: location.state.funcKeyboardClick, funcMouseClick: location.state.funcMouseClick,
                strapClick: location.state.strapClick, financeClick: location.state.financeClick, basePrice: location.state.basePrice
                , addressId: addressId, financeClick: location.state.financeClick
            }
        })
    }

    const validateForm = () => {
        const errors = {};

        if (!nameState) {
            errors.name = "Name is required";
        }

        if (!emailState) {
            errors.email = "Email is required";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailState)) {
            errors.email = "Invalid email format";
            setEmailState("")
        }

        if (!contactState) {
            errors.contact = "Contact number is required";
        } else if (!/^\d{10}$/.test(contactState)) {
            errors.contact = "Contact number must be 10 digits";
            setContactState("")

        }
        if (alternativeMobState) {
            if (!/^\d{10}$/.test(alternativeMobState)) {
                errors.alternativeMob = "Alternative contact number must be 10 digits";
                setAlternativeMobState(""); // Clear the value
            }
        }
        if (!pincodeState) {
            errors.pincode = "PIN code is required";
        } else if (!/^\d{6}$/.test(pincodeState)) {
            errors.pincode = "PIN code must be 6 digits";
            setPincodeState("")
        }



        // Add similar validation for other fields

        setErrors(errors);

        // Check if there are any validation errors
        return Object.keys(errors).length === 0;
    };
    const handleSelectBrand = () => {
        let cat = location.state?.productCategory
        if (cat === "Desktop/Imac") {
            cat = "Desktop-Imac"
        }
        navigate(`/selling/${cat}`,)
    }
    const handleSelectProduct = () => {
        navigate(`/allbrands/${location.state?.productBrand}`, {
            state: {
                productCategory: location.state?.productCategory, productBrand: location.state?.productBrand,
            }
        })
    }
    return (
        <>
            <Navigation />
            <div className="banner">
                <h2>
                    Please Fill Your Address
                </h2>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="search-input"
                    />
                    {/* <div className="search-icon">
                        <FaSearch />
                    </div> */}
                </div>
                <Breadcrumb>
                    <Breadcrumb.Item ><Link to="/">Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a onClick={handleSelectBrand}>Select Brand</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={handleSelectProduct}>Sell Product</Breadcrumb.Item>
                </Breadcrumb>

            </div>
            <Container style={{ marginTop: "1rem" }}>
                <Row>
                    <Col md={6}>
                        <Card style={{ padding: "2rem", height: "100%" }}>
                            <Form.Group className="mb-3"  >
                                <Form.Control type="text" placeholder="Enter Name" name="name" style={{ fontWeight: "bold" }} onChange={(e) => setNameState(e.target.value)} value={nameState} />
                                {errors.name && <p className="text-danger">{errors.name}</p>}

                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Control type="email" placeholder="Enter Email" style={{ fontWeight: "bold" }} onChange={(e) => setEmailState(e.target.value)} value={emailState} />
                                {errors.email && <p className="text-danger">{errors.email}</p>}

                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="number" placeholder="Enter Contact Number" name="phone" style={{ fontWeight: "bold" }} onChange={(e) => setContactState(e.target.value)} value={contactState} />
                                {errors.contact && <p className="text-danger">{errors.contact}</p>}

                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Control type="text" placeholder="Flat no / Office" name="address" style={{ fontWeight: "bold" }} onChange={(e) => setFlateState(e.target.value)} value={flateState} />

                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Control type="text" placeholder="Enter Your State" style={{ fontWeight: "bold" }} onChange={(e) => setStateState(e.target.value)} value={stateState} />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Control type="text" placeholder="Enter Your City" style={{ fontWeight: "bold" }} onChange={(e) => setCityState(e.target.value)} value={cityState} />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Control type="text" placeholder="Landmark" style={{ fontWeight: "bold" }} onChange={(e) => setLandmarkState(e.target.value)} value={landmarkState} />
                            </Form.Group>
                            <Form.Group className="mb-3" >

                                <Form.Control type="number" placeholder="Pincode" style={{ fontWeight: "bold" }} onChange={(e) => setPincodeState(e.target.value)} value={pincodeState} />
                                {errors.pincode && <p className="text-danger">{errors.pincode}</p>}

                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Control type="number" placeholder="Alternative Phone Number (Optional)" style={{ fontWeight: "bold" }} onChange={(e) => setAlternativeMobState(e.target.value)} value={alternativeMobState} />
                                {errors.alternativeMob && <p className="text-danger">{errors.alternativeMob}</p>}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label as={Col} column sm={2} style={{ fontWeight: "bold" }}>
                                    Save as
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col sm={10} style={{ fontWeight: "bold" }}>
                                    <Form.Check
                                        type="radio"
                                        label="Home"
                                        name="radioGroup"
                                        value="Home"
                                        onChange={(e) => setSaveAsState(e.target.value)}
                                        inline
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Office"
                                        name="radioGroup"
                                        value="Office"
                                        onChange={(e) => setSaveAsState(e.target.value)}
                                        inline
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Other"
                                        name="radioGroup"
                                        value="Other"
                                        onChange={(e) => setSaveAsState(e.target.value)}
                                        inline
                                    />
                                </Col>
                            </Form.Group>


                            <Card.Footer style={{ marginTop: "1rem" }} >
                                <Button disabled={nameState && emailState && contactState && flateState && stateState && cityState && pincodeState && saveAsState ? false : true} variant="warning" onClick={() => handlePincodeCheck()} style={{ borderRadius: '0px', width: 150, color: '#fff', fontWeight: 'bold', fontSize: '20px', backgroundColor: "#40a737", border: "0px", borderRadius: '10px' }}>Next <FaArrowRight /></Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card style={{ height: "100%" }} className="d-none d-sm-block">
                            <Card.Body>
                                <Card.Title style={{ fontWeight: "bold" }}>Price Summary</Card.Title>
                                <Image fluid style={{ width: "14rem", margin: "1rem" }} src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${location?.state?.imgurl}`} className='mx-auto d-block' />
                                <Card.Text style={{ marginTop: "1rem", fontWeight: "bold" }} className="sell-price"> Final Selling Price<span style={{ color: "rgb(64, 167, 55)" }} className="price-val" > Rs.{location.state.basePrice} </span></Card.Text>
                                {/* <Card.Text style={{ fontWeight: "bold" }}>Pick Up Charges ₹<span style={{ color: "rgb(64, 167, 55)" }}> 00 </span></Card.Text> */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>



            <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton className="px-lg-5 px-sm-0 text-danger" style={{ backgroundColor: "#DFEBFF" }}>
                    <Modal.Title style={{ fontSize: "17px", fontWeight: "bold" }}>
                        Oops!! We don't have the Option of Instant/Spot Payment at your pincode. <br /><span className="text-success">  But Don't worry you can still Sell your Device with us.</span>


                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="px-lg-5 px-sm-0">

                        <Col md={12} style={{ marginTop: "15px" }} >
                            <div style={{ display: "flex", marginTop: 10 }}>
                                <div style={{ marginTop: '-25px' }}>
                                    <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 1</h1>
                                    <div className='SellBox'>
                                        <Image src={step1} style={{ width: 50, height: 50 }} />
                                    </div>
                                    <div className='lineArrow1'></div>
                                    <div id="dialog2" class="triangle_down1"></div>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <h5 style={{ marginTop: 10, fontSize: 18, fontWeight: 600 }}>Pack your Products</h5>
                                    <p style={{ fontSize: 13, fontWeight: 500 }}>Pack your products safely to avoid damage in transit. Use Bubblewraps, thermocols. </p>
                                </div>
                            </div>

                            <div style={{ display: "flex", marginTop: 10 }}>
                                <div style={{ marginTop: '-25px' }}>
                                    <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 2</h1>
                                    <div className='SellBox'>
                                        <Image src={step2} style={{ width: 50, height: 50 }} />
                                    </div>
                                    <div className='lineArrow2'></div>
                                    <div id="dialog2" class="triangle_down2"></div>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <h5 style={{ marginTop: 10, fontSize: 18, fontWeight: 600 }}>Handover the Package</h5>
                                    <p style={{ fontSize: 13, fontWeight: 500 }}>Handover the package to the renouned logistics partner assigned for the job. You can track your device with the tracking number provided to you. </p>
                                </div>
                            </div>


                            <div style={{ display: "flex", marginTop: 10 }}>
                                <div style={{ marginTop: '-25px' }}>
                                    <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 3</h1>
                                    <div className='SellBox'>
                                        <Image src={step3} style={{ width: 50, height: 50 }} />
                                    </div>
                                    <div className='lineArrow3'></div>
                                    <div id="dialog2" class="triangle_down3"></div>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <h5 style={{ marginTop: 10, fontSize: 18, fontWeight: 600 }}>Product Reached Our Headoffice</h5>
                                    <p style={{ fontSize: 13, fontWeight: 500 }}> Once product reached our Office you will be notified through Email/SMS. </p>
                                </div>
                            </div>
                            <div style={{ display: "flex", marginTop: 10 }}>
                                <div style={{ marginTop: '-25px' }}>
                                    <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 4</h1>
                                    <div className='SellBox'>
                                        <Image src={step4} style={{ width: 50, height: 50 }} />
                                    </div>
                                    <div className='lineArrow4'></div>
                                    <div id="dialog2" class="triangle_down4"></div>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <h5 style={{ marginTop: 10, fontSize: 18, fontWeight: 600 }}>Inspection of your device</h5>
                                    <p style={{ fontSize: 13, fontWeight: 500 }}>We will do the Inspection of you device and the result will be notified through Email. Usually it will be completed on the same business day. </p>
                                </div>
                            </div>

                            <div style={{ display: "flex", marginTop: 10 }}>
                                <div style={{ marginTop: '-25px' }}>
                                    <h1 style={{ fontSize: 15, textAlign: 'center', fontWeight: 600 }}>Step 5</h1>
                                    <div className='SellBox'>
                                        <Image src={step5} style={{ width: 50, height: 50 }} />
                                    </div>

                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <h5 style={{ marginTop: 10, fontSize: 18, fontWeight: 600 }}>Payment Completion</h5>
                                    <p style={{ fontSize: 13, fontWeight: 500 }}>Once Inspection is completed Payment wil be done on the same day based on Inspection results. </p>
                                </div>
                            </div>
                            <div className="text-danger fw-bold ps-lg-5 ps-sm-0 pt-3">
                                <em>
                                    Note:  In case of any mismatch in the price and you refuse to accept the same we will again send it back to you for free ( <span className="text-primary text-decoration-underline"> <Link to="/terms-condition">  Refer to T&C for more </Link></span>  ) .
                                </em>
                            </div>
                        </Col>

                        <Col>
                            <Row>
                                <Col md={6} xs={6}>
                                    <div className='whycardNew' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div className='imgwhyNew'>
                                            <HiShieldCheck color="#fff" className='imgchooseNew' />
                                        </div>
                                        <div style={{ textAling: 'left' }}>
                                            <h4 className='txtchooseNew'>100% Safe and Secure</h4>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} xs={6}>
                                    <div className='whycardNew' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <div className='imgwhyNew'>
                                            <FaMapMarkedAlt color="#fff" className='imgchooseNew' />
                                        </div>
                                        <div style={{ textAling: 'left' }}>
                                            <h4 className='txtchooseNew'>Real Time Tracking</h4>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="primary" onClick={() => handleProceed()}>
                        Continue
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Exit
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddAddress