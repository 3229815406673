import React, { useState } from "react";
import { Form, Button, Dropdown, Container } from 'react-bootstrap';
import swal from 'sweetalert';
import { useMutation } from '@apollo/client';
import { MUTATION_CREATE_QUOTE_REQ } from '../../graphql/Mutations';
import { QUERY_GET_ALL_QUOTE_REQ } from '../../graphql/Query';

const PhoneQuote = ({ category }) => {
    const userId = localStorage.getItem('userId');

    const [productType, setProductType] = useState("");
    const [productBrand, setProductBrand] = useState("");
    const [productModel, setProductModel] = useState("");
    const [productAge, setProductAge] = useState("Select Age");
    const [productCondition, setProductCondition] = useState("Select Condition");
    const [productAccessories, setProductAccessories] = useState("");
    const [productInvoice, setProductInvoice] = useState("Select");
    const [productDefects, setProductDefects] = useState("");
    const [productStorage, setProductStorage] = useState("");
    const [productRAM, setProductRAM] = useState("");
    const [productProcessor, setProductProcessor] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    const [createQuoteReq] = useMutation(MUTATION_CREATE_QUOTE_REQ, {
        refetchQueries: [QUERY_GET_ALL_QUOTE_REQ]
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate phone number
        if (!/^\d{10}$/.test(phone)) {
            swal("Invalid Phone Number", "Please enter a 10-digit phone number.", "error");
            return;
        }

        createQuoteReq({
            variables: {
                quoteRequestInput: {
                    userId, name, email, phone,
                    productType, productBrand, productModel,
                    ageOfProduct: productAge,
                    physicalCondition: productCondition,
                    accessoriesIncluded: productAccessories,
                    originalInvoice: productInvoice,
                    deviceDefect: productDefects,
                    productStorage, productRam: productRAM,
                    productProcessor
                }
            }
        }).then(() => {
            swal("Successful!", "Request Successfully Sent", "success");
            // Reset all state variables
            setProductType("");
            setProductBrand("");
            setProductModel("");
            setProductAge("Select Age");
            setProductCondition("Select Condition");
            setProductAccessories("");
            setProductInvoice("Select");
            setProductDefects("");
            setProductStorage("");
            setProductRAM("");
            setProductProcessor("");
            setName("");
            setEmail("");
            setPhone("");
        }).catch((e) => console.log(e));
    };

    // Function to check if all required fields are filled
    const isFormValid = () => {
        let valid = productType && productBrand && productModel &&
            productAge !== "Select Age" && productCondition !== "Select Condition" &&
            productAccessories && productInvoice !== "Select" &&
            productDefects && name && email && phone;

        if (category === "Laptops" || category === "Desktop/Imac") {
            valid = valid && productProcessor && productStorage && productRAM;
        }
        if (category === "Tablets") {
            valid = valid && productStorage && productRAM;
        }
        if(category === "Phones") {
            valid = valid && productStorage && productRAM;
        }
        if  (category === "Smart Watches") {
            valid = valid && productStorage && productRAM;
        }
        return valid;
    };

    return (
        <>
            <div className="banner">

                <h3 style={{ textAlign: "center", fontWeight: "bold", fontSize: "18px" }} className=" py-4 mb-3" > If your Product is not listed with us you can still<span className="text-success">  Request a Quote. </span>
                </h3>
            </div>
            <Container style={{ padding: "0px", marginTop: "2rem" }} >

                <Form style={{ width: "90%", padding: "1rem", boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px" }} className="mx-auto d-block " onSubmit={handleSubmit} >
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="other-form-lable">Name</Form.Label> <span style={{ color: "red" }}>*</span>
                        <Form.Control type="text" placeholder="Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="other-form-lable">Email</Form.Label> <span style={{ color: "red" }}>*</span>
                        <Form.Control type="email" placeholder="Your Email Id" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="other-form-lable">Phone</Form.Label> <span style={{ color: "red" }}>*</span>
                        <Form.Control type="tel" placeholder="Your Phone Number" value={phone} onChange={(e) => setPhone(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail" >
                        <Form.Label className="other-form-lable" >Product Type</Form.Label> <span style={{ color: "red" }} >*</span>
                        <Form.Control type="text" placeholder=" Eg: Phones, Laptop, Tablets" value={productType} onChange={(e) => setProductType(e.target.value)}
                        />

                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label className="other-form-lable" >Brand Of Your Product</Form.Label> <span style={{ color: "red" }} >*</span>

                        <Form.Control type="text" placeholder=" Eg: Apple, Samsung, Oneplus" value={productBrand} onChange={(e) => setProductBrand(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label className="other-form-lable" >Model</Form.Label>  <span style={{ color: "red" }} >*</span>

                        <Form.Control type="text" placeholder=" Paste exact online link of your product" value={productModel} onChange={(e) => setProductModel(e.target.value)} />
                        <div style={{ fontSize: "12px", fontWeight: "bold" }} className=" mt-1 text-success"> Eg: www.flipkart.com/apple-iphone-13 </div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label className="other-form-lable" >Storage</Form.Label><span style={{ color: "red" }} > *</span>

                        <Form.Control type="text" placeholder="Eg: 32GB, 64GB, 128GB." value={productStorage} onChange={(e) => setProductStorage(e.target.value)} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label className="other-form-lable" >RAM</Form.Label><span style={{ color: "red" }} > *</span>

                        <Form.Control type="text" placeholder="Eg: 8GB, 16GB" value={productRAM} onChange={(e) => setProductRAM(e.target.value)} />
                    </Form.Group>
                    {
                        category == "Laptops" || category == "Desktop/Imac" ?
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label className="other-form-lable" >Processor </Form.Label><span style={{ color: "red" }} >*</span>

                                <Form.Control type="text" placeholder="Eg: i5 3rd Gen, Ryzen 7 5th Gen." value={productProcessor} onChange={(e) => setProductProcessor(e.target.value)} />
                            </Form.Group> : ""
                    }
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label className="other-form-lable" >Age of your Product </Form.Label> <span style={{ color: "red" }} >*</span>

                        <Dropdown>
                            <Dropdown.Toggle variant="success" className="other-dropdown" id="dropdown-basic">
                                {productAge}
                            </Dropdown.Toggle>

                            <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}>
                                <Dropdown.Item onClick={(e) => setProductAge("Less than 3 Months")}  >Less than 3 months</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => setProductAge("3-6 Months")} >3-6 Months</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => setProductAge("6-12 Months")}>6-12 Months</Dropdown.Item>
                                <Dropdown.Item onClick={(e) => setProductAge("More than 1 Year")} >More than 1 Year</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label className="other-form-lable"  >Physical Condition</Form.Label> <span style={{ color: "red" }} >*</span>

                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="other-dropdown">
                                {productCondition}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setProductCondition("Excellent")} >Excellent</Dropdown.Item>
                                <Dropdown.Item onClick={() => setProductCondition("Good")}>Good</Dropdown.Item>
                                <Dropdown.Item onClick={() => setProductCondition("Fair")} >Fair</Dropdown.Item>
                                <Dropdown.Item onClick={() => setProductCondition("Poor")}>Poor</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="other-form-lable">Accessories Included</Form.Label> <span style={{ color: "red" }} >*</span>
                        <Form.Control type="text" placeholder="  Eg: Box, Charger, Cable etc." value={productAccessories} onChange={(e) => setProductAccessories(e.target.value)} />

                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label className="other-form-lable">Original Invoice</Form.Label> <span style={{ color: "red" }} >*</span>
                        <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" className="other-dropdown">
                                {productInvoice}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setProductInvoice("Available")} >Available</Dropdown.Item>
                                <Dropdown.Item onClick={() => setProductInvoice("Not available")} >Not available</Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="other-form-lable">Tell us about the device(Additional incase of any defect/problem in it)</Form.Label> <span style={{ color: "red" }} >*</span>
                        <Form.Control type="textarea" style={{ height: "100px" }} onChange={(e) => setProductDefects(e.target.value)} value={productDefects} />

                    </Form.Group>
                    <Button 
                        variant="success" 
                        type="submit" 
                        className="mx-auto d-block" 
                        disabled={!isFormValid()}
                    >
                        REQUEST A QUOTE
                    </Button>
                </Form>
            </Container>
        </>
    )
}
export default PhoneQuote