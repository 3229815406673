import React from "react";
import { Box } from "@mui/material";
import HomePageContainer from "../shared/HomePageContainer";
import PrelovedGadgets from "./PrelovedGadgets";

const PrelovedElectronicGadgets = () => {
  return (
    <HomePageContainer>
      <PrelovedGadgets />
    </HomePageContainer>
  );
};

export default PrelovedElectronicGadgets;
