import React, { useState } from 'react'
import { QUERY_GET_ALL_ORDERS_BY_USERID } from '../../graphql/Query'
import { useMutation, useQuery } from '@apollo/client';
import { Container, Col, Row, Card, Breadcrumb, Spinner, Button, Image } from 'react-bootstrap';
import '../../ComponentsCss/userCss/SellOrder.css'
import { useNavigate } from 'react-router-dom';

import Moment from 'react-moment';

export default function UserOrders() {
  const userId = localStorage.getItem('userId')
  const [selectedOrder, setSelectedOrder] = useState()
  const navigate = useNavigate()
  const { data: getSellOrderByUserId, loading: ordersLoading } = useQuery(QUERY_GET_ALL_ORDERS_BY_USERID,
    {
      variables:
      {
        "userId": userId
      }
    });
  const handleDetailClick = (order) => {
    setSelectedOrder(order)
    navigate('/orderdetails', { state: { order: order } })
  }
  return (
    <>
      {window.scrollTo(0, 0)}
      <Container fluid>
        {
          ordersLoading ? <Spinner animation="border" variant="success" className='mx-auto d-block' /> :
            getSellOrderByUserId && getSellOrderByUserId?.getSellOrderByUserId?.slice().reverse().map((order) => {
              return (
                <Card>
                  {/* <Card.Img variant="top" src="holder.js/100px180" /> */}

                  <Card.Body>
                    <div style={{ display: "flex", justifyContent: "space-between", padding: "10px 0px " }}>
                      <div>
                        <Card.Title style={{ color: "rgb(64, 167, 55)" }}>{order.productName}</Card.Title>
                      </div>
                      <div>
                        <Card.Text style={{ fontSize: ".75rem", fontWeight: "bold" }} className='grey-color' > Booking Id : {order.orderId}</Card.Text>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <div>

                        <Card.Text style={{ fontSize: ".75rem", fontWeight: "bold" }} className='grey-color'>
                          Date :
                          <Moment format="DD/MM/YYYY, hh:mm A">{order.createdDateTime}</Moment>

                        </Card.Text>
                        <Card.Text style={{ fontSize: ".75rem" }}>
                          Status : {order.status}
                        </Card.Text>
                      </div>

                      <div style={{ textAlign: "right", width: "20%" }}>
                        {/* <Image fluid src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${order?.imageKey}`} style={{ width: "60%" }} className='order-image' /> */}
                      </div>
                    </div>
                    <div className='dashed-line'></div>

                    <div style={{ display: "flex", justifyContent: "space-between", padding: "20px 0px " }} className='price-section'>

                      <div >
                        <Card.Text style={{ fontSize: "14px" }}> <span className='grey-color'>Selling Amount :</span> ₹{order?.sellingAmount} </Card.Text>
                      </div>
                      <div style={{ textAlign: "right" }}>
                        <Button variant="success" onClick={() => handleDetailClick(order)} className='detail-btn'>See Details</Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              )
            })
        }
      </Container>
    </>
  )
}
