import React from 'react';
import GadgetSalePreparation from '../Components/instruction';
import Footer from '../Components/Footer';
import { Outlet } from 'react-router-dom';

const MainOutlet = () => {
    // Your component logic goes here

    return (
        <>
            <Outlet />
            <GadgetSalePreparation />
            <Footer />
        </>
    );
};

export default MainOutlet;
