import React, { useEffect, useState } from "react";
import { CREATE_REMAINING_AMOUNT_ORDER } from "../../../graphql/Mutations";
import { useMutation } from "@apollo/client";
import logo from "../../../Images/homepage/logo.png";
import {
  orderIdAtom,
  paymentInfoModalOpen,
  paymentSuccessAtom,
} from "../../../store/atoms/payment/paymentAtom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { cartPageBackdrop } from "../../../store/atoms/addToCart/addToCart";

const useRemainingAmtPay = () => {
  const [createRemainingAmountOrder] = useMutation(
    CREATE_REMAINING_AMOUNT_ORDER
  );
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);
  const [modalOpen, setModalOpen] = useRecoilState(paymentInfoModalOpen);
  const [paymentSuccess, setPaymentSuccess] =
    useRecoilState(paymentSuccessAtom);
  const setOrderId = useSetRecoilState(orderIdAtom);
  const [isRazorpayLoaded, setIsRazorpayLoaded] = useState(false);

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = () => setIsRazorpayLoaded(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePayment = async (
    paymentMethod,
    paymentType,
    amount,
    orderId,
    selectedAddressState
  ) => {
    if (!isRazorpayLoaded) {
      console.log("Razorpay script not yet loaded.");
      return;
    }

    setBackdropOpen(true);

    try {
      const { data, loading, error } = await createRemainingAmountOrder({
        variables: {
          paymentMethod,
          paymentType,
          amount,
          orderId,
        },
      });

      if (error) {
        console.error("Error in createRemainingAmountOrder:", error);
        setBackdropOpen(false);
        return;
      }

      console.log("Order data received:", data);

      const options = {
        key: "rzp_test_W20dHFUEmnlSr6",
        // key: "rzp_live_wMYTQ0r7qPLEjR", // For live environments
        amount: data.createRemainingAmountOrder.amount,
        currency: data.createRemainingAmountOrder.currency,
        name: "HelloFi Recommerce",
        description: "Purchase Description",
        order_id: data.createRemainingAmountOrder.order_Id,
        image: logo,
        handler: async function (response) {
          console.log("Payment Success:", response);
          setPaymentSuccess(true);
          setModalOpen(true);
          setOrderId(data.createRemainingAmountOrder.orderId);
          setBackdropOpen(false);
        },
        modal: {
          ondismiss: function () {
            console.log("Payment modal closed.");
            setBackdropOpen(false);
          },
        },
        prefill: {
          name: selectedAddressState.name,
          email: selectedAddressState.email,
          contact: selectedAddressState.contact,
        },
        retry: {
          enabled: false,
        },
        theme: {
          color: "rgb(6, 155, 170)",
        },
      };

      const paymentObject = new window.Razorpay(options);

      paymentObject.on("payment.failed", function (response) {
        console.log("Payment failed:", response.error);
        setPaymentSuccess(false);
        setModalOpen(true);
        setOrderId(data.createRemainingAmountOrder.orderId);
        setBackdropOpen(false);
      });

      paymentObject.open();

      setBackdropOpen(false);
    } catch (error) {
      console.error(
        "Error creating order or initiating payment:",
        error.message
      );
      setBackdropOpen(false);
    }
  };

  return { isRazorpayLoaded, handlePayment };
};

export default useRemainingAmtPay;
