import React, { useState, useEffect, useRef } from "react";
import { Modal, Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import Close from "@mui/icons-material/Close";
import { useSwipeable } from "react-swipeable";
import ImageZoom from "./ImageZoom";
import ImageZoomSmall from "./ImageZoomSmall";

const ImagesShowModal = React.memo(({ images, openModal, setOpenModal }) => {
  const theme = useTheme();
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const isSmallScreen = useMediaQuery("(max-width:768px)");
  const isSmallScreenTrue = useMediaQuery(theme.breakpoints.down("desktop"));
  const thumbnailsRef = useRef(null);

  const imageUrl = `https://hellofi.s3.ap-south-1.amazonaws.com/${
    images[activeImageIndex][isSmallScreen ? "lg" : "xl"]
  }`;

  useEffect(() => {
    if (thumbnailsRef.current) {
      const thumbnailElement =
        thumbnailsRef.current.childNodes[activeImageIndex];
      if (thumbnailElement) {
        thumbnailElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    }
  }, [activeImageIndex]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleThumbnailClick = (index) => {
    setActiveImageIndex(index);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      setActiveImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      ),
    onSwipedRight: () =>
      setActiveImageIndex((prevIndex) =>
        prevIndex === 0 ? images.length - 1 : prevIndex - 1
      ),
  });

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "none",
        outline: "none",
      }}
    >
      <Box
        sx={{
          width: isSmallScreen ? "100%" : "80%",
          height: isSmallScreen ? "100%" : "90vh",
          bgcolor: "background.paper",
          boxShadow: 24,
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          borderRadius: isSmallScreen ? "0" : "1rem",
          padding: isSmallScreen ? "0.5rem" : "2rem",
          maxWidth: "1000px",
          maxHeight: isSmallScreen ? "100vh" : "90vh",
          overflow: "auto",
        }}
      >
        {isSmallScreen && (
          <Box
            display={"flex"}
            height={"5%"}
            justifyContent={"end"}
            marginBottom={"1rem"}
          >
            <IconButton onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Box>
        )}
        {isSmallScreenTrue ? (
          <ImageZoomSmall
            imageUrl={imageUrl}
            zoomImageUrl={imageUrl}
            isSmallScreen={isSmallScreen}
            handlers={handlers}
          />
        ) : (
          <ImageZoom
            src={imageUrl}
            alt="Active Product"
            isSmallScreen={isSmallScreen}
          />
        )}

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={isSmallScreen ? "end" : ""}
          sx={{
            overflowY: "0",
            height: isSmallScreen ? "20%" : "auto",
            flexShrink: "0",
            width: isSmallScreen ? "100%" : "35%",
            padding: isSmallScreen ? "0" : "0 0 0 2rem",
          }}
        >
          {!isSmallScreen && (
            <Box
              display={"flex"}
              height={"5%"}
              justifyContent={"end"}
              marginBottom={"1rem"}
            >
              <IconButton onClick={handleCloseModal}>
                <Close />
              </IconButton>
            </Box>
          )}
          <Box
            ref={thumbnailsRef}
            display={"flex"}
            flexDirection={"row"}
            flexWrap={isSmallScreen ? "nowrap" : "wrap"}
            overflow={"auto"}
            gap={"0.5rem"}
            sx={{ padding: "0 0 0.5rem 0" }}
          >
            {images.map((image, index) => (
              <img
                key={index}
                src={`https://hellofi.s3.ap-south-1.amazonaws.com/${image.xs}`}
                alt={`Product ${index}`}
                style={{
                  flexShrink: "0",
                  width: "94px",
                  height: "94px",
                  objectFit: "cover",
                  borderRadius: "0.5rem",
                  cursor: "pointer",
                  padding: "0.4rem",
                  border:
                    activeImageIndex === index
                      ? "2px solid blue"
                      : "1.5px solid #D3D3D3",
                  borderRadius: "0.5rem",
                }}
                onClick={() => handleThumbnailClick(index)}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
});

export default ImagesShowModal;
