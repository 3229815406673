import React, { useState } from 'react';
import { Button, Container, Image, Carousel } from 'react-bootstrap';
import '../ComponentsCss/MainPage.css';
import { FaSearch } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useTypewriter } from 'react-simple-typewriter';
import banner1 from '../Images/banner/banner3.JPG';
import banner2 from '../Images/banner/banner4.JPG';
import banner3 from '../Images/banner/newBanner1.JPG';
import banner4 from '../Images/banner/newBanner2.JPG';
import banner5 from '../Images/banner/newBanner3.JPG';
import CategoryModal from './HomePageModals/Category';
import BuyCategoryModal from './HomePageModals/BuyCategoryModal';
import ExchangeModal from './HomePageModals/ExchangeModal';

export default function MainPage() {
    const [searchInput, setSearchInput] = useState('');
    const navigate = useNavigate();

    const [text] = useTypewriter({
        words: ['Brand', 'Models to Buy', 'Sell', 'Exchange'],
        loop: 0,
    });

    const handleSearch = () => {
        if (searchInput) {
            navigate('/search-result', {
                state: {
                    search: true,
                    searchInput: searchInput,
                },
            });
        }
    };

    const [showCategory, setShowCategory] = useState(false);
    const [showCategory2, setShowCategory2] = useState(false);
    const [showExchangeModal, setShowExchangeModal] = useState(false);
    const phoneNumber = '+918150835583'; // Replace with the recipient's phone number
    const whatsappURLPhone = `https://wa.me/${phoneNumber}`;

    return (
        <>
            <div className='serchint2'>
                <input
                    className='searchinput text-center'
                    type='search'
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    placeholder={`Search Models to buy`}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                    style={{ outline: 'none', fontSize: 14 }}
                ></input>
                <FaSearch className='searchIcon' style={{ cursor: 'pointer' }} onClick={() => handleSearch()} />
            </div>
            <Container style={{ marginTop: 20 }}>
                <div style={{}} className='bannerHome'>
                    <Carousel interval={3000} controls={true} pauseOnHover={true}>
                        <Carousel.Item onClick={() => setShowCategory2(true)}>
                            <Image className='imgslid' src={banner1} />
                        </Carousel.Item>
                        <Carousel.Item onClick={() => setShowCategory(true)}>
                            <Image className='imgslid' src={banner2} />
                        </Carousel.Item>
                        <Carousel.Item onClick={() => window.open(whatsappURLPhone, '_blank')}>
                            <Image className='imgslid' src={banner3} />
                        </Carousel.Item>
                        <Carousel.Item onClick={() => window.open('https://youtube.com/@HelloFiRecommerce?si=GN7Ez_hocBT3L4Sf', '_blank')}>
                            <Image className='imgslid' src={banner4} />
                        </Carousel.Item>
                        <Carousel.Item onClick={() => setShowExchangeModal(true)}>
                            <Image className='imgslid' src={banner5} />
                        </Carousel.Item>
                    </Carousel>
                </div>
            </Container>
            {showCategory ? <CategoryModal showCategory={showCategory} setShowCategory={setShowCategory} /> : ''}
            {showCategory2 ? <BuyCategoryModal showCategory2={showCategory2} setShowCategory2={setShowCategory2} /> : ''}
            {showExchangeModal ? <ExchangeModal showExchangeModal={showExchangeModal} setShowExchangeModal={setShowExchangeModal} /> : ''}
        </>
    );
}
