import React, { useEffect } from "react";
import { ORDER_SUMMARY } from "../../../../graphql/Mutations";
import { useQuery } from "@apollo/client";
import { useRecoilState } from "recoil";
import {
  latestPaymentAtom,
  orderSummaryAtom,
} from "../../../../store/atoms/orderSummary/orderSummary";
import { orderIdAtom } from "../../../../store/atoms/payment/paymentAtom";

const useGetOrderSummary = (orderId) => {
  const [orderSummary, setOrderSummary] = useRecoilState(orderSummaryAtom);
  const [latestPayment, setLatestPayment] = useRecoilState(latestPaymentAtom);
  const { data, loading, error, refetch } = useQuery(ORDER_SUMMARY, {
    variables: { orderId },
  });

  useEffect(() => {
    if (data && data.getOrderSummary && data.getOrderSummary.payments) {
      setOrderSummary(data.getOrderSummary);
      const payment =
        data.getOrderSummary.payments[data.getOrderSummary.payments.length - 1];
      setLatestPayment(payment);
    }
  }, [data]);
  return { orderSummary, loading, error, refetch };
};

export default useGetOrderSummary;
