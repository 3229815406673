import { useMediaQuery } from "@mui/material";
import { QUERY_GET_FILTERED_BUY_PRODUCT } from "../../../../../graphql/Query";
import RelatedProductContainer from "../../productDetails/RelatedProductContainer";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";
import "../../../css/style.css";
import { Box } from "@mui/material";
import { useQuery } from "@apollo/client";

const YouMayAlsoLike = ({ products }) => {
  const isSmallScreen = useMediaQuery("(max-width:767px)");

  return (
    <Box display={"flex"} flexDirection={"column"}>
      <Box>
        <CustomTypography
          variant="subtitle1"
          sx={{
            fontOpticalSizing: "auto",
            fontWeight: "600",
            fontSize: isSmallScreen ? "20px" : "24px",
          }}
        >
          You may also like
        </CustomTypography>
      </Box>
      <RelatedProductContainer products={products} length={products.length} />
    </Box>
  );
};

export default YouMayAlsoLike;
