import React, { useEffect, useState } from "react";
import "../ComponentsCss/CrowdFavourites.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import AppleLaptops from "../Images/slider/AppleLaptops.JPEG";
import Laptop from "../Images/slider/Laptop.JPEG";
import Mobiles from "../Images/slider/mobiles.JPEG";
import Tablets from "../Images/slider/Tablets.JPEG";
import Watches from "../Images/slider/Watches.JPEG";
import Others from "../Images/slider/Others.JPEG";
import { Card, Container, Image } from "react-bootstrap";
import { FaArrowCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const CrowdFavourites = () => {
  const navigate = useNavigate();
  const testiMonials = [
    {
      img: Mobiles,
      title: "Apple iPhones and Other Brands",
      description1: `UPTO 50% OFF`,
      description2: "Easy EMI options available.",
      description3: "With Added Accessories.",
      link: "/buying-products/Phones",
    },
    {
      img: AppleLaptops,
      title: "Apple MacBook",
      description1: `UPTO 50% OFF`,
      description2: "Easy EMI options available.",
      description3: "With Added Accessories.",
      link: "/buying-product-filter/2/Laptops/APPLE",
    },
    {
      img: Tablets,
      title: "Apple iPads and Other Tablets",
      description1: `UPTO 50% OFF`,
      description2: "Easy EMI options available.",
      description3: "With Added Accessories.",
      link: "/buying-products/Tablets",
    },
    {
      img: Laptop,
      title: "Gaming Laptops and Others",
      description1: `UPTO 50% OFF`,
      description2: "Easy EMI options available.",
      description3: "With Added Accessories.",
      link: "/buying-products/Laptops",
    },

    {
      img: Watches,
      title: "Best Smartwatches",
      description1: `UPTO 50% OFF`,
      description2: "Easy EMI options available.",
      description3: "With Added Accessories.",
      link: "/buying-products/Smart Watches",
    },

    {
      img: Others,
      title: "Other Gadgets you may love",
      description1: `Special Discount Offer.`,
      description2: "Easy EMI options available.",
      description3: "",
      link: "/buying-products/Other Accessories/",
    },
  ];

  const options = {
    loop: true,
    default: false,
    center: true,
    items: 1,
    margin: 0,
    autoplay: true,
    autoplayTimeout: 4000,
    smartSpeed: 450,
    responsive: {
      0: {
        items: 1,
        nav: true,
        dots: true,
      },

      707: {
        items: 2,
        nav: true,
        dots: true,
      },
      1000: {
        items: 3,
        nav: true,
        dots: true,
      },
    },
    nav: true,
    dots: true,
  };

  return (
    <Container id="crowdFavourites">
      <div className="crowdfavourites">
        <div className="slider-heading">
          <h3 className="main-text">
            Preloved Electronic Gadgets <br /> TO BUY
          </h3>
        </div>
        <div className="row">
          <div className="col-md-12">
            <OwlCarousel
              id="products-slider"
              className="owl-carousel owl-theme"
              {...options}
            >
              {testiMonials.map((testiMonialDetail, index) => {
                return (
                  <div
                    key={index}
                    className="item"
                    onClick={() => navigate(`${testiMonialDetail.link}`)}
                  >
                    <div className="img-slider">
                      <img className="" src={testiMonialDetail.img} alt="" />
                    </div>

                    <div className="buy">
                      <div>
                        <p className="brand-text">{testiMonialDetail.title}</p>
                        <div className="des">
                          <div className="brand-des">
                            <p>
                              {testiMonialDetail.description1} <br />
                              {testiMonialDetail.description2} <br />
                              {testiMonialDetail.description3}
                            </p>
                          </div>

                          <div className="right-arrow">
                            <FaArrowCircleRight className="arrow-icon" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CrowdFavourites;
