import React, { useState, useEffect, useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import { useQuery } from "@apollo/client";
import { QUERY_GET_ORDERTIMELINE } from "../../../../graphql/Query";

const OrderTimelineMobile = ({ currentStatus, orderTimelineName }) => {
  const theme = useTheme();

  const { data, loading, error } = useQuery(QUERY_GET_ORDERTIMELINE, {
    variables: { timelineName: orderTimelineName },
  });

  const steps = useMemo(() => {
    return data
      ? data?.getOrderTimeline?.steps?.map((step) => step.status)
      : [];
  }, [data]);

  const [progress, setProgress] = useState(0);
  const [animationStarted, setAnimationStarted] = useState(false);

  const animationDuration = 3;

  const getColor = (dotIndex, step) => {
    if (!animationStarted) return "grey";

    const lineProgress = progress;
    const dotChangePoint = (dotIndex * 100) / (steps.length - 1);
    const shouldChangeDot = lineProgress >= dotChangePoint;

    return shouldChangeDot
      ? step === "cancelled"
        ? "#f16565"
        : "rgb(6, 155, 170)"
      : "grey";
  };

  useEffect(() => {
    if (steps?.length > 0) {
      const currentIndex = steps.indexOf(currentStatus);

      if (currentIndex !== -1) {
        const targetProgress = currentIndex * (100 / (steps?.length - 1));

        setProgress(0);
        setAnimationStarted(false);

        const timer = setTimeout(() => {
          setAnimationStarted(true);
          const startTime = Date.now();
          const animate = () => {
            const elapsed = Date.now() - startTime;
            const duration = animationDuration * 1000;

            if (elapsed < duration) {
              const currentProgress = (elapsed / duration) * targetProgress;
              setProgress(currentProgress);
              requestAnimationFrame(animate);
            } else {
              setProgress(targetProgress);
            }
          };

          requestAnimationFrame(animate);
        }, 100);

        return () => clearTimeout(timer);
      }
    }
  }, [currentStatus, steps, animationDuration]);

  const dynamicHeight = steps?.length > 1 ? 100 / (steps?.length - 1) : 100;

  if (loading) return <Box>Loading...</Box>;
  if (error) return <Box>Error loading timeline</Box>;

  return (
    <>
      {currentStatus !== undefined && (
        <Box
          sx={{
            width: "100%",
            maxWidth: "600px",
            height: "100%",
            position: "relative",
            padding: "1rem",
          }}
        >
          {/* Vertical line container */}
          <Box
            sx={{
              position: "absolute",
              left: "20px",
              top: "24px", // Starting point for the line
              bottom: "24px", // Ensures the line stretches to the bottom
              width: "3px",
              bgcolor: "grey.300",
            }}
          >
            {/* Animated progress line */}
            <Box
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: animationStarted ? `${progress}%` : "0%", // Progress in percentage
                bgcolor: "rgb(6, 155, 170)",
                transition: "none",
                maxHeight: "100%", // Ensures the progress line stops at the bottom
              }}
            />
          </Box>

          {/* Steps container */}
          <Box sx={{ position: "relative" }}>
            {steps?.map((step, index) => {
              const isFirstDot = index === 0;
              const isLastDot = index === steps?.length - 1;

              return (
                <Box
                  key={index}
                  sx={{
                    mb: isLastDot ? 0 : 6,
                    position: "relative",
                  }}
                >
                  {/* Dot */}
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      borderRadius: "50%",
                      bgcolor: getColor(index, step),
                      transition: "background-color 0.2s ease",
                      zIndex: 1,
                      mr: 2,
                      position: "absolute",
                      top: isFirstDot
                        ? "0%"
                        : isLastDot
                        ? "100%"
                        : `${(index / (steps?.length - 1)) * 100}%`,
                      transform: isFirstDot
                        ? "translateY(0)"
                        : isLastDot
                        ? "translateY(-100%)"
                        : "translateY(-50%)",
                    }}
                  />

                  <Box
                    sx={{
                      flex: 1,
                      pt: 0,
                      marginLeft: "1.5rem",
                      position: "relative",
                      bottom: isFirstDot ? "5px" : "inherit",
                      top: isLastDot ? "5px" : "inherit",
                    }}
                  >
                    <CustomTypography
                      variant="subtitle2"
                      sx={{
                        fontSize: "0.875rem",
                        fontWeight: 500,
                        color: "text.primary",
                        mb: 0.5,
                      }}
                    >
                      {data?.getOrderTimeline?.steps[index]?.label}
                    </CustomTypography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
};

export default OrderTimelineMobile;
