import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Row, Table, Spinner, Image, Dropdown, Modal } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'
import { GET_ALL_PINCODE } from '../../graphql/Query'
import { MUTATION_CREATE_PINCODE } from '../../graphql/Mutations'
import { MUTATION_DELETE_PINCODE } from '../../graphql/Mutations'
import swal from 'sweetalert';



export const AddPincode = () => {
    const [pincodeState, setPincodeState] = useState("");
    const [validated, setValidated] = useState("");
    const [cityState, setCityState] = useState("")
    const { data: getAllPincode, loading: allBrandLoading } = useQuery(GET_ALL_PINCODE);
    const [createPincode, { loading: createLoading }] = useMutation(MUTATION_CREATE_PINCODE, {
        refetchQueries: [
            GET_ALL_PINCODE
        ]
    })
    const [deletePincode, { loading: dltLoading }] = useMutation(MUTATION_DELETE_PINCODE, {
        refetchQueries: [
            GET_ALL_PINCODE
        ]
    })
    const handleSubmit = (e) => {
        e.preventDefault();
        createPincode({
            variables: {
                "cityPincodeInput": {
                    "pincode": pincodeState ,
                    "city": cityState
                }
            }
        }).then(() => {

            swal({
                title: "Success",
                text: "Added successfully",
                icon: "success",
            })
            setCityState("")
            setPincodeState("")
        }
            
        ).catch((e)=> alert(e))
    }
    let count = 1

    const handleDelete=(offerid)=>{
        deletePincode({
            variables :{
                "dataId":offerid
            }
        }).then(() => {

            swal({
                title: "Success",
                text: "deleted successfully",
                icon: "success",
            })
    
        }
            
        ).catch((e)=> alert(e))
    }
    return (
        <>

            <Container style={{ padding: 20 }} fluid>
                <Row>
                    <Col md={4}>
                        <div style={{ padding: "1rem" }} className='box-shadow'>
                            <h3 style={{ textAlign: 'center' }}>Add Pincode Here</h3>
                            <Col>
                                <Form.Group as={Col} md="12" controlId="validationCustom02">
                                <Form.Control
                                            required
                                            type="text"
                                            className='txtinpt'
                                            placeholder="City"
                                            onChange={(e) => setCityState(e.target.value)}
                                            value={cityState}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    
                                </Form.Group>
                            </Col>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Control
                                            required
                                            type="number"
                                            className='txtinpt'
                                            placeholder="Pincode"
                                            onChange={(e) => setPincodeState(e.target.value)}
                                            value={pincodeState}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>

                                    <Button type='submit' variant='dark' className='mt-5' disabled={pincodeState && cityState ? false : true} >  Add Pincode </Button>
                                </Row>
                            </Form>

                        </div>
                    </Col>
                    <Col>
                        <Table responsive striped bordered hover>
                            <thead className='table-head'>
                                <tr>
                                    <th>S.no.</th>
                                    <th>City</th>
                                    <th>Pincode</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    getAllPincode && getAllPincode?.getAllPincode?.map((data) => {
                                        return (
                                            <tr>
                                                <td> {count++} </td>
                                                <td> {data?.city} </td>
                                                <td> {data?.pincode} </td>
                                                <td> <Button variant='danger' onClick={()=>handleDelete(data.id)} >Delete</Button> </td>
                                            </tr>
                                        )
                                    })
                                }
                                <td>  </td>
                            </tbody>

                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
