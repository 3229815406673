import { Box } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../../muicomponents/shared/CustomTypography";

const ProductCondition = ({ condition, isSmall = false, inStock }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      gap={"0.3rem"}
      sx={{ padding: isSmall ? "0" : "0 1rem" }}
    >
      <CustomTypography variant={"subtitle2"} sx={{ fontWeight: "500" }}>
        Condition:
      </CustomTypography>
      <CustomTypography
        variant={"subtitle2"}
        sx={{
          fontWeight: "700",
          color: inStock ? "rgb(6, 155, 170)" : "rgb(119, 119, 119)",
        }}
      >
        {condition}
      </CustomTypography>
    </Box>
  );
};

export default ProductCondition;
