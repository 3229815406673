import { Box } from "@mui/material";
import React from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const AddressCard = ({ address }) => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{ padding: "0.75rem", height: "100%" }}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box>
          <CustomTypography
            variant={"subtitle1"}
            sx={{ fontWeight: "600", fontSize: "0.85rem" }}
          >
            {address.name}
          </CustomTypography>
        </Box>
        <Box
          sx={{
            backgroundColor: "#f4f4f5",
            borderRadius: "1rem",
            padding: "0.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "1.5rem",
          }}
        >
          <CustomTypography
            variant={"subtitle2"}
            sx={{
              textTransform: "uppercase",
              fontSize: "0.65rem",
              fontWeight: 500,
            }}
          >
            {address.addressType}
          </CustomTypography>
        </Box>
      </Box>

      <CustomTypography
        sx={{ fontSize: "0.80rem", color: "#696E79", fontWeight: "400" }}
      >
        {address.address}
      </CustomTypography>
      <CustomTypography
        sx={{ fontSize: "0.80rem", color: "#696E79", fontWeight: "400" }}
      >
        {address.cityDistrict} - <span>{address.pinCode}</span>
      </CustomTypography>
      <CustomTypography
        sx={{ fontSize: "0.80rem", color: "#696E79", fontWeight: "400" }}
      >
        {address.state}
      </CustomTypography>
      <CustomTypography
        sx={{ fontSize: "0.80rem", color: "#696E79", fontWeight: "400" }}
      >
        Email: {address.email}
      </CustomTypography>
      <CustomTypography
        sx={{ fontSize: "0.80rem", color: "#696E79", fontWeight: "400" }}
      >
        Contact: {address.contact}
        {address.alternateContactNo && ` ,${address.alternateContactNo}`}
      </CustomTypography>
      {address.gst && (
        <CustomTypography
          sx={{ fontSize: "0.80rem", color: "#696E79", fontWeight: "400" }}
        >
          GST: {address.gst}
        </CustomTypography>
      )}
      {address.landMark && (
        <CustomTypography
          sx={{ fontSize: "0.80rem", color: "#696E79", fontWeight: "400" }}
        >
          Landmark: {address.landMark}
        </CustomTypography>
      )}
    </Box>
  );
};

export default AddressCard;
