import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import HandOver from "../../../../../Images/home/sellinguide/icons/Handover.png";
import HeadOffice from "../../../../../Images/home/sellinguide/icons/HeadOffice.png";
import Inspection from "../../../../../Images/home/sellinguide/icons/Inspection.png";
import Pack from "../../../../../Images/home/sellinguide/icons/Pack.png";
import Payment from "../../../../../Images/home/sellinguide/icons/Payment.png";
import SellingGuideCard from "./SellingGuideCard";
import Secure from "../../../../../Images/home/sellinguide/icons/Secure.png";
import LocationTracking from "../../../../../Images/home/sellinguide/icons/LocationTracking.png";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const SellingGuideCards = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("laptop"));

  const SellingGuide = [
    {
      key: 1,
      title: "Step 1",
      img: Pack,
      subtitle: "Pack your Products",
      body: "Pack your products safely to avoid damage in transit. Use Bubblewraps, thermocols.",
    },
    {
      key: 2,
      title: "Step 2",
      img: HandOver,
      subtitle: "Handover the Package",
      body: "Handover the package to the renowned logistics partner assigned for the job. You can track your device with the tracking number provided to you.",
    },
    {
      key: 3,
      title: "Step 3",
      img: HeadOffice,
      subtitle: "Product Reached Our Headoffice",
      body: "Once Product reaches our Office you will be notified through Email/SMS.",
    },
    {
      key: 4,
      title: "Step 4",
      img: Inspection,
      subtitle: "Inspection of your device",
      body: "We will do the Inspection of your device and the result will be notified through Email. Usually it will be completed on the same business day.",
    },
    {
      key: 5,
      title: "Step 5",
      img: Payment,
      subtitle: "Payment Completion",
      body: "Once Inspection is completed Payment will be done on the same day based on Inspection results.",
    },
  ];
  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "250px",
        borderRadius: "1rem",
        padding: "1rem",
        background:
          "linear-gradient(to bottom right, rgba(255, 255, 255, 0.3), rgba(33, 76, 123, 0.3))",
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={isMobileScreen ? 1 : 2}>
          {SellingGuide.map((obj) => (
            <Grid
              key={obj.key}
              item
              xs={12}
              tabletLarge={2.4}
              alignItems="stretch"
            >
              <SellingGuideCard
                img={obj.img}
                title={obj.title}
                subtitle={obj.subtitle}
                body={obj.body}
              />
            </Grid>
          ))}
        </Grid>
        <Box
          sx={{
            display: "flex",
            margin: "1rem auto 0 auto",
            flexDirection: "row",
            justifyContent: "center",
          }}
          gap={"0.5rem"}
          width={isMobileScreen ? "100%" : "80%"}
        >
          <Box
            display={"flex"}
            flexDirection={isMobileScreen ? "column" : "row"}
            flexBasis={"50%"}
            sx={{
              gap: isMobileScreen ? "0.5rem" : "1rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#D2CBCB",
                width: isMobileScreen ? "70px" : "80px",
                height: isMobileScreen ? "70px" : "80px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={Secure}
                style={{
                  width: isMobileScreen ? "40px" : "50px",
                  height: isMobileScreen ? "40px" : "50px",
                }}
              />
            </Box>
            <Box display={"flex"} sx={{ alignItems: "center" }}>
              <CustomTypography
                variant={"subtitle2"}
                sx={{
                  fontWeight: "600",
                  fontSize: {
                    mobile: "0.875rem",
                    tablet: "0.95rem",
                  },
                }}
              >
                100% Safe and Secure
              </CustomTypography>
            </Box>
          </Box>
          <Box
            display={"flex"}
            flexBasis={"50%"}
            flexDirection={isMobileScreen ? "column" : "row"}
            sx={{
              gap: isMobileScreen ? "0.5rem" : "1rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#D2CBCB",
                width: isMobileScreen ? "70px" : "80px",
                height: isMobileScreen ? "70px" : "80px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={LocationTracking}
                style={{
                  width: isMobileScreen ? "40px" : "50px",
                  height: isMobileScreen ? "40px" : "50px",
                }}
              />
            </Box>
            <Box display={"flex"} sx={{ alignItems: "center" }}>
              <CustomTypography
                variant={"subtitle2"}
                sx={{
                  fontWeight: "600",
                  fontSize: {
                    mobile: "0.875rem",
                    tablet: "0.95rem",
                  },
                }}
              >
                Real Time Tracking
              </CustomTypography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SellingGuideCards;
