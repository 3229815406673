import { Box, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";

const ProfileTextField = ({
  label,
  name,
  onChange,
  placeHolder,
  value,
  onBlur,
  error,
  disabled,
  helperText,
  sx = {},
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("laptop"));

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      sx={{
        position: "relative",
        width: isSmallScreen ? "100%" : "260px",
        ...sx,
      }}
    >
      <input
        disabled={disabled}
        style={{
          backgroundColor: disabled ? "#F7EEEE" : "transparent",
          border: error ? "1.5px solid red" : "1.5px solid rgb(6, 155, 170)",
          borderRadius: "0.2rem",
          outline: "none",
          padding: !isFocused && disabled ? "1rem" : "1rem 1rem 0 1rem",
          height: "50px",
        }}
        placeholder={placeHolder}
        name={name}
        value={value || ""}
        onChange={onChange}
        onBlur={(e) => {
          setIsFocused(false);
          if (onBlur) onBlur(e);
        }}
        onFocus={() => setIsFocused(true)}
        {...props}
      />
      {(isFocused || !disabled) && (
        <label
          style={{
            color: error ? "red" : "#0077c0",
            padding: "0 0 0 0.85rem",
            position: "absolute",
            fontSize: "0.85rem",
            transform: "translateY(10%) scale(.815)",
          }}
        >
          {label}
        </label>
      )}
      {error && helperText && (
        <span style={{ color: "red", fontSize: "0.75rem", marginTop: "4px" }}>
          {helperText}
        </span>
      )}
    </Box>
  );
};

export default ProfileTextField;
