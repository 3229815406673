import { useEffect, useState } from "react";
import Footer from "../../Footer"
import Navigation from "../../Navigation"
import { useMutation, useQuery } from '@apollo/client';
import { Container, Row, Col, Image, Form, Button, Card, Breadcrumb, Spinner, Modal } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa'
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { QUERY_GET_SMARTWATCH_QUESTION } from '../../../graphql/Query'
import { QUERY_GET_PRODUCT_BY_ID } from '../../../graphql/Query'
import '../../../ComponentsCss/sellSection/sellPricePage.css'

import { MUTATION_SEND_USEROTP, MUTATION_USER_OTP_LOGIN } from '../../../graphql/Mutations'
import swal from 'sweetalert';
import { QUERY_GET_ALL_USER } from '../../../graphql/Query'
import imglog from '../../../Images/5856-removebg-preview.png'
import Formfile from '../../../Documents/IndemnityBond.pdf'
import Userlogin from "../../User/Userlogin";

const SmartWatchPricePage = () => {
    const userId = localStorage.getItem('userId')
    const [basePrice, setBasePrice] = useState(0);
    const [productSummary, setProductSummary] = useState([]);
    const location = useLocation();
    const navigate = useNavigate()
    const { data: getSmartWatchQuestion, loading: questionLoading } = useQuery(QUERY_GET_SMARTWATCH_QUESTION);
    const { data: getProductById, loading: productLoading } = useQuery(QUERY_GET_PRODUCT_BY_ID,
        {
            variables:
            {
                "id": location.state.id

            }
        });
    const handleDownload = () => {
        // Create a new link element
        const link = document.createElement('a');

        // Set the URL of the file you want to download
        link.href = Formfile;

        // Use download attribute to set the file name
        link.download = 'Indemnity Bond.pdf';

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the body
        document.body.removeChild(link);
    }
    function calculatePriceDeduction(price, percentage) {
        percentage = parseFloat(percentage);
        const decimalPercentage = percentage / 100;
        const deduction = price * decimalPercentage;
        const finalPrice = price - deduction;
        return finalPrice;
    }
    function calculatePriceIncrement(price, percentage) {
        percentage = parseFloat(percentage);
        const decimalPercentage = percentage / 100;
        const deduction = price * decimalPercentage;
        const finalPrice = price + deduction;

        return finalPrice;
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        setProductSummary([])
        if (getProductById?.getProductById[0] && getSmartWatchQuestion?.getSmartWatchQuestion[0]) {
            let price = getProductById?.getProductById[0].productPrice;
            const selection = location.state;
            const questionPer = getSmartWatchQuestion?.getSmartWatchQuestion[0];

            if (selection.isDeviceSwitch == "no") {
                price = calculatePriceDeduction(price, questionPer?.isDeviceSwitchPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }

            if (selection.issueWithDeviceDisplay == "yes") {
                price = calculatePriceDeduction(price, questionPer?.issueWithDeviceDisplayPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceScreen == "original") {
                price = calculatePriceDeduction(price, questionPer?.deviceScreenOriginPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceScreen == "change1") {
                price = calculatePriceDeduction(price, questionPer?.deviceScreenChangesPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceScreen == "change2") {
                price = calculatePriceDeduction(price, questionPer?.deviceScreenOriginalChangedPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceEverRepair == "yes1") {
                price = calculatePriceDeduction(price, questionPer?.deviceRepairAuthCenterPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceEverRepair == "yes2") {
                price = calculatePriceDeduction(price, questionPer?.deviceRepairUnAuthCenterPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceEverRepair == "no") {
                price = calculatePriceDeduction(price, questionPer?.deviceRepairNoPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceScreen == "minor") {
                price = calculatePriceDeduction(price, questionPer?.deviceConditionMinorPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceScreen == "major") {
                price = calculatePriceDeduction(price, questionPer?.deviceConditionMajorPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceScreen == "no") {
                price = calculatePriceDeduction(price, questionPer?.deviceConditionNoScratchPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceBody == "excellent") {
                price = calculatePriceDeduction(price, questionPer?.deviceBodyConditionExcellentPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceBody == "good") {
                price = calculatePriceDeduction(price, questionPer?.deviceBodyConditionGoodPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceBody == "average") {
                price = calculatePriceDeduction(price, questionPer?.deviceBodyConditionAveragePer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceBody == "poor") {
                price = calculatePriceDeduction(price, questionPer?.deviceBodyConditionPoorPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }

            if (selection.ButtonIssue) {
                price = calculatePriceDeduction(price, questionPer?.ButtonIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Button Issue"]);
            }
            if (selection.faceSensorIssueState) {
                price = calculatePriceDeduction(price, questionPer?.FaceSensorIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Face Sensor"]);
            }
            if (selection.fingerPrintIssueState) {
                price = calculatePriceDeduction(price, questionPer?.FingerPrintIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Finger Print"]);
            }
            if (selection.AutoRestartIssue) {

                price = calculatePriceDeduction(price, questionPer?.AutoRestartIssuePer);

                setProductSummary(prevProductSummary => [...prevProductSummary, "Auto Restart"]);
            }
            if (selection.BatteryIssue) {
                price = calculatePriceDeduction(price, questionPer?.BatteryIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Battery"]);

            }
            if (selection.BluetoothIssue) {
                price = calculatePriceDeduction(price, questionPer?.BluetoothIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Bluetooth"]);

            }
            if (selection.cellularState) {
                price = calculatePriceDeduction(price, questionPer?.CellularConIssue)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Cellular connectivity"]);

            }
            if (selection.digitalCrownState) {
                price = calculatePriceDeduction(price, questionPer?.DigitalCrownIssuePer);
                setProductSummary(prevProductSummary => [...prevProductSummary, "Digital Crown"]);


            }
            if (selection.GpsIssue) {
                price = calculatePriceDeduction(price, questionPer?.GpsIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "GPS"]);


            }
            if (selection.magneticCharging) {
                price = calculatePriceDeduction(price, questionPer?.MagneticCharginPortPer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Magnetic Charging port"]);


            }
            if (selection.usbPortState) {
                price = calculatePriceDeduction(price, questionPer?.UsbPortIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Usb Port"]);
            }
            if (selection.hingeLooseState) {
                price = calculatePriceDeduction(price, questionPer?.HingeLoosePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Hinge Loose"]);
            }
            if (selection.MicIssue) {
                price = calculatePriceDeduction(price, questionPer?.MicIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Mic"]);


            }
            if (selection.chargerFaultyState) {
                price = calculatePriceDeduction(price, questionPer?.ChargerFaultyPer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Charger"]);
            }
            if (selection.hardDriveState) {
                price = calculatePriceDeduction(price, questionPer?.HardDriveFaultyPer
                );
                setProductSummary(prevProductSummary => [...prevProductSummary, "Hard Drive"]);

            }
            if (selection.ramIssueState) {
                price = calculatePriceDeduction(price, questionPer?.RamIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Ram Issue"]);


            }
            if (selection.SpeakerIssue) {
                price = calculatePriceDeduction(price, questionPer?.SpeakerIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Speaker"]);


            }
            if (selection.SensorIssue) {
                price = calculatePriceDeduction(price, questionPer?.SensorIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Sensor"]);

            }
            if (selection.WifiIssue) {
                price = calculatePriceDeduction(price, questionPer?.WifiIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Wiffi "]);

            }
            // calculating warranty Period
            if (selection.billClick) {
                if (selection.warrantyPeriod == "1") {
                    price = calculatePriceDeduction(price, questionPer?.billBelowOneMonthPer)

                } else if (selection.warrantyPeriod == "3") {
                    price = calculatePriceDeduction(price, questionPer?.billOneToThreeMonthPer)

                }
                else if (selection.warrantyPeriod == "6") {
                    price = calculatePriceDeduction(price, questionPer?.billThreeToSixMonthPer)

                }
                else if (selection.warrantyPeriod == "9") {
                    price = calculatePriceDeduction(price, questionPer?.billSixToNineMonthPer)

                }
                else if (selection.warrantyPeriod == "12") {
                    price = calculatePriceDeduction(price, questionPer?.billNineToTwelveMonthPer)

                }
                else if (selection.warrantyPeriod == "default") {
                    price = calculatePriceDeduction(price, questionPer?.billAboveOneYear)

                }

            } else {
                price = calculatePriceDeduction(price, questionPer?.HaveBillPer)

            }
            // calculating original accessories available

            if (selection.HaveBoxWithIMEI == false) {
                price = calculatePriceDeduction(price, questionPer?.HaveBoxWithIMEIPer)

            }
            if (selection.powerCableClick == false) {
                price = calculatePriceDeduction(price, questionPer?.HavePowerCablePer)

            }
            if (selection.strapClick == false) {
                price = calculatePriceDeduction(price, questionPer?.HaveStrapPer)

            }

            // calculating purchased place
            if (selection.PurchasedPlace == "India") {
                price = calculatePriceDeduction(price, questionPer?.purchasePlaceIndiaPer)

            }
            if (selection.PurchasedPlace == "Abroad") {
                price = calculatePriceDeduction(price, questionPer?.purchasePlaceAbroadPer)

            }
            // calculating Additional Insurance
            if (selection.AdditionalInsurance == "Apple Care+") {
                price = calculatePriceIncrement(price, questionPer?.addInsAppleCarePer)

            }
            if (selection.AdditionalInsurance == "Samsung Care+") {
                price = calculatePriceIncrement(price, questionPer?.addInsSamsungCarePer)

            }
            if (selection.AdditionalInsurance == "3rd Party") {
                price = calculatePriceIncrement(price, questionPer?.addInsThirdPartyPer)

            }
            if (selection.AdditionalInsurance == "Not applicable") {
                price = calculatePriceIncrement(price, questionPer?.addInsNotApplicablePer)

            }




            if (price != null) {
                price = parseFloat(price);
                if (!isNaN(price)) {
                    price = Math.floor(price);
                    setBasePrice(price);
                }
            }
        }
    }, [getSmartWatchQuestion?.getSmartWatchQuestion[0], getProductById?.getProductById[0], location.state])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const handleSubmit = () => {
        if (!userId) {
            handleClose()
            handleShow2()
        } else {
            navigate("/addaddress", {
                state: {
                    id: location.state.id, isDeviceSwitch: location.state.isDeviceSwitch, issueWithDeviceDisplay: location.state.issueWithDeviceDisplay, deviceScreen: location.state.deviceScreen, deviceEverRepair: location.state.deviceEverRepair,
                    keyBoardState: location.state.keyBoardState, trackpadState: location.state.trackpadState, SpeakerIssue: location.state.SpeakerIssue, MicIssue: location.state.MicIssue, usbPortState: location.state.usbPortState, CharginPortIssue: location.state.CharginPortIssue, webCamState: location.state.webCamState, BatteryIssue: location.state.BatteryIssue, hingeLooseState: location.state.hingeLooseState, WifiIssue: location.state.WifiIssue, BluetoothIssue: location.state.BluetoothIssue, chargerFaultyState: location.state.chargerFaultyState, AutoRestartIssue: location.state.AutoRestartIssue, EarphonePortIssue: location.state.EarphonePortIssue, graphicsCardState: location.state.graphicsCardState, hardDriveState: location.state.hardDriveState, ramIssueState: location.state.ramIssueState, productCategory: location.state.productCategory, productBrand: location.state.productBrand,
                    productName: location.state.productName, imgurl: location.state.imgurl, processorState: location.state.processorState, laptopStorage: location.state.laptopStorage, laptopRam: location.state.laptopRam, graphicsState: location.state.graphicsState, screenSizeState: location.state.screenSizeState, ConditionDeviceScreen: location.state.ConditionDeviceBody, ConditionDeviceBody: location.state.ConditionDeviceBody, HaveBoxWithIMEI: location.state.HaveBoxWithIMEI, powerCableClick: location.state.powerCableClick, adapterClick: location.state.adapterClick, PurchasedPlace: location.state.PurchasedPlace, AdditionalInsurance: location.state.AdditionalInsurance, warrantyPeriod: location.state.warrantyPeriod, defects: productSummary, strapClick: location.state.strapClick, financeClick: location.state.financeClick, basePrice: basePrice,
                    HaveBill : location.state.billClick

                }
            })
        }
    }
    //login Functionality

    const userToken = localStorage.getItem('userToken')

    const { data: userData } = useQuery(QUERY_GET_ALL_USER)

    const [validated, setValidated] = useState(false);
    const [validated2, setValidated2] = useState(false);
    const [contact, setContact] = useState('')
    const [otpSent, setOtpSent] = useState(false)
    const [gOtp, setGeneratedOtp] = useState('')
    const [enteredOtp, setEnteredOtp] = useState('')

    const [userLoginDone, setUserLoginDone] = useState(false)
    const [sendOtp] = useMutation(MUTATION_SEND_USEROTP)
    const [userOtpLogin, { data: logindata, error }] = useMutation(MUTATION_USER_OTP_LOGIN)

    // const { data: user_data } = useQuery(GET_USERDATA_BY_USERID,{
    //   variables:{
    //     "userId": `${userId}`
    //   }
    // })

    const [otpSendClick, setOtpSendClick] = useState(false)
    const [otpState, setOtpState] = useState('')
    const [otpError, setOtpError] = useState(false)
    const [otpResend, setOtpResend] = useState(false)


    function generateUserOtp(event) {
        if (contact === '' || contact.length < 10 || contact.length > 10) {
            setValidated(true)
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            const gOtp = Math.floor(1000 + Math.random() * 9000);
            setValidated(false)
            localStorage.removeItem("userOtp");
            localStorage.setItem('userOtp', gOtp);
            sendOtp({
                variables: {
                    "contact": `${contact}`,
                    "otp": `${gOtp}`
                }
            })
            swal({
                title: "OTP Send",
                text: "OTP Send on your mobile no.",
                icon: "success",
            })
            setGeneratedOtp(gOtp)
            setOtpSent(true)
        }
    }

    function handleClick(event) {
        const otp = localStorage.getItem('userOtp');
        if (otp === otpState) {
            setValidated2(true);
            event.preventDefault();
            event.stopPropagation();
            userOtpLogin({
                variables: {
                    "contact": `${contact}`,
                    "otp": `${otp}`
                }
            })

        } else {
            event.preventDefault();
            setValidated2(false);
            alert("OTP Not Match !!!")
        }
    }


    function contactEnterAgain() {
        localStorage.removeItem("userOtp")
        setOtpError(false)
        setContact('')
        setOtpState('')
        setOtpSendClick(false)
    }

    function otpSendAgain() {
        setOtpResend(true)
        setOtpError(false)
        localStorage.removeItem("userOtp")
        setOtpState('')
        generateUserOtp()
    }


    if (logindata) {
        localStorage.setItem('userId', logindata.userOtpLogin.userId)
        // setUserLoginDone(true)
        localStorage.setItem('userToken', logindata.userOtpLogin.userToken)
        localStorage.setItem('userTokenExpiration', logindata.userOtpLogin.userTokenExpiration)
        localStorage.removeItem("userOtp")
        if (userToken) {
            navigate("/addaddress", {
                state: {
                    id: location.state.id, isDeviceSwitch: location.state.isDeviceSwitch, issueWithDeviceDisplay: location.state.issueWithDeviceDisplay, deviceScreen: location.state.deviceScreen, deviceEverRepair: location.state.deviceEverRepair,
                    keyBoardState: location.state.keyBoardState, trackpadState: location.state.trackpadState, SpeakerIssue: location.state.SpeakerIssue, MicIssue: location.state.MicIssue, usbPortState: location.state.usbPortState, CharginPortIssue: location.state.CharginPortIssue, webCamState: location.state.webCamState, BatteryIssue: location.state.BatteryIssue, hingeLooseState: location.state.hingeLooseState, WifiIssue: location.state.WifiIssue, BluetoothIssue: location.state.BluetoothIssue, chargerFaultyState: location.state.chargerFaultyState, AutoRestartIssue: location.state.AutoRestartIssue, EarphonePortIssue: location.state.EarphonePortIssue, graphicsCardState: location.state.graphicsCardState, hardDriveState: location.state.hardDriveState, ramIssueState: location.state.ramIssueState, productCategory: location.state.productCategory, productBrand: location.state.productBrand,
                    productName: location.state.productName, imgurl: location.state.imgurl, processorState: location.state.processorState, laptopStorage: location.state.laptopStorage, laptopRam: location.state.laptopRam, graphicsState: location.state.graphicsState, screenSizeState: location.state.screenSizeState, ConditionDeviceScreen: location.state.ConditionDeviceBody, ConditionDeviceBody: location.state.ConditionDeviceBody, HaveBoxWithIMEI: location.state.HaveBoxWithIMEI, powerCableClick: location.state.powerCableClick, adapterClick: location.state.adapterClick, PurchasedPlace: location.state.PurchasedPlace, AdditionalInsurance: location.state.AdditionalInsurance, warrantyPeriod: location.state.warrantyPeriod, defects: productSummary, strapClick: location.state.strapClick, financeClick: location.state.financeClick, basePrice: basePrice

                }
            })
        }
    }


    let warrantyPeriod = location.state.warrantyPeriod;
    const handleSelectBrand = () => {
    navigate(`/selling/${location.state?.productCategory}`, )
    }
    const handleSelectProduct = () => {
        navigate(`/allbrands/${location.state?.productBrand}`, {
            state: {
                productCategory: location.state?.productCategory, productBrand: location.state?.productBrand,
            }
        })
    }
    return (
        <>

            <Navigation />
            <div className="banner">
                <h2>
                    Summary of Your Old {location.state.productCategory}
                </h2>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="search-input"
                    />
                    {/* <div className="search-icon">
                        <FaSearch />
                    </div> */}
                </div>
                <Breadcrumb>
                    <Breadcrumb.Item ><Link to="/">Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>
                    <a  onClick={handleSelectBrand}>  Select Brand</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={handleSelectProduct}>Sell Product</Breadcrumb.Item>
                </Breadcrumb>

            </div>
            {questionLoading || productLoading ? <Spinner animation="border" variant="success" className='mx-auto d-block' />
                : <Container style={{ marginTop: "1rem" }}>
                    <Row>
                        <Col md={6}>
                            <Card >
                                <Card.Body >
                                    <Row>
                                        <Image fluid style={{ width: "12rem" }} src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${location.state?.imgurl}`} className="mx-auto d-block" />
                                    </Row>
                                    <Row>

                                        <Col style={{ marginLeft: "2%", fontSize: "12px" }} >


                                            <div style={{ fontWeight: "bold", marginTop: "1rem" }} > 1. Is your device switching on? : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.isDeviceSwitch == "yes" ? "Yes" : "No"} </span> </div>
                                            <div style={{ fontWeight: "bold" }}> 2. Any issue with your device display : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.issueWithDeviceDisplay == "yes" ? "Yes" : "No"} </span> </div>
                                            <div style={{ fontWeight: "bold" }}> 3. Tell us about your gadget’s screen? :  <span style={{ color: "rgb(64, 167, 55)" }}> {location.state.deviceScreen === "original" ? "Original" : location.state.deviceScreen === "change1" ? "Changed(Compatible)" : "Changed(Original Bill)"}</span> </div>
                                            <div style={{ fontWeight: "bold" }}> 4. Is your device ever repaired? : <span style={{ color: "rgb(64, 167, 55)" }}> {location.state.deviceEverRepair === "yes1" ? "Yes (Authorised Service Center)" : location.state.deviceEverRepair === "yes2" ? "Yes (Unauthorised Service Center)" : "No"}</span> </div>
                                            <div style={{ fontWeight: "bold", marginTop: "1rem", marginBottom: "1rem" }}  >  Appropriate defects of your device </div>
                                            {productSummary &&
                                                productSummary.map((item, index) => {
                                                    return (
                                                        <span style={{ color: "rgb(64, 167, 55)", fontWeight: "bold", marginLeft: "1rem" }}>{item} </span>
                                                    )
                                                })
                                            }
                                            <div style={{ fontWeight: "bold", marginTop: "1rem", marginBottom: "1rem" }}  > Physical condition of your device </div>
                                            <div style={{ fontWeight: "bold" }}>  Condition of your device screen : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.ConditionDeviceScreen === "minor" ? "Minor Scratches" : location.state.ConditionDeviceScreen === "major" ? "Major Scratches" : "No Scratches"} </span></div>
                                            <div style={{ fontWeight: "bold" }}>  Condition of your device Body : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.ConditionDeviceBody === "excellent" ? "Excellent Condition" : location.state.ConditionDeviceScreen === "good" ? "Good Condition" : location.state.ConditionDeviceScreen === "average" ? "Average Condition" : "Poor Condition"} </span></div>
                                            <div style={{ fontWeight: "bold", marginTop: "1rem", marginBottom: "1rem" }}  > Original Accessories available with your Device </div>
                                            <div style={{ fontWeight: "bold" }}>
                                                {
                                                    location.state.HaveBoxWithIMEI ? <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}> Box  </span> : ""
                                                }

                                                {
                                                    location.state.powerCableClick ? <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}> Power Cable  </span> : ""
                                                }

                                                {
                                                    location.state.strapClick ? <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}> Strap </span> : ""
                                                }

                                                {
                                                    location.state.billClick ? <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}> Original Bill </span> : ""
                                                }
                                                {warrantyPeriod && (
                                                    <div>
                                                        {warrantyPeriod === "1" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: Below 1 Month
                                                            </span>
                                                        )}
                                                        {warrantyPeriod === "3" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: 1 To 3 Months
                                                            </span>
                                                        )}
                                                        {warrantyPeriod === "6" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: 3 To 6 Months
                                                            </span>
                                                        )}
                                                        {warrantyPeriod === "9" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: 6 To 9 Months
                                                            </span>
                                                        )}
                                                        {warrantyPeriod === "12" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: 9 To 12 Months
                                                            </span>
                                                        )}
                                                        {warrantyPeriod === "default" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: Above 1 Year
                                                            </span>
                                                        )}
                                                    </div>
                                                )}

                                            </div>
                                            <div style={{ fontWeight: "bold", marginTop: "1rem" }}>  Purchased place : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.PurchasedPlace} </span></div>
                                            <div style={{ fontWeight: "bold" }}>  Additional Insurance : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.AdditionalInsurance} </span> </div>
                                            <div style={{ fontWeight: "bold" }}>  Under Finance : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.financeClick} </span> </div>

                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} className="d-flex align-items-stretch">
                            <Card style={{ width: "100%" }}>
                                <Card.Body  >
                                    <Card.Title style={{ fontWeight: "bold" }} > Price Summary </Card.Title>
                                    <Card.Text style={{ marginTop: "4rem", fontWeight: "bold" }} className="sell-price" >Final Selling Price<span className="price-val" style={{ color: "rgb(64, 167, 55)" }}>RS.{basePrice} </span> </Card.Text>
                                    <Card.Footer style={{ marginTop: "3rem" }} >
                                        <Button variant="warning" type="submit" onClick={handleShow} style={{ borderRadius: '0px', width: 150, color: '#fff', fontWeight: 'bold', fontSize: '20px', backgroundColor: "#40a737", border: "0px", borderRadius: '10px' }}>
                                            Sell Watch
                                        </Button>
                                    </Card.Footer>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>}
            {/* {showAddress && <AddAddress showAddress={showAddress} setShowAddress={setShowAddress} />} */}

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered size="lg">
                <Modal.Header closeButton className="fw-bold">
                    Mandatory Note
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <ul>
                            <li><strong>If you are located outside of Bangalore, Bhubaneswar, Kolkata, or Mumbai</strong>, please download the form and fill it out with all the necessary details. Attach the required documents and hand them over along with the device.</li>
                            <li><strong>If you are located in Bangalore, Bhubaneswar, Kolkata, or Mumbai</strong>, our executive will bring the form along with them. You can fill it up at the time of handing over the device.</li>
                            <li><strong>The form is mandatory</strong> along with the device.</li>
                        </ul>

                    </p>

                    <Button type="submit" variant="danger" className='mx-auto d-block' onClick={() => handleDownload()}  > Download PDF</Button>

                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="success" className='' onClick={() => { handleSubmit() }}> Sell Watch</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show2} onHide={handleClose2} backdrop="static" keyboard={false} centered size="lg">
                <Userlogin isPurchacing={true} handleClose2={handleClose2} />
            </Modal>

        </>
    )
}

export default SmartWatchPricePage