import React, { useState } from 'react';
import { Button, Col, Container, Form, Row, Spinner, Table } from 'react-bootstrap';
import { MUTATION_CREATE_BLOG,MUTATION_DELETE_BLOG } from '../../graphql/Mutations'
import { useMutation ,useQuery} from '@apollo/client';
import { GET_ALL_BLOG } from '../../graphql/Query'
import uniqid from "uniqid";
import swal from 'sweetalert';
import AWS from "aws-sdk";
const ID = "AKIA6GB4RFKTJXDPLPOH";
const SECRET = "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";
const BUCKET_NAME = "sanjaytestbucket";

const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET,
});

const AddBlog = () => {
    const [blogItem, setBlogItem] = useState({ title: '', description: '', image: null });
    const [createBlog] = useMutation(MUTATION_CREATE_BLOG, { refetchQueries: [GET_ALL_BLOG] });
    const { data: getAllBlog, loading: blogLoading } = useQuery(GET_ALL_BLOG);
    const [deleteBlog] = useMutation(MUTATION_DELETE_BLOG, { refetchQueries: [GET_ALL_BLOG] });

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        setBlogItem({ ...blogItem, [name]: files ? files[0] : value });
    };

    const uploadImageToS3 = async (file) => {
        const uniqueId = uniqid();
        const extension = file.name.split('.').pop();
        const uniqueFileName = `hellofi-img-blog-${uniqueId}.${extension}`;

        try {
            await s3.upload({
                Bucket: BUCKET_NAME,
                Key: uniqueFileName,
                Body: file,
            }).promise();
            return uniqueFileName;
        } catch (err) {
            console.error(err);
            swal("Error", "Failed to upload image", "error");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { title, description, image } = blogItem;
        if (!image || !title || !description) {
            return swal("Warning", "Please fill all the fields", "warning");
        }

        const imageKey = await uploadImageToS3(image);
        if (imageKey) {
            try {
                await createBlog({
                    variables: {
                        blogInput: { title, description, imageKey }
                    }
                });
                swal("Success", "Blog added successfully", "success");
                setBlogItem({ title: '', description: '', image: null });
            } catch (error) {
                swal("Error", error.message, "error");
            }
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteBlog({ variables: { blogId: id } });
            swal("Success", "Blog deleted successfully", "success");
        } catch (error) {
            swal("Error", error.message, "error");
        }
    };
    let count = 1
    return (

        <Container style={{ padding: 20 }} fluid>


            <Row className="justify-content-md-center">
                <Col md={6}>
                    <div style={{ padding: "1rem" }} className='box-shadow'>
                        <h3 style={{ textAlign: 'center' }}>Add Blog Post</h3>
                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group as={Col} controlId="formTitle">
                                <Form.Control
                                    required
                                    type="text"
                                    className='txtinpt'
                                    placeholder="Blog Title"
                                    name="title"
                                    onChange={handleInputChange}
                                    value={blogItem.title}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formDescription">
                                <Form.Control
                                    required
                                    as="textarea"
                                    className='txtinpt'
                                    placeholder="Blog Description"
                                    name="description"
                                    onChange={handleInputChange}
                                    value={blogItem.description}
                                />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formImage">
                                <Form.Control
                                    type="file"
                                    className='txtinpt'
                                    name="image"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                            <Button type='submit' variant='dark' className='mt-3'>Add Blog</Button>
                        </Form>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                {
                        blogLoading ? <Spinner/> : 
            
                    <Table responsive striped bordered hover className="mt-4">
                        <thead className='table-head'>
                            <tr>
                                <th>S.no.</th>
                                <th>Title</th>
                                <th>Description</th>
                                <th>Image</th>
                                <th> Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                getAllBlog && getAllBlog?.getAllBlog?.map((blog) => {
                                    return (
                                        <tr key={blog.id}>
                                            <td>{count++}</td>
                                            <td>{blog.title}</td>
                                            <td>{blog.description}</td>
                                            <td><img src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${blog.imageKey}`} alt="blog" style={{ width: 100, height: 100 }} /></td>
                                            <td><Button variant='danger'onClick={()=> handleDelete(blog.id)}>Delete</Button></td>
                                        </tr>
                                    )
                                })
                            }
                      
                        </tbody>
                    </Table>
                        }
                </Col>
            </Row>
        </Container>
    );
};

export default AddBlog;
