import { GET_TABLET_QUESTIONS } from '../../graphql/Query'
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Table, Spinner, Modal, Button, Form } from 'react-bootstrap'
import { FaPen } from 'react-icons/fa'

import { MUTATION_UPDATE_TABLET_QUESTION } from '../../graphql/Mutations'


const AddTabletQuestionariesPer = () => {

    const [updateTabletQuestions] = useMutation(MUTATION_UPDATE_TABLET_QUESTION, {
        refetchQueries: [
            GET_TABLET_QUESTIONS
        ]
    })


    const { data: getTabletQuestions, loading: questionLoading } = useQuery(GET_TABLET_QUESTIONS);
    // const [editQuestion, setEditQuestion] = useState("");
    const [editPercentage, setEditPercentage] = useState("");
    const [questionNameState, setQuestionNameState] = useState("");


    const [showEditModal, setEditModal] = useState(false)

    const handleClickEdit = (que) => {
        setEditModal(true)
    }
    const handleEdit1 = () => {

        if (questionNameState === "isDeviceSwitchPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ableToMakeReceiveCallPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "issueWithDeviceDisplayPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceEverRepairPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "FrontCameraIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "BackCameraIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "SpeakerIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "MicIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "RingerIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "CharginPortIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "SensorIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "BatteryIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ButtonIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "WifiIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "BluetoothIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "FlashLightIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "VibratorIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "AutoRestartIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "EarphonePortIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "SimCardIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "GpsIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ConditionDeviceScreenPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ConditionDeviceBodyPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveBoxWithIMEIPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveDataCablePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveAdapterPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveEarphonesPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "AdditionalInsurancePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "PurchasedPlacePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "WarrantyPeriodPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveBoxWithIMEIPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage16gb") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage32gb") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage64gb") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage128gb") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage256gb") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage1tb") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage2tb") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram2gb") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram3gb") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram4gb") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram6gb") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram8gb") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram12gb") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram16gb") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram32gb") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram18gb") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram24gb") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenOriginPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenOriginalChangedPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenOriginPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceRepairAuthCenterPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceRepairUnAuthCenterPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceRepairNoPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceConditionMinorPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceConditionMajorPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceConditionNoScratchPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceBodyConditionExcellentPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceBodyConditionGoodPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceBodyConditionAveragePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceBodyConditionPoorPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "addInsAppleCarePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "addInsSamsungCarePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "addInsThirdPartyPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "addInsNotApplicablePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "purchasePlaceIndiaPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "purchasePlaceAbroadPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billBelowOneMonthPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billOneToThreeMonthPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billThreeToSixMonthPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billSixToNineMonthPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billNineToTwelveMonthPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billAboveOneYear") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenChangesPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveApplePencil") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveBillPer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "FaceSensorIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "FingerPrintIssuePer") {
            updateTabletQuestions({
                variables: {
                    id: `${getTabletQuestions?.getTabletQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }


    }

    function setQuestionName(qName) {
        setQuestionNameState(qName)
        handleClickEdit()
    }


    return (
        <>
            {
                questionLoading ?
                    <Spinner animation="border" variant="success" className='mx-auto d-block' />
                    :

                    <>
                        <Table responsive striped bordered hover>
                            <thead className='table-head table-head-question' >
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>Tell us about the Device Condition</th>
                                </tr>
                                <tr>
                                    <th>Questions</th>
                                    <th>Percentage deduction</th>
                                    <th>Edit Percentage</th>
                                </tr>
                                <tr>
                                    <td>Device Swithing</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.isDeviceSwitchPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("isDeviceSwitchPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Call Received </td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.ableToMakeReceiveCallPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ableToMakeReceiveCallPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Display Issue </td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.issueWithDeviceDisplayPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("issueWithDeviceDisplayPer")}><FaPen /></i> </td>
                                </tr>
                                <tr>
                                    <td>Device Screen Isuue (original) </td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.deviceScreenOriginPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceScreenOriginPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Screen Changed (Compatible) </td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.deviceScreenChangesPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceScreenChangesPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Screen Changed with Original Bill </td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.deviceScreenOriginalChangedPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceScreenOriginalChangedPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Repaired in Authorised Service Center</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.deviceRepairAuthCenterPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceRepairAuthCenterPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Repaired in UnAuthorised Service Center</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.deviceRepairUnAuthCenterPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceRepairUnAuthCenterPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> No Device Repaired </td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.deviceRepairNoPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceRepairNoPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>Want to know the exact value of your device?</th>
                                </tr>

                                <tr>
                                    <td>Front Camera Isuue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.FrontCameraIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("FrontCameraIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Back Camera Isuue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.BackCameraIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("BackCameraIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Speaker Issue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.SpeakerIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("SpeakerIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Mic Issue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.MicIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("MicIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Ringer Issue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.RingerIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("RingerIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Charging Port Issue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.CharginPortIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("CharginPortIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Senser Issue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.SensorIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("SensorIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Battery Issue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.BatteryIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("BatteryIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Button Issue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.ButtonIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ButtonIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Wiffi Issue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.WifiIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("WifiIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Bluetooth Isuue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.BluetoothIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("BluetoothIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Flash Light Issue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.FlashLightIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("FlashLightIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Vibrator Isuue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.VibratorIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("VibratorIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Auto Restart Issue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.AutoRestartIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("AutoRestartIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Ear Phone Port Issue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.EarphonePortIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("EarphonePortIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Sim Card Issue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.SimCardIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("SimCardIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>GPS Isuue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.GpsIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("GpsIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Finger Print Issue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.FingerPrintIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("FingerPrintIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Face Sensor Issue</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.FaceSensorIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("FaceSensorIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>Tell us about the cosmetic/physical condition of your device</th>
                                </tr>
                                <tr>
                                    <td>Condition of Device Screen (Minor)</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.deviceConditionMinorPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceConditionMinorPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Screen (Major)</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.deviceConditionMajorPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceConditionMajorPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Screen (No Scratches)</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.deviceConditionNoScratchPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceConditionNoScratchPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Body (Excellent)</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.deviceBodyConditionExcellentPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceBodyConditionExcellentPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Body (Good)</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.deviceBodyConditionGoodPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceBodyConditionGoodPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Body (Average)</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.deviceBodyConditionAveragePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceBodyConditionAveragePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Body (Poor)</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.deviceBodyConditionPoorPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceBodyConditionPoorPer")}><FaPen /></i></td>
                                </tr>

                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>  Select the Original Accessories available with your Device.</th>
                                </tr>
                                <tr>
                                    <td>   Box (with same IMEI/Serial number)</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.HaveBoxWithIMEIPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveBoxWithIMEIPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Charger</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.HaveDataCablePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveDataCablePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Adapter</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.HaveAdapterPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveAdapterPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Apple Pencil</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.HaveApplePencil}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveApplePencil")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Have Bill </td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.HaveBillPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveBillPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Earphones</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.HaveEarphonesPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveEarphonesPer")}><FaPen /></i></td>
                                </tr>

                                <tr>
                                    <td> Insurance AppleCare </td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.addInsAppleCarePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("addInsAppleCarePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Insurance SamsungCare </td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.addInsSamsungCarePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("addInsSamsungCarePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Insurance 3rd Prty </td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.addInsThirdPartyPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("addInsThirdPartyPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Insurance Not Applicable </td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.addInsNotApplicablePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("addInsNotApplicablePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Purchased Place (India) </td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.purchasePlaceIndiaPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("purchasePlaceIndiaPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Purchased Place (Abroad) </td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.purchasePlaceAbroadPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("purchasePlaceAbroadPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (below 1 month) </td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.billBelowOneMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billBelowOneMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (1 to 3 month) </td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.billOneToThreeMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billOneToThreeMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (3 to 6 month)</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.billThreeToSixMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billThreeToSixMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (6 to 9 month)</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.billSixToNineMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billSixToNineMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (9to 12 month) </td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.billNineToTwelveMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billNineToTwelveMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period ( Above 1 year) </td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.billAboveOneYear}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billAboveOneYear")}><FaPen /></i></td>
                                </tr>

                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>  Storage Price </th>
                                </tr>
                                <tr>
                                    <td> 16 GB</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.storage16gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage16gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 32 GB</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.storage32gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage32gb")}><FaPen /></i></td>
                                </tr>

                                <tr>
                                    <td> 64 GB</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.storage64gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage64gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 128 GB</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.storage128gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage128gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 256 GB</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.storage256gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage256gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 1 TB</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.storage1tb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage1tb")}><FaPen /></i></td>
                                </tr>
                                <tr> 
                                    <td> 2 TB</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.storage2tb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage2tb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>  Ram Price</th>
                                </tr>
                                <tr>
                                    <td> 2 GB</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.ram2gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram2gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 3 GB</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.ram3gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram3gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 4 GB</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.ram4gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram4gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 6 GB</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.ram6gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram6gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 8 GB</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.ram8gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram8gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 12 GB</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.ram12gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram12gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 16 GB</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.ram16gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram16gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 32 GB</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.ram32gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram32gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 18 GB</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.ram18gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram18gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 24 GB</td>
                                    <td>{getTabletQuestions?.getTabletQuestions[0]?.ram24gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram24gb")}><FaPen /></i></td>
                                </tr>

                            </thead>
                        </Table>
                    </>
            }
            {

            }
            <Modal show={showEditModal} onHide={() => setEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Percentage</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="number" placeholder="Enter value" onChange={(e) => setEditPercentage(e.target.value)} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => { handleEdit1(); }}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default AddTabletQuestionariesPer