import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import Heading from "../shared/Heading";
import ServiceCard from "./ServiceCard";
import SellService from "../../../../../Images/home/services/SellService.png";
import BuyService from "../../../../../Images/home/services/BuyService.png";
import ExchangeService from "../../../../../Images/home/services/ExchangeService.png";

const Services = () => {
  const theme = useTheme();
  const isDeskTop = useMediaQuery(theme.breakpoints.up("desktop"));
  const isTabletScreen = useMediaQuery(
    theme.breakpoints.between("laptop", "desktop")
  );

  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const handleSlideChange = (swiper) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: { xs: "0 0.5rem", laptop: 0 },
          gap: {
            mobile: "0.5rem",
            tablet: "1rem",
            laptop: "1rem",
            desktop: "1rem",
          },
        }}
      >
        <Heading text={"Our Services"} />
        <Box sx={{ flexGrow: "1", width: "100%" }}>
          {isDeskTop ? (
            <Grid container spacing={2} sx={{ justifyContent: "center" }}>
              <Grid item xs={12} laptop={6} desktop={4}>
                <ServiceCard
                  img={SellService}
                  title={"Earn"}
                  subtitleBefore={"while you"}
                  bold={"Sell"}
                  subtitleAfter={"your Electronic Gadgets"}
                  buttonName={"Sell it Now"}
                />
              </Grid>
              <Grid item xs={12} laptop={6} desktop={4}>
                <ServiceCard
                  img={BuyService}
                  title={"Save"}
                  subtitleBefore={"while you"}
                  bold={"Buy"}
                  subtitleAfter={"Preowned Gadgets"}
                  buttonName={"Buy Now"}
                />
              </Grid>
              <Grid item xs={12} laptop={6} desktop={4}>
                <ServiceCard
                  img={ExchangeService}
                  title={"Upgrade"}
                  subtitleBefore={"while you"}
                  bold={"Exchange"}
                  subtitleAfter={"Gadgets"}
                  buttonName={"Exchange Now"}
                />
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ position: "relative", height: "100%" }}>
              <Swiper
                modules={[Navigation]}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                spaceBetween={10}
                slidesPerView={isTabletScreen ? 2 : 1}
                onSlideChange={handleSlideChange}
                onInit={handleSlideChange}
                className="swiper-centered"
                style={{
                  "--swiper-navigation-color": "#000",
                  "--swiper-pagination-color": "#000",
                }}
              >
                <SwiperSlide>
                  <ServiceCard
                    img={SellService}
                    title={"Earn"}
                    subtitleBefore={"while you"}
                    bold={"Sell"}
                    subtitleAfter={"your Electronic Gadgets"}
                    buttonName={"Sell it Now"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ServiceCard
                    img={BuyService}
                    title={"Save"}
                    subtitleBefore={"while you"}
                    bold={"Buy"}
                    subtitleAfter={"Preowned Gadgets"}
                    buttonName={"Buy Now"}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ServiceCard
                    img={ExchangeService}
                    title={"Upgrade"}
                    subtitleBefore={"while you"}
                    bold={"Exchange"}
                    subtitleAfter={"Gadgets"}
                    buttonName={"Exchange Now"}
                  />
                </SwiperSlide>
              </Swiper>
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  zIndex: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <button
                  className={`swiper-button-prev ${
                    isBeginning ? "swiper-button-hidden" : ""
                  }`}
                />
                <button
                  className={`swiper-button-next ${
                    isEnd ? "swiper-button-hidden" : ""
                  }`}
                />
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      <style jsx>{`
        .swiper-centered {
          position: relative;
        }

        .swiper-button-next,
        .swiper-button-prev {
          position: absolute;
          top: 50% !important;
          transform: translateY(-50%) !important;
          background-color: rgba(255, 255, 255, 0.75);
          width: 40px;
          height: 40px;
          border-radius: 50%;
          transition: all 0.3s ease;
          z-index: 10;
          margin: 0 !important;
        }

        .swiper-button-prev {
          left: 10px !important; /* Adjust this value to control distance from edge */
        }

        .swiper-button-next {
          right: 10px !important; /* Adjust this value to control distance from edge */
        }

        .swiper-button-next::after,
        .swiper-button-prev::after {
          font-size: 20px !important;
          font-weight: bold;
        }

        /* Hide navigation arrows when at the beginning/end */
        .swiper-button-hidden {
          display: none !important;
        }

        /* Make sure buttons appear on top of slides */
        .swiper-centered .swiper-wrapper {
          position: relative;
        }
        @media (max-width: 520px) {
         .swiper-button-next,
         .swiper-button-prev {
           display: flex !important; /* Ensure visibility at smaller screen sizes */
         }
      `}</style>
    </>
  );
};

export default Services;
