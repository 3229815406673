import React, { useState } from 'react'
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap'
import '../ComponentsCss/DealerCard.css'
import corporate from '../Images/homepage/corporate.png';
import vendor from '../Images/homepage/vendor.png';
import { useNavigate } from "react-router-dom";

export default function Dealer() {
    const navigate = useNavigate()
    return (
        <>
            <Container className='cardbgservice2 mx-auto d-block' style={{ marginTop: 5 }} fluid>
                <Row>
                    <Col md={1}></Col>
                    <Col md={4} style={{ marginTop: 20, marginBottom: 20 }}>
                        <Card className='dealercard'>
                            <Image src={corporate} style={{ height: '150px', width: '200px' }}></Image>
                            <h3 className='txtdealer'>Sell/Buy as a Corporate</h3>
                            <Button className='btndealer' onClick={() => navigate("/corporate-contact")} >Continue</Button>
                        </Card>
                    </Col>
                    <Col md={2}>
                    </Col>
                    <Col md={4} style={{ marginTop: 20, marginBottom: 20 }}>
                        <Card className='dealercard'>
                            <Image src={vendor} style={{ height: '120px', width: '200px' }}></Image>
                            <h3 className='txtdealer'>Sell/Buy as a Vendor</h3>
                            <Button className='btndealer' onClick={() => navigate("/vendor-contact")} >Continue</Button>
                        </Card>
                    </Col>
                    <Col md={1}></Col>
                </Row>
            </Container>
            <Container>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                    <div className='Selline'></div>
                    <div>
                        <h3 style={{ marginTop: 30, marginLeft: 10, fontWeight: 500 }}>Don't worry if we do not have the option of <span style={{ color: "#2ecc71" }}>Instant Payment</span> at your pincode. </h3>
                        <h3 style={{ marginLeft: 10 }}> <span style={{ fontSize: 18 }}>You can still <span style={{ color: "#2ecc71" }}> Sell your Device </span> with us in a few simple steps.</span></h3>
                    </div>
                </div>
            </Container>
        </>
    )
}
