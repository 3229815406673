import React, { useEffect } from "react";
import Footer from "../Footer";
import Navigation from "../Navigation";
// import "../../ComponentsCss/ReturnRefundPolicy.css"; // Ensure this CSS file exists for styling

const ReturnPolicy = () => {
    useEffect(() => { 
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navigation />
            <div className="term-container">
                <div className="policy-content px-3" style={{ textAlign: "justify" }}>
                    <h2 className="mb-5">Return and Refund Policy</h2>

                    <h3>1. Product Eligibility:</h3>
                    <p><strong>Product(s) eligibility:</strong></p>
                    <ul>
                        <li>Disparity in the product delivered and product details: If the product does not match the specifications mentioned under the product description.</li>
                        <li>Damaged or defective product.</li>
                        <li>The accessories and the packaging are intact and to be returned along with the product.</li>
                        <li>The product is in its original condition, i.e., the condition in which it was delivered and received by the customer.</li>
                        <li>QC stickers shall be intact like it was during delivery.</li>
                    </ul>

                    <h3>2. Time Frame:</h3>
                    <p>For products that are subject to disparity from description/different product received, the return request should be made the same day as delivery.</p>

                    <h3>3. Return and Refund Process:</h3>
                    <p>The return request can be raised by logging in with your registered email/phone number (must be the same as used during purchase) or by calling us on our registered phone number or by emailing to us at contact@hellofi.in with your registered email address.</p>
                    <p>Kindly note any third-party activity regarding return/refund/replacement request will be discarded.</p>
                    <p>Fill in the reason for return.</p>
                    <p>Based on your reason video and/or other form of graphical proof like picture may need to be attached. Failing to do so within 24hrs will lead to the request be declined and new request cannot be raised regarding the same product(s).</p>
                    <p>Upon verification, return request will be approved and a pickup will be scheduled from our end you can confirm the same or request for an alternative pickup date/time.</p>
                    <p>Once the product reaches our facility, refund will be initiated within 3-5 business days post physical verification.</p>
                    <p>Product taken out of our premises by the buyer in person or buyers representative shall not be taken back without any further internal issue assuming it has been physically/functionally checked by the said person in our office premises itself.</p>

                    <h3>4. Warranty from Manufacturer:</h3>
                    <p>For products covered under warranty from the manufacturer, the complaint issue(s) needs to be raised with the manufacturer directly.</p>

                    <h3>5. Replacement Policy:</h3>
                    <p>Add the product you wish to replace your current product with to the Wishlist, in your account with us. The account shall be the same used for making the purchase.</p>
                    <p>Once the product condition is verified by our team, we will notify you and you need to pay only the differential amount, if any.</p>
                    <p>Product will be delivered within 7 business days from the date of transaction completion.</p>

                    <h3>6. Additional Charges:</h3>
                    <p>All return requests are subjects to return shipping cost except under the condition where an error is from the company’s end; exclusive of products meeting the eligibility conditions.</p>

                    <p>For further enquiry feel free to reach out to us by writing to us on <span style={{ color: "#0563C1", textDecoration: "underline" }}>
                        <strong>
                            <a href="mailto:contact@hellofi.in">contact@hellofi.in</a>
                        </strong>
                    </span></p>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ReturnPolicy;
