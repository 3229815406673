import { useEffect, useState } from "react";
import Footer from "../../Footer"
import Navigation from "../../Navigation"
import { useMutation, useQuery } from '@apollo/client';
import { Container, Row, Col, Image, Form, Button, Card, Breadcrumb, Spinner, Modal } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa'
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { QUERY_GET_QUESTIONS } from '../../../graphql/Query'
import { QUERY_GET_PRODUCT_BY_ID } from '../../../graphql/Query'

import { MUTATION_SEND_USEROTP, MUTATION_USER_OTP_LOGIN } from '../../../graphql/Mutations'
import swal from 'sweetalert';
import { QUERY_GET_ALL_USER } from '../../../graphql/Query'
import imglog from '../../../Images/5856-removebg-preview.png'
import '../../../ComponentsCss/sellSection/sellPricePage.css'
import Formfile from '../../../Documents/IndemnityBond.pdf'
import Userlogin from "../../User/Userlogin";

const ProductPrice = () => {
    const userId = localStorage.getItem('userId')
    const [basePrice, setBasePrice] = useState(0);
    const [productSummary, setProductSummary] = useState([]);
    const location = useLocation();
    const navigate = useNavigate()
    const { data: getQuestions, loading: questionLoading } = useQuery(QUERY_GET_QUESTIONS);
    const { data: getProductById, loading: productLoading } = useQuery(QUERY_GET_PRODUCT_BY_ID,
        {
            variables:
            {
                "id": location.state.id
            }
        });


    const handleDownload = () => {
        // Create a new link element
        const link = document.createElement('a');

        // Set the URL of the file you want to download
        link.href = Formfile;

        // Use download attribute to set the file name
        link.download = 'Indemnity Bond.pdf';

        // Append the link to the body
        document.body.appendChild(link);

        // Programmatically click the link to trigger the download
        link.click();

        // Remove the link from the body
        document.body.removeChild(link);
    }

    function calculatePriceDeduction(price, percentage) {
        percentage = parseFloat(percentage);
        const decimalPercentage = percentage / 100;
        const deduction = price * decimalPercentage;
        const finalPrice = price - deduction;
        return finalPrice;
    }
    function calculatePriceIncrement(price, percentage) {
        percentage = parseFloat(percentage);
        const decimalPercentage = percentage / 100;
        const deduction = price * decimalPercentage;
        const finalPrice = price + deduction;

        return finalPrice;
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        setProductSummary([])
        if (getProductById?.getProductById[0] && getQuestions?.getQuestions[0]) {
            let price = getProductById?.getProductById[0].productPrice;
            const selection = location.state;
            const questionPer = getQuestions?.getQuestions[0];

            if (selection.BackCameraIssue) {
                price = calculatePriceDeduction(price, questionPer?.BackCameraIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.faceSensorIssueState) {
                price = calculatePriceDeduction(price, questionPer?.FaceSensorIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Face Sensor"]);
            }
            if (selection.fingerPrintIssueState) {
                price = calculatePriceDeduction(price, questionPer?.FingerPrintIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Finger Print"]);
            }
            if (selection.isDeviceSwitch == "no") {
                price = calculatePriceDeduction(price, questionPer?.isDeviceSwitchPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ableToMakeReceiveCall == "no") {
                price = calculatePriceDeduction(price, questionPer?.ableToMakeReceiveCallPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.issueWithDeviceDisplay == "yes") {
                price = calculatePriceDeduction(price, questionPer?.issueWithDeviceDisplayPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceScreen == "original") {
                price = calculatePriceDeduction(price, questionPer?.deviceScreenOriginPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceScreen == "change1") {
                price = calculatePriceDeduction(price, questionPer?.deviceScreenChangesPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceScreen == "change2") {
                price = calculatePriceDeduction(price, questionPer?.deviceScreenOriginalChangedPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceEverRepair == "yes1") {
                price = calculatePriceDeduction(price, questionPer?.deviceRepairAuthCenterPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceEverRepair == "yes2") {
                price = calculatePriceDeduction(price, questionPer?.deviceRepairUnAuthCenterPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.deviceEverRepair == "no") {
                price = calculatePriceDeduction(price, questionPer?.deviceRepairNoPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceScreen == "minor") {
                price = calculatePriceDeduction(price, questionPer?.deviceConditionMinorPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceScreen == "major") {
                price = calculatePriceDeduction(price, questionPer?.deviceConditionMajorPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceScreen == "no") {
                price = calculatePriceDeduction(price, questionPer?.deviceConditionNoScratchPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceBody == "excellent") {
                price = calculatePriceDeduction(price, questionPer?.deviceBodyConditionExcellentPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceBody == "good") {
                price = calculatePriceDeduction(price, questionPer?.deviceBodyConditionGoodPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceBody == "average") {
                price = calculatePriceDeduction(price, questionPer?.deviceBodyConditionAveragePer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }
            if (selection.ConditionDeviceBody == "poor") {
                price = calculatePriceDeduction(price, questionPer?.deviceBodyConditionPoorPer)
                // setProductSummary(prevProductSummary => [...prevProductSummary, "Back Camera"]);
            }

            if (selection.AutoRestartIssue) {

                price = calculatePriceDeduction(price, questionPer?.AutoRestartIssuePer);

                setProductSummary(prevProductSummary => [...prevProductSummary, "Auto Restart"]);
            }
            if (selection.BatteryIssue) {
                price = calculatePriceDeduction(price, questionPer?.BatteryIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Battery"]);

            }
            if (selection.BluetoothIssue) {
                price = calculatePriceDeduction(price, questionPer?.BluetoothIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Bluetooth"]);

            }
            if (selection.ButtonIssue) {
                price = calculatePriceDeduction(price, questionPer?.ButtonIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Button"]);

            }
            if (selection.CharginPortIssue) {
                price = calculatePriceDeduction(price, questionPer?.CharginPortIssuePer);
                setProductSummary(prevProductSummary => [...prevProductSummary, "Charging Port"]);


            }
            if (selection.EarphonePortIssue) {
                price = calculatePriceDeduction(price, questionPer?.EarphonePortIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Earphones"]);


            }
            if (selection.FlashLightIssue) {
                price = calculatePriceDeduction(price, questionPer?.FlashLightIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Flash Light"]);


            }
            if (selection.FrontCameraIssue) {
                price = calculatePriceDeduction(price, questionPer?.FrontCameraIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Front Camera"]);
            }
            if (selection.GpsIssue) {
                price = calculatePriceDeduction(price, questionPer?.GpsIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "GPS"]);
            }
            if (selection.MicIssue) {
                price = calculatePriceDeduction(price, questionPer?.MicIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Mic"]);


            }
            if (selection.RingerIssue) {
                price = calculatePriceDeduction(price, questionPer?.RingerIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Ringer Issue"]);
            }
            if (selection.SensorIssue) {
                price = calculatePriceDeduction(price, questionPer?.SensorIssuePer);
                setProductSummary(prevProductSummary => [...prevProductSummary, "Sensor"]);

            }
            if (selection.SimCardIssue) {
                price = calculatePriceDeduction(price, questionPer?.SimCardIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Sim Card"]);


            }
            if (selection.SpeakerIssue) {
                price = calculatePriceDeduction(price, questionPer?.SpeakerIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Speaker"]);


            }
            if (selection.VibratorIssue) {
                price = calculatePriceDeduction(price, questionPer?.VibratorIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "vibrator"]);

            }
            if (selection.WifiIssue) {
                price = calculatePriceDeduction(price, questionPer?.WifiIssuePer)
                setProductSummary(prevProductSummary => [...prevProductSummary, "Wiffi "]);


            }
            // calculating warranty Period
            if (selection.HaveBill) {

                if (selection.WarrantyPeriod == "1") {
                    price = calculatePriceDeduction(price, questionPer?.billBelowOneMonthPer)

                } else if (selection.WarrantyPeriod == "3") {
                    price = calculatePriceDeduction(price, questionPer?.billOneToThreeMonthPer)

                }
                else if (selection.WarrantyPeriod == "6") {
                    price = calculatePriceDeduction(price, questionPer?.billThreeToSixMonthPer)

                }
                else if (selection.WarrantyPeriod == "9") {
                    price = calculatePriceDeduction(price, questionPer?.billSixToNineMonthPer)

                }
                else if (selection.WarrantyPeriod == "12") {
                    price = calculatePriceDeduction(price, questionPer?.billNineToTwelveMonthPer)

                }
                else if (selection.WarrantyPeriod == "default") {
                    price = calculatePriceDeduction(price, questionPer?.billAboveOneYear)

                }

            } else {
                price = calculatePriceDeduction(price, questionPer?.HaveBillPer)

            }
            // calculating original accessories available
            if (selection.HaveAdapter == false) {
                price = calculatePriceDeduction(price, questionPer?.HaveAdapterPer)

            }
            if (selection.HaveBoxWithIMEI == false) {
                price = calculatePriceDeduction(price, questionPer?.HaveBoxWithIMEIPer)

            }
            if (selection.HaveDataCable == false) {
                price = calculatePriceDeduction(price, questionPer?.HaveDataCablePer)

            }
            if (selection.HaveEarphones == false) {
                price = calculatePriceDeduction(price, questionPer?.HaveEarphonesPer)

            }
            // calculating purchased place
            if (selection.PurchasedPlace == "India") {
                price = calculatePriceDeduction(price, questionPer?.purchasePlaceIndiaPer)

            }
            if (selection.PurchasedPlace == "Abroad") {
                price = calculatePriceDeduction(price, questionPer?.purchasePlaceAbroadPer)

            }
            // calculating Additional Insurance 
            if (selection.AdditionalInsurance == "Apple Care+") {
                price = calculatePriceIncrement(price, questionPer?.addInsAppleCarePer)

            }
            if (selection.AdditionalInsurance == "Samsung Care+") {
                price = calculatePriceIncrement(price, questionPer?.addInsSamsungCarePer)

            }
            if (selection.AdditionalInsurance == "3rd Party") {
                price = calculatePriceIncrement(price, questionPer?.addInsThirdPartyPer)

            }
            if (selection.AdditionalInsurance == "Not applicable") {
                price = calculatePriceIncrement(price, questionPer?.addInsNotApplicablePer)

            }
            // calculating Ram 
            if (selection.ram == "2 GB") {
                price += parseInt(questionPer?.ram2gb)
            }
            if (selection.ram == "3 GB") {
                price += parseInt(questionPer?.ram3gb)
            }
            if (selection.ram == "4 GB") {
                price += parseInt(questionPer?.ram4gb)
            }
            if (selection.ram == "6 GB") {
                price += parseInt(questionPer?.ram6gb)
            }
            if (selection.ram == "8 GB") {
                price += parseInt(questionPer?.ram8gb)
            }
            if (selection.ram == "12 GB") {
                price += parseInt(questionPer?.ram12gb)
            }
            if (selection.ram == "16 GB") {
                price += parseInt(questionPer?.ram16gb)
            }
            if (selection.ram == "32 GB") {
                price += parseInt(questionPer?.ram32gb)
            }
            if (selection.ram == "18 GB") {
                price += parseInt(questionPer?.ram18gb)
            }
            if (selection.ram == "24 GB") {
                price += parseInt(questionPer?.ram24gb)
            }
            // calculating Storage
            if (selection.storage == "16 GB") {
                price += parseInt(questionPer?.storage16gb)
            }
            if (selection.storage == "32 GB") {
                price += parseInt(questionPer?.storage32gb)
            }
            if (selection.storage == "64 GB") {
                price += parseInt(questionPer?.storage64gb)
            }
            if (selection.storage == "128 GB") {
                price += parseInt(questionPer?.storage128gb)
            }
            if (selection.storage == "256 GB") {
                price += parseInt(questionPer?.storage256gb)
            }
            if (selection.storage == "1 TB") {
                price += parseInt(questionPer?.storage1tb)
            }
            if (selection.storage == "2 TB") {
                price += parseInt(questionPer?.storage2tb)
            }


            if (price != null) {
                price = parseFloat(price);
                if (!isNaN(price)) {
                    price = Math.floor(price);
                    setBasePrice(price);
                }
            }
        }
    }, [getQuestions?.getQuestions[0], getProductById?.getProductById[0], location.state])

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const handleSubmit = () => {
        if (!userId) {
            handleClose()
            handleShow2()
        } else {
            navigate("/addaddress", {
                state: {
                    id: location.state.id, basePrice: basePrice, imgurl: location.state.imgurl, ram: location.state.ram, storage: location.state.storage, isDeviceSwitch: location.state.isDeviceSwitch, ableToMakeReceiveCall: location.state.ableToMakeReceiveCall, issueWithDeviceDisplay: location.state.issueWithDeviceDisplay, deviceScreen: location.state.deviceScreen, deviceEverRepair: location.state.deviceEverRepair,
                    FrontCameraIssue: location.state.FrontCameraIssue, BackCameraIssue: location.state.BackCameraIssue, SpeakerIssue: location.state.SpeakerIssue, MicIssue: location.state.MicIssue, RingerIssue: location.state.RingerIssue, CharginPortIssue: location.state.CharginPortIssue, SensorIssue: location.state.SensorIssue, BatteryIssue: location.state.BatteryIssue,
                    ButtonIssue: location.state.ButtonIssue, WifiIssue: location.state.WifiIssue, BluetoothIssue: location.state.BluetoothIssue, FlashLightIssue: location.state.FlashLightIssue, AutoRestartIssue: location.state.AutoRestartIssue, EarphonePortIssue: location.state.EarphonePortIssue, GpsIssue: location.state.GpsIssue, VibratorIssue: location.state.VibratorIssue,
                    SimCardIssue: location.state.SimCardIssue, ConditionDeviceScreen: location.state.ConditionDeviceScreen, ConditionDeviceBody: location.state.ConditionDeviceBody, HaveBoxWithIMEI: location.state.HaveBoxWithIMEI, HaveDataCable: location.state.HaveDataCable, HaveAdapter: location.state.HaveAdapter, HaveEarphones: location.state.HaveEarphones, PurchasedPlace: location.state.PurchasedPlace, AdditionalInsurance: location.state.AdditionalInsurance, HaveBill: location.state.HaveBill
                    , WarrantyPeriod: location.state.WarrantyPeriod, productCategory: location.state.productCategory,
                    productBrand: location.state.productBrand,
                    productName: location.state.productName, defects: productSummary, financeClick: location.state.financeClick
                }
            })
        }
    }
    //login Functionality

    const userToken = localStorage.getItem('userToken')

    const { data: userData } = useQuery(QUERY_GET_ALL_USER)

    const [validated, setValidated] = useState(false);
    const [validated2, setValidated2] = useState(false);
    const [contact, setContact] = useState('')
    const [otpSent, setOtpSent] = useState(false)
    const [gOtp, setGeneratedOtp] = useState('')
    const [enteredOtp, setEnteredOtp] = useState('')

    const [userLoginDone, setUserLoginDone] = useState(false)
    const [sendOtp] = useMutation(MUTATION_SEND_USEROTP)
    const [userOtpLogin, { data: logindata, error }] = useMutation(MUTATION_USER_OTP_LOGIN)

    // const { data: user_data } = useQuery(GET_USERDATA_BY_USERID,{
    //   variables:{
    //     "userId": `${userId}`
    //   }
    // })

    const [otpSendClick, setOtpSendClick] = useState(false)
    const [otpState, setOtpState] = useState('')
    const [otpError, setOtpError] = useState(false)
    const [otpResend, setOtpResend] = useState(false)


    function generateUserOtp(event) {
        if (contact === '' || contact.length < 10 || contact.length > 10) {
            setValidated(true)
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            const gOtp = Math.floor(1000 + Math.random() * 9000);
            setValidated(false)
            localStorage.removeItem("userOtp");
            localStorage.setItem('userOtp', gOtp);
            sendOtp({
                variables: {
                    "contact": `${contact}`,
                    "otp": `${gOtp}`
                }
            })
            swal({
                title: "OTP Send",
                text: "OTP Send on your mobile no.",
                icon: "success",
            })
            setGeneratedOtp(gOtp)
            setOtpSent(true)
        }
    }

    function handleClick(event) {
        const otp = localStorage.getItem('userOtp');
        if (otp === otpState) {
            setValidated2(true);
            event.preventDefault();
            event.stopPropagation();
            userOtpLogin({
                variables: {
                    "contact": `${contact}`,
                    "otp": `${otp}`
                }
            })

        } else {
            event.preventDefault();
            setValidated2(false);
            alert("OTP Not Match !!!")
        }
    }


    function contactEnterAgain() {
        localStorage.removeItem("userOtp")
        setOtpError(false)
        setContact('')
        setOtpState('')
        setOtpSendClick(false)
    }

    function otpSendAgain() {
        setOtpResend(true)
        setOtpError(false)
        localStorage.removeItem("userOtp")
        setOtpState('')
        generateUserOtp()
    }


    if (logindata) {
        localStorage.setItem('userId', logindata.userOtpLogin.userId)
        // setUserLoginDone(true)
        localStorage.setItem('userToken', logindata.userOtpLogin.userToken)
        localStorage.setItem('userTokenExpiration', logindata.userOtpLogin.userTokenExpiration)
        localStorage.removeItem("userOtp")
        if (userToken) {
            navigate("/addaddress", {
                state: {
                    id: location.state.id, basePrice: basePrice, imgurl: location.state.imgurl, ram: location.state.ram, storage: location.state.storage, isDeviceSwitch: location.state.isDeviceSwitch, ableToMakeReceiveCall: location.state.ableToMakeReceiveCall, issueWithDeviceDisplay: location.state.issueWithDeviceDisplay, deviceScreen: location.state.deviceScreen, deviceEverRepair: location.state.deviceEverRepair,
                    FrontCameraIssue: location.state.FrontCameraIssue, BackCameraIssue: location.state.BackCameraIssue, SpeakerIssue: location.state.SpeakerIssue, MicIssue: location.state.MicIssue, RingerIssue: location.state.RingerIssue, CharginPortIssue: location.state.CharginPortIssue, SensorIssue: location.state.SensorIssue, BatteryIssue: location.state.BatteryIssue,
                    ButtonIssue: location.state.ButtonIssue, WifiIssue: location.state.WifiIssue, BluetoothIssue: location.state.BluetoothIssue, FlashLightIssue: location.state.FlashLightIssue, AutoRestartIssue: location.state.AutoRestartIssue, EarphonePortIssue: location.state.EarphonePortIssue, GpsIssue: location.state.GpsIssue, VibratorIssue: location.state.VibratorIssue,
                    SimCardIssue: location.state.SimCardIssue, ConditionDeviceScreen: location.state.ConditionDeviceScreen, ConditionDeviceBody: location.state.ConditionDeviceBody, HaveBoxWithIMEI: location.state.HaveBoxWithIMEI, HaveDataCable: location.state.HaveDataCable, HaveAdapter: location.state.HaveAdapter, HaveEarphones: location.state.HaveEarphones, PurchasedPlace: location.state.PurchasedPlace, AdditionalInsurance: location.state.AdditionalInsurance, HaveBill: location.state.HaveBill
                    , WarrantyPeriod: location.state.WarrantyPeriod, productCategory: location.state.productCategory,
                    productBrand: location.state.productBrand,
                    productName: location.state.productName, defects: productSummary, financeClick: location.state.financeClick
                }
            })
        }
    }

    // if (userToken) {
    //     navigate("/addaddress", { state: { id: location.state.id, basePrice: basePrice, imgurl: getProductById?.getProductById[0].image, } })
    // }

    // if (userLoginDone) {
    //     window.location.reload()
    //     return <Navigate to='/userDashboard' />
    // }

    let warrantyPeriod = location.state.WarrantyPeriod;
    const handleSelectBrand = () => {
        navigate(`/selling/${location.state?.productCategory}`,)
    }
    const handleSelectProduct = () => {
        navigate(`/allbrands/${location.state?.productBrand}`, {
            state: {
                productCategory: location.state?.productCategory, productBrand: location.state?.productBrand,
            }
        })
    }
    return (
        <>

            <Navigation />
            <div className="banner">
                <h2>
                    Summary of Your Old {location.state.productCategory}
                </h2>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="search-input"
                    />
                    {/* <div className="search-icon">
                        <FaSearch />
                    </div> */}
                </div>
                <Breadcrumb>
                    <Breadcrumb.Item ><Link to="/">Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a onClick={handleSelectBrand}>  Select Brand</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={handleSelectProduct} >Sell Product</Breadcrumb.Item>
                </Breadcrumb>

            </div>
            {questionLoading || productLoading ? <Spinner animation="border" variant="success" className='mx-auto d-block' />
                : <Container style={{ marginTop: "1rem" }}>
                    <Row>
                        <Col md={6}>
                            <Card >
                                <Card.Body >
                                    <Row>
                                        <Image fluid style={{ width: "12rem" }} src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${location.state?.imgurl}`} className="mx-auto d-block" />
                                    </Row>
                                    <Row>

                                        <Col style={{ marginLeft: "2%", fontSize: "12px" }} >

                                            <div style={{ fontWeight: "bold", marginTop: "1rem" }}> Storage : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.storage} </span> </div>
                                            <div style={{ fontWeight: "bold" }}> Ram : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.ram} </span> </div>

                                            <div style={{ fontWeight: "bold", marginTop: "1rem" }} > 1. Is your device switching on? : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.isDeviceSwitch == "yes" ? "Yes" : "No"} </span> </div>
                                            <div style={{ fontWeight: "bold" }}> 2. Able to make and receive calls? : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.ableToMakeReceiveCall == "yes" ? "Yes" : "No"} </span> </div>
                                            <div style={{ fontWeight: "bold" }}> 3. Any issue with your device display : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.issueWithDeviceDisplay == "yes" ? "Yes" : "No"} </span> </div>
                                            <div style={{ fontWeight: "bold" }}> 4. Tell us about your gadget’s screen? :  <span style={{ color: "rgb(64, 167, 55)" }}> {location.state.deviceScreen === "original" ? "Original" : location.state.deviceScreen === "change1" ? "Changed(Compatible)" : "Changed(Original Bill)"}</span> </div>
                                            <div style={{ fontWeight: "bold" }}> 5. Is your device ever repaired? : <span style={{ color: "rgb(64, 167, 55)" }}> {location.state.deviceEverRepair === "yes1" ? "Yes (Authorised Service Center)" : location.state.deviceEverRepair === "yes2" ? "Yes (Unauthorised Service Center)" : "No"}</span> </div>
                                            <div style={{ fontWeight: "bold", marginTop: "1rem", marginBottom: "1rem" }}  >  Appropriate defects of your device </div>
                                            {productSummary &&
                                                productSummary.map((item, index) => {
                                                    return (
                                                        <span style={{ color: "rgb(64, 167, 55)", fontWeight: "bold", marginLeft: "1rem" }}>{item} </span>
                                                    )
                                                })
                                            }
                                            <div style={{ fontWeight: "bold", marginTop: "1rem", marginBottom: "1rem" }}  > Physical condition of your device </div>
                                            <div style={{ fontWeight: "bold" }}>  Condition of your device screen : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.ConditionDeviceScreen === "minor" ? "Minor Scratches" : location.state.ConditionDeviceScreen === "major" ? "Major Scratches" : "No Scratches"} </span></div>
                                            <div style={{ fontWeight: "bold" }}>  Condition of your device Body : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.ConditionDeviceBody === "excellent" ? "Excellent Condition" : location.state.ConditionDeviceScreen === "good" ? "Good Condition" : location.state.ConditionDeviceScreen === "average" ? "Average Condition" : "Poor Condition"} </span></div>
                                            <div style={{ fontWeight: "bold", marginTop: "1rem", marginBottom: "1rem" }}  > Original Accessories available with your Device </div>
                                            <div style={{ fontWeight: "bold" }}>
                                                {
                                                    location.state.HaveBoxWithIMEI ? <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}> Box  </span> : ""
                                                }

                                                {
                                                    location.state.HaveDataCable ? <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}> Data Cable  </span> : ""
                                                }

                                                {
                                                    location.state.HaveAdapter ? <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}> Adapter </span> : ""
                                                }
                                                {
                                                    location.state.HaveEarphones ? <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}> Earphones </span> : ""
                                                }
                                                {
                                                    location.state.HaveBill ? <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}> Original Bill </span> : ""
                                                }
                                                {warrantyPeriod && (
                                                    <div>
                                                        {warrantyPeriod === "1" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: Below 1 Month
                                                            </span>
                                                        )}
                                                        {warrantyPeriod === "3" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: 1 To 3 Months
                                                            </span>
                                                        )}
                                                        {warrantyPeriod === "6" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: 3 To 6 Months
                                                            </span>
                                                        )}
                                                        {warrantyPeriod === "9" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: 6 To 9 Months
                                                            </span>
                                                        )}
                                                        {warrantyPeriod === "12" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: 9 To 12 Months
                                                            </span>
                                                        )}
                                                        {warrantyPeriod === "default" && (
                                                            <span style={{ color: "rgb(64, 167, 55)", marginRight: "1rem" }}>
                                                                Warranty Period: Above 1 Year
                                                            </span>
                                                        )}
                                                    </div>
                                                )}

                                            </div>
                                            <div style={{ fontWeight: "bold", marginTop: "1rem" }}>  Purchased place : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.PurchasedPlace} </span></div>
                                            <div style={{ fontWeight: "bold" }}>  Additional Insurance : <span style={{ color: "rgb(64, 167, 55)" }}>{location.state.AdditionalInsurance} </span> </div>

                                        </Col>
                                    </Row>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6} className="d-flex align-items-stretch">
                            <Card style={{ width: "100%" }}>
                                <Card.Body  >
                                    <Card.Title style={{ fontWeight: "bold" }} > Price Summary </Card.Title>
                                    <Card.Title style={{ marginTop: "4rem", fontWeight: "bold" }} className="sell-price" > Final Selling Price <span className="price-val" style={{ color: "rgb(64, 167, 55)" }}> Rs. {basePrice} </span> </Card.Title>
                                    <Card.Footer style={{ marginTop: "3rem" }} >
                                        <Button variant="warning" type="submit" onClick={handleShow} style={{ borderRadius: '0px', width: 150, color: '#fff', fontWeight: 'bold', fontSize: '20px', backgroundColor: "#40a737", border: "0px", borderRadius: '10px' }}>
                                            Sell Phone
                                        </Button>
                                    </Card.Footer>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>}
            {/* {showAddress && <AddAddress showAddress={showAddress} setShowAddress={setShowAddress} />} */}

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered size="lg">
                <Modal.Header closeButton className="fw-bold">
                    Mandatory Note
                </Modal.Header>
                <Modal.Body>
                    <p>
                        <ul>
                            <li><strong>If you are located outside of Bangalore, Bhubaneswar, Kolkata, or Mumbai</strong>, please download the form and fill it out with all the necessary details. Attach the required documents and hand them over along with the device.</li>
                            <li><strong>If you are located in Bangalore, Bhubaneswar, Kolkata, or Mumbai</strong>, our executive will bring the form along with them. You can fill it up at the time of handing over the device.</li>
                            <li><strong>The form is mandatory</strong> along with the device.</li>
                        </ul>

                    </p>

                    <Button type="submit" variant="danger" className='mx-auto d-block' onClick={() => handleDownload()}  > Download PDF</Button>

                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="success" className='' onClick={() => { handleSubmit() }}> Sell Phone</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={show2} onHide={handleClose2} backdrop="static" keyboard={false} centered size="lg">
                <Userlogin isPurchacing={true} handleClose2={handleClose2} />
            </Modal>


        </>
    )
}

export default ProductPrice