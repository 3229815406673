import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, CircularProgress } from "@mui/material";
import useGetDefaultAddress from "../../hooks/addAddressMobile/useGetDefaultAddress";
import AddressMobile from "./chekoutAddress/AddressMobile";
import CustomTypography from "../../../../muicomponents/shared/CustomTypography";
import useGetAllAddress from "../../hooks/addAddress/useGetAllAddress";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { selectedAddressIdState } from "../../../../store/atoms/addAddress/addAddressAtoms";
import useAddAddressId from "../../hooks/addAddress/useAddAddressId";
import useGetCartItems from "../../hooks/addToCart/useGetCartItems";
import useCobmbineAdrsCart from "../../hooks/addAddress/useCobmbineAdrsCart";
import { cartPageBackdrop } from "../../../../store/atoms/addToCart/addToCart";
import MuiBackdrop from "../../../../muicomponents/shared/MuiBackdrop";

const AddAddressMobile = () => {
  const navigate = useNavigate();
  const setBackdropOpen = useSetRecoilState(cartPageBackdrop);
  const {
    cartData,
    allAddress,
    addressCount,
    loading,
    error,
    cartDataRefetch,
    addressRefetch,
  } = useCobmbineAdrsCart();
  const userId = localStorage.getItem("userId");
  const selectedAddressId = useRecoilValue(selectedAddressIdState);
  const [selectedAddressMobile, setSelectedAddressMobile] = useState(null);
  const addAddressIdToCart = useAddAddressId();
  const handleNavigate = () => {
    addAddressIdToCart();
    navigate("/checkout/payment");
  };

  useEffect(() => {
    setBackdropOpen(loading);
  }, [loading]);

  useEffect(() => {
    cartDataRefetch();
    addressRefetch();
  }, []);

  useEffect(() => {
    if (
      cartData &&
      cartData.items
        .filter((item) => item.isCheckedForCheckout === true)
        .some((item) => item.product.quantity === 0)
    ) {
      navigate("/checkout/cart");
    }
  }, [cartData]);

  useEffect(() => {
    if (addressCount === 0) {
      navigate("/checkout/address/add", { replace: true });
    } else if (allAddress.length > 0) {
      const address = allAddress.find(
        (address) => selectedAddressId === address.id
      );
      setSelectedAddressMobile(address);
    }
  }, [addressCount, allAddress]);

  if (error) return <p>Error: {error.message}</p>;

  return (
    <>
      <MuiBackdrop />
      {!loading && (
        <Box>
          <Box>
            {selectedAddressMobile !== null ? (
              <>
                <AddressMobile selectedAddress={selectedAddressMobile} />
              </>
            ) : (
              <p>No default address found.</p>
            )}
            <Box
              width={"100%"}
              sx={{
                position: "fixed",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: "white",
                padding: "1rem",
                zIndex: 1000,
              }}
            >
              <Button
                onClick={() => handleNavigate()}
                variant="outlined"
                sx={{
                  width: "100%",
                  color: "white",
                  borderRadius: "0.2rem",
                  padding: "0.5rem",
                  backgroundColor: "rgb(6, 155, 170)",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "none",
                    backgroundColor: "rgb(6, 155, 170)",
                  },
                }}
              >
                <CustomTypography
                  variant="subtitle1"
                  sx={{ fontWeight: "600" }}
                >
                  Continue
                </CustomTypography>
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AddAddressMobile;
