import { Button, Col, Container, Form, Image, Modal, Row, Spinner, Table, Dropdown, Card } from 'react-bootstrap'
import { QUERY_GET_All_SELLING_ORDERS } from '../../graphql/Query'
import { QUERY_GET_ALL_USER } from '../../graphql/Query'
import { MUTATION_GET_USER } from '../../graphql/Mutations'
import { useMutation, useQuery } from '@apollo/client';
import { MUTATION_UPDATE_SELLORDER_STATUS } from '../../graphql/Mutations'
import { FaEye, FaPen } from 'react-icons/fa'
import React, { useState } from 'react'

const AllSellingOrders = () => {
    const { data: sellOrdersData, loading: sellOrderLoading } = useQuery(QUERY_GET_All_SELLING_ORDERS);

    const { data: AllUserData, loading: Alluserloading } = useQuery(QUERY_GET_ALL_USER);
    let count = 1
    console.table(sellOrdersData?.getSellOrders);

    const [updateSellOrderStatusById] = useMutation(MUTATION_UPDATE_SELLORDER_STATUS, {
        refetchQueries: [
            QUERY_GET_All_SELLING_ORDERS
        ]
    })

    const [getUser] = useMutation(MUTATION_GET_USER);

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);


    const [orserStatus, setOrderStatus] = useState("");
    const [selectedOrder, setSelectedOrder] = useState("")
    const handleClose = () => setShow(false);
    const handleClose2 = () => {
        setSelectedOrder("")
        setShow2(false)
    }
    const [orderID, setOrderID] = useState("")

    const handleStatusUpdate = (id) => {
        setShow(true);
        setOrderID(id)
    }

    const handleSubmit = () => {
        if (orderID && orserStatus) {

            updateSellOrderStatusById({

                variables: {

                    "orderId": `${orderID}`,
                    "status": `${orserStatus}`,
                }
            })


        }
        setOrderStatus("")
        setShow(false)
    }
    const handleOrderDetail = (order) => {
        setSelectedOrder(order)
        setShow2(true)
    }
    ///      ========================= modal   

    const [show3, setShow3] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");
    const handleUserDetails = (id) => {

        const data = AllUserData?.getAllUser?.filter((user) => { return user.id === id })
        setSelectedUser(data[0]);
        setShow3(true);

    }
    const handleClose3 = () => {
        setShow3(false);
        setSelectedOrder("")
    }

    return (
        <>
            <h1 style={{ fontSize: 20, textAlign: 'center', fontWeight: 'bold', marginTop: 20 }}>Selling Orders</h1>
            <Table striped bordered style={{ marginTop: 10 }}>
                <thead className='table-head'>
                    <tr>
                        <th>s.no.</th>
                        <th>Product Category</th>
                        <th>Product Brand</th>
                        <th>Product Name</th>
                        <th>Status</th>
                        <th>Price </th>
                        <th>Order Details</th>
                        <th>User Details</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sellOrderLoading ?
                            <Spinner animation="border" variant="success" className='mx-auto d-block' />
                            :
                            sellOrdersData && sellOrdersData.getSellOrders.slice(0).reverse().map(data =>
                                <tr className='table-data'>
                                    <td>{count++}</td>
                                    <td> {data.productCategory} </td>
                                    <td> {data.productBrand}</td>
                                    <td> {data.productName}</td>
                                    <td> {data.status} <span style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => handleStatusUpdate(data.id)}> <FaPen /> </span></td>
                                    <td>Rs. {data?.sellingAmount} </td>
                                    <td style={{ cursor: "pointer" }} onClick={() => handleOrderDetail(data)}  >  < FaEye /> </td>
                                    <td className='mx-auto d-block' style={{ cursor: "pointer" }} onClick={() => handleUserDetails(data.userId)} >  <FaEye /> </td>
                                </tr>
                            )
                    }
                </tbody>
            </Table>

            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Product Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            Product Status
                        </Dropdown.Toggle>
                        {orserStatus}
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setOrderStatus("Waiting for Pickup")}>Waiting for Pickup</Dropdown.Item>
                            <Dropdown.Item onClick={() => setOrderStatus("Picked up")}>Picked up</Dropdown.Item>
                            <Dropdown.Item onClick={() => setOrderStatus("In transit")}>In transit</Dropdown.Item>
                            <Dropdown.Item onClick={() => setOrderStatus("Product received at the destination")}>Product received at the destination.</Dropdown.Item>
                            <Dropdown.Item onClick={() => setOrderStatus("Ongoing QC")}>Ongoing QC</Dropdown.Item>
                            <Dropdown.Item onClick={() => setOrderStatus("Payment initiated")}>Payment initiated</Dropdown.Item>
                            <Dropdown.Item onClick={() => setOrderStatus("Completed")} >Completed</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={show2} onHide={handleClose2} size="lg" >
                <Modal.Header closeButton>
                    <Modal.Title>Order Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive striped bordered hover>

                        <thead className='table-head'>

                            {
                                selectedOrder.wantToPickupYourself == "true" ?
                                    <>
                                        <tr>
                                            <th colspan="2">User Want To Drop Your Item In Our Office .</th>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                Yes
                                            </td>
                                        </tr>
                                    </> :
                                    <>
                                        <tr>
                                            <th colspan="2">Pickup Date</th>
                                            <th colspan="2">Pickup Time</th>
                                        </tr>
                                        <tr>
                                            <td colspan="2">
                                                {selectedOrder?.pickupDate != "false" ? selectedOrder?.pickupDate : "Not Available"}
                                            </td>
                                            <td colspan="2">
                                                {selectedOrder?.pickupTime != "undefined" ? selectedOrder?.pickupTime : "Not Available"}
                                            </td>
                                        </tr>
                                    </>

                            }


                            <tr>
                                <th colspan="6" style={{ textAlign: 'center' }}>  Storage And RAM </th>

                            </tr>
                            <tr>
                                <th> Ram </th>
                                <th> Storage </th>
                            </tr>
                            <tr>
                                <td>
                                    {selectedOrder?.ram}
                                </td>
                                <td>
                                    {selectedOrder?.storage}
                                </td>
                            </tr>
                            <tr>
                                <th colspan="6" style={{ textAlign: 'center' }}>  Payment Details </th>

                            </tr>
                            <tr>
                                <th >
                                    Payment Method
                                </th>
                                <th>
                                    Bank Name
                                </th>
                                <th>
                                    Account Holder Name
                                </th>
                                <th>
                                    Account No
                                </th>
                                <th>
                                    Bank Ifsc
                                </th>
                                <th>
                                    UPI Id
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    {selectedOrder?.paymentMethod}
                                </td>
                                <td>
                                    {selectedOrder?.bankName}
                                </td>
                                <td>
                                    {selectedOrder?.bankAccountHolderName}
                                </td>
                                <td>
                                    {selectedOrder?.bankAccountNo}
                                </td>
                                <td>
                                    {selectedOrder?.bankIfsc}
                                </td>
                                <td>
                                    {selectedOrder?.upiId}
                                </td>
                            </tr>
                            <tr>
                                <th colspan="6" style={{ textAlign: 'center' }}>  Tell us about the Device Condition </th>

                            </tr>
                            {/* <tr>
                                <th>
                                  {selectedOrder?.details[0]?.que}
                                </th>
                                <td>
                                {selectedOrder?.details[0]?.ans}
                                </td>
                            </tr> */}

                            {/*
                            <tr>
                                <th>
                                  {selectedOrder?.details[1]?.que}
                                </th>
                                <td>
                                {selectedOrder?.details[1]?.ans}
                                </td>
                            </tr> */}

                            {
                                selectedOrder?.details?.map((detail) => {
                                    let answer = detail.ans;
                                    if (answer === "undefined") {
                                        answer = "Not Available"
                                    } else if (answer == "false" || answer == false) {
                                        answer = "Not Available"
                                    } else if (answer == "true") {
                                        answer = "Available"
                                    } else {

                                        if (detail.que === "4. Tell us about your gadget’s screen?") {
                                            if (detail.ans === "change1") {
                                                answer = "Changed (Compatible)";
                                            } else if (detail.ans === "change2") {
                                                answer = "Changed but Original (Replacement Bill Required)";
                                            }
                                        }
                                        if (detail.que === "5. Is your device ever repaired?") {
                                            if (detail.ans === "yes1") {
                                                answer = "Yes (Repaired in Authorised Service Centre - Job Sheet Required"
                                            }
                                            else if (detail.ans === "yes2") {
                                                answer = "Yes (Repaired in Unauthorised Centre"
                                            }

                                        }
                                        if (detail.que === "4. Is your device ever repaired?") {
                                            if (detail.ans === "yes1") {
                                                answer = "Yes (Repaired in Authorised Service Centre - Job Sheet Required"
                                            }
                                            else if (detail.ans === "yes2") {
                                                answer = "Yes (Repaired in Unauthorised Centre"
                                            }

                                        }
                                        if (detail.que === "condition of your device screen	") {
                                            if (detail.ans === "minor") {
                                                answer = "Minor/least visible scratches (Less than 3)";
                                            } else if (detail.ans === "major") {
                                                answer = "Major/More Visible Scratches (More Than 3)";
                                            } else if (detail.ans === "no") {
                                                answer = "No Scratches";
                                            }
                                        }
                                        if (detail.que === "condition of your device Body") {
                                            if (detail.ans === "excellent") {
                                                answer = "Excellent Condition (No visible sign of usage)";
                                            } else if (detail.ans === "good") {
                                                answer = "Good Condition (Minor visible scratches, Minor discoloration";
                                            } else if (detail.ans === "average") {
                                                answer = "Average Condition (Major visible scratches, Minor dent, Major discoloration)";
                                            } else if (detail.ans === "poor") {
                                                answer = "Poor Condition (Major visible scratches, Major discoloration, Cracks, Bend, Gap between display and body, Camera glass broken)";
                                            }
                                        }
                                        if (detail.que === "Warranty Period") {
                                            if (detail.ans === "1") {
                                                answer = "Below 1 Month";
                                            } else if (detail.ans === "3") {
                                                answer = "1 To 3 Months";
                                            } else if (detail.ans === "6") {
                                                answer = "3 To 6 Months";
                                            }
                                            else if (detail.ans === "9") {
                                                answer = "6 To 9 Months";
                                            }
                                            else if (detail.ans === "12") {
                                                answer = "9 To 12 Months";
                                            }
                                            else if (detail.ans === "default") {
                                                answer = "Above 1 year";
                                            }
                                        }
                                    }
                                    return (
                                        <tr>
                                            <th colspan="4">
                                                {detail.que}
                                            </th>
                                            <td colspan="4" style={{ marginLeft: "1rem", color: "green" }}>
                                                {answer}
                                            </td>
                                        </tr>
                                    );
                                })

                            }

                            <h3 className='mt-3' style={{ textAlign: 'center' }}> Address Details </h3>
                            <tr>
                                <th colspan="1">
                                    User Name :
                                </th>
                                <td colspan="5">
                                    {selectedOrder?.addressName}
                                </td>
                            </tr>
                            <tr>
                                <th colspan="1">
                                    City :
                                </th>
                                <td colspan="5">
                                    {selectedOrder?.addressCity}
                                </td>
                            </tr>
                            <tr>
                                <th colspan="1">
                                    Contact :
                                </th>
                                <td colspan="5">
                                    {selectedOrder?.addressContact}
                                </td>
                            </tr>
                            <tr>
                                <th colspan="1">
                                    Email :
                                </th>
                                <td colspan="5">
                                    {selectedOrder?.addressEmail}
                                </td>
                            </tr>
                            <tr>
                                <th colspan="1">
                                    Flat :
                                </th>
                                <td colspan="5">
                                    {selectedOrder?.addressFlat}
                                </td>
                            </tr>
                            <tr>
                                <th colspan="1">
                                    Landmark :
                                </th>
                                <td colspan="5">
                                    {selectedOrder?.addressLandmark !== "undefined" ? selectedOrder?.addressLandmark : "Not Available"}
                                </td>
                            </tr>
                            <tr>
                                <th colspan="1">
                                    Pincode :
                                </th>
                                <td colspan="5">
                                    {selectedOrder?.addressPincode}
                                </td>
                            </tr>
                            <tr>
                                <th colspan="1">
                                    State :
                                </th>
                                <td colspan="5">
                                    {selectedOrder?.addressState}
                                </td>
                            </tr>
                            <tr>
                                <th colspan="1">
                                    Address Type :
                                </th>
                                <td colspan="5">
                                    {selectedOrder?.addressType}
                                </td>
                            </tr>
                            <tr>
                                <th colspan="1">
                                    Alternative Contact :
                                </th>
                                <td colspan="5">
                                    {selectedOrder?.addressAlternatContact !== "undefined" ? selectedOrder?.addressAlternatContact : "Not Available"}
                                </td>
                            </tr>

                        </thead>

                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
            <Modal show={show3} onHide={handleClose3}>
                <Modal.Header closeButton>
                    <Modal.Title>UserDetails</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <Card.Body>

                            <Card.Text>
                                First Name :   {selectedUser?.fName}
                            </Card.Text>
                            <Card.Text>
                                Last Name :   {selectedUser?.lName}
                            </Card.Text>
                            <div style={{ marginLeft: "12px" }} >
                                Email :   {selectedUser?.email}
                            </div>
                            <Card.Text>
                                Contact :   {selectedUser?.contact}
                            </Card.Text>
                            <Card.Text>
                                Status :   {selectedUser?.status}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose3}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>


        </>
    )
}
export default AllSellingOrders
