import React from 'react'
import { Container, Tab, Tabs } from 'react-bootstrap'
import UserOrders from './UserOrders'
import UserBuyOrder from './userBuyOrder'

export default function AllOrders() {
    return (
        <>
            <Container fluid className='mt-3' style={{ padding: "0px" }}>
                <h4 style={{ fontSize: 20, fontWeight: 600, textDecoration: 'underline' }}>All Orders :-</h4>
                <Tabs
                    defaultActiveKey="Buy"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="Buy" de title="Buy">
                        {/* <UserOrders /> */}
                        <UserBuyOrder />
                    </Tab>
                    <Tab eventKey="Sell" title="Sell">
                        <UserOrders />
                    </Tab>
                    {/* <Tab eventKey="Exchange" title="Exchange">
                  
                    </Tab> */}
                </Tabs>
            </Container>

        </>
    )
}
