import { Box } from "@mui/material";
import React from "react";
import TitleSubtitle from "../shared/TitleSubtitle";
import HomePageContainer from "../shared/HomePageContainer";
import SellingGuideCards from "./SellingGuideCards";

const SellingGuide = () => {
  return (
    <>
      <HomePageContainer sx={{ margin: { xs: "0 0.5rem", laptop: 0 } }}>
        <TitleSubtitle
          title={
            "Don't worry if we do not have the option of Instant Payment at your pincode."
          }
          subtitle={
            "You can still Sell your Device with us in a few simple steps."
          }
        />
        <SellingGuideCards />
      </HomePageContainer>
    </>
  );
};

export default SellingGuide;
