import React, { useEffect } from "react";
import Footer from "../Footer";
import Navigation from "../Navigation";
// import "../../ComponentsCss/PrivacyPolicy.css"; // Ensure this CSS file exists for styling

const PrivacyPolicy = () => {
    useEffect(() => { 
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navigation />
            <div className="term-container">
                <div className="privacy-content px-3" style={{ textAlign: "justify" }}>
                    <h1>Welcome to HelloFi</h1>
                    <p>Welcome to hellofi.in, referred here after as “HelloFi”, “hellofi.in”, “service”, ”us”, “we”, “company”, “the company”.
                        HelloFi Recommerce is a reverse commercial space where we deal in pre-owned IT devices (laptops, mobile phones, tablets, smartwatches). We understand you lay your trust when sharing your information with us and are committed to making sure that the information is collected and stored safely. The privacy policy will give you all the necessary information related to the collection, storage, usage and protection of your data. Please go through the privacy policy and continue on the website only on your agreement with the understated. By using the website, you understand and agree to our privacy policy norms.</p>

                    <h2>A. Information Collected</h2>
                    <h3>1) Personal:</h3>
                    <p>We may collect information related to you including but not limited to name, age, gender, phone number, email ID, shipping and billing address, at the time of creating the account, registering and while making a purchase through the company, in case you reached us through reference we may collect information regarding the reference in the form of promotional code or other form of referral by the company.</p>
                    <p>Providing certain information will be optional, and opting out from doing so will cause limitations in the services that can be provided by us to you.</p>

                    <h3>2) Payments:</h3>
                    <p>We may collect information relevant to making payments like the medium of payment, billing address, and card details to process transactions securely, the information collected is at all times encrypted and stored securely. However, you will have the option to save certain information like card details and you may opt-out from doing so, but the basic information like the card service provider will be retained by the company and the information will be used strictly in accordance with our information usage policy. By entering the required information you agree to the privacy policy.</p>

                    <h3>3) Device and Usage Information:</h3>
                    <p>The company may collect information regarding the device used to access the service and complete the transaction(s). The information collected includes but is not limited to IP address, model of the device, brand, operating system, geographic location, time spent on the website and browser. You will be prompted with the request to share your location on your device and you may proceed as per your comfort. Such information helps us enhance your experience with the service.</p>

                    <h3>4) Cookies:</h3>
                    <p>Cookies are small files with limited data that are sent to your browser from a website and stored on your device. The website will request your permission for cookies, you will have the option to accept all or de-select the ones you do not wish to keep, however, some cookies are essential requirements of the service and cannot be deselected. These help us to enhance your experience on our website by collecting information regarding your activity on the website.</p>

                    <h2>B. Usage of Information</h2>
                    <p>The personal information collected by us is used to process orders, identify the availability of payment options, provide customer support, for marketing purposes, recommend products, and other activities that help us improve the customer experience with the service and a hassle-free transaction procedure.</p>
                    <p>The company may hire a third party for the execution of certain tasks and they may store your data which is relevant as per their service agreement, these companies use different mediums to collect information including cookies, web beacons, and pixels. The companies are however prohibited from disclosing the information or using the information for any purpose extending beyond the service agreements. Third-party analytical services may be hired by the company to monitor and analyze user information relevant to our service. The company may use your information to advertise to you on third-party website(s), by providing advertisement(s) based on your activity on our website. By using the website, you agree to such third-party involvement.</p>

                    <h2>C. Data Regulations</h2>
                    <p>The company does not collect data from people under the age of 18 please refer to the terms and conditions for further details on company policy regarding the age of the user. Individuals have the right to access, correct or delete their information with the company at any given time during their association with the service and can mail contact@hellofi.in for the same, A verification question will be asked to move ahead with the process.</p>
                    <p>Data security regulations are implemented as per the industry standards and government regulations; however, we cannot guarantee 100% security under circumstances that can lie beyond our control. The company still pledges to protect your data in a commercially acceptable way. Personal data will remain with the company as long as the user does not demand otherwise and the law permits. Data may be disclosed to third parties under the conditions of business transactions, vendors and related parties for services that are in a service agreement with the company, legal proceedings to exercise our legal rights, lawful requests by public authorities and professional advisors that will help protect the company’s interest. The user has the right to request the restriction of sharing all/selected information.</p>

                    <p>The company will update you regarding any updates and/or changes in the privacy policy by the preferred medium of communication selected by you.</p>
                    <p>We recommend you go through the privacy policy. By proceeding ahead with your association with the service you understand and agree to the privacy policy of the company, please refrain from using in case of non-agreement.</p>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PrivacyPolicy;
