import React, { useState } from 'react';
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap';
import swal from 'sweetalert';
import {GET_ALL_TESTIMONIAL} from '../../graphql/Query';
import {MUTATION_CREATE_TESTIMONIAL} from '../../graphql/Mutations';
import {MUTATION_DELETE_TESTIMONIAL} from '../../graphql/Mutations';
import { useMutation, useQuery } from '@apollo/client'

const AddTestimonials = () => {
    const [testimonialList, setTestimonialList] = useState([]);
    const [testimonial, setTestimonial] = useState({ name: '', position: '', description: '' });
    const { data: getAllTestimonial, loading: testimonialLoading } = useQuery(GET_ALL_TESTIMONIAL);
    const [createTestimonial, { loading: createLoading }] = useMutation(MUTATION_CREATE_TESTIMONIAL, {
        refetchQueries: [ GET_ALL_TESTIMONIAL ]
    });
    const [deleteTestimonial, { loading: dltLoading }] = useMutation(MUTATION_DELETE_TESTIMONIAL, {
        refetchQueries: [ GET_ALL_TESTIMONIAL ]
    });

    const handleInputChange = (e) => {
        setTestimonial({ ...testimonial, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!testimonial.name || !testimonial.position || !testimonial.description) {
            swal("Error", "Please fill all the fields", "error");
            return;
        }
        createTestimonial({
            variables: {
                "testimonialInput": {
                    "name": testimonial.name,
                    "position": testimonial.position,
                    "description": testimonial.description
                }
            }
        }).then(() => {
            swal({
                title: "Success",
                text: "Added successfully",
                icon: "success",
            });
            setTestimonial({ name: '', position: '', description: '' });
        }).catch((e) => alert(e));


    };

    let count = 1;

    return (
        <>
            <Container style={{ padding: 20 }} fluid>
                {/* Form in the middle */}
                <Row className="justify-content-md-center">
                    <Col md={6}>
                        <div style={{ padding: "1rem" }} className='box-shadow'>
                            <h3 style={{ textAlign: 'center' }}>Add Testimonial Here</h3>
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group as={Col} controlId="formName">
                                    <Form.Control
                                        required
                                        type="text"
                                        className='txtinpt'
                                        placeholder="Name"
                                        name="name"
                                        onChange={handleInputChange}
                                        value={testimonial.name}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formPosition">
                                    <Form.Control
                                        required
                                        type="text"
                                        className='txtinpt'
                                        placeholder="Position"
                                        name="position"
                                        onChange={handleInputChange}
                                        value={testimonial.position}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formDescription">
                                    <Form.Control
                                        required
                                        as="textarea"
                                        className='txtinpt'
                                        placeholder="Description"
                                        name="description"
                                        onChange={handleInputChange}
                                        value={testimonial.description}
                                    />
                                </Form.Group>
                                <Button type='submit' variant='dark' className='mt-3'>Add Testimonial</Button>
                            </Form>
                        </div>
                    </Col>
                </Row>

                {/* Table in the next line */}
                <Row>
                    <Col>
                        <Table responsive striped bordered hover className="mt-4">
                            <thead className='table-head'>
                                <tr>
                                    <th>S.no.</th>
                                    <th>Name</th>
                                    <th>Position</th>
                                    <th style={{width:"50%"}}>Description</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    testimonialLoading ? <tr><td colSpan="4" className="text-center">Loading...</td></tr>
                                    
                                    : 
                                    getAllTestimonial && getAllTestimonial.getAllTestimonial.map((item, index) => (
                                    <tr key={index}>
                                        <td>{count++}</td>
                                        <td>{item.name}</td>
                                        <td>{item.position}</td>
                                        <td>{item.description}</td>
                                        <td>
                                            <Button variant="danger" onClick={() => {
                                                deleteTestimonial({ 
                                                    variables: {
                                                        "testimonialId": item.id
                                                    }
                                                }).then(() => {
                                                    swal({
                                                        title: "Success",
                                                        text: "Deleted successfully",
                                                        icon: "success",
                                                    });
                                                }).catch((e) => alert(e));
                                            }}>Delete</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default AddTestimonials;
