import React, { useEffect } from "react";
import useGetCartItems from "../addToCart/useGetCartItems";
import useGetAddressById from "./useGetAddressById";

const useCombineCartSelectedAddress = () => {
  const userId = localStorage.getItem("userId");
  const {
    cartData,
    loading: cartLoading,
    error: cartError,
    refetch: refetchCartItems,
  } = useGetCartItems(userId);

  const addressId = cartData?.address;
  const {
    selectedAddressState,
    loading: addressLoading,
    error: addressError,
    refetch: refetchAddress,
  } = useGetAddressById(addressId);

  useEffect(() => {
    refetchCartItems();
  }, []);

  useEffect(() => {
    if (addressId) {
      refetchAddress();
    }
  }, [addressId]);

  const loading = cartLoading || addressLoading;
  const error = addressError || cartError;

  return {
    cartData,
    selectedAddressState,
    loading,
    error,
    refetchCartItems,
    refetchAddress,
  };
};

export default useCombineCartSelectedAddress;
