import { QUERY_GET_QUESTIONS } from '../../graphql/Query'
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import { Table, Spinner, Modal, Button, Form } from 'react-bootstrap'
import { FaPen } from 'react-icons/fa'
import { MUTATION_UPDATE_isDeviceSwitchPer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_ableToMakeReceiveCallPer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_issueWithDeviceDisplayPer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_deviceScreenPer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_deviceEverRepairPer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_FrontCameraIssuePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_SpeakerIssuePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_BackCameraIssuePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_MicIssuePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_RingerIssuePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_CharginPortIssuePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_SensorIssuePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_BatteryIssuePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_ButtonIssuePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_WifiIssuePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_BluetoothIssuePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_FlashLightIssuePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_VibratorIssuePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_AutoRestartIssuePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_EarphonePortIssuePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_SimCardIssuePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_GpsIssuePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_ConditionDeviceScreenPer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_ConditionDeviceBodyPer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_HaveBoxWithIMEIPer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_HaveDataCablePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_HaveAdapterPer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_HaveEarphonesPer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_AdditionalInsurancePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_PurchasedPlacePer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_WarrantyPeriodPer } from '../../graphql/Mutations'
import { MUTATION_UPDATE_QUESTION } from '../../graphql/Mutations'





const AddQuestionariesPer = () => {

    const [updateEachQuestion] = useMutation(MUTATION_UPDATE_QUESTION, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateisDeviceSwitchPer] = useMutation(MUTATION_UPDATE_isDeviceSwitchPer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateableToMakeReceiveCallPer] = useMutation(MUTATION_UPDATE_ableToMakeReceiveCallPer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateissueWithDeviceDisplayPer] = useMutation(MUTATION_UPDATE_issueWithDeviceDisplayPer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updatedeviceScreenPer] = useMutation(MUTATION_UPDATE_deviceScreenPer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updatedeviceEverRepairPer] = useMutation(MUTATION_UPDATE_deviceEverRepairPer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateFrontCameraIssuePer] = useMutation(MUTATION_UPDATE_FrontCameraIssuePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateBackCameraIssuePer] = useMutation(MUTATION_UPDATE_BackCameraIssuePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateSpeakerIssuePer] = useMutation(MUTATION_UPDATE_SpeakerIssuePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateMicIssuePer] = useMutation(MUTATION_UPDATE_MicIssuePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateRingerIssuePer] = useMutation(MUTATION_UPDATE_RingerIssuePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateCharginPortIssuePer] = useMutation(MUTATION_UPDATE_CharginPortIssuePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateSensorIssuePer] = useMutation(MUTATION_UPDATE_SensorIssuePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateBatteryIssuePer] = useMutation(MUTATION_UPDATE_BatteryIssuePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateButtonIssuePer] = useMutation(MUTATION_UPDATE_ButtonIssuePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateWifiIssuePer] = useMutation(MUTATION_UPDATE_WifiIssuePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateBluetoothIssuePer] = useMutation(MUTATION_UPDATE_BluetoothIssuePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateFlashLightIssuePer] = useMutation(MUTATION_UPDATE_FlashLightIssuePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateVibratorIssuePer] = useMutation(MUTATION_UPDATE_VibratorIssuePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateAutoRestartIssuePer] = useMutation(MUTATION_UPDATE_AutoRestartIssuePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateEarphonePortIssuePer] = useMutation(MUTATION_UPDATE_EarphonePortIssuePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateSimCardIssuePer] = useMutation(MUTATION_UPDATE_SimCardIssuePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateGpsIssuePer] = useMutation(MUTATION_UPDATE_GpsIssuePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateConditionDeviceScreenPer] = useMutation(MUTATION_UPDATE_ConditionDeviceScreenPer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })

    const [updateConditionDeviceBodyPer] = useMutation(MUTATION_UPDATE_ConditionDeviceBodyPer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    //           ==========================
    const [updateHaveBoxWithIMEIPer] = useMutation(MUTATION_UPDATE_HaveBoxWithIMEIPer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateHaveDataCablePer] = useMutation(MUTATION_UPDATE_HaveDataCablePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateHaveAdapterPer] = useMutation(MUTATION_UPDATE_HaveAdapterPer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateHaveEarphonesPer] = useMutation(MUTATION_UPDATE_HaveEarphonesPer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateAdditionalInsurancePer] = useMutation(MUTATION_UPDATE_AdditionalInsurancePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updatePurchasedPlacePer] = useMutation(MUTATION_UPDATE_PurchasedPlacePer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })
    const [updateWarrantyPeriodPer] = useMutation(MUTATION_UPDATE_WarrantyPeriodPer, {
        refetchQueries: [
            QUERY_GET_QUESTIONS
        ]
    })

    const { data: getQuestions, loading: questionLoading } = useQuery(QUERY_GET_QUESTIONS);
    // const [editQuestion, setEditQuestion] = useState("");
    const [editPercentage, setEditPercentage] = useState("");
    const [questionNameState, setQuestionNameState] = useState("");


    const [showEditModal, setEditModal] = useState(false)

    const handleClickEdit = (que) => {
        setEditModal(true)
    }
    const handleEdit1 = () => {

        if (questionNameState === "isDeviceSwitchPer") {
            updateisDeviceSwitchPer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ableToMakeReceiveCallPer") {
            updateableToMakeReceiveCallPer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "issueWithDeviceDisplayPer") {
            updateissueWithDeviceDisplayPer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenPer") {
            updatedeviceScreenPer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceEverRepairPer") {
            updatedeviceEverRepairPer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "FrontCameraIssuePer") {
            updateFrontCameraIssuePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "BackCameraIssuePer") {
            updateBackCameraIssuePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "SpeakerIssuePer") {
            updateSpeakerIssuePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "MicIssuePer") {
            updateMicIssuePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "RingerIssuePer") {
            updateRingerIssuePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "CharginPortIssuePer") {
            updateCharginPortIssuePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "SensorIssuePer") {
            updateSensorIssuePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "BatteryIssuePer") {
            updateBatteryIssuePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ButtonIssuePer") {
            updateButtonIssuePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "WifiIssuePer") {
            updateWifiIssuePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "BluetoothIssuePer") {
            updateBluetoothIssuePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "FlashLightIssuePer") {
            updateFlashLightIssuePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "VibratorIssuePer") {
            updateVibratorIssuePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "AutoRestartIssuePer") {
            updateAutoRestartIssuePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "EarphonePortIssuePer") {
            updateEarphonePortIssuePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "SimCardIssuePer") {
            updateSimCardIssuePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "GpsIssuePer") {
            updateGpsIssuePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
      
        else if (questionNameState === "ConditionDeviceScreenPer") {
            updateConditionDeviceScreenPer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ConditionDeviceBodyPer") {
            updateConditionDeviceBodyPer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveBoxWithIMEIPer") {
            updateHaveBoxWithIMEIPer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveDataCablePer") {
            updateHaveDataCablePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveAdapterPer") {
            updateHaveAdapterPer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveEarphonesPer") {
            updateHaveEarphonesPer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "AdditionalInsurancePer") {
            updateAdditionalInsurancePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "PurchasedPlacePer") {
            updatePurchasedPlacePer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "WarrantyPeriodPer") {
            updateWarrantyPeriodPer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveBoxWithIMEIPer") {
            updateHaveBoxWithIMEIPer({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage16gb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage32gb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage64gb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage128gb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage256gb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })

            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage1tb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "storage2tb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram2gb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram3gb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram4gb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram6gb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram8gb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram12gb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram16gb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram32gb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram18gb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram24gb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram64gb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "ram128gb") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenOriginPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenOriginalChangedPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenOriginPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceRepairAuthCenterPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceRepairUnAuthCenterPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceRepairNoPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceConditionMinorPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceConditionMajorPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceConditionNoScratchPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceBodyConditionExcellentPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceBodyConditionGoodPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceBodyConditionAveragePer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceBodyConditionPoorPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "addInsAppleCarePer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "addInsSamsungCarePer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "addInsThirdPartyPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "addInsNotApplicablePer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "purchasePlaceIndiaPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "purchasePlaceAbroadPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billBelowOneMonthPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billOneToThreeMonthPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billThreeToSixMonthPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billSixToNineMonthPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billNineToTwelveMonthPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "billAboveOneYear") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "deviceScreenChangesPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "HaveBillPer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "FingerPrintIssuePer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }
        else if (questionNameState === "FaceSensorIssuePer") {
            updateEachQuestion({
                variables: {
                    id: `${getQuestions?.getQuestions[0].id}`,
                    question: `${questionNameState}`,
                    questionPer: `${editPercentage}`
                }
            })
            setQuestionName("")
            setEditPercentage("")
            setEditModal(false)
        }


    }

    function setQuestionName(qName) {
        setQuestionNameState(qName)
        handleClickEdit()
    }


    return (
        <>
            {
                questionLoading ?
                    <Spinner animation="border" variant="success" className='mx-auto d-block' />
                    :

                    <>
                        <Table responsive striped bordered hover>
                            <thead className='table-head table-head-question' >
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>Tell us about the Device Condition</th>
                                </tr>
                                <tr>
                                    <th>Questions</th>
                                    <th>Percentage deduction</th>
                                    <th>Edit Percentage</th>
                                </tr>
                                <tr>
                                    <td>Device Swithing</td>
                                    <td>{getQuestions?.getQuestions[0]?.isDeviceSwitchPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("isDeviceSwitchPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Call Received </td>
                                    <td>{getQuestions?.getQuestions[0]?.ableToMakeReceiveCallPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ableToMakeReceiveCallPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Display Issue </td>
                                    <td>{getQuestions?.getQuestions[0]?.issueWithDeviceDisplayPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("issueWithDeviceDisplayPer")}><FaPen /></i> </td>
                                </tr>
                                <tr>
                                    <td>Device Screen Isuue (original) </td>
                                    <td>{getQuestions?.getQuestions[0]?.deviceScreenOriginPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceScreenOriginPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Screen Changed (Compatible) </td>
                                    <td>{getQuestions?.getQuestions[0]?.deviceScreenChangesPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceScreenChangesPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Screen Changed with Original Bill </td>
                                    <td>{getQuestions?.getQuestions[0]?.deviceScreenOriginalChangedPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceScreenOriginalChangedPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Repaired in Authorised Service Center</td>
                                    <td>{getQuestions?.getQuestions[0]?.deviceRepairAuthCenterPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceRepairAuthCenterPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Device Repaired in UnAuthorised Service Center</td>
                                    <td>{getQuestions?.getQuestions[0]?.deviceRepairUnAuthCenterPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceRepairUnAuthCenterPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> No Device Repaired </td>
                                    <td>{getQuestions?.getQuestions[0]?.deviceRepairNoPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceRepairNoPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>Want to know the exact value of your device?</th>
                                </tr>

                                <tr>
                                    <td>Front Camera Isuue</td>
                                    <td>{getQuestions?.getQuestions[0]?.FrontCameraIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("FrontCameraIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Back Camera Isuue</td>
                                    <td>{getQuestions?.getQuestions[0]?.BackCameraIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("BackCameraIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Speaker Issue</td>
                                    <td>{getQuestions?.getQuestions[0]?.SpeakerIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("SpeakerIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Mic Issue</td>
                                    <td>{getQuestions?.getQuestions[0]?.MicIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("MicIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Ringer Issue</td>
                                    <td>{getQuestions?.getQuestions[0]?.RingerIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("RingerIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Charging Port Issue</td>
                                    <td>{getQuestions?.getQuestions[0]?.CharginPortIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("CharginPortIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Senser Issue</td>
                                    <td>{getQuestions?.getQuestions[0]?.SensorIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("SensorIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Battery Issue</td>
                                    <td>{getQuestions?.getQuestions[0]?.BatteryIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("BatteryIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Button Issue</td>
                                    <td>{getQuestions?.getQuestions[0]?.ButtonIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ButtonIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Wiffi Issue</td>
                                    <td>{getQuestions?.getQuestions[0]?.WifiIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("WifiIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Bluetooth Isuue</td>
                                    <td>{getQuestions?.getQuestions[0]?.BluetoothIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("BluetoothIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Flash Light Issue</td>
                                    <td>{getQuestions?.getQuestions[0]?.FlashLightIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("FlashLightIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Vibrator Isuue</td>
                                    <td>{getQuestions?.getQuestions[0]?.VibratorIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("VibratorIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Auto Restart Issue</td>
                                    <td>{getQuestions?.getQuestions[0]?.AutoRestartIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("AutoRestartIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Ear Phone Port Issue</td>
                                    <td>{getQuestions?.getQuestions[0]?.EarphonePortIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("EarphonePortIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Sim Card Issue</td>
                                    <td>{getQuestions?.getQuestions[0]?.SimCardIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("SimCardIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>GPS Isuue</td>
                                    <td>{getQuestions?.getQuestions[0]?.GpsIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("GpsIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Finger Print Issue</td>
                                    <td>{getQuestions?.getQuestions[0]?.FingerPrintIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("FingerPrintIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Face Sensor Issue</td>
                                    <td>{getQuestions?.getQuestions[0]?.FaceSensorIssuePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("FaceSensorIssuePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>Tell us about the cosmetic/physical condition of your device</th>
                                </tr>
                                <tr>
                                    <td>Condition of Device Screen (Minor)</td>
                                    <td>{getQuestions?.getQuestions[0]?.deviceConditionMinorPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceConditionMinorPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Screen (Major)</td>
                                    <td>{getQuestions?.getQuestions[0]?.deviceConditionMajorPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceConditionMajorPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Screen (No Scratches)</td>
                                    <td>{getQuestions?.getQuestions[0]?.deviceConditionNoScratchPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceConditionNoScratchPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Body (Excellent)</td>
                                    <td>{getQuestions?.getQuestions[0]?.deviceBodyConditionExcellentPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceBodyConditionExcellentPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Body (Good)</td>
                                    <td>{getQuestions?.getQuestions[0]?.deviceBodyConditionGoodPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceBodyConditionGoodPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Body (Average)</td>
                                    <td>{getQuestions?.getQuestions[0]?.deviceBodyConditionAveragePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceBodyConditionAveragePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Condition of Device Body (Poor)</td>
                                    <td>{getQuestions?.getQuestions[0]?.deviceBodyConditionPoorPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("deviceBodyConditionPoorPer")}><FaPen /></i></td>
                                </tr>

                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>  Select the Original Accessories available with your Device.</th>
                                </tr>
                                <tr>
                                    <td>   Box (with same IMEI/Serial number)</td>
                                    <td>{getQuestions?.getQuestions[0]?.HaveBoxWithIMEIPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveBoxWithIMEIPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Data cable</td>
                                    <td>{getQuestions?.getQuestions[0]?.HaveDataCablePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveDataCablePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Adapter</td>
                                    <td>{getQuestions?.getQuestions[0]?.HaveAdapterPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveAdapterPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Earphones</td>
                                    <td>{getQuestions?.getQuestions[0]?.HaveEarphonesPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveEarphonesPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td>Have Bill </td>
                                    <td>{getQuestions?.getQuestions[0]?.HaveBillPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("HaveBillPer")}><FaPen /></i></td>
                                </tr>

                                <tr>
                                    <td> Insurance AppleCare </td>
                                    <td>{getQuestions?.getQuestions[0]?.addInsAppleCarePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("addInsAppleCarePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Insurance SamsungCare </td>
                                    <td>{getQuestions?.getQuestions[0]?.addInsSamsungCarePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("addInsSamsungCarePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Insurance 3rd Prty </td>
                                    <td>{getQuestions?.getQuestions[0]?.addInsThirdPartyPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("addInsThirdPartyPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Insurance Not Applicable </td>
                                    <td>{getQuestions?.getQuestions[0]?.addInsNotApplicablePer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("addInsNotApplicablePer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Purchased Place (India) </td>
                                    <td>{getQuestions?.getQuestions[0]?.purchasePlaceIndiaPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("purchasePlaceIndiaPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> Purchased Place (Abroad) </td>
                                    <td>{getQuestions?.getQuestions[0]?.purchasePlaceAbroadPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("purchasePlaceAbroadPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (below 1 month) </td>
                                    <td>{getQuestions?.getQuestions[0]?.billBelowOneMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billBelowOneMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (1 to 3 month) </td>
                                    <td>{getQuestions?.getQuestions[0]?.billOneToThreeMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billOneToThreeMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (3 to 6 month)</td>
                                    <td>{getQuestions?.getQuestions[0]?.billThreeToSixMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billThreeToSixMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (6 to 9 month)</td>
                                    <td>{getQuestions?.getQuestions[0]?.billSixToNineMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billSixToNineMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period (9to 12 month) </td>
                                    <td>{getQuestions?.getQuestions[0]?.billNineToTwelveMonthPer}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billNineToTwelveMonthPer")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> warranty Period ( Above 1 year) </td>
                                    <td>{getQuestions?.getQuestions[0]?.billAboveOneYear}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("billAboveOneYear")}><FaPen /></i></td>
                                </tr>

                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>  Storage Price </th>
                                </tr>
                                <tr>
                                    <td> 16 GB</td>
                                    <td>{getQuestions?.getQuestions[0]?.storage16gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage16gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 32 GB</td>
                                    <td>{getQuestions?.getQuestions[0]?.storage32gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage32gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 64 GB</td>
                                    <td>{getQuestions?.getQuestions[0]?.storage64gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage64gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 128 GB</td>
                                    <td>{getQuestions?.getQuestions[0]?.storage128gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage128gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 256 GB</td>
                                    <td>{getQuestions?.getQuestions[0]?.storage256gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage256gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 1 TB</td>
                                    <td>{getQuestions?.getQuestions[0]?.storage1tb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage1tb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 2 TB</td>
                                    <td>{getQuestions?.getQuestions[0]?.storage2tb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("storage2tb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <th colspan="3" style={{ textAlign: 'center' }}>  Ram Price</th>
                                </tr>
                                <tr>
                                    <td> 2 GB</td>
                                    <td>{getQuestions?.getQuestions[0]?.ram2gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram2gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 3 GB</td>
                                    <td>{getQuestions?.getQuestions[0]?.ram3gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram3gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 4 GB</td>
                                    <td>{getQuestions?.getQuestions[0]?.ram4gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram4gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 6 GB</td>
                                    <td>{getQuestions?.getQuestions[0]?.ram6gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram6gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 8 GB</td>
                                    <td>{getQuestions?.getQuestions[0]?.ram8gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram8gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 12 GB</td>
                                    <td>{getQuestions?.getQuestions[0]?.ram12gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram12gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 16 GB</td>
                                    <td>{getQuestions?.getQuestions[0]?.ram16gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram16gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 32 GB</td>
                                    <td>{getQuestions?.getQuestions[0]?.ram32gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram32gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 18 GB</td>
                                    <td>{getQuestions?.getQuestions[0]?.ram18gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram18gb")}><FaPen /></i></td>
                                </tr>
                                <tr>
                                    <td> 24 GB</td>
                                    <td>{getQuestions?.getQuestions[0]?.ram24gb}</td>
                                    <td><i className='bx bx-book-alt' onClick={() => setQuestionName("ram24gb")}><FaPen /></i></td>
                                </tr>


                            </thead>
                        </Table>
                    </>
            }
            {

            }
            <Modal show={showEditModal} onHide={() => setEditModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Percentage</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="number" placeholder="Enter value" onChange={(e) => setEditPercentage(e.target.value)} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => { handleEdit1(); }}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default AddQuestionariesPer