import { Box } from "@mui/material";
import React from "react";
import ProductDevliveryDates from "./ProductDevliveryDates";
import ProductCondition from "./ProductCondition";
import COD from "./COD";
import ProductWarranty from "./ProductWarranty";

const ProductFullfilment = ({ condition, warranty, inStock }) => {
  // console.log(warranty);
  return (
    <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
      <ProductDevliveryDates inStock={inStock} />
      <ProductCondition condition={condition} />
      <ProductWarranty warranty={warranty} />
      <COD />
    </Box>
  );
};

export default ProductFullfilment;
