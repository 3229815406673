// TermAndCondition.js
import React, { useEffect } from "react";
import Footer from "../Footer";
import Navigation from "../Navigation";
import "../../ComponentsCss/TermAndCondition.css"; // Add a CSS file for styling if needed

const TermAndCondition = () => {
  useEffect(() => { 
    window.scrollTo(0, 0)
}, [])
  return (
    <>

      <Navigation />
      <div className="term-container">
        <div className="term-content px-3" style={{ textAlign: "justify" }}>
          <h1>Welcome to HelloFi</h1>
          <p>Welcome to hellofi.in, referred here after as “HelloFi”, “hellofi.in”, “service”, ”us”, “we”, “company”, “the company”.</p>
          <p>
            HelloFi Recommerce is a reverse commercial space where we deal in pre-owned
            IT devices (laptop, mobile phones, tablets, smartwatch). The terms and
            conditions specified here govern your usage of our website, including but
            not limiting to all the services and products offered by HelloFi. By
            accessing and using our website, you agree to comply with and be bound by the
            following terms and conditions. Reading the agreement carefully before using
            our services, the website is recommended. These terms of service (“Terms”,
            “Terms of service”) govern your use of our website located at hellofi.in
            (together or individually “service”).
          </p>
          <p>
            Our privacy policy also governs your use of our service and explains how we
            collect, use, safeguard and disclose information that results from your use
            of web pages.
          </p>
          <p>
            Your agreement with us includes these terms and our privacy policy
            (“agreements”). You acknowledge that you have read and understood the
            agreements and agree to be bound by them.
          </p>
          <p>
            In case you do not agree (or cannot comply with) agreements, then you may
            refrain from using the service. You may mail to us under such situations at{" "}
            <a href="mailto:contact@hellofi.in" className="text-primary text-decoration-underline">
              contact@hellofi.in
            </a>{" "}
            and we may look into it and try to find a solution. These terms apply to all
            the visitors, users, and others who wish to access or use the service.
          </p>
          <div>
            <ol className="list-unstyled">
              <li>
                <strong>Acceptance of terms:</strong>
              </li>
            </ol>
          </div>
          <p className="ml-3">
            By using hellofi.in, you acknowledge that you have read, understood, and
            agree to these Terms and Conditions, our Privacy Policy, and any other
            applicable policies or guidelines. In case of disagreement, kindly refrain
            from using the service.
          </p>
          <ol className="list-unstyled">
            <li>
              <strong>User Eligibility:&nbsp;</strong>
            </li>
          </ol>
          <ul className="list-disc ml-4">
            <li>
              You are a resident of India and Residing in India at the time of usage of
              the service. You are above the age of 18; if you are under 18, then you
              have the consent of a parent or guardian to use the website of HelloFi Recommerce.
            </li>
            <li>
              Users under the age of 18 are prohibited from access and usage of the service.
              You are legally competent as per the Indian Contract Act, 1872, in full
              authority, right, and in capacity to enter into contracts.
            </li>
          </ul>
          <ol className="list-unstyled">
            <li>
              <strong>User Accounts:&nbsp;</strong>
            </li>
          </ol>
          <ol className="list-unstyled">
            <li>
              <strong>
                <span className="fs-4">User requirements:&nbsp;</span>
              </strong>
            </li>
          </ol>

          <ul className="list-disc ml-4">
            <li>
              You must be the legal owner of the product sent by you to us and that all
              the rights, interests, and titles in the product vest in you. You agree
              that you have/had not transferred or assigned the same (any rights, title,
              or interest in the product) to any third person.
            </li>
            <li>
              You agree that the ownership of the product(s) will pass on to us once we
              receive the product and confirm that the product matches the make, model,
              other information, and terms and conditions of the sell order and the
              payment will be made to you as per the preferred payment option selected
              by you.
            </li>
          </ul>

          <p className="mb-0">
            &nbsp;
          </p>

          <div className="mb-4">
            <ol className="list-unstyled">
              <li>
                <strong>
                  <span className="fs-4">Product requirement:&nbsp;</span>
                </strong>
              </li>
            </ol>
          </div>

          <p className="mb-0">
            <strong>
              <span className="fs-4">&nbsp;</span>
            </strong>
          </p>

          <p className="mb-0">
            <strong className="fs-4">SELL ORDER&nbsp;</strong>
          </p>

          <p className="mb-0">
            &nbsp;
          </p>

          <ul className="list-disc ml-4">
            <li>
              You agree that actions undertaken by you with the service are under your
              sole discretion and without any pressure or influence from
              anyone/anything.
            </li>
            {/* ... (Continue optimizing and adding Bootstrap classes for the rest of the code) */}
          </ul>

          <ol start={1} className="list-unstyled list-alpha ml-6">
            <li>
              The product(s) will be deemed working under the
              fulfilment of the following conditions:
            </li>
            {/* ... (Continue optimizing and adding Bootstrap classes for the rest of the code) */}
          </ol>

          <p className="mt-2">
            You agree to ensure that any airtime contract linked to your product(s)
            shall be cancelled by you before handing over the product to us/or delivery
            associates. The company shall not be responsible for any costs arising from
            the failure to comply with the stated requirement(s) and/or call costs arising
            before or after the receipt of your product or arising from any other
            circumstances whatsoever.
          </p>

          <p>
            You agree that all actions undertaken by you with the service are on your sole
            discretion and without any pressure influence.
          </p>

          <div className="mb-4">
            <ul className="list-disc ml-6">
              <li>
                You agree and understand, that the company will try to provide accurate
                up-to-date information about the product(s) but do not guarantee such
                accuracy or completeness; Prices and availability of the product(s) are
                subject to change without prior notice and we shall not be held
                responsible for any typographical errors or inaccuracies.
              </li>
            </ul>
          </div>

          <p className="mb-0">
            &nbsp;
          </p>

          <div className="mb-4">
            <ol className="list-disc">
              <li>
                <strong>
                  <span className="fs-4">Process:</span>
                </strong>
                :
              </li>
            </ol>
          </div>

          <p className="mb-0">
            &nbsp;
          </p>

          <p className="mb-0">
            <strong className="fs-4">SELL ORDER</strong>
          </p>

          <p className="mb-0">
            <strong>&nbsp;</strong>
          </p>

          <div className="mb-4">
            <ol start={1} className="list-alpha ml-6">
              <li>
                <strong>Registration :</strong>
              </li>
            </ol>
          </div>

          <p className="mt-2">
            You need to register with the service before placing a sell order by filling
            the form with accurate and complete information, up to your knowledge
            without any misinterpretation/statements with the potential for
            misinterpretation.
          </p>

          <ol start={2} className="list-alpha ml-6">
            <li>
              You agree to abide by the Terms and conditions of the sell order and other
              parts of the service by placing the sell order.
            </li>
            <li>
              <strong>Product values</strong>:
            </li>
          </ol>

          <ul className="list-disc ml-8">
            <li>
              You agree that the product value shall be decided by HelloFi Recommerce
              and is subject to change without any prior information/notice.
            </li>
            <li>
              You understand and agree that the price quoted is guaranteed for 7 days
              from the date of order, products received post 7 days from the packaging
              received date shall apply the current(up to date) price.
            </li>
          </ul>

          <ol start={4} className="list-alpha ml-6">
            <li>
              <strong>Adjusted price/returns</strong>:
            </li>
          </ol>

          <ul className="list-disc ml-8">
            <li>
              You understand and agree that the adjusted price will be proposed for the
              products not matching the make, model, and condition as updated while
              placing the sell order. After the mutual agreement of the price, the
              product shall not be returned to you. In case of it being declined by you,
              the product will be returned to you within 7 working days of the
              information of the said. If you fail to reply to the information,
              intimation email of the stated within 7 days of it being sent to you, the
              order will be automatically be processed for payment. If acceptation of
              the revised quotation by you is not received by us, we will close the
              transaction.
            </li>
            <li>You agree to pay the logistics and quality check charges to us.</li>
          </ul>
          <ol start={5} className="list-unstyled list-alpha ml-6">
            <li>
              <strong>Payments</strong> :
            </li>
          </ol>

          <ul className="list-disc ml-8">
            <li>
              You agree and understand that all payments will be received by you via
              direct online bank, payment through cheque can be processed only when
              direct transfer is not possible. The company shall not be held responsible
              for any loss arising due to incorrect information of payment details.
            </li>
            <li>
              If you initiate the sell order from outside Bangalore, the payment shall be
              initiated to you after the product is received and the conditions verified
              within 3-4 working days of receipt of the product.
            </li>
            <li>
              If the sell order is initiated from within Bangalore or places where we
              offer Spot payment, the payment shall be initiated on the spot after
              verifying the conditions of the product(s) physically; if the seller is
              reachable within the proximate geographical area where we offer on
              spot payment.
            </li>
          </ul>

          <ol start={6} className="list-unstyled list-alpha ml-6">
            <li>
              <strong>Indemnity</strong> :
            </li>
          </ol>

          <ul className="list-disc ml-8">
            <li>
              You understand that we are not responsible for any losses/claims/damages,
              including but not limited to the product ownership, stored data, etc.
            </li>
            <li>
              You agree that the company can terminate the contract at any time in case
              of any arising claims against us without prior notice. Any stored data
              will be erased, and we cannot be held responsible for any losses
              arising from the same. We claim no responsibility for any indirect,
              special, incidental, consequential, or punitive damages/loss, and
              have liability limited to the determined value of the product.
            </li>
            <li>
              You agree and understand the Company is not responsible for any delays or
              failure in the performance of any obligation in case of external events,
              including but not limited to compliance with laws or “force majeure”
              (wars, flood, etc.).
            </li>
            <li>
              We can terminate the contract if “force majeure” continues beyond 5
              calendar days. The company can levy return charges up to 1000/- and
              penalty charges in case of fake/duplicate sale or sale order.
            </li>
          </ul>

          <p className="mb-0">
            &nbsp;
          </p>

          <div className="mb-4">
            <ol start={7} className="list-unstyled list-alpha ml-6">
              <li>
                <strong>Packaging and shipping</strong> :
              </li>
            </ol>
          </div>

          <p className="mb-0">
            &nbsp;
          </p>

          <p>
            <strong>
              For customers initiating sales order form outside Bangalore and/or
              geographical areas where HelloFi does not offer spot cash:
            </strong>
          </p>

          <p className="mb-0">
            &nbsp;
          </p>

          <ul className="list-disc ml-8">
            <li>
              You understand and agree that packaging is your responsibility, and we
              shall claim no liability for damage in shipment. You are also recommended
              to keep a video proof of the packaging. You understand that the content of
              the package must include all the accessories provided along with the
              product(s), a discrepancy regarding the stated can lead to the order being
              subject to being modified/accepted/canceled by us, with the company not
              being answerable/responsible for the said.
            </li>
            <li>
              Certain products might be available for free pickup by the courier
              partner, if they fall under a location qualified for such services.
            </li>
            <li>
              Two attempts will be made to collect the product from you. If you are not
              available at the time provided/agreed by you, expenses shall be deducted
              from the sell order for the same. If, in case, the product falls under the
              requirement for manual shipping or label, the expenses arising from the
              conduct of the same will be deducted from the sale order. We provide 90
              days to resolve any dispute arising in the same.
            </li>
            <li>
              You understand that in case of damage in transit the company's liability is
              limited to the offered value of the sale order or 3000 rupees whichever is
              lesser, that is applicable only after the completion of 90 days from the
              date of shipment.
            </li>
            <li>We provide free shipment for only orders above rupees 1000.</li>
            <li>
              In case if the courier is of your choice, you agree to take responsibility
              for the package and the cost arising from the same and the package reaching
              us on our specified address. We shall claim no responsibility for any
              loss/damage/dispute arising from the stated.
            </li>
          </ul>

          <p className="mb-0">
            &nbsp;
          </p>

          <p>
            <strong>For customers qualifying for spot cash by HelloFi:</strong>
          </p>

          <p className="mb-0">
            &nbsp;
          </p>

          <ul className="list-disc ml-8">
            <li>
              You shall have a face-to-face deal with our representative and you agree for
              the same.
            </li>
            <li>
              Any communication regarding the collection of packages by our delivery
              associate will be communicated to you beforehand by us. If the package(s)
              is handed over by you to any individual/people claiming to be from HelloFi,
              without receiving any prior communication by us, you are solely
              responsible for initiating/participating in any such transaction(s).
            </li>
            <li>
              However, we suggest you to submit a claim with us on{" "}
              <span style={{ color: "#0563C1", textDecoration: "underline" }}>
                <strong>
                  <a href="mailto:contact@hellofi.in">contact@hellofi.in</a>
                </strong>
              </span>{" "}
              with us in the event of such happenings. You agree to abide by the
              company’s policy of document verification pre-sales.
            </li>
          </ul>

          <ol className="list-disc ml-8">
            <li>
              <strong>
                <span style={{ fontSize: 19, lineHeight: "107%" }}>Process:</span>
              </strong>
            </li>
          </ol>

          <p className="mb-0">
            <strong>
              <span style={{ fontSize: 19, lineHeight: "107%" }}>&nbsp;</span>
            </strong>
          </p>

          <p className="mt-0 mr-0 mb-1 ml-4 font-italic font-weight-bold">
            <strong>BUY ORDER</strong>
          </p>

          <p className="mt-0 mr-0 mb-1 ml-4">
            <strong>&nbsp;</strong>
          </p>

          <ol start={1} className="list-unstyled list-alpha ml-6">
            <li>
              <strong>General information/guidelines</strong>:
            </li>
          </ol>

          <ul className="list-disc ml-8">
            <li>
              Your login information is your personal property and you are solely
              responsible for it. We suggest refraining from sharing such confidential
              information with anyone. The stated information is not shared by us to any
              third party.
            </li>
            <li>
              Product images displayed on the website may slightly differ from the actual
              product for various reasons, including but not limited to lighting and
              angle.
            </li>
            <li>
              You shall provide accurate information about the product and the
              location for delivery true to your knowledge. Under the circumstance that
              the product fails to reach you/be received by you due to noncompliance of
              any of the following, you agree to take its entire responsibility and pay
              the shipping charges as determined by us.
            </li>
          </ul>

          <ol start={2} className="list-unstyled list-alpha ml-6">
            <li>
              <strong>Payment</strong>:
            </li>
          </ol>

          <p className="ml-8">
            All the payment gateway options provided by us are encrypted and
            protected in all ways fair and known to us. You understand that the
            availability of payment gateways and options may differ with respect to the
            provided location.
          </p>

          <p className="ml-8">
            If any transaction, be it a refund or while accepting online payments,
            involves any sort of Bank commission, the buyer will be charged the same in
            addition to the normal cost.
          </p>

          <ol start={3} className="list-unstyled list-alpha ml-6">
            <li>
              <strong>Purchase</strong>:
            </li>
          </ol>

          <ul className="list-disc ml-8">
            <li>
              You agree and understand that the service may request/ask you for purchase
              relevant information, including but not limited to card details, address.
            </li>
            <li>
              You agree that the entitlement of the payment gateway selected by you lies
              with you, including but not limiting to the legal right to use the credit,
              debit any other card payment selected by you.
            </li>
            <li>
              You understand that by submitting the information with us, you grant us the
              right to share the information(s) with third parties subject to our
              privacy policy.
            </li>
          </ul>

          <ol start={4} className="list-unstyled list-alpha ml-6">
            <li>
              <strong>Right to cancel/refusal</strong>:
            </li>
          </ol>

          <p className="ml-8">
            The company can cancel or refuse a request for service under the
            circumstances, including but not limited to errors in the description or the
            price of the product or service. You agree and understand that we may/shall
            cancel or refuse the request for service if there is a suspicion of any fraud or
            unauthorized or illegal transaction.
          </p>

          <div className="mb-4">
            <ol start={5} className="list-unstyled list-alpha ml-6">
              <li>
                <strong>Refunds</strong>:
              </li>
            </ol>
          </div>

          <p className="ml-8">
            The refund for the contracts will be initiated within 15 business days from
            the date of refund terms being satisfied and agreed upon by both the
            parties in interest.
          </p>

          <ol start={6} className="list-unstyled list-alpha ml-6">
            <li>
              <strong>Warranties:</strong>
            </li>
          </ol>

          <ul className="list-disc ml-8">
            <li>
              The company or the people associated with the company do not make warranty
              or representation of warranties of any kind, expressed or implied with
              respect to, including but not limiting to operations of their service or
              the information content or the material(s) included.
            </li>
            <li>
              However, this does not reflect for warranties that cannot be excluded or
              are under compulsion to be included or limited under any active law of
              India.
            </li>
          </ul>

          <ol start={7} className="list-unstyled list-alpha ml-6">
            <li>
              <strong>Limited liability:</strong>
            </li>
          </ol>

          <ul className="list-disc ml-8">
            <li>
              You understand that we are not liable for any direct or indirect or
              incidental or consequential damage that might result from your usage of
              the service or website. The company or the people associated with the
              company cannot be held liable for any loss arising from the agreement.
            </li>
            <li>
              The company has its liability to the amount for service and claims no
              liability for loss arising due to consequential or punitive damage.
            </li>
          </ul>

          <ol start={8} className="list-unstyled list-alpha ml-6">
            <li>
              <strong>Error reporting and feedback:</strong>
            </li>
          </ol>

          <ul className="list-disc ml-8">
            <li>
              You can inquire or assert any intellectual property right or any other
              right or title or interest in or to the feedback. However, the final
              authority to take any action lies with us, and you shall accept the
              decision of the company as it will be taken after taking into
              consideration the best interest of all the involved parties, including but
              not limiting to the contract/agreement.
            </li>
            <li>
              You understand that the feedback shall not contain any confidential
              information or proprietary information from you or any third party. Losses
              arising from the noncompliance of the same shall not lie as company
              liability.
            </li>
            <li>
              The company is under no obligation of confidentiality with respect to the
              feedback. In case the ownership transfer is not applicable, you agree to
              grant us the power to use the feedback in any manner or purpose we decide
              upon.
            </li>
          </ul>

          <p className="mt-0 mr-0 mb-1 ml-6">
            &nbsp;
          </p>


          <ol start={9} className="list-unstyled list-alpha ml-6">
            <li>
              <strong>Waiver and Severability:&nbsp;</strong>
            </li>
          </ol>

          <ul style={{ listStyleType: "disc", marginLeft: 62 }}>
            <li>
              The company will not provide any waiver in the terms and conditions under
              any circumstances. The failure to comply with any of the stated terms and
              conditions shall not be considered as a waiver.
            </li>
            <li>
              However, if under the circumstances the terms and conditions are
              identified or considered to be invalid due to jurisdiction, they will be
              eliminated or edited as per the requirement and understanding by us.
            </li>
          </ul>

          <ol start={10} className="list-unstyled list-alpha ml-6">
            <li>
              <strong>Packaging and Shipping:</strong>
            </li>
          </ol>

          <p className="ml-8">
            The packaging and shipping of the product(s) shall depend upon the specified
            details of the same which will be mentioned on the description page of each
            product. We recommend you go through it for further understanding.
          </p>

          <div className="mb-4">
            <ol className="list-unstyled list-disc">
              <li>
                <strong>Termination:&nbsp;</strong>
              </li>
            </ol>
          </div>

          <p className="ml-4 font-weight-bold">
            &nbsp;
          </p>
          <ul className="list-disc ml-8">
            <li>
              You understand and agree that the company or you may terminate the
              contract any time before pickup or receiving the package from you. There
              shall be no termination from either involved party once the package has
              been picked up by us/our delivery associate. However, the company
              withholds the right to terminate the contract under the conditions that
              any of the stated terms and conditions are not met, without prior
              knowledge of the customer or any other involved parties, and shall not be
              held answerable/responsible for the same.
            </li>
            <li>
              The contract/account can be terminated under the conditions including but
              not limiting to; use of service in a way that violates any national or
              international rules or regulations and exploitation or harming and attempt
              to exploitation or harm to the company, minors in any way by exposing
              them to inappropriate content or otherwise, transmit or procure the
              sending of any advertising or promotional material, including any junk
              mail, chain letter or spam or any other similar solicitation.
            </li>
            <li>
              You understand that termination can also take place under the events,
              impersonate the company or employee or any other user; infringes upon the
              rights of others or is illegal or threatening or fraudulent or harmful or
              found/suspected to be in connection with any unlawful, illegal or
              fraudulent or harmful purpose or activity. Conduct that restricts or
              inhibits anyone’s use of the service or may harm the company in any kind.
            </li>
            <li>
              The termination can also take place under the conditions of the use of a robot
              or any other automated devices to access services, monitor or copy
              material on service without prior written consent for unauthorized purposes,
              use of devices or software that interfere with the functioning,
              introduce any viruses or such malicious software(s), in suspicion or
              confirmed attack by denial or distributed denial of services, including
              but not limiting to actions that might/proven to damage company ratings,
              any other actions that the company may decide upon.
            </li>
          </ul>



          <ol className="list-unstyled list-disc mb-4">
            <li>
              <strong>Governing Laws:&nbsp;</strong>
            </li>
          </ol>

          <ul className="list-disc mb-4">
            <li>
              You understand and agree that any claims of breach of this or any other
              agreement so formed with the company within the company's knowledge shall be
              subject to the exclusive jurisdiction of the court(s) of
              Bangalore/Bengaluru, Karnataka, and all the contracts shall be
              constructed and interpreted in accordance with the laws of India,
              without regard to its conflict of law provisions.
            </li>
            <li>
              This further constitutes that the entire agreement between the involved
              parties regarding the service supersedes and/or replaces any prior
              agreement the service might have had.
            </li>
          </ul>



          <div className="mb-4">
            <ol className="list-unstyled list-disc">
              <li>
                <strong>Contract:&nbsp;</strong>
              </li>
            </ol>
          </div>



          <p className="ml-4">
            The user agrees that the contract between the involved parties will be
            concluded only upon receiving and confirming the product and payment
            initiation and approval by the company.
          </p>



          <div className="mb-4">
            <ol className="list-unstyled list-disc">
              <li>
                <strong>Intellectual Property and Copyright and Trademark:&nbsp;</strong>
              </li>
            </ol>
          </div>



          <ul className="list-disc ml-8">
            <li>
              All the content including but not limiting to text, software, videos,
              original photos, etc., displayed on the website is protected. The viewer/user
              shall not use or reproduce the content without the prior written approval of
              the company; violation/ignorance of such might lead to penalties civil
              and/or criminal. Contents which are not original may be under the license of
              exclusive use by HelloFi unless otherwise stated and cannot be used
              without the prior written consent from us.
            </li>
            <li>
              The company service and original content are exclusive property and are
              protected by copyright and other applicable intellectual property
              protection laws. This is excluding user-generated content displayed on the
              website and any other place that has the company presence.
            </li>
            <li>
              The trademarks cannot be used by anyone other than the company without
              the prior written consent by us.
            </li>
            <li>
              Under the circumstances that you are/claim to be the copyright owner or
              authorized by one of any content displayed on the website any other place of
              company presence and believe that the copyrighted work has been copied in
              a way that constitutes copyright infringement, we request you to submit any such
              claim or complaint to{" "}
              <span style={{ color: "#0563C1", textDecoration: "underline" }}>
                <strong>
                  <a href="mailto:contact@hellofi.in">contact@hellofi.in</a>
                </strong>
              </span>
              &nbsp; and the action on such will be taken on the company's discretion.
            </li>
            <li>
              The information available on the site and any other place of company
              presence is for general reference only and may not describe all the
              T&amp;C and exceptions applicable to the service. It may contain links to
              third party websites for information or other purposes, and the company does not
              guarantee the accuracy and authenticity of any such third party websites.
            </li>
            <li>
              In case of third party involvement in transactions unless otherwise
              specified, the company cannot control any aspect of these transactions and
              shall claim no responsibility for such sales and transactions.
            </li>
            <li>
              No warranties are provided by the company upon the involvement of any
              third party in any form unless otherwise specified.
            </li>
          </ul>

          <p className="ml-8 mb-8">
            &nbsp;
          </p>

          <p className="ml-8 mb-8">
            Please go ahead with the use of the service only if you understand and are
            willing to comply with the Terms and Conditions.
          </p>

          <p className="ml-8 mb-8">
            &nbsp;
          </p>



        </div>

      </div>
      <Footer />
    </>
  );
};

export default TermAndCondition;
