import React, { useEffect } from "react";
import { Box } from "@mui/material";
import useGetAllAddress from "../../hooks/addAddress/useGetAllAddress";
import AllAddress from "./allAddress/AllAddress";
import Form from "./addressForm/Form";

const AddressWrapper = ({ allAddress }) => {
  return (
    <Box>
      {allAddress && allAddress.length > 0 ? (
        <AllAddress addresses={allAddress} />
      ) : (
        <Form />
      )}
    </Box>
  );
};

export default AddressWrapper;
