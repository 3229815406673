import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, useMediaQuery, Button, useTheme } from "@mui/material";
import React from "react";
import RelatedProductCard from "./RelatedProductCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import Slider from "react-slick";

const CustomPrevArrow = (props) => (
  <Button
    onClick={props.onClick}
    sx={{
      width: "60px",
      height: "60px",
      position: "absolute",
      borderRadius: "50%",
      background: "rgb(6, 155, 170)",
      color: "white",
      left: "-30px",
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 1,
      minWidth: "auto",
      padding: "5px",
      "&:hover": {
        background: "rgba(255, 255, 255, 1)",
        color: "rgb(6, 155, 170)",
        border: "0.5px solid rgb(6, 155, 170)",
      },
    }}
  >
    <ArrowBack />
  </Button>
);

const CustomNextArrow = (props) => (
  <Button
    onClick={props.onClick}
    sx={{
      width: "60px",
      height: "60px",
      position: "absolute",
      borderRadius: "50%",
      background: "rgb(6, 155, 170)",
      color: "white",
      right: "-30px",
      top: "50%",
      transform: "translateY(-50%)",
      zIndex: 1,
      minWidth: "auto",
      padding: "5px",
      "&:hover": {
        background: "rgba(255, 255, 255, 1)",
        color: "rgb(6, 155, 170)",
        border: "0.5px solid rgb(6, 155, 170)",
      },
    }}
  >
    <ArrowForward />
  </Button>
);

const RelatedProductContainer = ({ products, length }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("tablet"));
  const isTabletScreen = useMediaQuery(
    theme.breakpoints.between("tablet", "700")
  );

  const settings = {
    dots: true,
    infinite: length >= 4 ? true : false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };
  const isSmallScreen = useMediaQuery("(max-width:1025px)");

  return (
    <Box sx={{ position: "relative" }}>
      {isSmallScreen ? (
        <Box
          display={"flex"}
          flexDirection="row"
          gap="2rem"
          sx={{
            width: "100%",
            // overflowX: "auto",
            // flexWrap: "nowrap",
            // scrollbarWidth: "none",
            // msOverflowStyle: "none",
            // "&::-webkit-scrollbar": {
            //   display: "none",
            // },
          }}
        >
          <Swiper
            slidesPerView={
              length <= 2
                ? length
                : isMobileScreen
                ? 1.5
                : isTabletScreen
                ? 2
                : 3
            }
            loop={true}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay]}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            {products.map((product, index) => (
              <SwiperSlide key={index} style={{ padding: "1rem" }}>
                <RelatedProductCard
                  key={product.id}
                  product={product}
                  isBuy={true}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      ) : (
        <Slider {...settings}>
          {products.map((product) => (
            <RelatedProductCard
              key={product.id}
              product={product}
              isBuy={true}
            />
          ))}
        </Slider>
      )}
    </Box>
  );
};

export default RelatedProductContainer;
