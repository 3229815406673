import React from "react";
import { Box, Card, CardContent, Avatar, Rating } from "@mui/material";
import { FormatQuote } from "@mui/icons-material";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const TestimonialCard = ({ review }) => {
  const starRatingMapping = {
    FIVE: 5,
    FOUR: 4,
    THREE: 3,
    TWO: 2,
    ONE: 1,
  };

  const reviewRating = starRatingMapping[review.starRating];
  return (
    <Card
      className="customer-testimonials-card"
      sx={{
        width: "100%",
        height: { xs: "300px" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        mx: "auto",
        p: 2,
        boxShadow: 3,
        borderRadius: "16px",
        background: "#FFFFFF",
        color: "black",
        position: "relative",
        overflow: "visible",
      }}
    >
      <Avatar
        sx={{
          width: "75px",
          height: "75px",
          bgcolor: "#D9D9D9",
          color: "#4caf50",
          position: "absolute",
          top: "-40px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 1,
        }}
      >
        <FormatQuote sx={{ color: "#214C7B", fontSize: "2rem" }} />
      </Avatar>

      <CardContent>
        <CustomTypography
          variant="body1"
          sx={{
            textAlign: "center",
            color: "#5E5C5C",
            mb: 2,
            fontSize: {
              xs: "0.875rem",
              desktop: "0.90rem",
            },
            fontWeight: "400",
            display: "-webkit-box",
            WebkitLineClamp: 7,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {review.comment}
        </CustomTypography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 0.5,
            mb: 2,
          }}
        >
          <Rating name="simple-controlled" value={reviewRating} readOnly />
        </Box>

        <CustomTypography
          variant="body2"
          sx={{
            textAlign: "center",
            color: "#214C7B",
            fontSize: {
              xs: "0.95rem",
              desktop: "1rem",
            },
            fontWeight: "600",
          }}
        >
          {review.displayName}
        </CustomTypography>
      </CardContent>
    </Card>
  );
};

export default TestimonialCard;
