import { Box } from "@mui/material";
import React, { useState } from "react";
import CustomTypography from "../../../../../muicomponents/shared/CustomTypography";

const Impression = ({ review }) => {
  const [playClicked, setPlayClicked] = useState(false);

  const handlePlayClick = () => {
    setPlayClicked(true);
  };

  return (
    <Box sx={{ position: "relative", width: "100%", height: "auto" }}>
      {/* Conditionally render the thumbnail or video iframe */}
      {!playClicked ? (
        <>
          <img
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "0.5rem",
              objectFit: "cover", // Ensures the image fills the container without distortion
            }}
            src={review.thumbnail}
            alt="Review Thumbnail"
          />

          {/* Play button */}
          <Box
            onClick={handlePlayClick}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "50px",
              height: "50px",
              backgroundImage:
                'url(\'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="red"/><path d="M45 24 27 14v20" fill="white"/></svg>\')',
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              cursor: "pointer",
              zIndex: 1,
            }}
          />

          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "80%",
              padding: "2rem",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                bottom: "10px",
                width: "100%",
                color: "black",
                backgroundColor: "rgba(255, 255, 255)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "0 10px",
                borderRadius: "0.5rem",
                zIndex: 1,
              }}
            >
              <CustomTypography
                variant={"subtitle2"}
                sx={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  WebkitLineClamp: 3,
                }}
              >
                {review.title}
              </CustomTypography>
            </Box>
          </Box>
        </>
      ) : (
        <iframe
          width="100%"
          height="256px" // Set height to 100% to make it fill the container
          src={`https://www.youtube.com/embed/${review.youtube_ID}?autoplay=1`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          style={{
            borderRadius: "0.5rem", // Make the video have rounded corners if desired
            objectFit: "cover", // Ensures the video fills the container properly
          }}
        />
      )}
    </Box>
  );
};

export default Impression;
