import { Box } from "@mui/material";
import React, { useEffect } from "react";
import NavbarCartMobile from "../../../../../layout/NavbarCartMobile";
import Form from "../../addAddress/addressForm/Form";
import useGetAllAddress from "../../../hooks/addAddress/useGetAllAddress";
import useGetCartItems from "../../../hooks/addToCart/useGetCartItems";
import { useNavigate } from "react-router-dom";

const AddNewAddress = () => {
  const userId = localStorage.getItem("userId");
  const { allAddress, loading, error, refetch } = useGetAllAddress(userId);
  const navigate = useNavigate();
  const isAddress = allAddress.length > 0 ? false : true;
  const {
    cartData,
    loading: cartDataLoading,
    error: cartDataError,
    refetch: cartDataRefetch,
  } = useGetCartItems(userId);
  useEffect(() => {
    cartDataRefetch();
    if (
      cartData &&
      cartData.items
        .filter((item) => item.isCheckedForCheckout === true)
        .some((item) => item.product.quantity === 0)
    ) {
      navigate("/checkout/cart");
    }
  }, [cartData, cartDataRefetch]);
  return (
    <>
      <NavbarCartMobile
        title={"add new Address"}
        isAddressMain={false}
        isAddAddressPage={true}
        navigateReturn={isAddress ? "/checkout/cart" : "/checkout/address/list"}
      />
      <Form isMobile={true} />
    </>
  );
};

export default AddNewAddress;
